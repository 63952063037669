import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import { Button, Checkbox, Spin } from "antd";
import * as svg from "../../constants/ui/svg";
import { Step, Step1 } from "./components";
import { Select } from "antd";
import { AiOutlineLeft } from "react-icons/ai";
import { useLocation } from "react-router-dom";

const StoreInformation = () => {
  const location = useLocation();
  const careers = [
    { value: 1, label: "HOST" },
    { value: 2, label: "キャバクラ" },
    { value: 3, label: "BAR" },
    { value: 4, label: "RESTAURANT" },
  ];
  const [steps, setSteps] = useState(0);
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState({
    shopName: "",
    careers: careers[0].value,
    // postCode: "",
    // phone: "",
    largeLocation: null,
    smallLocation: null,
  });
  useEffect(() => {
    if (location.state && location.state.token) {
      setToken(location.state.token);
      setEmail(location.state.email);
    }
  }, [location.state]);

  const _renderContent = () => {
    const arr = [
      <Step
        steps={steps}
        setSteps={setSteps}
        state={state}
        careers={careers}
        setState={setState}
      />,
      <Step1 steps={steps} setSteps={setSteps} token={token} stateSteps={state} email={email} />,
    ];
    return arr[steps];
  };
  return (
    <div style={{ minHeight: "100vh", background: "white" }}>
      {/* {steps != 0 ? (
        <div style={{ background: "white", paddingTop: "20px" }}>
          <button
            style={{
              marginLeft: "250px",
              color: "#666687 ",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            type="button"
            onClick={() => {
              if (steps == 0) {
                return;
              } else {
                setSteps(steps - 1);
              }
            }}
          >
            <AiOutlineLeft />
            <span style={{ margin: "6px" }}>Back</span>
          </button>
        </div>
      ) : null} */}

      <div className="login_v2-page bg-secondary">
        <div className="login_v2-content">
          <div className="login_v2-wrap">
            <div className="logo-web">
              {/* <img src={Logo} alt="bg" /> */}
              {svg.logoPosWeb}
            </div>
            <div className="bg-input mt-30">
              <div className="bg-button_register">{_renderContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StoreInformation;
