import React from "react";
import TextInput from "../../../helpers/TextInput";
import { activeCodeAPI } from "../../../apis/user";
import { failedToast } from "../../../helpers/AlertHelper";
import { useState } from "react";
import Loading from "../../../components/Loading";
import * as cts from "../../../constants/ui/general";
const Step2 = (props) => {
  const { loading, setLoading } = props;
  const [code, setCode] = useState("");
  const { setSteps, email } = props;

  const activeCode = async () => {
    setLoading(true);
    try {
      const res = await activeCodeAPI({ code_active: code, email });
      if (res.data.message == "error") {
        failedToast(null, "Invalid Passocde");
      }
      if (res.data.message == "success") {
        setSteps(2);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      failedToast(error, "that bai");
    }
  };
  return (
    <div className="bg-button_register mt-30">
      <div className="bg-content_register">
        <div className="mb-30">
          <h5>下記にメールアドレスを入力してください。</h5>
          <h5> メールアドレスは、後からでも変更可能です。</h5>
        </div>
        <span style={{ color: "#000000", fontFamily: "NotoSans", fontSize: "16px" }}>
          {cts.AUTHENTICATION_KEY}
        </span>
        <input
          type="text"
          style={{ fontSize: "16px" }}
          placeholder="認証キーを入力してください"
          className="input-register mt-15"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />

        <button type="button" className="mt-25" onClick={activeCode}>
          {loading ? <Loading style={{ color: "white" }} /> : " 確認"}
        </button>
      </div>
    </div>
  );
};
export default Step2;
