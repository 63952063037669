import moment from "moment";
import request from "../helpers/axiosService";

// const URL_GET_HOST_FOR_ORDER_LIST = "api/user-of-shop";
// const URL_HOST_LIST = "api/host/list";
const URL_RECEIVABLES = "host/receivables";
// const URL_SALES = "api/host/sales";
// const URL_SALARY_HOST = "api/host/salary-host";
// const URL_OTHER_MONEY = "api/host/other-money";

const URL_GET_HOST_FOR_ORDER_LIST = "user-of-shop";
const URL_SETTINGS_NOMINATION_HOST = "/setting-nomination-fee";
const URL_HOST_LIST = "/v2/host/list";
// const URL_RECEIVABLES = "bill-receivables";
const URL_SALES = "host/sales";
const URL_SALARY_HOST = "salary-setting-user";
const URL_OTHER_MONEY = "host/other-money";
const URL_SALARY_DETAIL = "salary";
const URL_SALARY_CALCULATION = "salary-calculate";
const URL_INVITE_STAFF = "invite-staff";

// HOST LIST
export function fetchHostListAPI(token, params) {
  return request({
    url: URL_HOST_LIST,
    method: "get",
    params,
    token,
  });
}

export function fetchHostForOrderListAPI(token, shopId) {
  return request({
    url: URL_GET_HOST_FOR_ORDER_LIST,
    method: "get",
    params: { shopId, per_page: 9999999999 },
    token,
  });
}
export function updateHostForOrderListAPI(token, id, data) {
  return request({
    url: `${URL_GET_HOST_FOR_ORDER_LIST}/${id}`,
    method: "put",
    data,
    token,
    // isFormData: true,
  });
}
export function updateSettingNominationHostAPI(token, data) {
  return request({
    url: URL_SETTINGS_NOMINATION_HOST,
    method: "post",
    data,
    token,
  });
}

export function fetchHostForOrderListApiByDate(token, shopId, date) {
  return request({
    url: URL_GET_HOST_FOR_ORDER_LIST,
    method: "get",
    params: { shopId, date, per_page: 1000 },
    token,
  });
}

// HOST RECEIVABLES
export function fetchHostReceivablesListAPI(token, params) {
  return request({
    url: URL_RECEIVABLES,
    method: "get",
    params,
    token,
  });
}

export function createHostReceivablesAPI(token, data) {
  return request({
    url: URL_RECEIVABLES,
    method: "post",
    data,
    token,
  });
}

export function updateHostReceivablesAPI(token, id, data) {
  return request({
    url: `${URL_RECEIVABLES}/${id}`,
    method: "put",
    data,
    token,
  });
}

export function deleteHostReceivablesAPI(token, id, shop_id) {
  return request({
    url: `${URL_RECEIVABLES}/${id}`,
    method: "delete",
    params: { shop_id },
    token,
  });
}

// HOST SALES
export function fetchHostSalesAPI(token, params) {
  return request({
    url: URL_SALES,
    method: "get",
    params,
    token,
  });
}

// HOST SALARY
export function fetchHostSalaryAPI(token, params) {
  return request({
    url: URL_SALARY_HOST,
    method: "get",
    params,
    token,
  });
}

export function updateHostSalaryAPI(token, data) {
  return request({
    url: URL_SALARY_HOST,
    method: "post",
    data,
    token,
  });
}

export function calculateSalaryAPI(token, params) {
  return request({
    url: URL_SALARY_CALCULATION,
    method: "get",
    params,
    token,
  });
}

// HOST SALARY DETAIL
export function fetchHostSalaryDetailAPI(token, id, params) {
  return request({
    url: `${URL_SALARY_DETAIL}/${id}`,
    method: "get",
    params,
    token,
  });
}

export function updateHostSalaryDetailAPI(token, data) {
  return request({
    url: URL_SALARY_DETAIL,
    method: "post",
    data,
    token,
  });
}

// HOST OTHER MONEY
export function fetchHostOtherMoneyListAPI(token, params) {
  return request({
    url: URL_OTHER_MONEY,
    method: "get",
    params: { ...params, per_page: 200, date: moment().format("YYYY-MM-DD") },
    token,
  });
}

export function createHostOtherMoneyAPI(token, data) {
  return request({
    url: URL_OTHER_MONEY,
    method: "post",
    data,
    token,
  });
}

export function updateHostOtherMoneyAPI(token, id, data) {
  return request({
    url: `${URL_OTHER_MONEY}/${id}`,
    method: "put",
    data,
    token,
  });
}

export function deleteHostOtherMoneyAPI(token, id, shop_id) {
  return request({
    url: `${URL_OTHER_MONEY}/${id}`,
    method: "delete",
    params: { shop_id },
    token,
  });
}
// invite staff
export function inviteStaffAPI(token, data) {
  return request({
    url: URL_INVITE_STAFF,
    method: "post",
    data,
    token,
  });
}
