import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as svg from "../../constants/ui/svg";
import Logo from "../../assets/images/logo.png";
import { Steps, Step1, Step2, Step3, StepsSuccess } from "./components";
import { AiOutlineLeft } from "react-icons/ai";
import { ArrowLeftOutlined } from "@ant-design/icons";
const RegisterPage = () => {
  const history = useHistory();
  const [steps, setSteps] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const _renderContent = () => {
    const arr = [
      <Step1
        steps={steps}
        setSteps={setSteps}
        setEmail={setEmail}
        email={email}
        loading={loading}
        setLoading={setLoading}
      />,
      <Step2 setSteps={setSteps} email={email} loading={loading} setLoading={setLoading} />,
      <Step3
        setSteps={setSteps}
        email={email}
        setIsSuccess={setIsSuccess}
        password={password}
        setPassword={setPassword}
        loading={loading}
        setLoading={setLoading}
      />,
    ];
    return arr[steps];
  };
  return (
    <div style={{ minHeight: "100vh", background: "white" }}>
      {/* {steps != 0 ? (
        <div style={{ background: "white", paddingTop: "20px" }}>
          <button
            style={{
              marginLeft: "250px",
              color: "#666687 ",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            type="button"
            onClick={() => {
              if (steps == 0) {
                return;
              } else {
                setSteps(steps - 1);
              }
            }}
          >
            <AiOutlineLeft />
            <span style={{ margin: "6px" }}>Back</span>
          </button>
        </div>
      ) : null} */}

      <div className="login_v2-page bg-secondary">
        <div className="login_v2-content">
          <div className="login_v2-wrap">
            <div className="logo-web">
              {/* <img src={Logo} alt="bg" /> */}
              {svg.logoPosWeb}
            </div>
            <div className="bg-input mt-30">
              <Steps steps={steps} />
              {isSuccess ? (
                <StepsSuccess
                  email={email}
                  password={password}
                  loading={loading}
                  setLoading={setLoading}
                />
              ) : (
                _renderContent()
              )}
              {steps != 2 || isSuccess ? (
                <div className="btn-register mt-30" style={{ padding: "0" }}>
                  <button
                    type="button"
                    className="btn-login_register "
                    onClick={() => {
                      if (steps == 0 || isSuccess) {
                        history.push("/");
                      }
                    }}
                  >
                    {isSuccess ? (
                      "ログインはこちら"
                    ) : steps == 0 ? (
                      "ログインはこちら"
                    ) : (
                      <div
                        onClick={() => {
                          if (steps == 0) {
                            return;
                          } else {
                            setSteps(steps - 1);
                          }
                        }}
                      >
                        <ArrowLeftOutlined style={{ marginRight: "15px" }} />
                        もう一度メールを送りたい
                      </div>
                    )}
                  </button>
                </div>
              ) : null}
            </div>
            {/* {steps != 2 || isSuccess ? (
              <div className="btn-register mt-30">
                <button
                  type="button"
                  className="btn-login_register "
                  onClick={() => {
                    if (steps == 0 || isSuccess) {
                      history.push("/");
                    }
                  }}
                >
                  {isSuccess
                    ? "ログインはこちら"
                    : steps == 0
                    ? "ログインはこちら"
                    : " もう一度メールを送りたい方はこちら"}
                </button>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegisterPage;
