import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { loginV2API } from "../../../apis/user";
import Loading from "../../../components/Loading";
import { toast } from "../../../helpers/AlertHelper";

import * as successCts from "../../../constants/ui/success";

const StepsSuccess = (props) => {
  const history = useHistory();
  const { email, password, loading, setLoading } = props;

  const onSuccess = async () => {
    setLoading(true);
    try {
      const res = await loginV2API({
        email,
        password,
        remember_me: true,
        role: 1,
      });
      if (res.data.access_token) {
        toast(successCts.WAIT_FOR_CONFIRMATION, "success", 5000);

        history.push({
          pathname: "/storeinformation",
          state: {
            token: res.data.access_token,
            email: res.data.user.email,
          },
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="bg-button_register mt-25">
      <div className="bg-content_register">
        <h5 style={{ fontSize: "16px", fontWeight: "500" }}>アカウントを作成しました。</h5>
        <button type="button" className="forgot mt-25" onClick={onSuccess}>
          {loading ? <Loading style={{ color: "white" }} /> : "店舗のアカウントを作成する"}
        </button>
      </div>
    </div>
  );
};
export default StepsSuccess;
