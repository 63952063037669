import request from "../helpers/axiosService";

const URL_USER_OF_SHOP = "user-of-shop";

const URL_ADD_USER_OF_SHOP = "shop/staff";

export function fetchUserOfShopListAPI(token, params) {
  return request({
    url: URL_USER_OF_SHOP,
    method: "get",
    params,
    token,
  });
}

export function updateUserOfShopListAPI(token, id, data) {
  return request({
    url: `${URL_USER_OF_SHOP}/${id}`,
    method: "put",
    token,
    data,
  });
}

export function deleteUserOfShopListAPI(token, id) {
  return request({
    url: `${URL_USER_OF_SHOP}/${id}`,
    method: "delete",
    token,
  });
}

export function createUserOfShopListAPI(token, shop_id, data) {
  return request({
    url: URL_ADD_USER_OF_SHOP,
    method: "post",
    token,
    shop_id,
    data,
  });
}
