import { put, takeEvery, call, select } from "redux-saga/effects";
import * as cts from "../constants/events/report";
import * as apis from "../apis/report";
import * as act from "../actions/report";
import * as ctsErr from "../constants/ui/error";
import { showLoading, hideLoading } from "../actions/general";
import { failedToast } from "../helpers/AlertHelper";

export function* fetchReportDaySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  if (!payload.noLoading) {
    yield put(showLoading());
  }
  try {
    const res = yield call(apis.fetchReportDayAPI, token, payload.params);

    yield put(
      act.fetchReportDaySuccess({
        data: res.data,
        date: payload.params.date,
      })
    );

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.FETCH_REPORT_DAY_FAILED);
  }
}

export function* fetchReportMonthSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    const res = yield call(apis.fetchReportMonthAPI, token, payload.params);
    const data = res.data.filter((i) => i.target > 0 || i.sales > 0);

    yield put(
      act.fetchReportMonthSuccess({
        // eslint-disable-next-line object-shorthand
        data: data,
        shopId: payload.params.shop_id,
      })
    );

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.FETCH_REPORT_MONTH_FAILED);
  }
}

export function* fetchWorkingStaffSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    const res = yield call(apis.fetchWorkingStaffAPI, token, payload.params);
    yield put(
      act.fetchWorkingStaffSuccess({
        data: res.data,
        date: payload.params.date,
      })
    );
  } catch (error) {
    failedToast(error, ctsErr.FETCH_WORKING_STAFF_FAILED);
  }
}

export default function* reportWatcher() {
  yield takeEvery(cts.FETCH_REPORT_DAY, fetchReportDaySaga);
  yield takeEvery(cts.FETCH_REPORT_MONTH, fetchReportMonthSaga);
  yield takeEvery(cts.FETCH_WORKING_STAFF, fetchWorkingStaffSaga);
}
