export const FETCH_TABLE_LIST = "FETCH_TABLE_LIST";
export const FETCH_TABLE_LIST_SUCCESS = "FETCH_TABLE_LIST_SUCCESS";

export const CREATE_NEW_TABLE = "CREATE_NEW_TABLE";
export const CREATE_NEW_TABLE_SUCCESS = "CREATE_NEW_TABLE_SUCCESS";

export const UPDATE_TABLE = "UPDATE_TABLE";
export const UPDATE_TABLE_SUCCESS = "UPDATE_TABLE_SUCCESS";

export const DELETE_TABLE = "DELETE_TABLE";
export const DELETE_TABLE_SUCCESS = "DELETE_TABLE_SUCCESS";

export const GET_TAX = "GET_TAX";
export const GET_TAX_SUCCESS = "GET_TAX_SUCCESS";

export const UPDATE_TAX = "UPDATE_TAX";
export const UPDATE_TAX_SUCCESS = "UPDATE_TAX_SUCCESS";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";

export const GET_SHOP_SETTINGS = "GET_SHOP_SETTINGS";
export const GET_SHOP_SETTINGS_SUCCESS = "GET_SHOP_SETTINGS_SUCCESS";

export const UPDATE_SHOP_SETTINGS = "UPDATE_SHOP_SETTINGS";
export const UPDATE_SHOP_SETTINGS_SUCCESS = "UPDATE_SHOP_SETTINGS_SUCCESS";

export const SET_SHOW_CATEGORY = "SET_SHOW_CATEGORY";
export const SET_SHOW_CATEGORY_SUCCESS = "SET_SHOW_CATEGORY_SUCCESS";
