import * as cts from "../constants/events/user-of-shop";

export const fetchUserOfShopList = (params) => ({
  type: cts.FETCH_USER_OF_SHOP_LIST,
  payload: { params },
});

export const fetchUserOfShopListSuccess = (data) => ({
  type: cts.FETCH_USER_OF_SHOP_LIST_SUCCESS,
  payload: { data },
});

export const updateUserOfShopList = (params) => ({
  type: cts.UPDATE_USER_OF_SHOP_LIST,
  payload: { params },
});

export const updateUserOfShopListSuccess = (data) => ({
  type: cts.UPDATE_USER_OF_SHOP_LIST_SUCCESS,
  payload: { data },
});

export const deleteUserOfShopList = (id) => ({
  type: cts.DELETE_USER_OF_SHOP_LIST,
  payload: { id },
});

export const deleteUserOfShopListSuccess = (data) => ({
  type: cts.DELETE_USER_OF_SHOP_LIST_SUCCESS,
  payload: { data },
});

export const createNewUserOfShopList = (params) => ({
  type: cts.CREATE_USER_OF_SHOP_LIST,
  payload: { params },
});

export const createNewUserOfShopListSuccess = (shopId, params) => ({
  type: cts.CREATE_USER_OF_SHOP_LIST_SUCCESS,
  payload: { shopId, params },
});
