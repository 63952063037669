export const lang = process.env.NODE_ENV !== "development" ? "jp" : "jp";

export const LOGIN_WRONG_MESSAGE =
  lang === "en" ? "Wrong email or password!" : "メールアドレスまたはパスワードが間違っています！";
export const NETWORK_DISCONNECTED =
  lang === "en" ? "Network disconnected" : "ネットワークが切断されました";
export const LOGIN_FAILED = lang === "en" ? "Login failed" : "ログインに失敗しました";
export const GET_USER_INFO_FAILED =
  lang === "en" ? "Get user information failed" : "ユーザー情報の取得に失敗しました";
export const FETCH_SHOP_LIST_FAILED =
  lang === "en" ? "Get shop list failed" : "店舗リストの取得に失敗しました";
export const FETCH_TABLE_LIST_FAILED =
  lang === "en" ? "Get table list failed" : "テーブルリストの取得に失敗しました";
export const CREATE_TABLE_FAILED =
  lang === "en" ? "Create table failed" : "テーブルの作成に失敗しました";
export const UPDATE_TABLE_FAILED =
  lang === "en" ? "Update table failed" : "テーブルの更新に失敗しました";
export const DELETE_TABLE_FAILED =
  lang === "en" ? "Delete table failed" : "テーブルの削除に失敗しました";
export const FETCH_HOST_LIST_FAILED =
  lang === "en" ? "Get host list failed" : "HOSTリストの取得に失敗しました";
export const FETCH_CATEGORY_LIST_FAILED =
  lang === "en" ? "Get category list failed" : "カテゴリーリストの取得に失敗しました";
export const CREATE_CATEGORY_FAILED =
  lang === "en" ? "Create category failed" : "カテゴリーの作成に失敗しました";
export const CREATE_MULTI_CATEGORY_FAILED =
  lang === "en" ? "Create multi category failed" : "マルチカテゴリの作成に失敗しました";
export const UPDATE_CATEGORY_FAILED =
  lang === "en" ? "Update category failed" : "カテゴリーの更新に失敗しました";
export const DELETE_CATEGORY_FAILED =
  lang === "en" ? "Delete category failed" : "カテゴリーの削除に失敗しました";
export const FETCH_PRODUCT_LIST_FAILED =
  lang === "en" ? "Get product list failed" : "商品リストの取得に失敗しました";
export const CREATE_PRODUCT_FAILED =
  lang === "en" ? "Create product failed" : "商品の作成に失敗しました";
export const CREATE_MULTI_PRODUCT_FAILED =
  lang === "en" ? "Create multi product failed" : "複数の商品の作成に失敗しました";
export const UPDATE_PRODUCT_FAILED =
  lang === "en" ? "Update product failed" : "商品の更新に失敗しました";
export const DELETE_PRODUCT_FAILED =
  lang === "en" ? "Delete product failed" : "商品の削除に失敗しました";
export const GET_TAX_FAILED =
  lang === "en" ? "Get TAX settings failed" : "TAX設定の取得に失敗しました";
export const UPDATE_TAX_FAILED =
  lang === "en" ? "Update TAX settings failed" : "TAX設定の更新に失敗しました";
export const FETCH_CUSTOMER_LIST_FAILED =
  lang === "en"
    ? "Get customer list failed, Please try again"
    : "顧客リストの取得に失敗しました。もう一度やり直してください";
export const CREATE_CUSTOMER_FAILED =
  lang === "en"
    ? "Create customer failed, Please try again"
    : "顧客の作成に失敗しました。もう一度やり直してください";
export const UPDATE_CUSTOMER_FAILED =
  lang === "en"
    ? "Update customer failed, Please try again"
    : "顧客の更新に失敗しました。もう一度お試しください";
export const DELETE_CUSTOMER_FAILED =
  lang === "en"
    ? "Delete customer failed, Please try again"
    : "顧客の削除に失敗しました。もう一度やり直してください";
export const FETCH_BILL_LIST_FAILED =
  lang === "en"
    ? "Get bill list failed, Please try again"
    : "伝票リストの取得に失敗しました。もう一度お試しください";
export const CREATE_BILL_FAILED =
  lang === "en"
    ? "Create bill failed, Please try again"
    : "伝票の作成に失敗しました。もう一度お試しください";
export const UPDATE_BILL_FAILED =
  lang === "en"
    ? "Update bill failed, Please try again"
    : "伝票の更新に失敗しました。もう一度お試しください";
export const PAYMENT_BILL_FAILED =
  lang === "en"
    ? "Pay bill failed, Please try again"
    : "伝票の支払いに失敗しました。もう一度お試しください";
export const DELETE_BILL_FAILED =
  lang === "en"
    ? "Delete bill failed, Please try again"
    : "伝票の削除に失敗しました。もう一度お試しください";
export const FETCH_PRODUCT_IN_BILL_FAILED =
  lang === "en"
    ? "Get products in bill failed, Please try again"
    : "伝票に記載されている商品の取得に失敗しました。もう一度お試しください";
export const UPDATE_PRODUCT_IN_BILL_FAILED =
  lang === "en"
    ? "Update product failed, Please try again"
    : "商品の更新に失敗しました。再試行してください";
export const DELETE_PRODUCT_IN_BILL_FAILED =
  lang === "en"
    ? "Delete product failed, Please try again"
    : "商品の削除に失敗しました。再試行してください";
export const EMPTY_BILL_FAILED =
  lang === "en"
    ? "Empty bill failed, Please try again"
    : "空の伝票が失敗しました。もう一度やり直してください";
export const FETCH_OPEN_SETTINGS_FAILED =
  lang === "en"
    ? "Get open settings failed, Please try again"
    : "OPEN設定を開くことができませんでした。もう一度やり直してください";
export const SET_OPEN_SETTINGS_FAILED =
  lang === "en"
    ? "Set open settings failed, Please try again"
    : "OPEN設定の設定に失敗しました。もう一度お試しください";
export const CLOSE_SHOP_FAILED =
  lang === "en"
    ? "Close shop failed, Please paid all bill first"
    : "店舗がクローズできませんでした。先に伝票を支払いした後にクローズしてください";
export const FETCH_LOGS_FAILED =
  lang === "en"
    ? "Get Logs failed, Please try again"
    : "ログの取得に失敗しました。再試行してください";
export const FETCH_SALARY_LIST_FAILED =
  lang === "en"
    ? "Get salary settings list failed, Please try again"
    : "給与設定リストの取得に失敗しました。もう一度やり直してください";
export const CREATE_SALARY_FAILED =
  lang === "en"
    ? "Create salary settings failed, Please try again"
    : "給与設定の作成に失敗しました。もう一度やり直してください";
export const UPDATE_SALARY_FAILED =
  lang === "en"
    ? "Update salary settings failed, Please try again"
    : "給与設定の更新に失敗しました。もう一度やり直してください";
export const DELETE_SALARY_FAILED =
  lang === "en"
    ? "Delete salary settings failed, Please try again"
    : "給与設定の削除に失敗しました。もう一度やり直してください";
export const FETCH_RECEIVABLES_LIST_FAILED =
  lang === "en"
    ? "Get receivables list failed, Please try again"
    : "売掛金リストの取得に失敗しました。再試行してください";
export const CREATE_RECEIVABLES_FAILED =
  lang === "en"
    ? "Create receivables failed, Please try again"
    : "売掛金の作成に失敗しました。再試行してください";
export const UPDATE_RECEIVABLES_FAILED =
  lang === "en"
    ? "Update receivables failed, Please try again"
    : "売掛金の更新に失敗しました。再試行してください";
export const DELETE_RECEIVABLES_FAILED =
  lang === "en"
    ? "Delete receivables failed, Please try again"
    : "売掛金の削除に失敗しました。再試行してください";
export const FETCH_HOST_SALES_FAILED =
  lang === "en"
    ? "Get sales of host failed, Please try again"
    : "HOSTの販売に失敗しました。もう一度お試しください";
export const FETCH_HOST_SALARY_FAILED =
  lang === "en"
    ? "Get salary of host failed, Please try again"
    : "HOSTの給与を取得できませんでした。もう一度やり直してください";
export const UPDATE_HOST_SALARY_FAILED =
  lang === "en"
    ? "Update salary of host failed, Please try again"
    : "HOSTの給与の更新に失敗しました。もう一度やり直してください";
export const FETCH_OTHER_MONEY_LIST_FAILED =
  lang === "en"
    ? "Get other money list failed, Please try again"
    : "その他のお金のリストを取得できませんでした。もう一度やり直してください";
export const CREATE_OTHER_MONEY_FAILED =
  lang === "en"
    ? "Create other money failed, Please try again"
    : "その他のお金を作成できませんでした。もう一度やり直してください";
export const UPDATE_OTHER_MONEY_FAILED =
  lang === "en"
    ? "Update other money failed, Please try again"
    : "その他のお金の更新に失敗しました。もう一度お試しください";
export const DELETE_OTHER_MONEY_FAILED =
  lang === "en"
    ? "Delete other money failed, Please try again"
    : "その他のお金の削除に失敗しました。もう一度お試しください";
export const GET_NOTIFICATION_FAILED =
  lang === "en"
    ? "Get notification settings failed, Please try again"
    : "通知設定の取得に失敗しました。再試行してください";
export const UPDATE_NOTIFICATION_FAILED =
  lang === "en"
    ? "Update notification settings failed, Please try again"
    : "通知設定の更新に失敗しました。再試行してください";
export const FETCH_REPORT_FAILED =
  lang === "en"
    ? "Get report failed, Please try again"
    : "レポートの取得に失敗しました。もう一度やり直してください";
export const FETCH_PROFIT_FAILED = lang === "en" ? "Get profit failed" : "売上の取得に失敗しました";
export const FETCH_PROTFIT_TIME_FAILED =
  lang === "en" ? "Get profit time failed" : "売上時間別の取得に失敗しました";
export const FETCH_PROFIT_HOST_FAILED =
  lang === "en" ? "Get profit host failed" : "売上HOST別の取得に失敗しました";
export const FETCH_PROFIT_PRODUCT_FAILED =
  lang === "en" ? "Get profit product failed" : "売上商品別の取得に失敗しました";
export const ADD_SHOP_TO_COMPARE_FAILED =
  lang === "en" ? "Add shop to compare failed" : "店舗の追加に失敗しました";
export const ADD_MULTI_SHOP_TO_COMPARE_FAILED =
  lang === "en" ? "Add multi shop to compare failed " : "複数店舗比較の追加に失敗しました";
export const FETCH_REPORT_DAY_FAILED =
  lang === "en" ? "Get report day failed" : "レポート日を取得できませんでした";
export const FETCH_REPORT_MONTH_FAILED =
  lang === "en" ? "Get report month failed" : "レポート月の取得に失敗しました";
export const FETCH_RANKING_FAILED =
  lang === "en" ? "Get ranking failed" : "ランキングの取得に失敗しました";
export const FETCH_WORKING_STAFF_FAILED =
  lang === "en" ? "Get working staff failed" : "出勤スタッフの取得に失敗しました";
export const NOTIFICATION_MESSAGE =
  lang === "en"
    ? "Create failed! Please try again"
    : "作成に失敗しました！もう一度やり直してください";
export const NOTIFICATION_DESCRIPTION =
  lang === "en" ? "All field is required a value" : "すべてのフィールドには値が必要です";
export const FETCH_SHOP_SETTINGS_FAILED =
  lang === "en" ? "Get shop settings failed" : "店舗設定の取得に失敗しました";
export const UPDATE_SHOP_SETTINGS_FAILED =
  lang === "en" ? "Update shop settings failed" : "店舗設定の更新に失敗しました";
export const GET_FEES_FAILED = lang === "en" ? "Get fees failed" : "手数料の取得に失敗しました";
export const UPDATE_FAILED = lang === "en" ? "Update failed" : "更新に失敗しました";
export const DELETE_FAILED = lang === "en" ? "Delete failed" : "削除に失敗しました";
export const CREATE_FAILED = lang === "en" ? "Create failed" : "作成に失敗しました";
export const FETCH_SET_MENU_FAILED =
  lang === "en" ? "Fetch set menu failed" : "SET MENUの取得に失敗しました";
export const NAME_EXISTS = lang === "en" ? "Name already exists" : "名前はすでに存在します";
export const NO_CUSTOMER_TYPE_AVAILABLE =
  lang === "en" ? "No customer type available" : "利用可能な顧客タイプはありません";
export const ADD_PRODUCT_IN_BILL_FAILED =
  lang === "en" ? "Add product failed" : "商品の追加に失敗しました";
export const UPDATE_STAFF_SALARY_FAILED =
  lang === "en" ? "Update staff salary failed" : "スタッフの給与の更新に失敗しました";
export const FETCH_SALARY_DETAIL_FAILED =
  lang === "en" ? "Fetch salary detail failed" : "給与詳細の取得に失敗しました";
export const FETCH_STAFF_SALARY_FAILED =
  lang === "en" ? "Fetch staff salary failed" : "スタッフの給与の取得に失敗しました";
export const FETCH_SALARY_NOMINATION_FAILED =
  lang === "en" ? "Fetch salary nomination failed" : "指名の給与を取得できませんでした";
export const UPDATE_SALARY_NOMINATION_FAILED =
  lang === "en" ? "Update salary nomination failed" : "指名の給与の更新に失敗しました";
//TrangNguyen
export const FETCH_STAFF_LIST_FAILED =
  lang === "en" ? "Get staff list failed" : "スタッフリストの取得に失敗しました";
export const CREATE_STAFF_FAILED =
  lang === "en" ? "Create staff failed" : "スタッフの作成に失敗しました";
export const DELETE_STAFF_FAILED =
  lang === "en" ? "Delete staff failed" : "スタッフの削除に失敗しました";
export const UPDATE_STAFF_FAILED =
  lang === "en" ? "Update staff failed" : "スタッフの更新に失敗しました";

export const NAME_ALREADY_EXIST = lang === "en" ? "Name already exist" : "名前はすでに存在します";
export const FETCH_FAILED = lang === "en" ? "Fetch failed" : "フェッチに失敗しました";
export const UPDATED_FAILED = lang === "en" ? "Updated failed" : "更新失敗";
export const DELETE_PRODUCT_IMAGE_FAILED =
  lang === "en" ? "Delete product image failed" : "削除に失敗しました";
export const DELETE_SPENDING_MONEY_FAILED =
  lang === "en" ? "Delete spending money failed" : "支出金額の削除に失敗しました";
///DungLe
export const UPDATE_ORDER_LIST_FAILED =
  lang === "en" ? "Update order list failed" : "注文リストの更新に失敗しました";
// INVITE STAFF
export const INVITE_STAFF_FAIL =
  lang === "en" ? "Invite staff fail" : "スタッフの招待に失敗しました";

export const SAVE_BEFORE_SORT =
  lang === "en" ? "Save before sort" : "順番を変更する前に保存してください";
