export const FETCH_PROFIT = "FETCH_PROFIT";
export const FETCH_PROFIT_SUCCESS = "FETCH_PROFIT_SUCCESS";

export const FETCH_PROFIT_TIME = "FETCH_PROFIT_TIME_DETAIL";
export const FETCH_PROFIT_TIME_SUCCESS = "FETCH_PROFIT_TIME_SUCCESS";

export const FETCH_PROFIT_HOST = "FETCH_PROFIT_HOST_DETAIL";
export const FETCH_PROFIT_HOST_SUCCESS = "FETCH_PROFIT_HOST_SUCCESS";

export const FETCH_PROFIT_PRODUCT = "FETCH_PROFIT_PRODUCT_DETAIL";
export const FETCH_PROFIT_PRODUCT_SUCCESS = "FETCH_PROFIT_PRODUCT_SUCCESS";

export const ADD_SHOP_TO_COMPARE = "ADD_SHOP_TO_COMPARE";
export const ADD_SHOP_TO_COMPARE_SUCCESS = "ADD_SHOP_TO_COMPARE_SUCCESS";

export const ADD_MULTI_SHOP_COMPARE = "ADD_MULTI_SHOP_COMPARE";
export const RESET_SHOP_COMPARE = "RESET_SHOP_COMPARE";
export const REMOVE_SHOP_COMPARE = "REMOVE_SHOP_COMPARE";
