export const FETCH_CUSTOMER_TYPE = "FETCH_CUSTOMER_TYPE";
export const FETCH_CUSTOMER_TYPE_SUCCESS = "FETCH_CUSTOMER_TYPE_SUCCESS";

export const CREATE_CUSTOMER_TYPE = "CREATE_CUSTOMER_TYPE";
export const CREATE_CUSTOMER_TYPE_SUCCESS = "CREATE_CUSTOMER_TYPE_SUCCESS";

export const UPDATE_CUSTOMER_TYPE = "UPDATE_CUSTOMER_TYPE";
export const UPDATE_CUSTOMER_TYPE_SUCCESS = "UPDATE_CUSTOMER_TYPE_SUCCESS";

export const DELETE_CUSTOMER_TYPE = "DELETE_CUSTOMER_TYPE";
export const DELETE_CUSTOMER_TYPE_SUCCESS = "DELETE_CUSTOMER_TYPE_SUCCESS";

export const FETCH_SET_MENU = "FETCH_SET_MENU";
export const FETCH_SET_MENU_SUCCESS = "FETCH_SET_MENU_SUCCESS";

export const FETCH_SET_MENU_ITEM = "FETCH_SET_MENU_ITEM";
export const FETCH_SET_MENU_ITEM_SUCCESS = "FETCH_SET_MENU_ITEM_SUCCESS";

export const CREATE_SET_MENU = "CREATE_SET_MENU";
export const CREATE_SET_MENU_SUCCESS = "CREATE_SET_MENU_SUCCESS";

export const UPDATE_SET_MENU = "UPDATE_SET_MENU";
export const UPDATE_SET_MENU_SUCCESS = "UPDATE_SET_MENU_SUCCESS";

export const DELETE_SET_MENU = "DELETE_SET_MENU";
export const DELETE_SET_MENU_SUCCESS = "DELETE_SET_MENU_SUCCESS";

export const DELETE_ADS = "DELETE_ADS";
export const DELETE_ADS_SUCCESS = "DELETE_ADS_SUCCESS";
