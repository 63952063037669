import { hideFetchLoading, hideLoading, showFetchLoading, showLoading } from "../actions/general";
import { call, delay, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";

import * as act from "../actions/ads";
import * as apis from "../apis/ads";
import * as cts from "../constants/events/ads";
import * as ctsErr from "../constants/ui/error";
import { failedToast, successToast, toast } from "../helpers/AlertHelper";

function* deleteAdsSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));

  try {
    if (payload.id) {
      yield call(apis.deleteAdsAPI, token, { deleted: [payload.id] });
      yield put(act.deleteAdsSuccess(payload));
    }
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.DELETE_FAILED);
  }
}

export default function* userWatcher() {
  yield takeEvery(cts.DELETE_ADS, deleteAdsSaga);
}
