import { Button, Switch, Table, Modal, Tooltip, Input } from "antd";
import React, { useState, useRef, useEffect, useCallback, memo } from "react";
import { MenuOutlined } from "@ant-design/icons";
import * as cts from "../../../constants/ui/general";
import { useOverFlowHidden } from "../../../helpers/functionHelpersUI";
import { AiOutlineEdit } from "react-icons/ai";
import * as act from "../../../actions";
import * as success from "../../../constants/ui/success";
import * as error from "../../../constants/ui/error";

import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
  SortableHandle as sortableHandle,
} from "react-sortable-hoc";

import { useDispatch, useSelector } from "react-redux";
import { HiOutlineTrash } from "react-icons/hi";
import * as svg from "../../../constants/ui/svg";
import { is } from "immutable";
import { failedToast, successToast } from "../../../helpers/AlertHelper";

const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const DragHandle = sortableHandle(() => (
  <MenuOutlined
    style={{
      marginLeft: 20,
      alignItems: "center",
      justifyContent: "center",
      color: " #666687",
      fontSize: "16px",
    }}
  />
));

function CategoryListTable(props) {
  const {
    categoryList,
    categoryDataPage,
    setCategoryDataPage,
    shopSelected,
    itemEditing,
    setItemEditing,
    idEditItem,
    setIdEditItem,
    categoryName,
    setCategoryName,
    listEditTemp,
    setListEditTemp,
    dataTableTemp,
    setDataTableTemp,
    table,
    setTable,
    width,
    fromSetup,
    setIsFetchCate,
    itemCreateData,
    setItemCreateData,
    setIsSave,
    isSave,
  } = props;

  const inputRef = useRef(null);
  useOverFlowHidden();
  const dispatch = useDispatch();

  const SortableItem = sortableElement((props) => {
    return <tr {...props} />;
  });

  const tableDataCate = categoryDataPage
    ?.filter((item) => item?.is_main_menu !== 1)
    .map((item, index) => {
      return { ...item, index };
    });

  useEffect(() => {
    // setIdEditItem();
    setListEditTemp([]);
    // setCategoryDataPage(categoryList.data);
  }, [categoryList.data]);

  const updateProductCategory = useCallback((item) => {
    dispatch(
      act.products.updateCategory(item?.id, {
        ...item,
        code: item.code,
        shopId: shopSelected?.id,
        status: item?.status,
      })
    );
  });
  const onDeleteItem = (id) => {
    Modal.confirm({
      className: "Modal_confirm_product delete_cate",
      title: (
        <span className="" style={{ textAlign: "center" }}>
          {cts.DELETE_ITEM_SPENDING}
          <br />
          紐付いている商品も削除されますので、ご注意ください。
        </span>
      ),
      okText: __renderDeleteItem(id),
      cancelText: __renderCancelItem(),
      icon: null,
    });
  };
  const __renderDeleteItem = (id) => {
    return (
      <Button
        className="btn-remove_item"
        onClick={() => {
          var cateList = categoryList?.data.filter((cate) => cate.id === id);
          if (cateList.length === 0) {
            setCategoryDataPage(categoryDataPage.filter((dataCate) => dataCate.id !== id));
            successToast(success.CREATE_CATEGORY_MESSAGE);
          } else {
            dispatch(act.products.deleteCategory(id));
            setCategoryDataPage(categoryDataPage.filter((i) => i.id !== id));
          }
        }}
      >
        <span>{cts.REMOVE}</span>
      </Button>
    );
  };
  const __renderCancelItem = () => {
    return (
      <Button className="btn-cancel_item">
        <span>{cts.CANCEL}</span>
      </Button>
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (listEditTemp.length === 0) {
      const items = Array.from(tableDataCate);
      const [reOrderedItem] = items.splice(oldIndex, 1);
      items.splice(newIndex, 0, reOrderedItem);
      setIsFetchCate(false);
      dispatch(
        act.products.updateOrderCategory({
          shopId: shopSelected.id,
          is_orderby: items.map((i, idx) => ({ id: i.id, orderby: items?.length - idx })),
          localData: items.map((item, index) => {
            item.orderby = items?.length - index;
            return item;
          }),
        })
      );
    } else {
      failedToast("error", error.SAVE_BEFORE_SORT);
    }
    // dispatch(act.products.fetchCategoryList(shopSelected.id, "all"));
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = tableDataCate?.findIndex((x) => x.index === restProps["data-row-key"]);
    return (
      <SortableItem
        index={index}
        {...restProps}
        className={className}
        style={
          {
            // justifyContent: "flex-end",
          }
        }
      />
    );
  };

  const __saveButton = () => {
    const editData = listEditTemp.filter((i) => i.id !== undefined);
    if (itemCreateData?.length > 0) {
      dispatch(
        act.products.createNewCategory({
          shopId: shopSelected.id,
          list_category: categoryDataPage.filter((i) => i.isNew === true && i.name !== ""),
        })
      );

      categoryDataPage.forEach((i) => {
        i.isNew = false;
      });

      setItemCreateData([]);
    }
    if (editData?.length > 0) {
      dispatch(
        act.products.updateCategory(0, {
          shopId: shopSelected.id,
          items: categoryDataPage.filter((i) => i.isEdit === true && i.name !== ""),
        })
      );
      setIdEditItem(null);
    }
    setListEditTemp([...listEditTemp]);
    setIsSave(true);
  };
  const onSwitch = useCallback(
    (checked, index) => {
      categoryDataPage[index].status = checked ? 1 : 0;
      setCategoryDataPage([...categoryDataPage]);

      updateProductCategory(categoryDataPage[index]);
    },
    [Object.keys(categoryDataPage).length, categoryDataPage]
  );
  console.log("categoryDataPage", categoryDataPage);
  console.log(Object.keys(categoryDataPage).length, "key");
  const columns = [
    // width > 654
    //   ?
    {
      title: "",
      key: "sort",
      dataIndex: "sort",
      width: 100,
      // height: 10,
      className: "drag-visible",
      render: () => {
        return {
          props: {
            style: {
              alignItems: "center",
              display: "flex",
              height: "60px",
              padding: "0px 0px 0px 0px",
              width: 100,
            },
          },
          children: <DragHandle />,
        };
      },
    },
    //  <==== hide code category ====>
    // {
    //   key: "code",
    //   title: cts.CATEGORYID,
    //   className: "mt-10",
    //   dataIndex: "code",
    //   width: 115,
    //   render: (item, record, index) => {
    //     return {
    //       props: {
    //         style: {
    //           alignItems: "center",
    //           fontSize: "16px",
    //           padding: "0px 50px 0px 0px",
    //         },
    //       },
    //       children: (
    //         <>
    //           <Input
    //             ref={inputRef}
    //             style={{
    //               border: "none",
    //               width: "100px",
    //               backgroundColor: "#FFFFFF",
    //               textAlign: "center"
    //             }}
    //             defaultValue={record?.code}
    //             onChange={(e) => {
    //               inputRef.current.value = e.target.value
    //             }}
    //             onBlur={(e) => {

    //               if (record.isNew) {
    //                 setCategoryDataPage([...categoryDataPage], categoryDataPage[index].code = inputRef.current.value)
    //               } else {
    //                 record.code = inputRef.current.value;
    //                 updateProductCategory(record);
    //               }

    //             }}
    //           />

    //         </>

    //       ),
    //     };
    //   },
    // },
    //  <==== /hide code category ====>
    // : {},
    {
      key: "name",
      title: cts.CATEGORY_NAME,
      className: "title mt-10",
      dataIndex: "name",
      render: (item, record, index) => {
        return {
          props: {
            style: {
              padding: "0px 0px 0px 0px",
              width: "100%",
              borderBottom: "1px solid #f0f0f0",
            },
          },
          children: (
            <>
              <div
                key={index}
                className="edit d-flex center"
                style={{ width: " 100%", height: "35px", flexDirection: "column" }}
                onClick={() => {
                  setIdEditItem(record?.index);
                  const findIndex = listEditTemp.findIndex((x) => x.index === record?.index);
                  if (findIndex >= 0) {
                    listEditTemp[findIndex] = { ...listEditTemp[findIndex], ...record };
                    setListEditTemp(listEditTemp);
                  } else {
                    setListEditTemp([...listEditTemp, record]);
                  }
                  if (record?.isNew) {
                    setIdEditItem(record?.index);
                  }
                }}
              >
                {idEditItem === record?.id || idEditItem === record.index ? (
                  <div
                    className="content ml-10"
                    onClick={() => {
                      setItemEditing(item);
                    }}
                  >
                    <Input
                      style={{
                        padding: "0px 0px 0px 10px",
                        width: "100%",
                        boxShadow: "0px 4px 4px rgb(0 0 0 / 8%), 0px 0px 4px rgb(0 0 0 / 15%)",
                        height: "40px",
                        border: "0.5px solid rgba(90, 90, 90)",
                      }}
                      autoFocus
                      onBlur={() => {
                        setIdEditItem(null);
                        setCategoryDataPage(categoryDataPage);
                      }}
                      className="text-input"
                      // value={record?.name}
                      defaultValue={record?.name}
                      onChange={(e) => {
                        // if (record?.isNew) {
                        //   // const indexCreate = itemCreateData.findIndex(
                        //   //   (item) => item.index === record?.index
                        //   // );
                        //   [...itemCreateData][idEditItem].name = e.target.value;
                        //   setItemCreateData([...itemCreateData]);
                        //   // const findIndex = categoryDataPage.findIndex(
                        //   //   (x) => x.index === record?.index
                        //   // );
                        //   [...categoryDataPage][idEditItem].name = e.target.value;
                        //   setCategoryDataPage([...categoryDataPage]);
                        // }
                        // if ([...categoryDataPage][index].id) {
                        //   const findIndex = categoryDataPage.findIndex((x) => x.id === idEditItem);
                        //   [...categoryDataPage][findIndex].name = e.target.value;
                        //   setCategoryDataPage([...categoryDataPage]);
                        //   const indexEditItem = listEditTemp.findIndex((x) => x.id === idEditItem);
                        //   [...listEditTemp][indexEditItem].name = e.target.value;
                        //   setListEditTemp([...listEditTemp]);
                        // }
                        if (record?.isNew) {
                          const newArr = [...categoryDataPage];
                          newArr[index].name = e.target.value;
                        }
                        if (record?.id) {
                          const newArr = [...categoryDataPage];
                          const findIndex = newArr.findIndex((x) => x.id === record?.id);
                          newArr[findIndex].name = e.target.value;
                          newArr[findIndex].isEdit = true;
                        }
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <div
                      className="border-cate d-flex center"
                      style={{ width: "100%", height: "35px" }}
                    >
                      <span
                        className="name-item-category ml-10"
                        style={{
                          fontSize: "16px",
                          width: "100%",
                          fontFamily: "NotoSans",
                          fontWeight: 400,
                        }}
                      >
                        {item}
                      </span>
                      {idEditItem !== record?.index && (
                        <AiOutlineEdit
                          style={{ color: "#666687" }}
                          className="icon-edit"
                          size={24}
                          onClick={() => {
                            setIdEditItem(record?.index);
                            const findIndex = listEditTemp.findIndex(
                              (x) => x.index === record?.index
                            );

                            if (findIndex >= 0) {
                              listEditTemp[findIndex] = { ...listEditTemp[findIndex], ...record };
                              setListEditTemp(listEditTemp);
                            } else {
                              setListEditTemp([...listEditTemp, record]);
                            }
                          }}
                        />
                      )}
                    </div>
                    <div
                      className="border-table-page"
                      style={{ background: "#bfbfbf", width: "100%", height: "1px" }}
                    ></div>
                  </>
                )}
              </div>
            </>
          ),
        };
      },
    },
    {
      key: "status",
      title: cts.DISPLAY,
      className: "on_off d-flex center middle",
      dataIndex: "status",
      width: 110,
      render: (item, record, index) => {
        return {
          props: {
            style: {
              padding: " 10.5px 20px",
            },
          },
          children: (
            <Switch
              className="btn-switch mr-10 ml-20"
              checked={record?.status === 1}
              onChange={(checked) => {
                onSwitch(checked, index);
                // categoryDataPage[index].status = checked ? 1 : 0;
                // setCategoryDataPage([...categoryDataPage]);

                // updateProductCategory(categoryDataPage[index]);
              }}
            />
          ),
        };
      },
    },
    {
      key: "delete",
      title: "",
      dataIndex: "id",
      className: "delete",
      width: 80,
      render: (index, item) => {
        return {
          props: {
            style: { marginTop: "10px", padding: "0px 0px 0px 20px" },
          },
          children: (
            <>
              <Button
                className="btn-trash"
                onClick={() => {
                  onDeleteItem(item.id);
                }}
                style={{
                  background: "#666687",
                  borderRadius: "6px",
                  color: "#ffffff",
                  width: "37px",
                  height: "37px",
                  marginRight: "22px",
                }}
                icon={<HiOutlineTrash size={17} />}
              />
            </>
          ),
        };
      },
    },
  ];

  return (
    <div className="ctn-table-cate d-flex">
      <div className="category-page-content">
        <Table
          className="category-list-page-table"
          dataSource={tableDataCate}
          columns={columns}
          pagination={false}
          // rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
          rowKey="index"
          size="small"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </div>
      <div className="save-edit-category">
        <Button
          className="btn-save mr-10 mt-15 mb-15"
          disabled={listEditTemp.length === 0}
          onClick={__saveButton}
          icon={svg.saveIcon}
        >
          <span>{cts.KEEP}</span>
        </Button>
      </div>
    </div>
  );
}

export default memo(CategoryListTable);
