import memoize from "fast-memoize";

const isShowSidebar = (state) => state.getIn(["general", "isShowSidebar"]);
const loading = (state) => state.getIn(["general", "loading"]);
const fullScreenLoading = (state) => state.getIn(["general", "fullScreenLoading"]);
const fetchLoading = (state) => state.getIn(["general", "fetchLoading"]);
const breadcrumb = (state) => state.getIn(["general", "breadcrumb"]).toJS();
const showAlert = (state) => state.getIn(["general", "alertContent"]).toJS();
const language = (state) => state.getIn(["general", "language"]);
const activeStepSetup = (state) => state.getIn(["general", "activeStepSetup"]).toJS();

export const isShowSidebarSelector = memoize(isShowSidebar);
export const loadingSelector = memoize(loading);
export const fullScreenLoadingSelector = memoize(fullScreenLoading);
export const fetchLoadingSelector = memoize(fetchLoading);
export const breadcrumbSelector = memoize(breadcrumb);
export const activeStepSetupSelector = memoize(activeStepSetup);
export const showAlertSelector = memoize(showAlert);
export const languageSelector = memoize(language);
