import { fromJS } from "immutable";
import * as cts from "../constants/events/host";
import { SET_SELECTED_SHOP } from "../constants/events/user";
import { createReducer } from "../helpers/createReducer";

const initialState = fromJS({
  isActionDone: true,
  hostList: {
    data: [],
    shopId: null,
    currentPage: 1,
    totalPage: 1,
    total: 1,
  },
  hostForOrderList: {
    data: [],
    shopId: null,
  },
  hostReceivablesList: {
    data: [],
    currentPage: 1,
    totalPage: 1,
    hostId: null,
  },
  hostSales: {
    data: [],
    hostId: null,
  },
  hostSalary: {
    data: [],
    hostId: null,
  },
  hostOtherMoneyList: {
    data: [],
    currentPage: 1,
    totalPage: 1,
    hostId: null,
  },
});

// HOST LIST

const fetchHostListSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("hostList", fromJS(data));
};

const fetchHostForOrderListSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("hostForOrderList", fromJS(data));
};

const updateHostForOrderListSuccess = (state, action) => {
  const { data } = action.payload;
  const hosts = state.getIn(["hostForOrderList", "data"]).toJS();
  const index = hosts.findIndex((i) => i.id === data.id);
  if (index !== -1) {
    hosts[index] = data;
  }
  return state.setIn(["hostForOrderList", "data"], fromJS(hosts));
};

// HOST RECEIVABLE
const fetchHostReceivablesList = (state, action) => {
  const { params } = action.payload;
  const hostId = state.getIn(["hostReceivablesList", "hostId"]);

  if (params.host_id !== hostId) {
    return state.setIn(["hostReceivablesList", "data"], fromJS([]));
  }
  return state;
};

const fetchHostReceivablesListSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("hostReceivablesList", fromJS(data));
};

const createHostReceivablesSuccess = (state, action) => {
  const { data } = action.payload;
  const hostReceivablesList = state.getIn(["hostReceivablesList", "data"]).toJS();
  const newReceivables = [data].concat(hostReceivablesList);

  return state
    .setIn(["hostReceivablesList", "data"], fromJS(newReceivables))
    .set("isActionDone", true);
};

const updateHostReceivablesSuccess = (state, action) => {
  const { data } = action.payload;
  const hostReceivablesList = state.getIn(["hostReceivablesList", "data"]).toJS();
  const index = hostReceivablesList.findIndex((i) => i.id === data.id);
  hostReceivablesList[index] = data;

  return state
    .setIn(["hostReceivablesList", "data"], fromJS(hostReceivablesList))
    .set("isActionDone", true);
};

const deleteHostReceivablesSuccess = (state, action) => {
  const { data } = action.payload;
  const hostReceivablesList = state.getIn(["hostReceivablesList", "data"]).toJS();
  const newReceivables = hostReceivablesList.filter((i) => i.id !== data.id);

  return state
    .setIn(["hostReceivablesList", "data"], fromJS(newReceivables))
    .set("isActionDone", true);
};

// HOST SALES
const fetchHostSales = (state, action) => {
  const { params } = action.payload;
  const hostId = state.getIn(["hostSales", "hostId"]);

  if (params.host_id !== hostId) {
    return state.setIn(["hostSales", "data"], fromJS([]));
  }
  return state;
};

const fetchHostSalesSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("hostSales", fromJS(data));
};

// HOST SALARY
const fetchHostSalary = (state, action) => {
  const { params } = action.payload;
  const hostId = state.getIn(["hostSalary", "hostId"]);

  if (params.host_id !== hostId) {
    return state.setIn(["hostSalary", "data"], fromJS([]));
  }
  return state;
};

const fetchHostSalarySuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("hostSalary", fromJS(data));
};

const updateHostSalarySuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("hostSalary", fromJS(data));
};

// HOST OTHER MONEY
const fetchHostOtherMoneyList = (state, action) => {
  const { params } = action.payload;
  const hostId = state.getIn(["hostOtherMoneyList", "hostId"]);

  if (params.host_id !== hostId) {
    return state.setIn(["hostOtherMoneyList", "data"], fromJS([]));
  }
  return state;
};

const fetchHostOtherMoneyListSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("hostOtherMoneyList", fromJS(data));
};

const createHostOtherMoneySuccess = (state, action) => {
  const { data } = action.payload;
  const hostOtherMoneyList = state.getIn(["hostOtherMoneyList", "data"]).toJS();
  const newOtherMoney = [data].concat(hostOtherMoneyList);

  return state
    .setIn(["hostOtherMoneyList", "data"], fromJS(newOtherMoney))
    .set("isActionDone", true);
};

const updateHostOtherMoneySuccess = (state, action) => {
  const { data } = action.payload;
  const hostOtherMoneyList = state.getIn(["hostOtherMoneyList", "data"]).toJS();
  const index = hostOtherMoneyList.findIndex((i) => i.id === data.id);
  hostOtherMoneyList[index] = data;

  return state
    .setIn(["hostOtherMoneyList", "data"], fromJS(hostOtherMoneyList))
    .set("isActionDone", true);
};

const deleteHostOtherMoneySuccess = (state, action) => {
  const { data } = action.payload;
  const hostOtherMoneyList = state.getIn(["hostOtherMoneyList", "data"]).toJS();
  const newOtherMoney = hostOtherMoneyList.filter((i) => i.id !== data.id);

  return state
    .setIn(["hostOtherMoneyList", "data"], fromJS(newOtherMoney))
    .set("isActionDone", true);
};
const updateSettingNominationHostSuccess = (state, action) => {
  const { data } = action.payload;
  return state.setIn(["hostForOrderList", "data"], fromJS(data));
};

// reducer
const reducer = {
  [SET_SELECTED_SHOP]: () => initialState,
  [cts.FETCH_HOST_LIST_SUCCESS]: fetchHostListSuccess,
  [cts.FETCH_HOST_FOR_ORDER_LIST_SUCCESS]: fetchHostForOrderListSuccess,

  [cts.FETCH_HOST_RECEIVABLES_LIST]: fetchHostReceivablesList,
  [cts.FETCH_HOST_RECEIVABLES_LIST_SUCCESS]: fetchHostReceivablesListSuccess,

  [cts.CREATE_HOST_RECEIVABLES]: (state) => state.set("isActionDone", false),
  [cts.CREATE_HOST_RECEIVABLES_SUCCESS]: createHostReceivablesSuccess,

  [cts.UPDATE_HOST_RECEIVABLES]: (state) => state.set("isActionDone", false),
  [cts.UPDATE_HOST_RECEIVABLES_SUCCESS]: updateHostReceivablesSuccess,

  [cts.DELETE_HOST_RECEIVABLES]: (state) => state.set("isActionDone", false),
  [cts.DELETE_HOST_RECEIVABLES_SUCCESS]: deleteHostReceivablesSuccess,

  [cts.FETCH_HOST_SALES]: fetchHostSales,
  [cts.FETCH_HOST_SALES_SUCCESS]: fetchHostSalesSuccess,

  [cts.FETCH_HOST_SALARY]: fetchHostSalary,
  [cts.FETCH_HOST_SALARY_SUCCESS]: fetchHostSalarySuccess,
  [cts.UPDATE_HOST_SALARY_SUCCESS]: updateHostSalarySuccess,

  [cts.FETCH_HOST_OTHER_MONEY_LIST]: fetchHostOtherMoneyList,
  [cts.FETCH_HOST_OTHER_MONEY_LIST_SUCCESS]: fetchHostOtherMoneyListSuccess,

  [cts.CREATE_HOST_OTHER_MONEY]: (state) => state.set("isActionDone", false),
  [cts.CREATE_HOST_OTHER_MONEY_SUCCESS]: createHostOtherMoneySuccess,

  [cts.UPDATE_HOST_OTHER_MONEY]: (state) => state.set("isActionDone", false),
  [cts.UPDATE_HOST_OTHER_MONEY_SUCCESS]: updateHostOtherMoneySuccess,
  [cts.DELETE_HOST_OTHER_MONEY]: (state) => state.set("isActionDone", false),
  [cts.DELETE_HOST_OTHER_MONEY_SUCCESS]: deleteHostOtherMoneySuccess,
  [cts.UPDATE_SETTINGS_NOMINATION_HOST_SUCCESS]: updateSettingNominationHostSuccess,
  __default__: (state) => state,
};

export default createReducer(reducer, initialState);
