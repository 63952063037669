import { fromJS } from "immutable";
import { createReducer } from "../helpers/createReducer";
import * as cts from "../constants/events/ranking";
import { SET_SELECTED_SHOP } from "../constants/events/user";

const initialState = fromJS({
  ranking: {
    data: [],
    shopId: null,
  },
});

const fetchRankingSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("ranking", fromJS(data));
};

const reducer = {
  [SET_SELECTED_SHOP]: () => initialState,

  [cts.FETCH_RANKING_SUCCESS]: fetchRankingSuccess,
  __default__: (state) => state,
};

export default createReducer(reducer, initialState);
