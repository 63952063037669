import { message } from "antd";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import moment from "moment";
import { call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  hideFetchLoading,
  hideFullScreenLoading,
  hideLoading,
  showFetchLoading,
  showFullScreenLoading,
  showLoading,
} from "../actions/general";
import * as act from "../actions/user";
import { sendPushNotification } from "../apis/notification";
import { fetchReportDayAPI } from "../apis/report";
import * as apis from "../apis/user";
import { ENCODE_SECRET_KEY } from "../constants";
import * as cts from "../constants/events/user";
import * as errCts from "../constants/ui/error";
import * as generalCts from "../constants/ui/general";
import * as success from "../constants/ui/success";
import { errorToast, failedToast, successToast, toast } from "../helpers/AlertHelper";
import { formatMoney, getAllToken } from "../helpers/functionHelpers";
import * as socket from "../sockets";

function* loginFlowSaga({ payload }) {
  const { email, password, rememberMe } = payload.params;

  yield put(showLoading());

  try {
    yield put(showLoading());
    const res = yield call(apis.loginAPI, { email, password, remember_me: rememberMe, role: 3 });
    const { data } = res;

    if (data.access_token) {
      localStorage.setItem("POS_WEB_USER_ID", data.user.id);
      yield put(act.loginFlowSuccess(data));

      Cookies.set(
        "POS_WEB_TOKEN",
        CryptoJS.AES.encrypt(data.access_token, ENCODE_SECRET_KEY).toString(),
        {
          expires: rememberMe ? 365 : 7,
        }
      );
      yield put(hideLoading());
      payload.history.push({ pathname: "/", state: "from-login" });
    } else if (data.message && data.message === "Invalid Credentials") {
      yield delay(3000);
      errorToast(errCts.LOGIN_WRONG_MESSAGE, "top-center");
      message.error(errCts.LOGIN_FAILED);
      yield put(hideLoading());
    }
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.LOGIN_FAILED);
  }
}

function* logoutFlowSaga({ payload: history }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    localStorage.removeItem("POS_WEB_SELECTED_SHOP");
    localStorage.removeItem("POS_WEB_USER_ID");
    Cookies.remove("POS_WEB_TOKEN");
    yield call(apis.logoutAPI, token);
    yield call(history.push("/"));
    yield put(act.logoutFlowSuccess());
    yield put(hideFetchLoading());
  } catch (error) {
    localStorage.removeItem("POS_WEB_SELECTED_SHOP");
    localStorage.removeItem("POS_WEB_USER_ID");
    Cookies.remove("POS_WEB_TOKEN");
    yield call(history.push("/"));
    yield put(act.logoutFlowSuccess());
    yield put(hideFetchLoading());
  }
}

function* getUserInfoSaga({ payload }) {
  yield put(showFetchLoading());

  try {
    yield put(showFetchLoading());
    const res = yield call(apis.getUserInfoAPI, payload.params);

    yield put(act.getUserInfoSuccess(res.data));

    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, errCts.GET_USER_INFO_FAILED);
  }
}

function* fetchShopListSaga({ payload }) {
  yield put(showFullScreenLoading());

  try {
    const res = yield call(apis.fetchShopListAPI, payload.params);

    yield put(act.fetchShopListSuccess(res.data.data));

    yield put(hideFullScreenLoading());
  } catch (error) {
    yield put(hideFullScreenLoading());
    failedToast(error, errCts.FETCH_SHOP_LIST_FAILED);
  }
}

function* fetchOpenSettingsSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  if (payload.hasLoading) {
    yield put(showFetchLoading());
  }

  try {
    const res = yield call(apis.fetchOpenSettingsAPI, token, payload.params);
    if (payload.hasLoading) {
      yield put(hideFetchLoading());
    }
    if (payload.params.get_all) {
      const { current_page, data, total } = res.data;
      yield put(
        act.fetchOpenSettingsAllSuccess({
          currentPage: current_page,
          data,
          shopId: payload.params.shop_id,
          total,
        })
      );
    } else {
      res.data.shopId = res.data.shop_id;
      if (payload.params.date) {
        res.data.status = "2";
      }
      yield put(act.fetchOpenSettingsSuccess(res.data));
    }
    // yield put(hideFetchLoading());
  } catch (error) {
    failedToast(error, errCts.FETCH_OPEN_SETTINGS_FAILED);
    if (payload.hasLoading) {
      yield put(hideFetchLoading());
    }
  }
}

function* setOpenSettingsSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const selectedShop = yield select((state) => state.getIn(["user", "selectedShop"]).toJS());
  const notificationSettingsList = yield select((state) =>
    state.getIn(["settings", "notificationSettingsList"]).toJS()
  );
  yield put(showLoading());

  try {
    const res = yield call(apis.setOpenSettingsAPI, token, payload.params);
    const offset = moment().utcOffset() / 60;
    res.data.open_time = moment(res.data.open_time)
      .add(9 - offset, "hours")
      .format("YYYY-MM-DD HH:mm:ss");
    yield put(act.setOpenSettingsSuccess(`${res.data.status}` === "2" ? {} : res.data));
    socket.clientOpenSettings(
      `${res.data.status}` === "2" ? { shop_id: res.data.shop_id } : res.data
    );
    const msg = `${res.data.status}` === "2" ? generalCts.CLOSE_SHOP : generalCts.OPEN_SHOP;

    if (!payload.noMsg) {
      if (payload.params.isUpdate) {
        successToast(success.UPDATE_SHOP_SETTINGS_MESSAGE);
      } else {
        successToast(msg);
      }
    }
    yield put(hideLoading());
    if (`${res.data.status}` === "2") {
      const notify = notificationSettingsList.data.filter((i) => i.daily_report === 1);
      const TOKENS = getAllToken(notify);
      if (TOKENS.length > 0) {
        const report = yield call(fetchReportDayAPI, token, {
          date: moment().format("YYYY-MM-DD"),
          shop_id: selectedShop.id,
        });
        yield call(
          sendPushNotification,
          TOKENS,
          `${selectedShop.name} ${generalCts.lang === "en" ? "is closed" : "はクローズしました"}`,
          generalCts.lang === "en"
            ? `Today sales is ¥${formatMoney(report.data.sale || 0)}`
            : `今日の売り上げは¥${formatMoney(report.data.sale || 0)}です`,
          { type: "close-shop", shopId: selectedShop.id }
        );
      }
    }
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.SET_OPEN_SETTINGS_FAILED);
  }
}

function* fetchUserSettingsSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  try {
    const res = yield call(apis.fetchUserSettingsAPI, token, payload.params);
    yield put(act.fetchUserSettingsSuccess(res.data || {}));
  } catch (error) {
    failedToast(error, errCts.FETCH_FAILED);
  }
}

function* updateUserSettingsSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading("user-settings"));
  try {
    const res = yield call(apis.updateUserSettingsAPI, token, payload.params);
    yield put(act.updateUserSettingsSuccess(res.data || {}));
    toast(success.UPDATED_SUCCESS);
  } catch (error) {
    failedToast(error, errCts.UPDATED_FAILED);
  }
  yield put(hideLoading());
}

function* updateUserPassWordSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading("user-info"));
  try {
    const res = yield call(apis.updatePasswordStaff, token, payload.params);
    yield put(act.updateUserSettingsSuccess(res.data || {}));
    toast(success.UPDATED_SUCCESS);
  } catch (error) {
    failedToast(error, errCts.UPDATED_FAILED);
  }
  yield put(hideLoading());
}
// function* loginFlowSaga({ payload }) {
//   try {
//     const res = yield call(apis.loginAPI, payload.params);
//     Cookies.set(
//       "POS_WEB_TOKEN",
//       CryptoJS.AES.encrypt(res.data.access_token, ENCODE_SECRET_KEY).toString(),
//       {
//         expires: payload.params.remember_me ? 365 : 7,
//       }
//     );
//     yield put(act.loginFlowSuccess(res.data));
//   } catch (error) {
//     failedToast(error, errCts.LOGIN_FAILED);
//   }
// }
function* deleteUserSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  try {
    const res = yield call(apis.deleteUserAPI, token);
    yield put(act.deleteUserSuccess(res.data || {}));
    if (res.data?.id) {
      successToast("削除しました。");
      localStorage.removeItem("POS_WEB_SELECTED_SHOP");
      localStorage.removeItem("POS_WEB_USER_ID");
      Cookies.remove("POS_WEB_TOKEN");
      yield call(apis.logoutAPI, token);
      yield call(payload.params.push("/"));
      yield put(act.logoutFlowSuccess());
      yield put(hideFetchLoading());
    } else {
      // errorToast("削除失敗しました。");
    }
  } catch (error) {
    // errorToast("削除失敗しました。");
    console.log("error", error);
  }
}
export default function* userWatcher() {
  yield takeLatest(cts.LOGIN_FLOW, loginFlowSaga);
  yield takeLatest(cts.LOGOUT_FLOW, logoutFlowSaga);
  yield takeEvery(cts.GET_USER_INFO, getUserInfoSaga);
  yield takeEvery(cts.FETCH_SHOP_LIST, fetchShopListSaga);
  yield takeEvery(cts.FETCH_OPEN_SETTINGS, fetchOpenSettingsSaga);
  yield takeLatest(cts.SET_OPEN_SETTINGS, setOpenSettingsSaga);
  yield takeEvery(cts.FETCH_USER_SETTINGS, fetchUserSettingsSaga);
  yield takeLatest(cts.UPDATE_USER_SETTINGS, updateUserSettingsSaga);
  yield takeLatest(cts.URL_CHANGE_PASSWORD_STAFF, updateUserPassWordSaga);
  yield takeLatest(cts.DELETE_USER, deleteUserSaga);
}
