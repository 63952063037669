import { put, takeLatest, takeEvery, call, select } from "redux-saga/effects";
import { message } from "antd";
import { showLoading, hideLoading, hideFetchLoading, showFetchLoading } from "../actions/general";
import { failedToast, successToast, errorToast } from "../helpers/AlertHelper";
import * as apis from "../apis/other";
import * as cts from "../constants/events/other";
import * as act from "../actions/other";
import * as errCts from "../constants/ui/error";
import * as successCts from "../constants/ui/success";
import * as generalCts from "../constants/ui/general";

// CUSTOMER
function* fetchCustomerListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchCustomerListAPI, token, payload.params);
    yield put(
      act.fetchCustomerListSuccess({
        data: res.data.data,
        shopId: payload.params.shop_id,
        currentPage: res.data.current_page,
        totalPage: res.data.last_page,
        total: res.data.total,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, errCts.FETCH_CUSTOMER_LIST_FAILED);
  }
}

function* fetchCustomerForOrderListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchCustomerForOrderListAPI, token, payload.params);
    yield put(
      act.fetchCustomerForOrderListSuccess({
        data: res.data?.data?.data || res.data?.data || [],
        shopId: payload.params,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, errCts.FETCH_CUSTOMER_LIST_FAILED);
  }
}

function* fetchCustomerDetailSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchCustomerDetailAPI, token, payload.params);
    const { data, current_page, last_page, total_receivable } = res.data;
    yield put(
      act.fetchCustomerDetailSuccess({
        data,
        currentPage: current_page,
        totalPage: last_page,
        customerId: payload.params.customer_id,
        totalReceivables: total_receivable,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, errCts.FETCH_CUSTOMER_LIST_FAILED);
  }
}

function* createCustomerSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());
  try {
    const res = yield call(apis.createCustomerAPI, token, payload.params);
    if (
      res.data[0] === "The name field is required." &&
      res.data[1] === "The code has already been taken."
    ) {
      errorToast("The name field is required and The code has already been taken.");
    } else if (res.data[0] === "The code has already been taken.") {
      errorToast("The code has already been taken.");
    } else if (res.data[0] === "The name field is required.") {
      errorToast("The name field is required.");
    } else {
      yield put(act.createCustomerSuccess(res.data.data));
      successToast(successCts.CREATE_CUSTOMER_MESSAGE);
    }

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.CREATE_CUSTOMER_FAILED);
  }
}

function* updateCustomerSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    const res = yield call(apis.updateCustomerAPI, token, payload.id, payload.params);

    yield put(act.updateCustomerSuccess(res.data.data));
    successToast(successCts.UPDATE_CUSTOMER_MESSAGE);

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.UPDATE_CUSTOMER_FAILED);
  }
}

function* deleteCustomerSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());
  try {
    const res = yield call(apis.deleteCustomerAPI, token, payload.id);

    yield put(act.deleteCustomerSuccess(res.data.data));
    successToast(successCts.DELETE_CUSTOMER_MESSAGE);

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.DELETE_CUSTOMER_FAILED);
  }
}

// LOG
function* fetchLogsSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchLogsAPI, token, payload.params);
    const { data, current_page, last_page, total } = res.data;
    yield put(
      act.fetchLogsSuccess({
        data,
        shopId: payload.params.shopId,
        currentPage: current_page,
        totalPage: last_page,
        total,
      })
    );

    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, errCts.FETCH_LOGS_FAILED);
  }
}

// SALARY
function* fetchSalaryListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchSalaryListAPI, token, payload.shopId);
    yield put(act.fetchSalaryListSuccess({ data: res.data.data, shopId: payload.shopId }));
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, errCts.FETCH_SALARY_LIST_FAILED);
  }
}

function* createSalarySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading("create-salary"));

  try {
    const res = yield call(apis.createSalaryAPI, token, payload.params);
    yield put(act.createSalarySuccess(res.data));
    successToast(successCts.CREATE_SALARY_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.CREATE_SALARY_FAILED);
  }
}

function* updateSalarySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading("update-salary"));

  try {
    const res = yield call(apis.updateSalaryAPI, token, payload.id, payload.params);
    yield put(act.updateSalarySuccess(res.data));
    successToast(successCts.UPDATE_SALARY_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.UPDATE_SALARY_FAILED);
  }
}

function* deleteSalarySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const key = "delete-salary";
  message.loading({ content: generalCts.PLEASE_WAIT, key });

  try {
    yield call(apis.deleteSalaryAPI, token, payload.params);
    yield put(act.deleteSalarySuccess({ id: payload.params.id }));
    message.success({ content: successCts.DELETE_SALARY_SUCCESS, key });
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.DELETE_SALARY_FAILED);
  }
}

// function* fetchReportSaga({ payload }) {
//   const token = yield select((state) => state.getIn(["user", "token"]));
//   yield put(showFetchLoading());

//   try {
//     const res = yield call(apis.fetchReportAPI, token, payload.params);
//     yield put(
//       act.fetchReportSuccess({
//         data: res.data,
//         shopId: payload.params.shop_id,
//       })
//     );
//     yield put(hideFetchLoading());
//   } catch (error) {
//     yield put(hideFetchLoading());
//     failedToast(error, errCts.FETCH_REPORT_FAILED);
//   }
// }

function* fetchSalaryNominationSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchSalaryNominationAPI, token, payload.params);
    yield put(
      act.fetchSalaryNominationSuccess({
        data: res.data,
        shop_id: payload.params,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, errCts.FETCH_SALARY_NOMINATION_FAILED);
  }
}

function* updateSalaryNominationSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const key = "update-salary";
  message.loading({ content: generalCts.PLEASE_WAIT, key });
  try {
    const res = yield call(apis.updateSalaryNominationAPI, token, payload.params);
    yield put(act.updateSalaryNominationSuccess(res.data));
    message.success({ content: successCts.UPDATE_SALARY_NOMINATION_SUCCESS, key });
  } catch (error) {
    failedToast(error, errCts.UPDATE_SALARY_NOMINATION_FAILED);
  }
}

function* fetchReportInSidebarSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));

  try {
    const res = yield call(apis.fetchReportDayAPI, token, payload.params);
    yield put(
      act.fetchReportInSidebarSuccess({
        sales: res.data?.sales || 0,
        shopId: payload.params.shop_id,
      })
    );
  } catch (error) {
    failedToast(error, errCts.FETCH_REPORT_FAILED);
  }
}

export default function* otherWatcher() {
  yield takeEvery(cts.FETCH_CUSTOMER_LIST, fetchCustomerListSaga);
  yield takeEvery(cts.FETCH_CUSTOMER_FOR_ORDER_LIST, fetchCustomerForOrderListSaga);
  yield takeEvery(cts.FETCH_CUSTOMER_DETAIL, fetchCustomerDetailSaga);
  yield takeLatest(cts.CREATE_CUSTOMER, createCustomerSaga);
  yield takeLatest(cts.UPDATE_CUSTOMER, updateCustomerSaga);
  yield takeLatest(cts.DELETE_CUSTOMER, deleteCustomerSaga);
  yield takeEvery(cts.FETCH_LOGS, fetchLogsSaga);
  yield takeEvery(cts.FETCH_SALARY_LIST, fetchSalaryListSaga);
  yield takeLatest(cts.CREATE_SALARY, createSalarySaga);
  yield takeLatest(cts.UPDATE_SALARY, updateSalarySaga);
  yield takeLatest(cts.DELETE_SALARY, deleteSalarySaga);
  // yield takeEvery(cts.FETCH_REPORT, fetchReportSaga);
  yield takeEvery(cts.FETCH_SALARY_NOMINATION, fetchSalaryNominationSaga);
  yield takeLatest(cts.UPDATE_SALARY_NOMINATION, updateSalaryNominationSaga);
  yield takeEvery(cts.FETCH_REPORT_IN_SIDEBAR, fetchReportInSidebarSaga);
}
