import { all } from "redux-saga/effects";
import generalWatcher from "./general";
import userWatcher from "./user";
import settingsWatcher from "./settings";
import hostWatcher from "./host";
import productsWatcher from "./products";
import otherWatcher from "./other";
import billWatcher from "./bill";
import profitWatcher from "./profit";
import reportWatcher from "./report";
import rankingWatcher from "./ranking";
import setMenuWatcher from "./set-menu";
import userofshopWatcher from "./user-of-shop";
import adsWatcher from "./ads";

function* rootSaga() {
  yield all([
    adsWatcher(),
    generalWatcher(),
    userWatcher(),
    settingsWatcher(),
    hostWatcher(),
    productsWatcher(),
    otherWatcher(),
    billWatcher(),
    profitWatcher(),
    reportWatcher(),
    rankingWatcher(),
    setMenuWatcher(),
    userofshopWatcher(),
  ]);
}

export default rootSaga;
