// import { takeEvery, call, put, delay, takeLatest, select } from "redux-saga/effects";
// import { showLoading, hideLoading, showAlert, showErrorAlert } from "../actions/general";
// import * as apis from "../apis/general";
// import * as cts from "../constants/events/general";
// import * as act from "../actions/general";
// import * as errCts from "../constants/ui/error";

// function* loginFlowSaga({ payload }) {
//   const { email, password, rememberMe } = payload.params;

//   yield put(showLoading());

//   try {
//     yield put(showLoading());
//     const res = yield call(apis.loginAPI, { email, password });
//     const { data } = res;

//     if (data.access_token) {
//       yield put(act.loginFlowSuccess(data));

//       if (rememberMe) {
//         AsyncStorage.setItem("token", data.access_token);
//       }

//       yield put(hideLoading());
//     } else if (data.message) {
//       yield delay(3000);
//       yield put(showAlert(errCts.LOGIN_WRONG_MESSAGE, "danger"));
//       yield put(hideLoading());
//     }
//   } catch (error) {
//     yield put(hideLoading());
//     yield put(showErrorAlert(error, errCts.LOGIN_FAILED));
//   }
// }

export default function* userWatcher() {
  // yield takeLatest(cts.LOGIN_FLOW, loginFlowSaga);
}
