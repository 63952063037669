const IS_DEV = process.env.NODE_ENV !== "development" ? false : false; // true : backup, false: production
export const API_ENDPOINT = IS_DEV
  ? "http://160.251.15.118:8200/api"
  : "https://host-collection.com:8201/api";

export const SOCKET_SERVER = IS_DEV
  ? "http://backup-aube.pthwas.xyz.xyz:9005"
  : "http://host-collection.com:9005";

export const IMAGE_BASE_URL = IS_DEV
  ? "http://160.251.15.118:8200/upload"
  : "http://host-collection.com:8200/upload";

export const HOST_COLLECTION_URL = IS_DEV
  ? "http://160.251.15.118:8300"
  : "http://host-collection.com:8300";

export const IMAGE_BASE_URL_OF_USER = IS_DEV
  ? "http://160.251.15.118:8300/upload"
  : "http://host-collection.com:8300/upload";

export const POS_SETUP_URL = "http://host-collection.com:8500";

export const ENCODE_SECRET_KEY = process.env?.ENCODE_SECRET_KEY || "QiLCJhbGciOiJSUzI";
export const IS_SETUP_PAGE = false;
export const SHOW_SOMETHING = true;
export const SHOW_BILL_LIST = true; //false :hide "open-settings" true:show "open-settings"
