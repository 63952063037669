export const lang = process.env.NODE_ENV !== "development" ? "jp" : "jp";

export const CREATE_TABLE_MESSAGE =
  lang === "en" ? "Create table successfully" : "テーブルを作成しました";
export const UPDATE_TABLE_MESSAGE =
  lang === "en" ? "Update table successfully" : "テーブルを更新しました";
export const DELETE_TABLE_MESSAGE =
  lang === "en" ? "Delete table successfully" : "テーブルを削除しました";
export const CREATE_CATEGORY_MESSAGE =
  lang === "en" ? "Create category successfully" : "カテゴリーを作成しました";
export const CREATE_MULTI_CATEGORY_MESSAGE =
  lang === "en" ? "Create multi category successfully" : "マルチカテゴリを正常に作成する";
export const UPDATE_CATEGORY_MESSAGE =
  lang === "en" ? "Update category successfully" : "カテゴリーを更新しました";
export const DELETE_CATEGORY_MESSAGE =
  lang === "en" ? "Delete category successfully" : "カテゴリーを削除しました";
export const CREATE_PRODUCT_MESSAGE =
  lang === "en" ? "Create product successfully" : "商品を作成しました";
export const CREATE_MULTI_PRODUCT_MESSAGE =
  lang === "en" ? "Create multi product successfully" : "マルチ商品を作成しました";
export const UPDATE_PRODUCT_MESSAGE =
  lang === "en" ? "Update product successfully" : "商品を更新しました";
export const DELETE_PRODUCT_MESSAGE =
  lang === "en" ? "Delete product successfully" : "商品を削除しました";
export const UPDATE_TAX_MESSAGE =
  lang === "en" ? "Update TAX settings successfully" : "TAX設定を更新しました";
export const CREATE_CUSTOMER_MESSAGE =
  lang === "en" ? "Create customer successfully" : "顧客を作成しました";
export const UPDATE_CUSTOMER_MESSAGE =
  lang === "en" ? "Update customer successfully" : "顧客を更新しました";
export const DELETE_CUSTOMER_MESSAGE =
  lang === "en" ? "Delete customer successfully" : "顧客を削除しました";
export const CREATE_BILL_MESSAGE =
  lang === "en" ? "Create bill successfully" : "伝票を作成しました";
export const UPDATE_BILL_MESSAGE =
  lang === "en" ? "Update bill successfully" : "伝票を更新しました";
export const PAYMENT_BILL_MESSAGE =
  lang === "en" ? "This bill has been paid" : "この伝票は支払われました";
export const DELETE_BILL_MESSAGE =
  lang === "en" ? "Delete bill successfully" : "伝票を削除しました";
export const SET_OPEN_SETTINGS_MESSAGE =
  lang === "en" ? "Set Open settings successfully" : "OPEN設定を設定しました";
export const YOUR_SHOP_IS_CLOSED =
  lang === "en" ? "Your shop is closed!" : "店舗はCLOSSEしました！";
export const YOUR_SHOP_IS_OPENED = lang === "en" ? "Your shop is opened!" : "店舗がOPENしました！";
export const CREATE_SALARY_MESSAGE =
  lang === "en" ? "Add new salary settings successfully" : "新しい給与設定を追加しました";
export const UPDATE_SALARY_MESSAGE =
  lang === "en" ? "Update salary settings successfully" : "給与設定をに更新しました";
export const DELETE_SALARY_MESSAGE =
  lang === "en" ? "Delete salary settings successfully" : "給与設定を削除しました";
export const CREATE_HOST_RECEIVABLES_MESSAGE =
  lang === "en" ? "Create receivables of host successfully" : "HOSTの売掛金を作成しました";
export const UPDATE_HOST_RECEIVABLES_MESSAGE =
  lang === "en" ? "Update receivables of host successfully" : "HOSTの売掛金を更新しました";
export const DELETE_HOST_RECEIVABLES_MESSAGE =
  lang === "en" ? "Delete receivables of host successfully" : "HOSTの売掛金を削除しました";
export const UPDATE_HOST_SALARY_MESSAGE =
  lang === "en" ? "Update salary of host successfully" : "HOSTの給与を更新ました";
export const CREATE_HOST_OTHER_MONEY_MESSAGE =
  lang === "en" ? "Create other money of host successfully" : "HOSTのその他のお金を作成しました";
export const UPDATE_HOST_OTHER_MONEY_MESSAGE =
  lang === "en" ? "Update other money of host successfully" : "HOSTのその他のお金を更新しました";
export const DELETE_HOST_OTHER_MONEY_MESSAGE =
  lang === "en" ? "Delete other money of host successfully" : "HOSTのその他のお金を削除しました";
export const UPDATE_NOTIFICATION_MESSAGE =
  lang === "en" ? "Update notification settings successfully" : "通知設定を更新しました";
export const UPDATE_SHOP_SETTINGS_MESSAGE =
  lang === "en" ? "Update shop settings successfully" : "店舗の設定を更新しました";
export const UPDATE_SUCCESS = lang === "en" ? "Update successfully" : "更新しました";
export const DELETE_SUCCESS = lang === "en" ? "Delete successfully" : "削除しました";
export const CREATE_SUCCESS = lang === "en" ? "Create successfully" : "作成しまました";
export const DELETE_BILL_SUCCESS =
  lang === "en" ? "Delete bill successfully" : "伝票を削除しました";
export const CREATE_BILL_SUCCESS =
  lang === "en" ? "Create bill successfully" : "伝票を作成しました";
export const ADDED = lang === "en" ? "Added" : "追加しました";
export const UPDATED = lang === "en" ? "Updated" : "更新しました";
export const DELETED = lang === "en" ? "Deleted" : "削除しました";
export const UPDATE_OPEN_SETTINGS_MESSAGE =
  lang === "en" ? "Update open settings successfully" : "OPEN設定を更新しました";
export const DELETE_SALARY_SUCCESS =
  lang === "en" ? "Delete salary successfully" : "給与を削除しました";
export const UPDATE_SALARY_NOMINATION_SUCCESS =
  lang === "en" ? "Update salary nomination successfully" : "指名料金を更新しました";
export const EXPORT_SUCCESS_MESSAGE =
  lang === "en" ? "Export file successfully" : "ファイルを正常にエクスポート";
export const UPDATED_SUCCESS = lang === "en" ? "Updated successfully" : "更新成功";
export const RESTORE_BILL_SUCCESSFULLY =
  lang === "en" ? "Restore bill successfully" : "請求書を正常に復元する";
export const PRINT_SETTINGS_SUCCESSFULLY =
  lang === "en" ? "Save print settings successfully" : "印刷設定を正常に更新";
//TrangNguyen
export const CREATE_STAFF_MESSAGE =
  lang === "en" ? "Create staff successfully" : "スタッフを正常に作成する";
export const UPDATE_STAFF_MESSAGE =
  lang === "en" ? "Update staff successfully" : "スタッフを正常に更新する";
export const DELETE_STAFF_MESSAGE =
  lang === "en" ? "Delete staff successfully" : "スタッフを正常に削除する";

export const DELETE_PRODUCT_IMAGE_SUCCESS =
  lang === "en" ? "Delete product image successfully" : "商品画像を削除するうまく";
export const WAIT_FOR_CONFIRMATION =
  lang === "en"
    ? "I have created a store account. Please wait for confirmation."
    : "店舗のアカウントを作成しました。確認をお待ちください。";
//DungLe
export const UPDATE_ORDER_LIST_SUCCESS =
  lang === "en" ? "Update order list success" : "注文リストの更新の成功";
// INVITE STAFF
export const INVITE_STAFF_SUCCESS =
  lang === "en" ? "Invite staff successfully" : "スタッフを招待しました";

export const DELETE_CATEGORY_SUCCESS =
  lang === "en" ? "Delete category successfully" : "カテゴリーを削除しました";
