import request from "../helpers/axiosService";
const URL_ADS_CHANNEL = "ads-channel";
const URL_ADS_CUSTOMER_INTRODUCTION = "/v2/customer-store-arrival-date";

export function fetchAdsListApi(token, params) {
  return request({
    url: URL_ADS_CHANNEL,
    method: "get",
    params,
    token,
  });
}

export function createAdsAPI(token, data) {
  return request({
    url: URL_ADS_CHANNEL,
    method: "post",
    data,
    token,
  });
}

export function updateAdsAPI(token, id, data) {
  return request({
    url: `${URL_ADS_CHANNEL}/${id}`,
    method: "put",
    data,
    token,
  });
}

export function deleteAdsAPI(token, data) {
  return request({
    url: `${URL_ADS_CHANNEL}/${0}`,
    method: "delete",
    token,
    data,
  });
}

//Customer ADS

export function fetchAdsCustomerAPI(token, params) {
  return request({
    url: URL_ADS_CUSTOMER_INTRODUCTION,
    method: "get",
    params,
    token,
  });
}

//introduction ADS

export function fetchAdsIntroductionAPI(token, params) {
  return request({
    url: URL_ADS_CUSTOMER_INTRODUCTION,
    method: "get",
    params,
    token,
  });
}
