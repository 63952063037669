export const FETCH_CATEGORY_LIST = "FETCH_CATEGORY_LIST";
export const FETCH_CATEGORY_LIST_SUCCESS = "FETCH_CATEGORY_LIST_SUCCESS";

export const CREATE_NEW_CATEGORY = "CREATE_NEW_CATEGORY";
export const CREATE_NEW_CATEGORY_SUCCESS = "CREATE_NEW_CATEGORY_SUCCESS";

export const CREATE_MULTI_CATEGORY = "CREATE_MULTI_CATEGORY";
export const CREATE_MULTI_CATEGORY_SUCCESS = "CREATE_MULTI_CATEGORY_SUCCESS";

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";

export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";

export const FETCH_PRODUCT_LIST = "FETCH_PRODUCT_LIST";
export const FETCH_PRODUCT_LIST_SUCCESS = "FETCH_PRODUCT_LIST_SUCCESS";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILED = "CREATE_PRODUCT_FAILED";

export const CREATE_MULTI_PRODUCT = "CREATE_MULTI_PRODUCT";
export const CREATE_MULTI_PRODUCT_SUCCESS = "CREATE_MULTI_PRODUCT_SUCCESS";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";

export const FETCH_PRODUCT_LIST_FOR_ORDER = "FETCH_PRODUCT_LIST_FOR_ORDER";
export const FETCH_PRODUCT_LIST_FOR_ORDER_SUCCESS = "FETCH_PRODUCT_LIST_FOR_ORDER_SUCCESS";

export const UPDATE_ORDER_CATEGORY = "UPDATE_ORDER_CATEGORY";
export const UPDATE_ORDER_CATEGORY_SUCCESS = "UPDATE_ORDER_CATEGORY_SUCCESS";

export const UPDATE_ORDER_PRODUCT = "UPDATE_ORDER_PRODUCT";
export const UPDATE_ORDER_PRODUCT_SUCCESS = "UPDATE_ORDER_PRODUCT_SUCCESS";

export const RESET_FETCHED_CATEGORY = "RESET_FETCH_CATEGORY";
