import request from "../helpers/axiosService";

// const URL_CATEGORY = "api/product-category";
// const URL_PRODUCTS = "api/products";

const URL_CATEGORY = "product-category";
const URL_PRODUCTS = "products";

export function fetchCategoryListAPI(token, shopId, type_get) {
  return request({
    url: URL_CATEGORY,
    method: "get",
    params: { shopId, type_get },
    token,
  });
}

export function createCategoryAPI(token, data) {
  return request({
    url: URL_CATEGORY,
    method: "post",
    data,
    token,
  });
}

export function updateCategoryAPI(token, id, data) {
  return request({
    url: `${URL_CATEGORY}/${id}`,
    method: "put",
    data,
    token,
  });
}

// export function updateCategoryAPI(token, id, data) {
//   return request({
//     url: `${URL_CATEGORY}/${id}`,
//     method: "post",
//     data,
//     token,
//   });
// }

export function deleteCategoryAPI(token, id) {
  return request({
    url: `${URL_CATEGORY}/${id}`,
    method: "delete",
    token,
  });
}

export function fetchProductListAPI(token, params) {
  return request({
    url: URL_PRODUCTS,
    method: "get",
    params,
    token,
  });
}

export function createProductAPI(token, data) {
  return request({
    url: URL_PRODUCTS,
    method: "post",
    data,
    token,
    isFormData: true,
  });
}

export function updateProductAPI(token, id, data, noFormData) {
  return request({
    url: `${URL_PRODUCTS}/${id}`,
    method: noFormData ? "put" : "post",
    data,
    token,
    isFormData: !noFormData,
  });
}

export function deleteProductAPI(token, id, data) {
  return request({
    url: `${URL_PRODUCTS}/${id}`,
    method: "delete",
    token,
    data,
  });
}
