import request from "../helpers/axiosService";

// const URL_TABLE = "api/tables";
// const URL_TAX = "api/tax";
// const URL_NOTIFICATION = "api/notifications";
// const URL_SETTINGS = "api/shop-settings";

const URL_TABLE = "tables";
const URL_TAX = "tax";
const URL_NOTIFICATION = "notifications";
const URL_SETTINGS = "shop-settings";

export function fetchTableListAPI(token, shopId, type_get) {
  return request({
    url: URL_TABLE,
    method: "get",
    params: { shopId, type_get },
    token,
  });
}

export function createNewTableAPI(token, data) {
  return request({
    url: URL_TABLE,
    method: "post",
    data,
    token,
  });
}
// export function updateTableAPI(token, id, data) {
//   return request({
//     url: `${URL_TABLE}/${id}`,
//     method: "put",
//     data,
//     token,
//   });
// }

export function updateTableAPI(token, id, data) {
  return request({
    url: `${URL_TABLE}/${id}`,
    method: "put",
    data,
    token,
  });
}

export function deleteTableAPI(token, id) {
  return request({
    url: `${URL_TABLE}/${id}`,
    method: "delete",
    token,
  });
}

// TAX
export function getTaxAPI(token, shopId) {
  return request({
    url: URL_TAX,
    method: "get",
    params: { shopId },
    token,
  });
}

export function updateTaxAPI(token, data) {
  return request({
    url: URL_TAX,
    method: "post",
    data,
    token,
  });
}

// NOTIFICATION
export function getNotificationAPI(token, shop_id, user_id) {
  return request({
    url: URL_NOTIFICATION,
    method: "get",
    params: { shop_id, user_id },
    token,
  });
}

export function updateNotificationAPI(token, data) {
  return request({
    url: URL_NOTIFICATION,
    method: "post",
    data,
    token,
  });
}

// SHOP SETTINGS
export function getShopSettingsAPI(token, params) {
  return request({
    url: URL_SETTINGS,
    method: "get",
    token,
    params,
  });
}

export function updateShopSettingsAPI(token, data) {
  return request({
    url: URL_SETTINGS,
    method: "post",
    data,
    token,
  });
}
