/* eslint-disable prefer-const */
/* eslint-disable object-shorthand */
import { put, takeEvery, call, select } from "redux-saga/effects";
import * as cts from "../constants/events/profit";
import * as act from "../actions/profit";
import * as apis from "../apis/profit";
import * as ctsErr from "../constants/ui/error";
import { showLoading, hideLoading, showFetchLoading, hideFetchLoading } from "../actions/general";
import { failedToast } from "../helpers/AlertHelper";

function* fetchProfitSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());
  try {
    const res = yield call(apis.fetchProfitAPI, token, payload.params);
    const total = [...res.data].find((i) => i.total_current);

    // eslint-disable-next-line prefer-const
    let data = [...res.data].filter((i) => i.total_current === undefined);

    yield put(
      act.fetchProfitSuccess({
        data: data,
        total: total || 0,
        shopId: payload.params.shop_id,
      })
    );

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.FETCH_PROFIT_FAILED);
  }
}

function* fetchProfitTimeSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchProfitTimeAPI, token, payload.params);
    const { data, current_page, last_page, total } = res.data;

    yield put(
      act.fetchProfitTimeDetailSuccess({
        data: data,
        currentPage: current_page,
        totalPage: last_page,
        total,
        shopId: payload.params.shop_id,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsErr.FETCH_PROTFIT_TIME_FAILED);
  }
}

function* fetchProfitHostSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchProfitHostAPI, token, payload.params);

    const { data, current_page, last_page, total } = res.data;

    const dataPayload = {
      data: payload.params.type === "date" ? data : res.data,
      currentPage: payload.params.type === "date" ? current_page : 1,
      totalPage: payload.params.type === "date" ? last_page : 1,
      total,
      shopId: payload.params.shop_id,
    };

    yield put(act.fetchProfitHostDetailSuccess(dataPayload));
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsErr.FETCH_PROFIT_HOST_FAILED);
  }
}

function* fetchProfitProductSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchProfitProductAPI, token, payload.params);
    const { data, current_page, last_page, total } = res.data;

    yield put(
      act.fetchProfitProductDetailSuccess({
        data: payload.params.type === "date" ? data : res.data,
        currentPage: payload.params.type === "date" ? current_page : 1,
        totalPage: payload.params.type === "date" ? last_page : 1,
        total,
        shopId: payload.params.shop_id,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsErr.FETCH_PROFIT_PRODUCT_FAILED);
  }
}

function* addShopToCompareSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchProfitAPI, token, payload.params);

    const total = [...res.data].find((i) => i.total_current);

    const data = [...res.data].filter((i) => i.total_current === undefined);

    yield put(
      act.addShopToCompareSuccess({
        data: data,
        total: total || 0,
        shopId: payload.params.shop_id,
      })
    );

    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsErr.ADD_SHOP_TO_COMPARE_FAILED);
  }
}

function* addMultiShopToCompareSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());
  yield put(act.resetShopCompare());

  // eslint-disable-next-line no-restricted-syntax
  for (let id of payload.params.shops) {
    try {
      const res = yield call(apis.fetchProfitAPI, token, {
        name: payload.params.name,
        type: payload.params.type,
        date: payload.params.date,
        shop_id: id,
      });

      const data = [...res.data].filter((i) => i.total_current === undefined);
      const total = [...res.data].find((i) => i.total_current);

      yield put(
        act.addShopToCompareSuccess({
          data: data,
          shopId: id,
          total: total || 0,
        })
      );
      yield put(hideFetchLoading());
    } catch (error) {
      yield put(hideFetchLoading());
      failedToast(error, ctsErr.ADD_MULTI_SHOP_TO_COMPARE_FAILED);
    }
  }
}

export default function* profitWatcher() {
  yield takeEvery(cts.FETCH_PROFIT, fetchProfitSaga);
  yield takeEvery(cts.FETCH_PROFIT_TIME, fetchProfitTimeSaga);
  yield takeEvery(cts.FETCH_PROFIT_HOST, fetchProfitHostSaga);
  yield takeEvery(cts.FETCH_PROFIT_PRODUCT, fetchProfitProductSaga);
  yield takeEvery(cts.ADD_SHOP_TO_COMPARE, addShopToCompareSaga);
  yield takeEvery(cts.ADD_MULTI_SHOP_COMPARE, addMultiShopToCompareSaga);
}
