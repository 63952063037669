import { io } from "socket.io-client";
import { SOCKET_SERVER } from "../constants";

const socket = io(SOCKET_SERVER, { forceNew: true, transports: ["websocket"] });

export const clientJoinRoom = (data) => {
  socket.emit("client-join-room", data);
};

// bill

export const clientCreateBill = (data) => {
  socket.emit("client-create-bill", data);
};

export const serverSendBill = (callback) => {
  socket.on("server-send-bill", callback);
};

export const clientDeleteBill = (data) => {
  socket.emit("client-delete-bill", data);
};

export const serverSendBillDeleted = (callback) => {
  socket.on("server-send-bill-deleted", callback);
};

export const clientUpdateBill = (data) => {
  socket.emit("client-update-bill", data);
};

export const serverSendBillUpdated = (callback) => {
  socket.on("server-send-bill-updated", callback);
};

export const clientAddAppointeeToBill = (data) => {
  socket.emit("client-add-appointee-to-bill", data);
};

export const serverSendAppointeeAfterAddToBill = (callback) => {
  socket.on("server-send-appointee-after-add-to-bill", callback);
};

export const clientPaymentBill = (data) => {
  socket.emit("client-payment-bill", data);
};

export const serverSendBillAfterPayment = (callback) => {
  socket.on("server-send-bill-after-payment", callback);
};

export const clientPaymentBillGlobal = (data) => {
  socket.emit("client-payment-bill-global", data);
};

export const serverSendBillGlobal = (callback) => {
  socket.on("server-send-bill-global", callback);
};

export const clientAddProductInBill = (data) => {
  socket.emit("client-add-product-in-bill", data);
};

export const serverSendProductInBill = (callback) => {
  socket.on("server-send-product-in-bill", callback);
};

export const clientAddSetInBill = (data) => {
  socket.emit("client-add-set-in-bill", data);
};

export const serverSendSetInBill = (callback) => {
  socket.on("server-send-set-in-bill", callback);
};

export const clientUpdateProductInBill = (data) => {
  socket.emit("client-update-product-in-bill", data);
};

export const serverSendProductInBillAfterUpdate = (callback) => {
  socket.on("server-send-product-in-bill-after-update", callback);
};

export const clientUpdateSetInBill = (data) => {
  socket.emit("client-update-set-in-bill", data);
};

export const serverSendSetInBillAfterUpdate = (callback) => {
  socket.on("server-send-set-in-bill-after-update", callback);
};

export const clientDeleteProductInBill = (data) => {
  socket.emit("client-delete-product-in-bill", data);
};

export const serverSendProductInBillAfterDelete = (callback) => {
  socket.on("server-send-product-in-bill-after-delete", callback);
};

// settings

export const clientOpenSettings = (data) => {
  socket.emit("client-open-settings", data);
};

export const serverSendOpenSettings = (callback) => {
  socket.on("server-send-open-settings", callback);
};

export const clientUpdateShopSettings = (data) => {
  socket.emit("client-update-shop-settings", data);
};

export const serverSendShopSettingsUpdated = (callback) => {
  socket.on("server-send-shop-settings-updated", callback);
};

// category

export const clientCreateCategory = (data) => {
  socket.emit("client-create-category", data);
};

export const serverSendCategoryCreated = (callback) => {
  socket.on("server-send-category-created", callback);
};

export const clientUpdateCategory = (data) => {
  socket.emit("client-update-category", data);
};

export const serverSendCategoryUpdated = (callback) => {
  socket.on("server-send-category-updated", callback);
};

export const clientUpdateOrderCategory = (data) => {
  socket.emit("client-update-order-category", data);
};

export const serverSendCategoryUpdatedOrder = (callback) => {
  socket.on("server-send-category-updated-order", callback);
};

export const clientDeleteCategory = (data) => {
  socket.emit("client-delete-category", data);
};

export const serverSendCategoryDeleted = (callback) => {
  socket.on("server-send-category-deleted", callback);
};

// tables

export const clientCreateTable = (data) => {
  socket.emit("client-create-table", data);
};

export const serverSendTableCreated = (callback) => {
  socket.on("server-send-table-created", callback);
};

export const clientUpdateTable = (data) => {
  socket.emit("client-update-table", data);
};

export const serverSendTableUpdated = (callback) => {
  socket.on("server-send-table-updated", callback);
};

export const clientDeleteTable = (data) => {
  socket.emit("client-delete-table", data);
};

export const serverSendTableDeleted = (callback) => {
  socket.on("server-send-table-deleted", callback);
};

// tables

export const clientCreateSetMenu = (data) => {
  socket.emit("client-create-set-menu", data);
};

export const serverSendSetMenuCreated = (callback) => {
  socket.on("server-send-set-menu-created", callback);
};

export const clientUpdateSetMenu = (data) => {
  socket.emit("client-update-set-menu", data);
};

export const serverSendSetMenuUpdated = (callback) => {
  socket.on("server-send-set-menu-updated", callback);
};

export const clientDeleteSetMenu = (data) => {
  socket.emit("client-delete-set-menu", data);
};

export const serverSendSetMenuDeleted = (callback) => {
  socket.on("server-send-set-menu-deleted", callback);
};

//user of shop
export const clientCreateStaff = (data) => {
  socket.emit("client-create-staff", data);
};

export const clientUpdateStaff = (data) => {
  socket.emit("client-update-staff", data);
};

export const clientDeleteStaff = (data) => {
  socket.emit("client-delete-staff", data);
};

//ads

export const clientDeleteAds = (data) => {
  socket.emit("client-delete-ads", data);
};
