import { takeEvery, call, select, put, takeLatest } from "redux-saga/effects";
import * as cts from "../constants/events/set-menu";
import * as ctsErr from "../constants/ui/error";
import * as ctsSuccess from "../constants/ui/success";
import * as act from "../actions/set-menu";
import * as apis from "../apis/set-menu";
import * as socket from "../sockets";
import { failedToast, successToast } from "../helpers/AlertHelper";
import { showFetchLoading, hideFetchLoading, showLoading, hideLoading } from "../actions/general";

function* fetchSetMenuSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));

  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchSetMenuAPI, token, payload.params);
    yield put(act.fetchSetMenuSuccess({ data: res.data, shopId: payload.params.shop_id }));

    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsErr.FETCH_SET_MENU_FAILED);
  }
}

function* createSetMenuSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));

  yield put(showLoading("create-set-menu"));

  try {
    const res = yield call(apis.createSetMenuAPI, token, payload.params);
    yield put(act.createSetMenuSuccess(res.data));
    socket.clientCreateSetMenu(res.data);
    successToast(ctsSuccess.CREATE_SUCCESS);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.CREATE_FAILED);
  }
}

function* deleteSetMenuSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const selectedShop = yield select((state) => state.getIn(["user", "selectedShop"]).toJS());
  yield put(showLoading("delete-set-menu"));

  try {
    yield call(apis.deleteSetMenuAPI, token, payload.id);
    yield put(act.deleteSetMenuSuccess(payload));
    socket.clientDeleteSetMenu({ ...payload, shop_id: selectedShop.id });
    successToast(ctsSuccess.DELETE_SUCCESS);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.DELETE_FAILED);
  }
}

// function* fetchCustomerTypeSaga({ payload }) {
//   const token = yield select((state) => state.getIn(["user", "token"]));

//   yield put(showFetchLoading());

//   try {
//     const res = yield call(apis.fetchCustomerTypeAPI, token, payload.params);
//     yield put(act.fetchCustomerTypeSuccess({ data: res.data, shopId: payload.params.shop_id }));

//     yield put(hideFetchLoading());
//   } catch (error) {
//     yield put(hideFetchLoading());
//     failedToast(error, ctsErr.FETCH_SET_MENU_FAILED);
//   }
// }

// function* createCustomerTypeSaga({ payload }) {
//   const token = yield select((state) => state.getIn(["user", "token"]));

//   yield put(showLoading("create-customer-type"));

//   try {
//     const res = yield call(apis.createCustomerTypeAPI, token, payload.params);
//     yield put(act.createCustomerTypeSuccess(res.data));
//     successToast(ctsSuccess.CREATE_SUCCESS);
//     yield put(hideLoading());
//   } catch (error) {
//     yield put(hideLoading());
//     failedToast(error, ctsErr.CREATE_FAILED);
//   }
// }

// function* updateCustomerTypeSaga({ payload }) {
//   const token = yield select((state) => state.getIn(["user", "token"]));

//   yield put(showLoading(`update-customer-type`));

//   try {
//     const res = yield call(apis.updateCustomerTypeAPI, token, payload.id, payload.params);
//     yield put(act.updateCustomerTypeSuccess(res.data));
//     successToast(ctsSuccess.DELETE_SUCCESS);
//     yield put(hideLoading());
//   } catch (error) {
//     yield put(hideLoading());
//     failedToast(error, ctsErr.DELETE_FAILED);
//   }
// }

// function* deleteCustomerTypeSaga({ payload }) {
//   const token = yield select((state) => state.getIn(["user", "token"]));

//   yield put(showLoading(`delete-customer-type-${payload.id}`));

//   try {
//     yield call(apis.deleteCustomerTypeAPI, token, payload.id);
//     yield put(act.deleteCustomerTypeSuccess(payload));
//     successToast(ctsSuccess.DELETE_SUCCESS);
//     yield put(hideLoading());
//   } catch (error) {
//     yield put(hideLoading());
//     failedToast(error, ctsErr.DELETE_FAILED);
//   }
// }

// function* updateSetMenuSaga({ payload }) {
//   const token = yield select((state) => state.getIn(["user", "token"]));

//   yield put(showLoading(`update-set-menu`));

//   try {
//     if (payload.params.is_orderby) {
//       yield put(act.updateSetMenuSuccess(payload.params));
//     }
//     const res = yield call(apis.updateSetMenuAPI, token, payload.id, payload.params);
//     if (!payload.params.is_orderby) {
//       yield put(act.updateSetMenuSuccess(res.data));
//     }
//     socket.clientUpdateSetMenu(payload.params.is_orderby ? payload.params : res.data);
//     yield put(hideLoading());
//   } catch (error) {
//     yield put(hideLoading());
//     failedToast(error, ctsErr.UPDATE_FAILED);
//   }
// }

export default function* setMenuWatcher() {
  yield takeEvery(cts.FETCH_SET_MENU, fetchSetMenuSaga);
  yield takeLatest(cts.CREATE_SET_MENU, createSetMenuSaga);
  yield takeLatest(cts.DELETE_SET_MENU, deleteSetMenuSaga);
  // yield takeEvery(cts.FETCH_CUSTOMER_TYPE, fetchCustomerTypeSaga);
  // yield takeLatest(cts.CREATE_CUSTOMER_TYPE, createCustomerTypeSaga);
  // yield takeLatest(cts.DELETE_CUSTOMER_TYPE, deleteCustomerTypeSaga);
  // yield takeLatest(cts.UPDATE_CUSTOMER_TYPE, updateCustomerTypeSaga);
  // yield takeLatest(cts.UPDATE_SET_MENU, updateSetMenuSaga);
}
