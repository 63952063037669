import memoize from "fast-memoize";

const categoryList = (state) => state.getIn(["products", "categoryList"]).toJS();
const productList = (state) => state.getIn(["products", "productList"]).toJS();
const isActionDone = (state) => state.getIn(["products", "isActionDone"]);
const createMultiProductProcess = (state) => state.getIn(["products", "createMultiProductProcess"]);
const categoryNew = (state) => state.getIn(["products", "categoryNew"]);

export const categoryNewSelector = memoize(categoryNew);
export const categoryListSelector = memoize(categoryList);
export const productListSelector = memoize(productList);
export const isActionDoneSelector = memoize(isActionDone);
export const createMultiProductProcessSelector = memoize(createMultiProductProcess);
