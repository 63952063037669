import request from "../helpers/axiosService";

// const URL_CUSTOMER_TYPE = "set-menu-customer-type";
const URL_SET_MENU = "set-menu";

export function fetchSetMenuAPI(token, params) {
  return request({
    url: URL_SET_MENU,
    method: "get",
    params,
    token,
  });
}

export function createSetMenuAPI(token, data) {
  return request({
    url: URL_SET_MENU,
    method: "post",
    data,
    token,
  });
}

export function deleteSetMenuAPI(token, id) {
  return request({
    url: `${URL_SET_MENU}/${id}`,
    method: "delete",
    token,
  });
}

// CUSTOMER TYPE
// export function fetchCustomerTypeAPI(token, params) {
//   return request({
//     url: URL_CUSTOMER_TYPE,
//     method: "get",
//     params,
//     token,
//   });
// }

// export function createCustomerTypeAPI(token, data) {
//   return request({
//     url: URL_CUSTOMER_TYPE,
//     method: "post",
//     data,
//     token,
//   });
// }

// export function updateCustomerTypeAPI(token, id, data) {
//   return request({
//     url: `${URL_CUSTOMER_TYPE}/${id}`,
//     method: "put",
//     data,
//     token,
//   });
// }

// export function deleteCustomerTypeAPI(token, id) {
//   return request({
//     url: `${URL_CUSTOMER_TYPE}/${id}`,
//     method: "delete",
//     token,
//   });
// }

// export function updateSetMenuAPI(token, id, data) {
//   return request({
//     url: `${URL_SET_MENU}/${id}`,
//     method: "put",
//     data,
//     token,
//   });
// }
