/* eslint-disable dot-notation */
/* eslint-disable no-continue */
/* eslint-disable prefer-spread */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
/* eslint-disable no-else-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable one-var */
/* eslint-disable no-bitwise */
/* eslint-disable prefer-const */
/* eslint-disable prefer-template */
import { message } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
// import * as XLSX from "xlsx-js-style";
// import * as XLSX from "xlsx";
import * as XLSX from "sheetjs-style";
import "../assets/fonts/Meiryo UI W53 Regular-normal";
import "../assets/fonts/NotoSansCJKjp-Regular-normal";
import * as cts from "../constants/ui/general";
import * as ctsSuccess from "../constants/ui/success";
import { routes } from "../routes";

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export function formatMoney(val) {
  let value = `${val}`;
  value = value.replace(/,/g, "");
  const x = Number(value);

  return x.toLocaleString();
}

export function formatMoneyNew(val) {
  let value = `${val}`;
  if (val >= 0) {
    value = value.replace(/,/g, "");
    const x = Number(value);
    return `${cts.YEN}${x.toLocaleString()}`;
  } else {
    value = value.replace(/[,-]/g, "");
    const x = Number(value);
    return `-${cts.YEN}${x.toLocaleString()}`;
  }
}

export function getPriceWithRounding(total, settings) {
  if (settings.option_rounding === 1) {
    const round = settings.type_rounding || 100;
    return Math.ceil(total / round) * round;
  }

  if (settings.option_rounding === 2) {
    const round = settings.type_rounding || 100;
    return Math.floor(total / round) * round;
  }

  return total;
}

export function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  // if (num > 0) {
  //   return num.toFixed(1);
  // }
  return Math.round(num);
}

export function notRoundMoney(val) {
  let value = parseInt(val || 0, 10);
  return value.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

export const convertDate = (d) => {
  const offset = moment(d).utcOffset();
  const date = moment(d).subtract(offset, "minutes");

  if (cts.lang === "jp") {
    return date.format(`YYYY${cts.YEAR_C}M${cts.MONTH_C}D${cts.DAY_C}`);
  }

  return date.format(`YYYY${cts.YEAR_C}MM${cts.MONTH_C}DD${cts.DAY_C}`);
};

export const convertDate2 = (d) => {
  const date = moment(d);
  if (cts.lang === "jp") {
    return date.format(`YYYY${cts.YEAR_C}M${cts.MONTH_C}D${cts.DAY_C}`);
  }

  return date.format(`YYYY${cts.YEAR_C}MM${cts.MONTH_C}DD${cts.DAY_C}`);
};

export const getDayAndMonth = (d) => {
  const offset = moment(d).utcOffset();
  const date = moment(d).subtract(offset, "minutes");

  if (cts.lang === "jp") {
    return date.format(`M${cts.MONTH_C}D${cts.DAY_C}`);
  }

  return date.format(`MM/DD`);
};

export const getMonthAndYear = (d) => {
  const offset = moment(d).utcOffset();
  const date = moment(d).subtract(offset, "minutes");

  if (cts.lang === "jp") {
    return date.format(`YYYY${cts.YEAR_C}M${cts.MONTH_C}`);
  }

  return date.format(`YYYY/MM`);
};

export const getMonthAndYear2 = (d) => {
  const date = moment(d);
  if (cts.lang === "jp") {
    return date.format(`YYYY${cts.YEAR_C}M${cts.MONTH_C}`);
  }

  return date.format(`YYYY/MM`);
};

export function getTime(d) {
  const offset = moment(d).utcOffset();
  const date = moment(d).subtract(offset, "minutes");

  return moment(date).format("HH:mm");
}

export function getMonthNumeric(d) {
  const date = new Date(d);

  return date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
}

export const getDayInWeek = (d, isFull) => {
  const week = new Array(7);
  week[0] = isFull ? cts.SUNDAY_FULL : cts.SUNDAY;
  week[1] = isFull ? cts.MONDAY_FULL : cts.MONDAY;
  week[2] = isFull ? cts.TUESDAY_FULL : cts.TUESDAY;
  week[3] = isFull ? cts.WEDNESDAY_FULL : cts.WEDNESDAY;
  week[4] = isFull ? cts.THURSDAY_FULL : cts.THURSDAY;
  week[5] = isFull ? cts.FRIDAY_FULL : cts.FRIDAY;
  week[6] = isFull ? cts.SATURDAY_FULL : cts.SATURDAY;

  const offset = moment(d).utcOffset();
  const date = moment(d).subtract(offset, "minutes");

  return week[date.day()];
};

export const getFullDate = (d) => {
  const date = moment(d);

  return date.format(`YYYY-MM-DD`);
};

export const uuidv4 = () => {
  return "xxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getDayInMonth = (d) => {
  const date = new Date(d);
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export const getFirstRoute = () => {
  const route = routes.find((i) => i.id);
  return route?.path || "/";
};

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function parseContentOfSetMenu(content) {
  if (content && content.includes("[")) {
    let menu = JSON.parse(content);
    menu = menu.map((i) => `${i.qty === "free" ? cts.FREE : i.qty} ${i.prod}`);
    return menu.join(" + ");
  }
  return content;
}

export const nextDate = (d, type, number = 1) => {
  let date = moment(d);

  if (type === "y") {
    date = date.add(number, "year").format("YYYY-MM-DD");
  }

  if (type === "m") {
    date = date.add(number, "month").format("YYYY-MM-DD");
  }

  if (type === "d") {
    date = date.add(number, "day").format("YYYY-MM-DD");
  }

  return new Date(date);
};

export const prevDate = (d, type, number = 1) => {
  let date = moment(d);

  if (type === "y") {
    date = date.subtract(number, "year").format("YYYY-MM-DD");
  }

  if (type === "m") {
    date = date.subtract(number, "month").format("YYYY-MM-DD");
  }

  if (type === "d") {
    date = date.subtract(number, "day").format("YYYY-MM-DD");
  }

  return new Date(date);
};

export function getSetMenuPrice(item, time = moment(), billInfo) {
  let initPrice = item.init_price;
  let extPrice = item.extension_price;

  const diff = time.diff(moment(item?.created_at), "m");
  const extra = diff - item.init_per_time;

  let extraPrice = 0;
  if (extra > 0) {
    extraPrice = Math.floor(extra / item.extension_per_time);
    if (extra % item.extension_per_time > 0) {
      extraPrice += 1;
    }
    extraPrice *= extPrice;
  }
  // extension

  // price of extra product
  const set_detail =
    item.set_detail && item.set_detail.includes("[") && item.set_detail.includes("]");
  const setDetails = set_detail ? JSON.parse(item.set_detail) : [];
  const selectedSetDetail = item.note ? JSON.parse(item.note) : [];

  let extraProductPrice = selectedSetDetail.map((s) => {
    const found = setDetails.find((i) => i.prod === s.prod);
    if (found && parseInt(found?.qty || 0, 10) < parseInt(s.qty || 0, 10)) {
      return (parseInt(s.qty || 0, 10) - parseInt(found?.qty || 0, 10)) * found.price;
    }
    return 0;
  });
  extraProductPrice = extraProductPrice.length > 0 ? extraProductPrice.reduce((a, b) => a + b) : 0;
  extraProductPrice = Number.isNaN(extraProductPrice) ? 0 : extraProductPrice;
  // end price of extra product

  // price of drink buffet
  let drinkBuffetPrice = 0;

  const hasMain = billInfo?.service_fee?.find((i) => i.type === 1);
  if (item.drink_buffet && hasMain) {
    drinkBuffetPrice = parseInt(item.drink_buffet_price || 0, 10);
  }

  if (item.is_free_time) {
    return initPrice + drinkBuffetPrice;
  }

  return initPrice + (extraPrice || 0) + extraProductPrice + drinkBuffetPrice;
}

export function getNominationFees(set, appointees, billInfo) {
  if (!appointees || appointees?.length === 0) {
    return { main: 0, sub: 0, companion: 0, total: 0 };
  }

  let nomination = appointees.sort((a, b) => a.type - b.type);
  nomination = nomination.slice(set?.free_nomination || 0, nomination.length);

  const main = nomination.filter((i) => i.type === 1);
  const sub = nomination.filter((i) => i.type === 2);
  const companion = nomination.filter((i) => i.type === 3);

  const mainPrice = main.length * ((billInfo?.nomination_fee || 0) + (set?.seat_fees || 0));
  const subPrice = sub.length * (billInfo?.sub_nomination_fee || 0);
  const companionPrice = companion.length * (billInfo?.companion_fee || 0);

  return {
    main: mainPrice,
    sub: subPrice,
    companion: companionPrice,
    seat: (main.length + sub.length) * set?.seat_fees,
    total: mainPrice + subPrice + companionPrice,
    main_length: main.length,
    sub_length: sub.length,
    seat_fees: set?.seat_fees,
  };
}

export function getTaxOfSet({ item, time, TAX, VAT }) {
  let initPrice = 0;
  let extPrice = 0;

  if (TAX && !item.init_tax) initPrice = item.init_price * TAX;
  if (VAT && !item.init_consumption_tax) initPrice = item.init_price * VAT;
  if (TAX && !item.extension_tax) extPrice = item.extension_price * TAX;
  if (VAT && !item.extension_consumption_tax) extPrice = item.extension_price * VAT;

  if (item.is_free_time) {
    return initPrice;
  }

  const diff = time.diff(moment(item.created_at), "m");
  const extra = diff - item.init_per_time;

  let extraPrice = 0;
  if (extra > 0) {
    extraPrice = Math.floor(extra / (item.extension_per_time || 1));
    if (extra % item.extension_per_time > 0) {
      extraPrice += 1;
    }
    extraPrice *= extPrice;
  }

  return parseInt(initPrice, 10) + parseInt(extraPrice, 10);
}
export const formatDate = (d) => {
  const date = moment(d).format("YYYY/MM/DD");
  return date;
};

export const CSVConvertor = (data, plainOptions, setExportInfo, exportInfo, setExportLoading) => {
  let exportCSV = "";

  const findHeader = (item) => {
    return plainOptions?.find((i) => i.value === item);
  };
  // Get header
  let headers = "Shop name,";
  if (plainOptions && plainOptions.length > 0) {
    exportInfo.checkedList.map((item) => (headers += '"' + findHeader(item).label + '",'));
  } else {
    exportInfo.checkedList.map((item) => (headers += '"' + item.label + '",'));
  }
  exportCSV += headers + "\n";

  // Get value of object - loop each row
  for (let i = 0; i < data.length; i++) {
    let row = "";
    for (let index in data[i]) {
      row += '"' + data[i][index] + '",';
    }
    row.slice(0, row.length - 1);
    exportCSV += row + "\n";
  }

  const blob = new Blob([exportCSV], { type: "text/csv" });
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.href = URL.createObjectURL(blob);
  a.download = `${exportInfo.name}.csv`;
  // document.body.appendChild(a);
  a.click();
  // document.body.remove(a);

  setExportInfo({
    ...exportInfo,
    name: "",
    type: "csv",
    indeterminate: true,
    checkAll: false,
  });
  setExportLoading(0);
};

export const testExportXcel = (data) => {
  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.aoa_to_sheet([]);

  const ws_2 = XLSX.utils.aoa_to_sheet([]);

  const indexOfRow = data?.length + 2 || 4;

  const header = [
    "",
    cts.NAME,
    cts.WORKING_DAYS_OF_MONTH,
    cts.BASIC_SALARY,
    cts.WORKING_DAYS,
    cts.ABSENT_DAYS,
    cts.SALARY_RECEIVED,
    cts.BONUS_BY_SALES,
    cts.BONUS_BY_PRODUCTS,
    cts.SUB,
    cts.MAIN,
    cts.BONUS_BY_COMPANIONS,
    cts.OTHER_MONEY,
    cts.SALARY_ADVANCE,
    cts.TOTAL_SALARY,
  ];

  const headerTotal = [
    "総合売上",
    "カード売上",
    "現金売上",
    "酒代①",
    "食材②",
    "備品③",
    "他経費④",
    "バイト支給⑤",
    "業者支払⑥",
    "体入⑦",
    "キャスト報酬⑧",
    "キャンペーン",
    "日払い",
    "立替え",
    "持ち帰り金",
  ];

  const mainRange = { s: { r: 2, c: 0 }, e: { r: indexOfRow, c: header.length - 1 } };

  ws["!ref"] = "A1:AW1000";

  const wscols = [
    { wpx: 25 },
    { wpx: 120 },
    { wpx: 75 },
    { wpx: 75 },
    { wpx: 75 },
    { wpx: 75 },
    { wpx: 75 },
    { wpx: 90 },
    { wpx: 90 },
    { wpx: 65 },
    { wpx: 65 },
    { wpx: 65 },
    { wpx: 65 },
    { wpx: 65 },
    { wpx: 95 },
    { wpx: 10 },
    { wpx: 140 },
    { wpx: 140 },
  ];
  ws["!cols"] = wscols;
  const wsrows = [{ hpx: 30 }, { hpx: 15 }, { hpx: 35 }];
  ws["!rows"] = wsrows;

  let merge = [];

  const headerMerged = [{ s: { r: 0, c: 0 }, e: { r: 1, c: header.length + 2 } }];
  const totalTableMerged = [];
  for (let C = 16; C < 18; C++) {
    for (let R = 3; R < 33; R += 2) {
      const cell_merged = { s: { r: R, c: C }, e: { r: R + 1, c: C } };
      totalTableMerged.push(cell_merged);
    }
  }
  merge = merge.concat(headerMerged, totalTableMerged);
  ws["!merges"] = merge;

  const rangeMini = totalTableMerged;

  for (let temp = 0; temp < rangeMini.length; temp++) {
    for (let C = rangeMini[temp].s.c; C <= rangeMini[temp].e.c; C++) {
      for (let R = rangeMini[temp].s.r; R <= rangeMini[temp].e.r; R++) {
        const cell_default = { c: C, r: R };
        const cell_address = XLSX.utils.encode_cell(cell_default);
        ws[cell_address] = {
          t: "s",
          v: "",
          s: {
            border: {
              top: { style: "thin", color: "000000" },
              right: { style: "thin", color: "000000" },
              bottom: { style: "thin", color: "000000" },
              left: { style: "thin", color: "000000" },
            },
            alignment: { wrapText: true, vertical: "center", horizontal: "center" },
          },
        };
        if (C === 16) {
          ws[cell_address] = {
            ...ws[cell_address],
            v: headerTotal[temp],
            s: {
              ...ws[cell_address].s,
              border: {
                ...ws[cell_address].s.border,
                left: { style: "thick", color: "000000" },
              },
            },
          };
          if (
            R === 9 ||
            R === 11 ||
            R === 13 ||
            R === 15 ||
            R === 17 ||
            R === 19 ||
            R === 21 ||
            R === 23 ||
            R === 25
          ) {
            ws[cell_address] = {
              ...ws[cell_address],
              s: {
                ...ws[cell_address].s,
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "ffe598" },
                  bgColor: { rgb: "ffe598" },
                },
              },
            };
          }
        }
        if (C === 17) {
          ws[cell_address] = {
            ...ws[cell_address],
            s: {
              ...ws[cell_address].s,
              border: {
                ...ws[cell_address].s.border,
                right: { style: "thick", color: "000000" },
              },
            },
          };
        }
        if (R === 3) {
          ws[cell_address] = {
            ...ws[cell_address],
            s: {
              ...ws[cell_address],
              border: {
                ...ws[cell_address].s.border,
                top: { style: "thick", color: "000000" },
              },
              alignment: { wrapText: true, vertical: "center", horizontal: "center" },
            },
          };
        }
        if (R === 32) {
          ws[cell_address] = {
            ...ws[cell_address],
            s: {
              ...ws[cell_address],
              border: {
                ...ws[cell_address].s.border,
                bottom: { style: "thick", color: "000000" },
              },
            },
          };
        }
      }
    }
  }

  for (var R = mainRange.s.r; R <= mainRange.e.r; R++) {
    for (var C = mainRange.s.c; C <= mainRange.e.c; C++) {
      var cell = { c: C, r: R };
      var cell_address = XLSX.utils.encode_cell(cell);

      ws[cell_address] = {
        t: "s",
        v: "",
        s: {
          border: {
            top: { style: "thin", color: "000000" },
            right: { style: "thin", color: "000000" },
            bottom: { style: "thin", color: "000000" },
            left: { style: "thin", color: "000000" },
          },
        },
      };

      if (R === 2) {
        ws[cell_address] = {
          v: header[C],
          s: {
            ...ws[cell_address].s,
            fill: {
              patternType: "solid",
              fgColor: { rgb: "2770c0" },
              bgColor: { rgb: "2770c0" },
            },
            alignment: { wrapText: true, vertical: "center", horizontal: "center" },
            font: {
              color: {
                rgb: "FFFFFF",
              },
            },
          },
        };
      } else {
        if (C === 3) {
          ws[cell_address] = {
            ...ws[cell_address],
            s: {
              ...ws[cell_address].s,
              fill: {
                patternType: "solid",
                fgColor: { rgb: "fcccff" },
                bgColor: { rgb: "fcccff" },
              },
            },
          };
        }
        if (C === 12) {
          ws[cell_address] = {
            ...ws[cell_address],
            s: {
              ...ws[cell_address].s,
              fill: {
                patternType: "solid",
                fgColor: { rgb: "feffcc" },
                bgColor: { rgb: "feffcc" },
              },
            },
          };
        }
        if (C === 7 || C === 8 || C === 9 || C === 10 || C === 14) {
          ws[cell_address] = {
            ...ws[cell_address],
            s: {
              ...ws[cell_address].s,
              fill: {
                patternType: "solid",
                fgColor: { rgb: "e2efd9" },
                bgColor: { rgb: "e2efd9" },
              },
            },
          };
        }
      }
    }
  }

  ws["A1"] = {
    t: "s",
    v: "日計表",
    s: {
      font: {
        bold: true,
        sz: 24,
      },
      alignment: { wrapText: true, vertical: "center", horizontal: "center" },
    },
  };

  XLSX.utils.sheet_add_json(ws, data, {
    skipHeader: true,
    origin: "A4",
  });

  XLSX.utils.book_append_sheet(wb, ws, "日計表");
  XLSX.utils.book_append_sheet(wb, ws_2, "日計明細表");

  XLSX.writeFile(wb, "日計表.xlsx");
};

export const XLSXConvertor = async (
  data,
  exportInfo,
  setExportInfo,
  setExportLoading,
  setShowExport
) => {
  var wb = XLSX.utils.book_new();

  const _renderSub = (arrays) => {
    return arrays.filter((i) => i).join(" ・ ");
  };

  const sub = `${exportInfo.tab}`;
  const sub2 = `${exportInfo.dateType ? exportInfo.dateType : ""}`;
  const sub3 = `${exportInfo.subTab ? exportInfo.subTab : ""}`;

  let headerInfo = [
    [[`${cts.SHOP_NAME}`], [exportInfo?.shopName || cts.NA]],
    [[`${cts.AUTHOR}`], [exportInfo?.author || cts.NA]],
    [[`${cts.KINDS}`], [_renderSub([sub, sub2, sub3]) || cts.NA]],
  ];

  if (exportInfo.exportDate) {
    headerInfo = headerInfo.concat([[[`${cts.EXPORT_DATE}`], [exportInfo.exportDate]]]);
  }

  const ws = XLSX.utils.aoa_to_sheet(headerInfo);

  XLSX.utils.sheet_add_json(ws, data, {
    skipHeader: false,
    origin: "A6",
  });

  // const ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, "Worksheet 1");
  XLSX.write(wb, {
    type: "binary",
    bookType: "xlsx",
  });

  await XLSX.writeFile(wb, `${exportInfo.name}.${exportInfo.type}`);

  message.success(ctsSuccess.EXPORT_SUCCESS_MESSAGE);
  setExportInfo({
    ...exportInfo,
    name: "",
    type: "xlsx",
    indeterminate: true,
    checkAll: false,
  });
  setExportLoading(0);
  setShowExport(false);
};

export const PDFConvertor = async (
  data,
  setExportInfo,
  exportInfo,
  setExportLoading,
  plainOptions,
  setShowExport,
  landscapeMode,
  fromSalary
) => {
  const _renderSub = (val1, val2) => {
    if (!val2) {
      return `${val1}`;
    }
    if (!val1) {
      return `${val2}`;
    }
    return `${val1} (${val2})`;
  };

  const currentTime = () => {
    const currentTime = new Date();

    return `${convertDate(currentTime)} ${moment(currentTime).format("HH:mm")}`;
  };

  const sub = `${exportInfo.tab}`;
  const sub2 = `${exportInfo.dateType ? exportInfo.dateType : ""}`;
  const sub3 = `${exportInfo.subTab ? exportInfo.subTab : ""}`;

  const doc = new jsPDF({
    orientation: landscapeMode ? "landscape" : "portrait",
  });

  // doc.setFont("NotoSans-Regular");
  doc.setFont("Meiryo");

  const newArr = [];
  if (plainOptions && plainOptions.length > 0) {
    plainOptions?.forEach((item) => {
      exportInfo.checkedList.forEach((i) => {
        if (item.value === i) {
          newArr.push({ header: item.label, dataKey: item.value });
        }
      });
    });
  } else {
    newArr.push(
      { header: `${cts.NUMBER}`, dataKey: "no" },
      { header: `${cts.CATEGORY_NAME}`, dataKey: "category_name" }
    );
  }

  if (fromSalary === "salaryList") {
    newArr.unshift({ header: cts.NAME, dataKey: "host_name" });
  }

  let pageSize = doc.internal.pageSize;
  // pageWidth landscape: 297 - portrait: 210
  let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

  const landscapePosition = (position) => {
    let positionValue = 0;
    if (landscapeMode) {
      return (positionValue = position === "right" ? 282 : 148);
    }
    return (positionValue = position === "right" ? 195 : 105);
  };

  if (fromSalary === "salaryIndividual") {
    //
  } else {
    doc.setFontSize(10);
    doc.text(`${cts.SHOP_NAME} : ${exportInfo?.shopName}`, 15, 10);

    doc.setFontSize(10);
    // (pageWidth / 2) + (pageWidth / 2 - 15) = 282 landscape
    doc.text(
      `${cts.CREATE_AT} : ${currentTime()}`,
      landscapePosition("right"),
      10,
      null,
      null,
      "right"
    );
    doc.setFontSize(10);
    doc.text(`${cts.AUTHOR} : ${exportInfo?.author}`, 15, 16);

    doc.setFontSize(18);
    // pageWidth / 2  = 148
    doc.text(`${sub}`, landscapePosition("middle"), 34, null, null, "center");

    doc.setFontSize(10);
    if (exportInfo.exportDate) {
      doc.text(
        `${cts.EXPORT_DATE} : ${exportInfo.exportDate}`,
        landscapePosition("right"),
        16,
        null,
        null,
        "right"
      );
      doc.text(
        `${cts.KINDS} : ${_renderSub(sub3, sub2)}`,
        landscapePosition("right"),
        22,
        null,
        null,
        "right"
      );
    }
    if (!exportInfo.exportDate && exportInfo.subTab) {
      doc.text(
        `${cts.KINDS} : ${_renderSub(sub3, sub2)}`,
        landscapePosition("right"),
        16,
        null,
        null,
        "right"
      );
    }
    doc.autoTable({
      theme: "grid",
      styles: {
        font: "Meiryo",
        mincellHeight: 5,
        cellPadding: 1.5,
        fontSize: 8,
        halign: "center",
      },
      columns: newArr,
      body: data,
      startY: 40,
      showHead: "firstPage",
      // pageBreaK: "avoid",
      headStyles: {
        fillColor: [245, 246, 248],
        textColor: "#000",
        halign: "center",
        valign: "middle",
        lineWidth: 0.1,
      },
      columnStyles: {
        nick_name: {
          cellWidth: 45,
        },
        phone: {
          cellWidth: 25,
        },
        appointee_times: {
          cellWidth: 15,
        },
        working_days: { cellWidth: 15 },
        //   hour: { halign: "center", cellWidth: 8 },
        //   date: { halign: "center", cellWidth: 8 },
        //   day: { halign: "center", cellWidth: 8 },
        //   month: { halign: "center", cellWidth: 8 },
        //   number_customer: { halign: "center", cellWidth: 20 },
        //   number_table: { halign: "center", cellWidth: 20 },
        //   number_visitor: { halign: "center", cellWidth: 12 },
        //   table_used: { halign: "center", cellWidth: 20 },
        //   address: { cellWidth: 25, halign: "center" },
      },
      rowStyles: {
        valign: "center",
      },
    });

    await doc.save(`${exportInfo.name}.pdf`);
  }

  message.success(ctsSuccess.EXPORT_SUCCESS_MESSAGE);
  setExportInfo({
    ...exportInfo,
    name: "",
    type: "xlsx",
    indeterminate: true,
    checkAll: false,
  });
  setExportLoading(0);
  setShowExport(false);
};

export const equalArrays = (a, b) => {
  if (a.length !== b.length) {
    return false;
  } else {
    for (let i = 0; i < a.length; i++) {
      b[i].replace("\r", "");
      if (a[i].toLowerCase().trim() !== b[i].toLowerCase().trim()) {
        return false;
      }
    }
    return true;
  }
};

export function getSelectedProductOptions(options = [], selectedOptions = [], type) {
  let optionArray = selectedOptions.map((item) => {
    const found = options.find((i) => i.id === item.option_type_id);
    if (found) {
      const optionFound = found.options.find((i) => i.id === item.option_id);
      return optionFound;
    }
    return {};
  });
  optionArray = optionArray.filter((i) => i);

  if (type === "name") {
    return optionArray.map((i) => i.name);
  }

  const optionPrice = optionArray.map((i) => i.price).reduce((a, b) => a + b, 0);
  return optionPrice;
}

export function calendarBuilder(value) {
  const startDay = value.clone().startOf("month").startOf("week");
  const endDay = value.clone().endOf("month").endOf("week");
  const day = startDay.clone().subtract(1, "day");

  const calendar = [];
  while (day.isBefore(endDay, "day") || calendar.length < 6) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, "day").clone())
    );
  }
  return calendar;
}

export function getAllToken(data) {
  if (data.length > 0) {
    const tokens = data.map((item) => {
      if (item.notification_token) {
        const token = item.notification_token.token
          ? JSON.parse(item.notification_token.token)
          : [];
        return token;
      }
      return [];
    });

    return [].concat.apply([], tokens).filter((i) => i !== null);
  }

  return [];
}
export const validateBirthday = (birthday) => {
  if (birthday === "0000-00-00") {
    return false;
  }
  const birthdayDate = new Date(birthday);
  const today = new Date();
  let age = today.getFullYear() - birthdayDate.getFullYear();
  const m = today.getMonth() - birthdayDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthdayDate.getDate())) {
    age--;
  }
  return age;
};
