import React, { useCallback, useEffect, useState } from "react";
import queryString from "query-string";
import { Input, message } from "antd";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { addStaffAPI, checkEmailAPI, registerAPI } from "../apis/user";

export default function SignupFromInvitePage() {
  let queryUrl = window.location.href.split("?");
  queryUrl = queryUrl.length > 1 ? queryUrl[1] : "";
  const query = queryString.parse(queryUrl);

  const [process, setProcess] = useState("loading");
  const [state, setState] = useState({
    name: "",
    password: "",
    c_password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [userId, setUserId] = useState(null);

  const onCheckEmail = useCallback(async () => {
    const res = await checkEmailAPI({ email: query.email });
    if (res.data.message === "success") {
      setProcess("has");
      setUserId(res.data.user_id);
    } else {
      setProcess("no");
    }
  }, [query.email]);

  useEffect(() => {
    onCheckEmail();
  }, [onCheckEmail]);

  useEffect(() => {
    if (process === "has") {
      onAddStaff();
    }
  }, [process, userId]);

  const onAddStaff = useCallback(async () => {
    if (userId) {
      await addStaffAPI({ shop_ids: query.shop_ids?.split(","), user_id: userId });
    }
  }, [userId]);

  const onSubmit = async () => {
    if (!window.confirm("本気ですか？")) {
      return;
    }

    const res = await registerAPI({
      name: state.name,
      email: query.email,
      password: state.password,
      c_password: state.c_password,
      shop_ids: query.shop_ids?.split(","),
    });

    if (res.status === 200) {
      setProcess("signup");
    }
  };

  const _renderSuffix = () => {
    return (
      <button
        type="button"
        style={{
          width: 50,
          height: 50,
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          right: 0,
          top: 0,
          paddingTop: 5,
        }}
        onClick={() => setPasswordVisible(!passwordVisible)}
      >
        {passwordVisible ? (
          <AiOutlineEye style={{ fontSize: 20 }} />
        ) : (
          <AiOutlineEyeInvisible style={{ fontSize: 20 }} />
        )}
      </button>
    );
  };

  return (
    <div style={{ background: "white", minHeight: "100vh" }}>
      <div className="signup-page">
        {process === "loading" && <h2>Loading...</h2>}
        {process === "has" || process === "signup" ? (
          <h2>
            {query.shop_names?.replace(/,/g, ", ")}
            店舗にあたなが追加されました。アプリでログインしてください。
          </h2>
        ) : null}
        {process === "no" &&
          (step === 1 ? (
            <div>
              <h4>
                チャンナムズォンさんが、あなたを招待しました。
                <br />
                以下の項目を入力して、サービスに参加しましょう。
              </h4>
              <div className="signup-page__input">
                <p>
                  氏名 <span>*</span>
                </p>
                <Input
                  value={state.name}
                  onChange={(e) => setState({ ...state, name: e.target.value })}
                />
              </div>
              <div className="signup-page__input">
                <p>メールアドレス</p>
                <div>
                  <h6>{query.email}</h6>
                </div>
              </div>
              <div className="signup-page__input has-caption">
                <p>
                  パスワード <span>*</span>
                </p>
                <div>
                  <div style={{ position: "relative", width: "100%" }}>
                    <Input
                      value={state.password}
                      onChange={(e) => setState({ ...state, password: e.target.value })}
                      type={passwordVisible ? "text" : "password"}
                    />
                    {_renderSuffix()}
                  </div>
                  <div>
                    <h5>半角英字・数字・記号のすべてを組み合わせた、8〜64文字。使用できる記号は</h5>
                    <h5>{`! # $ % & = ~ ^ | @ < > ? + * / _ ; : -`}</h5>
                  </div>
                </div>
              </div>
              <div className="signup-page__input">
                <p>
                  パスワード (確認) <span>*</span>
                </p>
                <div style={{ position: "relative", width: "100%" }}>
                  <Input
                    value={state.c_password}
                    onChange={(e) => setState({ ...state, c_password: e.target.value })}
                    type={passwordVisible ? "text" : "password"}
                  />
                  {_renderSuffix()}
                </div>
              </div>
              <div className="signup-page__input">
                <p>ご利用店舗</p>
                <div>
                  <h6>{query.shop_names?.replace(/,/g, ", ")}</h6>
                </div>
              </div>
              <div className="signup-page__input">
                <button
                  disabled={!state.name || !state.password || !state.c_password}
                  className={!state.name || !state.password || !state.c_password ? "disabled" : ""}
                  type="submit"
                  onClick={() => {
                    if (state.password.length < 8) {
                      message.warn("半角英字・数字・記号のすべてを組み合わせた、8〜64文字。");
                      return;
                    }
                    if (state.password !== state.c_password) {
                      message.warn("パスワードが一致しません");
                      return;
                    }
                    setStep(2);
                  }}
                >
                  上記に同意して登録する
                </button>
              </div>
            </div>
          ) : (
            <div className="signup-policy">
              <h2>利用規約/プライバシーポリシーの確認</h2>
              <div className="signup-policy__content">
                <div className="text">
                  <h4>
                    下記の利用規約/プライバシーポリシーをご確認いただき、同意された方は「同意する」ボタンを押してください。
                  </h4>
                  <h4>
                    ※すでに同意いただいた方も、記載内容に変更があった場合は再度同意が必要となります。
                  </h4>
                </div>
                <div>
                  <button onClick={() => setStep(1)} type="button" className="cancel">
                    キャンセル
                  </button>
                  <button onClick={onSubmit} type="submit">
                    同意する
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
