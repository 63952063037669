import { fromJS } from "immutable";
import { createReducer } from "../helpers/createReducer";
import * as cts from "../constants/events/other";
import { SET_SELECTED_SHOP } from "../constants/events/user";

const initialState = fromJS({
  isActionDone: true,
  customerList: {
    data: [],
    shopId: null,
    currentPage: 1,
    totalPage: 1,
    total: 1,
  },
  customerForOrderList: {
    data: [],
    shopId: null,
  },
  customerDetailList: {
    data: [],
    totalReceivables: 0,
    customerId: null,
    currentPage: 1,
    totalPage: 1,
  },
  logList: {
    data: [],
    shopId: null,
    currentPage: 1,
    totalPage: 1,
    total: 1,
  },
  salaryList: {
    data: [],
    shopId: null,
  },
  salaryNominationList: {
    data: [],
    shop_id: null,
  },
  report: {
    data: {},
    shopId: null,
  },
  reportSidebar: {
    sales: 0,
    shopId: null,
  },
});

// CUSTOMER
const fetchCustomerListSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("customerList", fromJS(data));
};

const fetchCustomerForOrderListSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("customerForOrderList", fromJS(data));
};

const fetchCustomerDetail = (state, action) => {
  const { params } = action.payload;
  const detail = state.get("customerDetailList").toJS();

  if (params.customer_id !== detail.customerId) {
    return state.setIn(
      ["customerDetailList"],
      fromJS({
        ...detail,
        data: [],
        customerId: null,
        currentPage: 1,
        totalPage: 1,
        totalReceivables: 0,
      })
    );
  }
  return state;
};

const fetchCustomerDetailSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("customerDetailList", fromJS(data));
};

const createCustomerSuccess = (state, action) => {
  const { data } = action.payload;
  const customers = state.getIn(["customerList", "data"]).toJS();
  const newCustomers = [data].concat(customers);

  return state
    .setIn(["customerList", "data"], fromJS(newCustomers))
    .setIn(["customerForOrderList", "data"], fromJS(newCustomers))
    .set("isActionDone", true);
};

const updateCustomerSuccess = (state, action) => {
  const { data } = action.payload;
  const customers = state.getIn(["customerList", "data"]).toJS();

  const index = customers.findIndex((i) => i.id === data.id);
  customers[index] = { ...customers[index], ...data };

  return state
    .setIn(["customerList", "data"], fromJS(customers))
    .setIn(["customerForOrderList", "data"], fromJS(customers))
    .set("isActionDone", true);
};

const deleteCustomerSuccess = (state, action) => {
  const { data } = action.payload;
  const customers = state.getIn(["customerList", "data"]).toJS();

  const newCustomers = customers.filter((i) => i.id !== data.id);

  return state
    .setIn(["customerList", "data"], fromJS(newCustomers))
    .setIn(["customerForOrderList", "data"], fromJS(newCustomers))
    .set("isActionDone", true);
};

// LOG
const fetchLogsSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("logList", fromJS(data));
};

// SALARY
const fetchSalaryListSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("salaryList", fromJS(data));
};

const createSalarySuccess = (state, action) => {
  const { data } = action.payload;
  const salaries = state.getIn(["salaryList", "data"]).toJS();
  const newSalaries = [data].concat(salaries);

  return state.setIn(["salaryList", "data"], fromJS(newSalaries)).set("isActionDone", true);
};

const updateSalarySuccess = (state, action) => {
  const { data } = action.payload;
  const salaries = state.getIn(["salaryList", "data"]).toJS();
  const index = salaries.findIndex((i) => i.id === data.id);
  salaries[index] = data;

  return state.setIn(["salaryList", "data"], fromJS(salaries)).set("isActionDone", true);
};

const deleteSalarySuccess = (state, action) => {
  const { data } = action.payload;
  const salaries = state.getIn(["salaryList", "data"]).toJS();
  const newSalaries = salaries.filter((i) => i.id !== data.id);

  return state.setIn(["salaryList", "data"], fromJS(newSalaries)).set("isActionDone", true);
};

const fetchReportSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("report", fromJS(data));
};

const fetchSalaryNominationSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("salaryNominationList", fromJS(data));
};

const updateSalaryNominationSuccess = (state, action) => {
  const { data } = action.payload;
  return state.setIn(["salaryNominationList", "data"], fromJS(data));
};

const fetchReportInSidebarSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("reportSidebar", fromJS(data));
};

const fetchReportInSidebarFromSocket = (state, action) => {
  const { sales } = action.payload;
  const reportSidebar = state.getIn(["reportSidebar", "sales"]);
  return state.setIn(["reportSidebar", "sales"], parseInt(reportSidebar, 10) + parseInt(sales, 10));
};

const fetchReportInSidebarFromLocal = (state, action) => {
  const { sales } = action.payload;
  const reportSidebar = state.getIn(["reportSidebar", "sales"]);
  return state.setIn(["reportSidebar", "sales"], parseInt(reportSidebar, 10) + parseInt(sales, 10));
};

// reducer
const reducer = {
  [SET_SELECTED_SHOP]: () => initialState,
  [cts.FETCH_CUSTOMER_LIST_SUCCESS]: fetchCustomerListSuccess,
  [cts.FETCH_CUSTOMER_FOR_ORDER_LIST_SUCCESS]: fetchCustomerForOrderListSuccess,

  [cts.FETCH_CUSTOMER_DETAIL]: fetchCustomerDetail,
  [cts.FETCH_CUSTOMER_DETAIL_SUCCESS]: fetchCustomerDetailSuccess,

  [cts.CREATE_CUSTOMER]: (state) => state.set("isActionDone", false),
  [cts.CREATE_CUSTOMER_SUCCESS]: createCustomerSuccess,

  [cts.UPDATE_CUSTOMER_SUCCESS]: updateCustomerSuccess,
  [cts.UPDATE_CUSTOMER]: (state) => state.set("isActionDone", false),

  [cts.DELETE_CUSTOMER_SUCCESS]: deleteCustomerSuccess,
  [cts.DELETE_CUSTOMER]: (state) => state.set("isActionDone", false),

  [cts.FETCH_LOGS_SUCCESS]: fetchLogsSuccess,
  [cts.FETCH_SALARY_LIST_SUCCESS]: fetchSalaryListSuccess,

  [cts.CREATE_SALARY]: (state) => state.set("isActionDone", false),
  [cts.CREATE_SALARY_SUCCESS]: createSalarySuccess,

  [cts.UPDATE_SALARY]: (state) => state.set("isActionDone", false),
  [cts.UPDATE_SALARY_SUCCESS]: updateSalarySuccess,

  [cts.DELETE_SALARY]: (state) => state.set("isActionDone", false),
  [cts.DELETE_SALARY_SUCCESS]: deleteSalarySuccess,

  [cts.FETCH_SALARY_NOMINATION_SUCCESS]: fetchSalaryNominationSuccess,
  [cts.UPDATE_SALARY_NOMINATION_SUCCESS]: updateSalaryNominationSuccess,

  [cts.FETCH_REPORT_SUCCESS]: fetchReportSuccess,

  [cts.FETCH_REPORT_IN_SIDEBAR_SUCCESS]: fetchReportInSidebarSuccess,

  [cts.FETCH_REPORT_IN_SIDEBAR_FROM_SOCKET]: fetchReportInSidebarFromSocket,

  [cts.FETCH_REPORT_IN_SIDEBAR_FROM_LOCAL]: fetchReportInSidebarFromLocal,
  __default__: (state) => state,
};

export default createReducer(reducer, initialState);
