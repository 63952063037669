import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoadingOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Checkbox, Spin, Input, Space } from "antd";
import * as svg from "../constants/ui/svg";
import * as cts from "../constants/ui/general";
import * as act from "../actions/user";
import * as genSlt from "../selectors/general";
import Logo from "../assets/images/logo.png";
import TextInput from "../helpers/TextInput";
import { FiEyeOff } from "react-icons/fi";

export default function LoginPage() {
  const [state, setState] = useState({
    email: "",
    password: "",
    remember_me: false,
  });
  const [validate, setValidate] = useState({
    email: false,
    password: false,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector((state) => genSlt.loadingSelector(state));
  const onLogin = () => {
    if (state.email !== "" && state.password !== "") {
      setValidate({
        ...validate,
        email: false,
        password: false,
      });

      dispatch(act.loginFlow(state, history));
    } else {
      setValidate({
        ...validate,
        email: state.email === "",
        password: state.password === "",
      });
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onLogin();
    }
  };
  return (
    <div style={{ minHeight: "100vh", background: "white" }}>
      <div className="login_v2-page bg-secondary">
        <div className="login_v2-content">
          <div className="login_v2-wrap">
            <div className="logo-web">
              {/* <img src={Logo} alt="bg" /> */}
              {svg.logoPosWeb}
            </div>
            <div className="bg-input mt-30">
              <div className="email-only d-flex mb-5">
                <span className="mb-15">{cts.EMAIL_ONLY}</span>
                <input
                  className="input-register mb-20"
                  style={{ fontSize: "15px" }}
                  error={validate.email ? "error" : ""}
                  value={state.email}
                  onChange={(e) => setState({ ...state, email: e.target.value })}
                  onKeyPress={onKeyPress}
                  type="text"
                  placeholder="account@gmail.com"
                />
              </div>
              <div className="password-only d-flex">
                <span>{cts.PASSWORD}</span>
                {/* <Input
                  className="input-register mb-20 mt-15"
                  error={validate.password ? "error" : ""}
                  onKeyPress={onKeyPress}
                  value={state.password}
                  onChange={(e) => setState({ ...state, password: e.target.value })}
                  type="password"
                  placeholder={cts.PASSWORD}
                /> */}
                <Space direction="vertical">
                  <Input.Password
                    className="input-pass mb-20 mt-15"
                    error={validate.password ? "error" : ""}
                    onKeyPress={onKeyPress}
                    value={state.password}
                    onChange={(e) => setState({ ...state, password: e.target.value })}
                    type="password"
                    placeholder={cts.PASSWORD}
                  />
                </Space>
              </div>
              <div className="bg-checkbox">
                <Checkbox
                  checked={state.remember_me}
                  onChange={(e) => setState({ ...state, remember_me: e.target.checked })}
                >
                  <span className="text-left"> {cts.REMEMBER_ME}</span>
                </Checkbox>
                <span className="text-right">パスワードを忘れた方</span>
              </div>
              <div className="bg-button mt-30">
                <Button
                  // onClick={onLogin}
                  className={`submit${loading ? "loading" : ""}`}
                  type="button"
                  onClick={!loading ? onLogin : null}
                >
                  {loading && (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 20, color: "white", marginRight: 10 }}
                          spin
                        />
                      }
                    />
                  )}
                  {cts.LOGIN}
                </Button>
                <div className="bg-button_register">
                  <div className="line-content">
                    <div className="line-1"></div>
                    <span></span>
                    <div className="line-2"></div>
                  </div>
                  {/* <span
                    style={{ fontWeight: 600 }}
                    className="text-center mt-30 d-flex middle center"
                  >
                    はじめての方はこちら
                  </span>
                  <button
                    type="button"
                    className="mt-15"
                    style={{
                      border: "1px solid #D9D9D9",
                      backgroundColor: "white",
                      color: "#666687",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                    onClick={() => history.push("/register")}
                  >
                    アカウントの新規登録
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
