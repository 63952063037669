export const TOGGLE_ASIDE = "TOGGLE_ASIDE";

export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const SET_BREADCRUMB = "SET_BREADCRUMB";
export const SET_ACTIVE_STEP_SETUP = "SET_ACTIVE_STEP_SETUP";
export const SHOW_FULL_SCREEN_LOADING = "SHOW_FULL_SCREEN_LOADING";
export const HIDE_FULL_SCREEN_LOADING = "HIDE_FULL_SCREEN_LOADING";

export const SHOW_FETCH_LOADING = "SHOW_FETCH_LOADING";
export const HIDE_FETCH_LOADING = "HIDE_FETCH_LOADING";

export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
