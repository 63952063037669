import request from "../helpers/axiosService";

// const URL_GET_RANKING = "api/ranking";

const URL_GET_RANKING = "v2/ranking";

export function fetchRankingAPI(token, params) {
  return request({
    url: URL_GET_RANKING,
    method: "get",
    params,
    token,
  });
}
