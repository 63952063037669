import memoize from "fast-memoize";

const customerList = (state) => state.getIn(["other", "customerList"]).toJS();
const customerForOrderList = (state) => state.getIn(["other", "customerForOrderList"]).toJS();
const customerDetailList = (state) => state.getIn(["other", "customerDetailList"]).toJS();
const logList = (state) => state.getIn(["other", "logList"]).toJS();
const salaryList = (state) => state.getIn(["other", "salaryList"]).toJS();
const isActionDone = (state) => state.getIn(["other", "isActionDone"]);
const report = (state) => state.getIn(["other", "report"]).toJS();
const salaryNomination = (state) => state.getIn(["other", "salaryNominationList"]).toJS();
const reportSidebar = (state) => state.getIn(["other", "reportSidebar"]).toJS();

export const customerListSelector = memoize(customerList);
export const customerDetailListSelector = memoize(customerDetailList);
export const customerForOrderListSelector = memoize(customerForOrderList);
export const logListSelector = memoize(logList);
export const salaryListSelector = memoize(salaryList);
export const isActionDoneSelector = memoize(isActionDone);
export const reportSelector = memoize(report);
export const salaryNominationList = memoize(salaryNomination);
export const reportSidebarSelector = memoize(reportSidebar);
