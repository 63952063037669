import React from "react";
import { Typography } from "antd";
import { notRoundMoney, formatMoney } from "../helpers/functionHelpers";
import * as cts from "../constants/ui/general";

const { Text } = Typography;

export default function Money({ children, style, isNotRound, space, ...rest }) {
  let format;
  if (isNotRound) {
    format = notRoundMoney;
  } else {
    format = formatMoney;
  }

  if (children === 0) {
    return (
      <Text style={style} {...rest}>
        {cts.YEN}
        {space ? " " : ""}0
      </Text>
    );
  }

  if (!children) {
    return (
      <Text style={style} {...rest}>
        {cts.YEN}0
      </Text>
    );
  }

  if (children < 0) {
    return (
      <Text style={style} {...rest}>
        {`-${cts.YEN}`}
        {space ? " " : ""}
        {format(children).split("-")[1]}
      </Text>
    );
  }

  return (
    <Text style={style} {...rest}>
      {cts.YEN}
      {space ? " " : ""}
      {format(children)}
    </Text>
  );
}
