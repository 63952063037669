import memoize from "fast-memoize";

const hostList = (state) => state.getIn(["host", "hostList"]).toJS();
const hostForOrderList = (state) => state.getIn(["host", "hostForOrderList"]).toJS();
const isActionDone = (state) => state.getIn(["host", "isActionDone"]);
const hostReceivablesList = (state) => state.getIn(["host", "hostReceivablesList"]).toJS();
const hostSales = (state) => state.getIn(["host", "hostSales"]).toJS();
const hostSalary = (state) => state.getIn(["host", "hostSalary"]).toJS();
const hostOtherMoneyList = (state) => state.getIn(["host", "hostOtherMoneyList"]).toJS();

export const hostListSelector = memoize(hostList);
export const hostForOrderListSelector = memoize(hostForOrderList);
export const isActionDoneSelector = memoize(isActionDone);
export const hostReceivablesListSelector = memoize(hostReceivablesList);
export const hostSalesSelector = memoize(hostSales);
export const hostSalarySelector = memoize(hostSalary);
export const hostOtherMoneyListSelector = memoize(hostOtherMoneyList);
