import { fromJS } from "immutable";
import * as cts from "../constants/events/products";
import { SET_SELECTED_SHOP } from "../constants/events/user";
import { createReducer } from "../helpers/createReducer";

const initialState = fromJS({
  categoryList: {
    data: [],
    shopId: "",
    isFetched: false,
  },
  productList: {
    data: [],
    shopId: null,
    currentPage: 1,
    totalPage: 1,
    total: 1,
  },
  isActionDone: true,
  createMultiProductProcess: 0,
});

const setSelectedShop = () => {
  return initialState;
};

const fetchCategoryListSuccess = (state, action) => {
  const { data } = action.payload;
  data.isFetched = true;
  return state.set("categoryList", fromJS(data));
};

const createNewCategorySuccess = (state, action) => {
  const { data } = action.payload;
  // data.parent_id = +data.parent_id;
  const categories = state.getIn(["categoryList", "data"]).toJS();
  let newTable = null;
  if (data?.length > 0) {
    newTable = [...data, ...categories];
  } else {
    newTable = [data].concat(categories);
  }
  // const newCategory = [data].concat(categories);
  return (
    state.setIn(["categoryList", "data"], fromJS(newTable)),
    state.set("categoryNew", (data))
  );
};

const createMultiCategorySuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("createMultiProductProcess", data);
};

const updateCategorySuccess = (state, action) => {
  const { data } = action.payload;
  const categories = state.getIn(["categoryList", "data"]).toJS();
  // const index = categories.findIndex((i) => i.id === data.id);
  // categories[index] = data;
  if (data?.categories?.length > 0) {
    data.categories.map((item) => {
      const index = categories.findIndex((i) => i.id === item.id);
      categories[index] = item;
    });
  } else {
    const index = categories.findIndex((i) => i.id === data.id);
    categories[index] = data;
  }

  return state.setIn(["categoryList", "data"], fromJS(categories));
};

const deleteCategorySuccess = (state, action) => {
  const { data } = action.payload;
  const categories = state.getIn(["categoryList", "data"]).toJS();

  const newCategories = categories.filter((i) => i.id !== data.id);

  return state.setIn(["categoryList", "data"], fromJS(newCategories));
};

const fetchProductListSuccess = (state, action) => {
  const { data } = action.payload;
  let productList = state.getIn(["productList", "data"]).toJS();

  productList = productList.concat(data.data);

  return state.set(
    "productList",
    fromJS({
      data: data.isMerge ? productList : data.data,
      currentPage: data.current_page,
      totalPage: data.last_page,
      total: data.total,
      shopId: data.shopId,
    })
  );
};

const createProductSuccess = (state, action) => {
  const { data } = action.payload;
  const productList = state.getIn(["productList", "data"]).toJS();
  const newProducts = [data].concat(productList);

  return state.setIn(["productList", "data"], fromJS(newProducts)).set("isActionDone", true);
};

const createMultiProductSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("createMultiProductProcess", data);
};

const updateProductSuccess = (state, action) => {
  const { data } = action.payload;
  const productList = state.getIn(["productList", "data"]).toJS();
  const index = productList.findIndex((i) => i.id === data.id);
  productList[index] = data;

  return state.setIn(["productList", "data"], fromJS(productList)).set("isActionDone", true);
};

const deleteProductSuccess = (state, action) => {
  const { data, isDeleteImage } = action.payload;
  const productList = state.getIn(["productList", "data"]).toJS();

  let newProducts;
  if (!isDeleteImage) {
    newProducts = productList.filter((i) => i.id !== data.id);
  } else {
    const productIndex = productList.findIndex((i) => i.id === data.id);
    if (productIndex !== -1) {
      productList[productIndex].image = null;
      newProducts = [...productList];
    }
  }

  return state.setIn(["productList", "data"], fromJS(newProducts)).set("isActionDone", true);
};

const updateOrderCategorySuccess = (state, action) => {
  const { data } = action.payload;
  let categoryList = state.getIn(["categoryList", "data"]).toJS();

  categoryList = categoryList
    .map((item) => {
      const found = data.find((i) => i.id === item.id);
      if (found) {
        item.orderby = found.orderby;
      }
      return item;
    })
    .sort((a, b) => b.orderby - a.orderby);

  return state.setIn(["categoryList", "data"], fromJS(categoryList));
};

const updateOrderProductSuccess = (state, action) => {
  const { data } = action.payload;
  let productList = state.getIn(["productList", "data"]).toJS();

  productList = productList
    .map((item) => {
      const found = data.find((i) => i.id === item.id);
      if (found) {
        item.orderby = found.orderby;
      }
      return item;
    })
    .sort((a, b) => a.orderby - b.orderby);
  return state.setIn(["productList", "data"], fromJS(productList));
};
// reducer
const reducer = {
  [SET_SELECTED_SHOP]: setSelectedShop,
  [cts.FETCH_CATEGORY_LIST_SUCCESS]: fetchCategoryListSuccess,
  [cts.CREATE_NEW_CATEGORY_SUCCESS]: createNewCategorySuccess,
  [cts.UPDATE_CATEGORY_SUCCESS]: updateCategorySuccess,
  [cts.UPDATE_ORDER_CATEGORY_SUCCESS]: updateOrderCategorySuccess,
  [cts.UPDATE_ORDER_PRODUCT_SUCCESS]: updateOrderProductSuccess,
  [cts.DELETE_CATEGORY_SUCCESS]: deleteCategorySuccess,

  [cts.CREATE_MULTI_CATEGORY_SUCCESS]: createMultiCategorySuccess,

  [cts.FETCH_PRODUCT_LIST_SUCCESS]: fetchProductListSuccess,
  [cts.FETCH_PRODUCT_LIST]: (state) => state.set("isActionDone", false),
  [cts.CREATE_PRODUCT]: (state) => state.set("isActionDone", false),
  [cts.CREATE_PRODUCT_SUCCESS]: createProductSuccess,

  [cts.CREATE_MULTI_PRODUCT_SUCCESS]: createMultiProductSuccess,

  [cts.UPDATE_PRODUCT]: (state) => state.set("isActionDone", false),
  [cts.UPDATE_PRODUCT_SUCCESS]: updateProductSuccess,

  [cts.DELETE_PRODUCT]: (state) => state.set("isActionDone", false),
  [cts.DELETE_PRODUCT_SUCCESS]: deleteProductSuccess,

  __default__: (state) => state,
};

export default createReducer(reducer, initialState);
