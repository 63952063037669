import React, { useState, useEffect, useCallback } from "react";
import { HOST_COLLECTION_URL } from "./../../../constants";
import Axios from "axios";
import { Select } from "antd";

const Step = (props) => {
  const { setSteps, state, careers, setState } = props;
  const [locations, setLocations] = useState([]);

  const fetchLocations = useCallback(async () => {
    const res = await Axios.get(`${HOST_COLLECTION_URL}/api/location`);
    setLocations(
      res.data.map((item) => ({
        value: item.id,
        label: item.name,
        cid_location_parent: item.cid_location_parent,
      }))
    );
  }, []);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const onCreateShop = async () => {
    if (
      state.shopName === "" ||
      state.careers === "" ||
      // state.postCode === "" ||
      // state.phone === "" ||
      state.largeLocation === "" ||
      state.smallLocation === ""
    ) {
      return;
    } else {
      setSteps(1);
    }
  };

  const { Option } = Select;
  return (
    <div className="bg-content_register">
      <span
        className="mb-30"
        style={{
          fontSize: "25px",
          color: "#000000",
          display: "flex",
          justifyContent: "center",
          fontWeight: 600,
        }}
      >
        店舗情報
      </span>
      <div className="bg-form-register mt-15">
        <span
          className="mb-15"
          style={{ color: "#000000", fontFamily: "NotoSans", fontSize: "16px" }}
        >
          店舗名 <span style={{ color: "red" }}>*</span>{" "}
        </span>
        <input
          placeholder="店舗名"
          className="input-register"
          style={{ fontSize: "16px" }}
          value={state.shopName}
          onChange={(e) => setState({ ...state, shopName: e.target.value })}
        />
      </div>
      <div style={{ height: "1px", background: " #D9D9D9" }} className="mt-20 mb-25"></div>
      <div className="bg-form-register mt-25">
        <span
          className="mb-15"
          style={{ color: "#000000", fontFamily: "NotoSans", fontSize: "16px" }}
        >
          業種 <span style={{ color: "red" }}>*</span>{" "}
        </span>

        <Select
          className="shop-select"
          style={{ height: "50px", borderRadius: "99px", fontSize: "16px" }}
          value={state.careers}
          onChange={(e) => setState({ ...state, careers: e })}
        >
          {careers.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>
      {/* <div style={{ height: "1px", background: " #D9D9D9" }} className="mt-20 mb-25"></div>
      <div className="bg-form-register mt-25">
        <span
          className="mb-15"
          style={{ color: "#000000", fontFamily: "NotoSans", fontSize: "15px" }}
        >
          郵便番号 <span style={{ color: "red" }}>*</span>{" "}
        </span>

        <input
          placeholder="郵便番号"
          className="input-register"
          style={{ fontSize: "15px" }}
          value={state.postCode}
          onChange={(e) => setState({ ...state, postCode: e.target.value })}
        />
      </div> */}
      {/* <div style={{ height: "1px", background: " #D9D9D9" }} className="mt-20 mb-25"></div> */}
      {/* <div className="bg-form-register mt-25">
        <span
          className="mb-15"
          style={{ color: "#000000", fontFamily: "NotoSans", fontSize: "15px" }}
        >
          電話番号 <span style={{ color: "red" }}>*</span>{" "}
        </span>
        <input
          placeholder="電話番号を入力してください"
          className="input-register"
          style={{ fontSize: "15px" }}
          value={state.phone}
          onChange={(e) => setState({ ...state, phone: e.target.value })}
        />
      </div> */}
      <div style={{ height: "1px", background: " #D9D9D9" }} className="mt-20 mb-25"></div>
      <div className="bg-form-register mt-25">
        <span
          className="mb-15"
          style={{ color: "#000000", fontFamily: "NotoSans", fontSize: "16px" }}
        >
          大エリアを選択 <span style={{ color: "red" }}>*</span>{" "}
        </span>
        <Select
          value={state.largeLocation}
          className="shop-select"
          style={{ height: "50px", borderRadius: "99px", fontSize: "16px" }}
          onChange={(e) => setState({ ...state, largeLocation: e })}
        >
          {locations
            .filter((item) => {
              return item.cid_location_parent === 0;
            })
            .map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
        </Select>
      </div>
      <div style={{ height: "1px", background: " #D9D9D9" }} className="mt-20 mb-25"></div>
      <div className="bg-form-register mt-25">
        <span
          className="mb-15"
          style={{ color: "#000000", fontFamily: "NotoSans", fontSize: "16px" }}
        >
          小エリアを選択 <span style={{ color: "red" }}>*</span>{" "}
        </span>

        <Select
          value={state.smallLocation}
          className="shop-select"
          style={{ height: "50px", borderRadius: "99px" }}
          onChange={(e) => setState({ ...state, smallLocation: e })}
        >
          {locations
            .filter((item) => {
              return item.cid_location_parent === state.largeLocation;
            })
            .map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
        </Select>
      </div>
      <button type="button" className="forgot mt-25" onClick={onCreateShop}>
        作成
      </button>
    </div>
  );
};
export default Step;
