import * as cts from "../constants/events/bill";

export const fetchBillList = (shopId, status, page, date) => {
  return {
    type: cts.FETCH_BILL_LIST,
    payload: { shopId, status, page, date },
  };
};

export const searchBill = (shopId, status, page = 1, code) => {
  return {
    type: cts.SEARCH_BILL,
    payload: { shopId, status, page, code },
  };
};

export const deleteBill = (id, shopId, status) => ({
  type: cts.DELETE_BILL,
  payload: { id, shopId, status },
});

export const deleteBillSuccess = (data) => ({
  type: cts.DELETE_BILL_SUCCESS,
  payload: { data },
});

export const updateBill = (id, params, isRestoreBill) => ({
  type: cts.UPDATE_BILL,
  payload: { id, params, isRestoreBill },
});

export const updateBillSuccess = (data, isRestoreBill) => ({
  type: cts.UPDATE_BILL_SUCCESS,
  payload: { data, isRestoreBill },
});

export const addAppointeeToBill = (id, params, history) => ({
  type: cts.ADD_APPOINTEE_TO_BILL,
  payload: { id, params, history },
});

export const addAppointeeToBillSuccess = (data) => ({
  type: cts.ADD_APPOINTEE_TO_BILL_SUCCESS,
  payload: { data },
});

export const fetchBillListSuccess = (data) => {
  return {
    type: cts.FETCH_BILL_LIST_SUCCESS,
    payload: { data },
  };
};

export const setBillStep = (step) => ({
  type: cts.SET_BILL_STEP,
  payload: { step },
});

export const createBill = (params, history) => ({
  type: cts.CREATE_BILL,
  payload: { params, history },
});

export const createBillSuccess = (data) => ({
  type: cts.CREATE_BILL_SUCCESS,
  payload: { data },
});

export const paymentBill = (id, params, history, isNoRealtimeFetch) => ({
  type: cts.PAYMENT_BILL,
  payload: { id, params, history, isNoRealtimeFetch },
});

export const paymentBillSuccess = (data) => ({
  type: cts.PAYMENT_BILL_SUCCESS,
  payload: { data },
});

export const resetProductInBill = () => ({
  type: cts.RESET_PRODUCT_IN_BILL,
});

export const fetchProductInBill = (id) => ({
  type: cts.FETCH_PRODUCT_IN_BILL,
  payload: { id },
});

export const fetchProductInBillSuccess = (data) => ({
  type: cts.FETCH_PRODUCT_IN_BILL_SUCCESS,
  payload: { data },
});

export const addProductInBill = (params) => ({
  type: cts.ADD_PRODUCT_IN_BILL,
  payload: { params },
});

export const addProductInBillSuccess = (data, oldData) => ({
  type: cts.ADD_PRODUCT_IN_BILL_SUCCESS,
  payload: { data, oldData },
});

export const addSetInBill = (params) => ({
  type: cts.ADD_SET_IN_BILL,
  payload: { params },
});

export const addSetInBillSuccess = (data) => ({
  type: cts.ADD_SET_IN_BILL_SUCCESS,
  payload: { data },
});

export const updateProductInBill = (id, params) => ({
  type: cts.UPDATE_PRODUCT_IN_BILL,
  payload: { id, params },
});

export const updateProductInBillSuccess = (data) => ({
  type: cts.UPDATE_PRODUCT_IN_BILL_SUCCESS,
  payload: { data },
});

export const updateSetInBill = (id, params) => ({
  type: cts.UPDATE_SET_IN_BILL,
  payload: { id, params },
});

export const updateSetInBillSuccess = (data) => ({
  type: cts.UPDATE_SET_IN_BILL_SUCCESS,
  payload: { data },
});

export const deleteProductInBill = (params) => ({
  type: cts.DELETE_PRODUCT_IN_BILL,
  payload: { params },
});

export const deleteProductInBillSuccess = (data) => ({
  type: cts.DELETE_PRODUCT_IN_BILL_SUCCESS,
  payload: { data },
});

export const emptyBill = (billId, productInBill) => ({
  type: cts.EMPTY_BILL,
  payload: { billId, productInBill },
});

export const fetchBillDetailLoading = () => ({
  type: cts.FETCH_BILL_DETAIL_LOADING,
});

export const hideFetchBillDetailLoading = () => ({
  type: cts.HIDE_FETCH_BILL_DETAIL_LOADING,
});

export const resetFetchedBill = () => ({
  type: cts.RESET_FETCHED_BILL_STATE,
});

export const setNewBillInfo = (data) => ({
  type: cts.SET_NEW_BILL_INFO,
  payload: { data },
});

export const emptyNewBillInfo = () => ({
  type: cts.EMPTY_NEW_BILL_INFO,
});

export const refetchSidebarReport = (data) => ({
  type: cts.IS_REFETCH_SIDEBAR_REPORT,
  payload: { data },
});
