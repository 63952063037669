/* eslint-disable no-unused-vars */
import { message } from "antd";
import * as errorCts from "../constants/ui/error";

const success = (content, duration = 3000) => {
  message.config({
    top: 30,
    duration: duration / 1000,
  });
  message.success(content);
};

const danger = (content, duration = 3000) => {
  message.config({
    top: 30,
    duration: duration / 1000,
  });
  message.error(content);
};

const warning = (content, duration = 3000) => {
  message.config({
    top: 30,
    duration: duration / 1000,
  });
  message.warning(content);
};

export const toast = (message, type, time = 3000) => {
  if (type === "success") {
    return success(message, time);
  }

  if (type === "warning") {
    return warning(message, time);
  }
  return danger(message, time);
};

export const errorToast = (message) => {
  danger(message, 3000);
};

export const successToast = (message, time = 3000) => {
  success(message, time);
};

export const failedToast = (error, title) => {
  if (error && error.message === "Network Error") {
    danger(errorCts.NETWORK_DISCONNECTED);
  } else {
    danger(title);
  }
};
