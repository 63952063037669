/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import { NavLink } from "react-router-dom";
import { Result } from "antd";
import * as cts from "../constants/ui/general";
import { IS_SETUP_PAGE } from "../constants";

export default function NotFoundPage() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 60px)",
      }}
    >
      <Result
        status="404"
        title={cts.PAGE_NOT_FOUND}
        subTitle={cts.PAGE_NOT_FOUND_SUBTITLE}
        extra={
          IS_SETUP_PAGE ? (
            <NavLink style={{ fontSize: 16, color: "blue" }} to="/setup">
              {cts.SETUP}
            </NavLink>
          ) : (
            <NavLink style={{ fontSize: 16, color: "blue" }} to="/bills">
              {cts.BACK_TO_BILL_LIST}
            </NavLink>
          )
        }
      />
    </div>
  );
}
