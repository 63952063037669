import { useLocation } from "react-router-dom";

const useBreadCrumb = () => {
  const { pathname } = useLocation();
  const pathNames = pathname.split("/").filter((x) => x);

  const breadcrumbs = pathNames.map((name, index) => {
    const renamed = name.replace("-", " ").toUpperCase();
    const routeTo = `/${pathNames.slice(0, index + 1).join("/")}`;

    return {
      pathName: pathNames[index],
      name: renamed,
      routeTo,
      isLast: pathNames[pathNames.length - 1] === pathNames[index],
    };
  });

  return { breadcrumbs };
};

export default useBreadCrumb;
