import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Spin, Checkbox, Button, Space, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { CgExport } from "react-icons/cg";
import { MdToday } from "react-icons/md";
import * as cts from "../../../constants/ui/general";
import * as slt from "../../../selectors";
import * as socket from "../../../sockets";
import * as apis from "../../../apis/report";
import * as errCts from "../../../constants/ui/error";
import { setBreadcrumb } from "../../../actions/general";
import { errorToast } from "../../../helpers/AlertHelper";
import {
  convertDate2,
  formatMoney,
  getMonthAndYear2,
  prevDate,
  nextDate,
} from "../../../helpers/functionHelpers";
import { fetchReportMonthAPI, fetchReportDayAPI } from "../../../apis/report";
import ExportModal from "../../../components/ExportModal";
import CalendarV2 from "../../../components/CalendarV2";
import DatePicker from "../../../components/DatePicker";
import { useQuery } from "../../../hooks";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
const DailyCalendarPage = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const token = useSelector((state) => slt.user.tokenSelector(state));
  const selectedShop = useSelector((state) => slt.user.selectedShopSelector(state));
  const userInfo = useSelector((state) => slt.user.userInfoSelector(state));
  const printSettings = useSelector((state) => slt.user.printSettingsSelector(state));

  const [showTarget, setShowTarget] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [showExport, setShowExport] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [reportMonth, setReportMonth] = useState({
    data: [],
    shopId: null,
  });
  const [reportMonth1, setReportMonth1] = useState(false);

  const [date, setDate] = useState(moment(selectedDate).format("YYYY-MM-DD"));

  const timeTabs = [
    { value: "hour", label: `${cts.DAY}` },
    { value: "date", label: `${cts.MONTH}` },
    { value: "month", label: `${cts.YEAR}` },
  ];
  const [exportInfo, setExportInfo] = useState({
    type: "xlsx",
    name: "",
    exportType: "hour",
    checkedList: [], // no need in this component
    indeterminate: false, // no need in this component
    checkAll: false, // no need in this component
    author: "",
    shopName: "",
    tab: cts.DAILY_REPORT,
    exportDate: convertDate2(new Date()),
    dateType: timeTabs[0].label,
  });
  const [exportLoading, setExportLoading] = useState(0);
  const [isReFetchReport, setReFetchReport] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const fetchReportMonth = useCallback(async () => {
    try {
      setFetchLoading(true);
      const res = await apis.fetchReportMonthAPI(token, {
        shop_id: selectedShop.id,
        date: moment(selectedDate).format("YYYY-MM-DD"),
        type: "month",
      });
      const data = res.data.filter((i) => i.target > 0 || i.sales > 0);
      setReportMonth({
        data,
        shopId: selectedShop.id,
      });
      setFetchLoading(false);
    } catch (err) {
      errorToast(err, errCts.FETCH_REPORT_MONTH_FAILED);
      setFetchLoading(false);
    }
  }, [selectedShop.id, selectedDate]);

  useEffect(() => {
    if (query.get(`date`)) {
      setSelectedDate(moment(query.get(`date`)));
    }
  }, [query.get(`date`)]);
  useEffect(() => {
    dispatch(setBreadcrumb({ id: "daily-report", name: cts.DAILY_REPORT }));
  }, [dispatch]);

  // option
  useEffect(() => {
    const name = timeTabs.filter((i) => i.value === exportInfo.exportType)[0]?.label;
    const date =
      name === `${cts.MONTH}`
        ? getMonthAndYear2(selectedDate)
        : name === `${cts.YEAR}`
        ? moment(selectedDate).format("YYYY")
        : convertDate2(selectedDate);
    if (selectedShop && userInfo) {
      setExportInfo({
        ...exportInfo,
        checkAll: true,
        checkedList: plainOptions().map((item) => item.value),
        author: userInfo?.name,
        shopName: selectedShop?.name,
        dateType: name,
        exportDate: date,
      });
    }
  }, [exportInfo.exportType, selectedShop, userInfo, selectedDate, selectedShop, userInfo]);

  useEffect(() => {
    if (selectedShop.id && selectedDate) {
      fetchReportMonth();
    }
  }, [fetchReportMonth]);

  useEffect(() => {
    socket.serverSendBillAfterPayment((data) => {
      setReFetchReport(data.id);
    });
  }, []);

  useEffect(() => {
    if (isReFetchReport && moment(selectedDate).format("YYYY-MM") === moment().format("YYYY-MM")) {
      fetchReportMonth();
    }
  }, [isReFetchReport]);
  const onPrevDate = (e) => {
    e.stopPropagation();
    return setSelectedDate(prevDate(selectedDate, reportMonth ? "m" : "d"));
  };
  const onNextDate = (e) => {
    e.stopPropagation();

    return setSelectedDate(nextDate(selectedDate, reportMonth ? "m" : "d"));
  };
  const data = reportMonth.data.map((item) => {
    return {
      ...item,
      date:
        item.day < 10
          ? `${moment(selectedDate).format("YYYY-MM")}-0${item.day}`
          : `${moment(selectedDate).format("YYYY-MM")}-${item.day}`,
    };
  });

  const plainOptions = () => {
    if (exportInfo.exportType === "date") {
      return [
        {
          label: cts.DAY,
          value: "day",
        },
        { label: cts.TARGET, value: "target" },
        { label: cts.SALES, value: "sales" },
        { label: cts.NO_VISITORS, value: "number_visitor" },
        { label: cts.TABLE_USED, value: "table_used" },
        { label: cts.MONEY_RECEIVED, value: "money_received" },
        { label: cts.CASH, value: "cash" },
        { label: cts.CARD, value: "card" },
        { label: cts.RECEIVABLES, value: "receivables" },
        { label: cts.PRICE_ADJUSTMENT, value: "other_money" },
        { label: cts.TAX, value: "tax" },
        { label: cts.CONSUMPTION_TAX, value: "consumption_tax" },
      ];
    }
    if (exportInfo.exportType === "month") {
      return [
        {
          label: cts.MONTH,
          value: "month",
        },
        { label: cts.TARGET, value: "target" },
        { label: cts.SALES, value: "sales" },
        { label: cts.NO_VISITORS, value: "number_visitor" },
        { label: cts.TABLE_USED, value: "table_used" },
        { label: cts.MONEY_RECEIVED, value: "money_received" },
        { label: cts.CASH, value: "cash" },
        { label: cts.CARD, value: "card" },
        { label: cts.RECEIVABLES, value: "receivables" },
        { label: cts.PRICE_ADJUSTMENT, value: "other_money" },
        { label: cts.TAX, value: "tax" },
        { label: cts.CONSUMPTION_TAX, value: "consumption_tax" },
        { label: cts.TOTAL_SPENDING, value: "total_spending" },
        { label: cts.TOTAL_SALARY_ADVANCE, value: "total_salary_advance" },
      ];
    }
    return [
      {
        label: cts.HOUR,
        value: "hour",
      },
      { label: cts.TARGET, value: "target" },
      { label: cts.SALES, value: "sales" },
      { label: cts.NO_VISITORS, value: "number_customer" },
      { label: cts.MONEY_RECEIVED, value: "money_received" },
      { label: cts.CASH, value: "cash" },
      { label: cts.CARD, value: "card" },
      { label: cts.TOTAL_SPENDING, value: "total_spending" },
      { label: cts.TOTAL_SALARY_ADVANCE, value: "total_salary_advance" },
    ];
  };

  const getExportData = async () => {
    let exportData = [];
    let temps = [];
    if (exportInfo.exportType === "date") {
      const res = await fetchReportMonthAPI(token, {
        shop_id: selectedShop.id,
        date: moment(selectedDate).format("YYYY-MM-DD"),
        type: "month",
      });
      temps = temps.concat(res.data.filter((i) => i.sales > 0));
      const d = temps.map((item) => {
        return `0${item.day}`.slice(-2);
      });

      for (let i = 1; i <= d.length; i++) {
        const res = await fetchReportDayAPI(token, {
          shop_id: selectedShop.id,
          date: `${moment(selectedDate).format("YYYY-MM")}-${d[i - 1]}`,
        });
        exportData = exportData.concat(res.data);
        setExportLoading(Math.round((i / d.length) * 100));
      }
      const newArr = [];
      for (let i = 0; i < temps.length; i++) {
        newArr.push({ ...temps[i], ...exportData[i] });
      }

      const newData = [];
      newArr.forEach((item) => {
        const newObj = {};
        let v = "";
        exportInfo.checkedList.forEach((i) => {
          if (exportInfo.type !== "xlsx") {
            v = i;
            newObj["shop_name"] = selectedShop.name;
          } else {
            // newObj[`${cts.SHOP_NAME}`] = selectedShop.name;
            v = plainOptions().filter((a) => a.value === i)[0].label;
          }
          // newObj["day"] = `0${item[i]}`.slice(-2);
          newObj[v] = `${formatMoney(item[i] || 0)}${cts.YEN_2}`;
          if (i === "day") {
            newObj[v] = item[i];
          }
          if (i === "number_visitor" || i === "table_used") {
            newObj[v] = formatMoney(item[i] || 0);
          }
        });
        newData.push(newObj);
      });
      return newData;
    } else if (exportInfo.exportType === "month") {
      for (let i = 1; i <= 12; i++) {
        const m = `0${i}`.slice(-2);
        const res = await fetchReportDayAPI(token, {
          shop_id: selectedShop.id,
          date: `${moment(selectedDate).format("YYYY")}-${m}-${moment(selectedDate).format("DD")}`,
          type: "month",
        });
        exportData = exportData.concat({ month: i, ...res.data });
        setExportLoading(Math.round((i / 12) * 100));
      }
      const newData = [];
      exportData.forEach((item) => {
        const newObj = {};
        let v = "";
        exportInfo.checkedList.forEach((i) => {
          if (exportInfo.type !== "xlsx") {
            v = i;
            newObj["shop_name"] = selectedShop.name;
          } else {
            // newObj[`${cts.SHOP_NAME}`] = selectedShop.name;
            v = plainOptions().filter((a) => a.value === i)[0].label;
          }
          newObj[v] = `${formatMoney(item[i] || 0)}${cts.YEN_2}`;
          if (i === "month") {
            newObj[v] = item[i];
          }
          if (i === "number_visitor" || i === "table_used") {
            newObj[v] = formatMoney(item[i] || 0);
          }
        });
        newData.push(newObj);
      });
      return newData;
    } else {
      const res = await fetchReportDayAPI(token, {
        shop_id: selectedShop.id,
        date: `${moment(selectedDate).format("YYYY-MM-DD")}`,
        type: "hour",
      });
      exportData = exportData.concat(res.data);
      setExportLoading(50);
      const newData = [];
      exportData.forEach((item) => {
        const newObj = {};
        let v = "";
        exportInfo.checkedList.forEach((i) => {
          if (exportInfo.type !== "xlsx") {
            v = i;
            newObj["shop_name"] = selectedShop.name;
          } else {
            // newObj[`${cts.SHOP_NAME}`] = selectedShop.name;
            v = plainOptions().filter((a) => a.value === i)[0].label;
          }
          newObj[v] = `${formatMoney(item[i] || 0)}${cts.YEN_2}`;
          if (i === "hour") {
            newObj[v] = item[i];
          }
          if (i === "number_customer") {
            newObj[v] = formatMoney(item[i] || 0);
          }
        });
        newData.push(newObj);
      });
      return newData;
    }
  };

  const _renderHeader = () => {
    return (
      <div className="daily-report__header mt-15 mb-15">
        <div className="daily-report__header__left mt-20" />
        <div className="daily-report__header__middle mt-20">
          <Button className="date-btn">
            <Button className="btn-icon" onClick={onPrevDate}>
              <BsChevronLeft />
            </Button>
            <h4 style={{ color: "#8f8f8f" }}>
              {moment(selectedDate).format(reportMonth ? "YYYY年MM月" : "YYYY年MM月DD日")}
            </h4>
            <Button className="btn-icon" onClick={onNextDate}>
              <BsChevronRight />
            </Button>
          </Button>
          <Button className="btn-today ml-15" onClick={() => setSelectedDate(moment())}>
            <span>{cts.TODAY}</span>
          </Button>
          {/* <Button
            className="btnPrev"
            icon={<AiOutlineLeft />}
            onClick={() => setSelectedDate(moment(selectedDate).subtract(1, "month"))}
          />
       
          <DatePicker
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            setOpenDatePicker={setOpenDatePicker}
            openDatePicker={openDatePicker}
            dateFormat={"YYYY/MM"}
            datePicker={"month"}
      
          />
          <Button
            className="btnNext"
            icon={<AiOutlineRight />}
            onClick={() => setSelectedDate(moment(selectedDate).add(1, "month"))}
          /> */}
        </div>
        <div className="daily-report__header__right mt-20">
          <Space>
            <Checkbox
              checked={showTarget}
              onChange={(e) => setShowTarget(e.target.checked)}
              style={{ color: "#8f8f8f" }}
            >
              {cts.SHOW_TARGET}
            </Checkbox>

            <Tooltip title={cts.EXPORT} placement="bottom">
              <Button
                className="export-btn mr-20"
                icon={<CgExport />}
                onClick={() => setShowExport(true)}
              />
            </Tooltip>
            {/* <Button className="active mr-20">
              <Link
                className="active"
                to={{
                  pathname: `/daily-report/detail`,
                  search: `date=${moment(selectedDate).format("YYYY-MM-DD")}&type=month`,
                  state: {
                    type: "month",
                    date,
                  },
                }}
              >
                {cts.REPORT_MONTHLY}
              </Link>
            </Button> */}
          </Space>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="daily-reportV2">
        <div style={{ display: "flex" }}>
          <span className="daily-reportV2-icon mr-5">
            <svg
              width="14"
              height="14"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 10.5L10.5 1.5L19.5 10.5M3.5 11.5V15.5C3.5 16.0304 3.71071 16.5391 4.08579 16.9142C4.46086 17.2893 4.96957 17.5 5.5 17.5H15.5C16.0304 17.5 16.5391 17.2893 16.9142 16.9142C17.2893 16.5391 17.5 16.0304 17.5 15.5V11.5"
                stroke="#666687"
              />
            </svg>
          </span>
          <span>
            Home <span className="ml-5 mr-5">/</span>
            {cts.INCOME}
          </span>
        </div>
        <div style={{ background: "white" }}>
          {_renderHeader()}
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            spinning={fetchLoading}
          >
            <div className="daily-report__content">
              <CalendarV2
                value={moment(selectedDate)}
                showHeader={false}
                eventData={data}
                showEvent={showTarget}
              />
            </div>
          </Spin>
          {showExport ? (
            <ExportModal
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              showExport={showExport}
              setShowExport={setShowExport}
              exportInfo={exportInfo}
              setExportInfo={setExportInfo}
              loading={fetchLoading}
              getExportData={getExportData}
              exportLoading={exportLoading}
              setExportLoading={setExportLoading}
              plainOptions={plainOptions()}
              isOptional
              fromReport
              timeTabs={timeTabs}
              landscapeMode={exportInfo.exportType !== "hour" ? true : false}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
export default DailyCalendarPage;
