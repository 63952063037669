import { DatePicker as AntDatePicker } from "antd";
import JP from "antd/es/date-picker/locale/ja_JP";
import moment from "moment";
import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import * as cts from "../constants/ui/general";

const DatePicker = ({
  setOpenDatePicker,
  selectedDate,
  setSelectedDate,
  openDatePicker,
  dateFormat,
  datePicker,
  datePickerStyle,
  timeTab,
}) => {
  return (
    <div className="picker-wrapper" style={{ height: 30 }}>
      <div
        onClick={() => setOpenDatePicker(!openDatePicker)}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 100000,
          cursor: "pointer",
        }}
      />
      <AntDatePicker
        picker={datePicker}
        value={moment(selectedDate)}
        format={dateFormat}
        locale={JP}
        onChange={(date) => {
          setOpenDatePicker(false);
          setSelectedDate(date.format("YYYY-MM-DD"));
        }}
        allowClear={false}
        bordered={false}
        suffixIcon={null}
        dropdownAlign={{ points: ["tc", "bc"] }}
        // inputReadOnly
        open={openDatePicker}
        style={{ height: 30, ...datePickerStyle }}
        // onOpenChange={(open) => setOpenDatePicker(open)}
        // onClick={() => setOpenDatePicker(false)}
      />
      <div
        style={{
          position: "absolute",
          right: timeTab?.label === cts.MONTH || timeTab?.label === cts.YEAR ? 25 : 5,
          top: 8,
        }}
        onClick={() => setOpenDatePicker(false)}
      >
        <AiOutlineCalendar color="#c7c7c7" size={16} />
      </div>
    </div>
  );
};

export default DatePicker;
