import * as cts from "../constants/events/profit";

export const fetchProfit = (params) => ({
  type: cts.FETCH_PROFIT,
  payload: { params },
});

export const fetchProfitSuccess = (data) => ({
  type: cts.FETCH_PROFIT_SUCCESS,
  payload: { data },
});

export const fetchProfitTimeDetail = (params) => ({
  type: cts.FETCH_PROFIT_TIME,
  payload: { params },
});

export const fetchProfitTimeDetailSuccess = (data) => ({
  type: cts.FETCH_PROFIT_TIME_SUCCESS,
  payload: { data },
});

export const fetchProfitHostDetail = (params) => ({
  type: cts.FETCH_PROFIT_HOST,
  payload: { params },
});

export const fetchProfitHostDetailSuccess = (data) => ({
  type: cts.FETCH_PROFIT_HOST_SUCCESS,
  payload: { data },
});

export const fetchProfitProductDetail = (params) => ({
  type: cts.FETCH_PROFIT_PRODUCT,
  payload: { params },
});

export const fetchProfitProductDetailSuccess = (data) => ({
  type: cts.FETCH_PROFIT_PRODUCT_SUCCESS,
  payload: { data },
});

export const addShopToCompare = (params) => ({
  type: cts.ADD_SHOP_TO_COMPARE,
  payload: { params },
});

export const addShopToCompareSuccess = (data) => ({
  type: cts.ADD_SHOP_TO_COMPARE_SUCCESS,
  payload: { data },
});

export const addMultiShopToCompare = (params) => ({
  type: cts.ADD_MULTI_SHOP_COMPARE,
  payload: { params },
});

export const resetShopCompare = () => ({
  type: cts.RESET_SHOP_COMPARE,
});

export const removeShopCompare = (id) => ({
  type: cts.REMOVE_SHOP_COMPARE,
  payload: { id },
});
