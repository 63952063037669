import * as cts from "../constants/events/host";

// HOST LIST
export const fetchHostList = (shopId) => ({
  type: cts.FETCH_HOST_LIST,
  payload: { shopId },
});

export const fetchHostListSuccess = (data) => ({
  type: cts.FETCH_HOST_LIST_SUCCESS,
  payload: { data },
});

export const fetchHostForOrderList = (shopId) => ({
  type: cts.FETCH_HOST_FOR_ORDER_LIST,
  payload: { shopId },
});

export const fetchHostForOrderListSuccess = (data) => ({
  type: cts.FETCH_HOST_FOR_ORDER_LIST_SUCCESS,
  payload: { data },
});

// HOST RECEIVABLES
export const fetchHostReceivablesList = (params) => ({
  type: cts.FETCH_HOST_RECEIVABLES_LIST,
  payload: { params },
});

export const fetchHostReceivablesListSuccess = (data) => ({
  type: cts.FETCH_HOST_RECEIVABLES_LIST_SUCCESS,
  payload: { data },
});

export const createHostReceivables = (params) => ({
  type: cts.CREATE_HOST_RECEIVABLES,
  payload: { params },
});

export const createHostReceivablesSuccess = (data) => ({
  type: cts.CREATE_HOST_RECEIVABLES_SUCCESS,
  payload: { data },
});
export const updateHostForOrderList = (id, params) => ({
  type: cts.UPDATE_HOST_FOR_ORDER_LIST,
  payload: { id, params },
});

export const updateHostForOrderListSuccess = (data) => ({
  type: cts.UPDATE_HOST_FOR_ORDER_LIST_SUCCESS,
  payload: { data },
});

export const updateHostReceivables = (id, params) => ({
  type: cts.UPDATE_HOST_RECEIVABLES,
  payload: { id, params },
});

export const updateHostReceivablesSuccess = (data) => ({
  type: cts.UPDATE_HOST_RECEIVABLES_SUCCESS,
  payload: { data },
});

export const deleteHostReceivables = (params) => ({
  type: cts.DELETE_HOST_RECEIVABLES,
  payload: { params },
});

export const deleteHostReceivablesSuccess = (data) => ({
  type: cts.DELETE_HOST_RECEIVABLES_SUCCESS,
  payload: { data },
});

// HOST SALES
export const fetchHostSales = (params) => ({
  type: cts.FETCH_HOST_SALES,
  payload: { params },
});

export const fetchHostSalesSuccess = (data) => ({
  type: cts.FETCH_HOST_SALES_SUCCESS,
  payload: { data },
});

// HOST SALARY
export const fetchHostSalary = (params) => ({
  type: cts.FETCH_HOST_SALARY,
  payload: { params },
});

export const fetchHostSalarySuccess = (data) => ({
  type: cts.FETCH_HOST_SALARY_SUCCESS,
  payload: { data },
});

export const updateHostSalary = (params) => ({
  type: cts.UPDATE_HOST_SALARY,
  payload: { params },
});

export const updateHostSalarySuccess = (data) => ({
  type: cts.UPDATE_HOST_SALARY_SUCCESS,
  payload: { data },
});

// HOST OTHER MONEY
export const fetchHostOtherMoneyList = (params) => ({
  type: cts.FETCH_HOST_OTHER_MONEY_LIST,
  payload: { params },
});

export const fetchHostOtherMoneyListSuccess = (data) => ({
  type: cts.FETCH_HOST_OTHER_MONEY_LIST_SUCCESS,
  payload: { data },
});

export const createHostOtherMoney = (params) => ({
  type: cts.CREATE_HOST_OTHER_MONEY,
  payload: { params },
});

export const createHostOtherMoneySuccess = (data) => ({
  type: cts.CREATE_HOST_OTHER_MONEY_SUCCESS,
  payload: { data },
});

export const updateHostOtherMoney = (id, params) => ({
  type: cts.UPDATE_HOST_OTHER_MONEY,
  payload: { id, params },
});

export const updateHostOtherMoneySuccess = (data) => ({
  type: cts.UPDATE_HOST_OTHER_MONEY_SUCCESS,
  payload: { data },
});

export const deleteHostOtherMoney = (params) => ({
  type: cts.DELETE_HOST_OTHER_MONEY,
  payload: { params },
});

export const deleteHostOtherMoneySuccess = (data) => ({
  type: cts.DELETE_HOST_OTHER_MONEY_SUCCESS,
  payload: { data },
});
export const updateSettingNominationHost = (params) => ({
  type: cts.UPDATE_SETTINGS_NOMINATION_HOST,
  payload: { params },
});

export const updateSettingNominationHostSuccess = (data) => ({
  type: cts.UPDATE_SETTINGS_NOMINATION_HOST_SUCCESS,
  payload: { data },
});
