import React from "react";
const Steps = (props) => {
  const { steps } = props;

  return (
    <div className="bg-checkbox-register mt-10">
      <div className="bg-step1">
        <span
          className={steps == 0 ? "active-circle" : "circle-step text-active"}
          style={{ backgroundColor: (steps == 1 || steps == 2) && "#666687" }}
        ></span>
        <div className="step-line1" style={{ background: steps !== 0 ? "#666687" : "#999" }}></div>
        <span
          className={steps == 1 ? "active-circle" : "circle-step text-active"}
          style={{ backgroundColor: steps == 2 && "#666687" }}
        ></span>
        <div className="step-line1" style={{ background: steps == 2 ? "#666687" : "#999" }}></div>
        <span className={steps == 2 ? "active-circle" : "circle-step text-active"}></span>
      </div>
      <div className="bg-step2">
        <span>メールアドレス登録</span>
        <div className="step-line2"></div>
        <span>認証キーの入力</span>
        <div className="step-line2"></div>
        <span>アカウント情報の入力</span>
      </div>
    </div>
  );
};
export default Steps;
