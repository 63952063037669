/* eslint-disable no-restricted-syntax */
/* eslint-disable no-else-return */
import { fromJS } from "immutable";
import moment from "moment";
import * as constants from "../constants/events/bill";
import { createReducer } from "../helpers/createReducer";

const initialState = fromJS({
  listBill: {
    paidData: [],
    unpaidData: [],
    isFetchedPaid: false,
    isFetchedUnpaid: false,
    currentPage: 1,
    totalPage: 1,
    totalItem: 1,
    perPage: 1,
    shopId: null,
  },
  step: 2,
  productInBill: [],
  fetchBillDetailLoading: false,
  previousStep: [],
  newBillInfo: {},
  isRefetchSidebarReport: false,
  isActionDone: false,
});

// function
const getTotalPayment = (productInBill) => {
  const products = productInBill.map((i) => i.amount);
  const total = products.reduce((a, b) => a + b, 0);

  return total;
};

const resetFetchedState = (state) => {
  const listBill = state.getIn(["listBill"]).toJS();
  return state.set(
    "listBill",
    fromJS({
      ...listBill,
      paidData: [],
      unpaidData: [],
      isFetchedPaid: false,
      isFetchedUnpaid: false,
    })
  );
};

const fetchBillListSuccess = (state, { payload: { data } }) => {
  const listBill = state.getIn(["listBill"]).toJS();
  if (data.status === "unpaid") {
    return state.set(
      "listBill",
      fromJS({
        ...listBill,
        unpaidData: data.data || [],
        shopId: data.shopId,
        isFetchedUnpaid: true,
      })
    );
  } else {
    return state.set(
      "listBill",
      fromJS({
        ...listBill,
        paidData: data.data || [],
        shopId: data.shopId,
        totalItem: data.totalItem,
        perPage: data.per_page,
        isFetchedPaid: true,
      })
    );
  }
};

const deleteBillSuccess = (state, action) => {
  const { data } = action.payload;

  const bills =
    `${data.status}` === "3"
      ? state.getIn(["listBill", "unpaidData"]).toJS()
      : state.getIn(["listBill", "paidData"]).toJS();

  const newBills = bills.filter((i) => i.id !== data.id);

  return `${data.status}` === "3"
    ? state.setIn(["listBill", "unpaidData"], fromJS(newBills))
    : state.setIn(["listBill", "paidData"], fromJS(newBills));
};

const createBillSuccess = (state, action) => {
  const { data } = action.payload;
  const bills = state.getIn(["listBill", "unpaidData"]).toJS();
  const newBills = [{ ...data, set_menu: [], service_fee: [] }, ...bills];

  return state.setIn(["listBill", "unpaidData"], fromJS(newBills));
};

const setBillStep = (state, action) => {
  const { step } = action.payload;
  return state.set("step", fromJS(step));
};

const updateBillSuccess = (state, action) => {
  const { data, isRestoreBill } = action.payload;
  const unpaid = state.getIn(["listBill", "unpaidData"]).toJS();
  const paid = state.getIn(["listBill", "paidData"]).toJS();
  const bills =
    `${data.status}` === "3"
      ? state.getIn(["listBill", "unpaidData"]).toJS()
      : state.getIn(["listBill", "paidData"]).toJS();

  const index = bills.findIndex((i) => i.id === data.id);
  bills[index] = { ...bills[index], ...data };

  if (isRestoreBill) {
    if (
      data.stay_time &&
      moment(data.stay_time).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
    ) {
      return state.setIn(["listBill", "paidData"], fromJS([data, ...paid]));
    }
    if (!data.stay_time) {
      return state.setIn(["listBill", "unpaidData"], fromJS([data, ...unpaid]));
    }
    return state;
  }

  return `${data.status}` === "3"
    ? state.setIn(["listBill", "unpaidData"], fromJS(bills))
    : state.setIn(["listBill", "paidData"], fromJS(bills));
};

const fetchProductInBillSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("productInBill", fromJS(data));
};

const addSetInBillSuccess = (state, action) => {
  const { data, from } = action.payload;
  let productInBill = state.get("productInBill").toJS();
  const unpaid = state.getIn(["listBill", "unpaidData"]).toJS();
  const paid = state.getIn(["listBill", "paidData"]).toJS();

  const indexUnpaid = unpaid.findIndex((i) => i.id === data.bill_id);
  const indexPaid = paid.findIndex((i) => i.id === data.bill_id);

  if (from !== "socket-global") {
    productInBill = [data, ...productInBill];
  }

  if (from !== "socket-in-bill") {
    if (indexUnpaid !== -1) {
      unpaid[indexUnpaid].set_menu = [...unpaid[indexUnpaid].set_menu, data];
    }

    if (indexPaid !== -1) {
      paid[indexPaid].set_menu = [...paid[indexPaid].set_menu, data];
    }
  }

  return state
    .set("productInBill", fromJS(productInBill))
    .setIn(["listBill", "unpaidData"], fromJS(unpaid))
    .setIn(["listBill", "paidData"], fromJS(paid))
    .set("isActionDone", "set");
};

const updateSetInBillSuccess = (state, action) => {
  const { data } = action.payload;
  const productInBill = state.get("productInBill").toJS();
  const unpaid = state.getIn(["listBill", "unpaidData"]).toJS();
  const paid = state.getIn(["listBill", "paidData"]).toJS();

  const index = productInBill.findIndex((i) => i.id === data.id);

  if (index !== -1) {
    productInBill[index] = { ...productInBill[index], ...data };
  }

  const indexUnpaid = unpaid.findIndex((i) => i.id === data.bill_id);
  if (indexUnpaid !== -1) {
    const idx = unpaid[indexUnpaid].set_menu.findIndex((i) => i.id === data.id);
    if (idx !== -1) unpaid[indexUnpaid].set_menu[idx] = data;
  }

  const indexPaid = paid.findIndex((i) => i.id === data.bill_id);
  if (indexPaid !== -1) {
    const idx = paid[indexPaid].set_menu.findIndex((i) => i.id === data.id);
    if (idx !== -1) paid[indexPaid].set_menu[idx] = data;
  }

  return state
    .set("productInBill", fromJS(productInBill))
    .setIn(["listBill", "unpaidData"], fromJS(unpaid))
    .setIn(["listBill", "paidData"], fromJS(paid));
};

const deleteProductInBillSuccess = (state, action) => {
  const { data } = action.payload;

  let productInBill = state.get("productInBill").toJS();
  const unpaid = state.getIn(["listBill", "unpaidData"]).toJS();
  const paid = state.getIn(["listBill", "paidData"]).toJS();

  productInBill = productInBill.filter((i) => i.id !== data.id);

  const total = getTotalPayment(productInBill);
  const unpaidIndex = unpaid.findIndex((i) => i.id === data.bill_id);
  const paidIndex = paid.findIndex((i) => i.id === data.bill_id);

  if (unpaidIndex !== -1) {
    unpaid[unpaidIndex].total_payment = total;
    unpaid[unpaidIndex].set_menu = unpaid[unpaidIndex].set_menu.filter((i) => i.id !== data.id);
  }

  if (paidIndex !== -1) {
    paid[paidIndex].total_payment = total;
    paid[paidIndex].set_menu = paid[paidIndex].set_menu.filter((i) => i.id !== data.id);
  }

  return state
    .set("productInBill", fromJS(productInBill))
    .setIn(["listBill", "unpaidData"], fromJS(unpaid))
    .setIn(["listBill", "paidData"], fromJS(paid));
};

const addProductInBillSuccess = (state, action) => {
  const { data } = action.payload;
  let productInBill = state.get("productInBill").toJS();

  for (const item of data) {
    const index = productInBill.findIndex((i) => i.id === item.id);
    if (index !== -1) {
      productInBill[index] = item;
    } else {
      productInBill = [item].concat(productInBill);
    }
  }

  return state.set("productInBill", fromJS(productInBill)).set("isActionDone", "add");
};

const updateProductInBillSuccess = (state, action) => {
  const { data } = action.payload;
  const productInBill = state.get("productInBill").toJS();
  const index = productInBill.findIndex((i) => i.id === data.id);

  if (index !== -1) {
    productInBill[index] = data;
  }

  const total = getTotalPayment(productInBill);
  const unpaid = state.getIn(["listBill", "unpaidData"]).toJS();
  const paid = state.getIn(["listBill", "paidData"]).toJS();
  const unpaidIndex = unpaid.findIndex((i) => i.id === data.bill_id);
  const paidIndex = paid.findIndex((i) => i.id === data.bill_id);

  if (unpaidIndex !== -1) {
    unpaid[unpaidIndex].total_payment = total;
  }

  if (paidIndex !== -1) {
    paid[paidIndex].total_payment = total;
  }

  return state
    .set("productInBill", fromJS(productInBill))
    .setIn(["listBill", "unpaidData"], fromJS(unpaid))
    .setIn(["listBill", "paidData"], fromJS(paid));
};

const paymentBillSuccess = (state, action) => {
  const { data } = action.payload;
  let unpaid = state.getIn(["listBill", "unpaidData"]).toJS();
  let paid = state.getIn(["listBill", "paidData"]).toJS();

  const item = unpaid.find((i) => i.id === data.id);
  paid = [{ ...item, ...data }, ...paid];

  unpaid = unpaid.filter((i) => i.id !== data.id);

  return state
    .setIn(["listBill", "unpaidData"], fromJS(unpaid))
    .setIn(["listBill", "paidData"], fromJS(paid));
};

const setNewBillInfo = (state, action) => {
  const { data } = action.payload;
  return state.set("newBillInfo", fromJS(data));
};

const emptyNewbillInfo = (state) => {
  return state.set("newBillInfo", fromJS({}));
};

const reducers = {
  [constants.RESET_FETCHED_BILL_STATE]: resetFetchedState,
  [constants.FETCH_BILL_LIST_SUCCESS]: fetchBillListSuccess,
  [constants.DELETE_BILL_SUCCESS]: deleteBillSuccess,
  [constants.SET_BILL_STEP]: setBillStep,
  [constants.CREATE_BILL_SUCCESS]: createBillSuccess,
  [constants.UPDATE_BILL_SUCCESS]: updateBillSuccess,
  [constants.FETCH_PRODUCT_IN_BILL_SUCCESS]: fetchProductInBillSuccess,
  [constants.ADD_SET_IN_BILL_SUCCESS]: addSetInBillSuccess,

  [constants.DELETE_PRODUCT_IN_BILL_SUCCESS]: deleteProductInBillSuccess,

  [constants.ADD_PRODUCT_IN_BILL]: (state) => state.set("isActionDone", false),
  [constants.ADD_PRODUCT_IN_BILL_SUCCESS]: addProductInBillSuccess,

  [constants.ADD_SET_IN_BILL]: (state) => state.set("isActionDone", false),
  [constants.UPDATE_SET_IN_BILL_SUCCESS]: updateSetInBillSuccess,

  [constants.UPDATE_PRODUCT_IN_BILL_SUCCESS]: updateProductInBillSuccess,

  [constants.SET_NEW_BILL_INFO]: setNewBillInfo,
  [constants.EMPTY_NEW_BILL_INFO]: emptyNewbillInfo,
  [constants.FETCH_BILL_DETAIL_LOADING]: (state) => state.set("fetchBillDetailLoading", true),
  [constants.HIDE_FETCH_BILL_DETAIL_LOADING]: (state) => state.set("fetchBillDetailLoading", false),
  [constants.PAYMENT_BILL_SUCCESS]: paymentBillSuccess,
  [constants.IS_REFETCH_SIDEBAR_REPORT]: (state, action) =>
    state.set("isRefetchSidebarReport", action.payload.data),
};

export default createReducer(reducers, initialState);
