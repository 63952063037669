export const lang = process.env.NODE_ENV !== "development" ? "ja" : "ja";

// DATE

export const DAY_C = lang === "en" ? "" : "日";
export const MONTH_C = lang === "en" ? "/" : "月";
export const YEAR_C = lang === "en" ? "/" : "年";

export const DAY_A = lang === "en" ? "Date" : "日";
export const MONTH_A = lang === "en" ? "Month" : "月";
export const YEAR_A = lang === "en" ? "Year" : "年";

export const MINUTE_MINI = lang === "en" ? "m" : "分";
export const MINUTE = lang === "en" ? "m" : "m";

export const HOUR_MINI = lang === "en" ? "h" : "時";
export const HOUR_CHARACTERS = lang === "en" ? "h" : "h";
export const SUNDAY = lang === "en" ? "Sun" : "日";
export const MONDAY = lang === "en" ? "Mon" : "月";
export const TUESDAY = lang === "en" ? "Tue" : "火";
export const WEDNESDAY = lang === "en" ? "Wed" : "水";
export const THURSDAY = lang === "en" ? "Thu" : "木";
export const FRIDAY = lang === "en" ? "Fri" : "金";
export const SATURDAY = lang === "en" ? "Sat" : "土";

export const SUNDAY_FULL = lang === "en" ? "Sunday" : "日";
export const MONDAY_FULL = lang === "en" ? "Monday" : "月";
export const TUESDAY_FULL = lang === "en" ? "Tuesday" : "火";
export const WEDNESDAY_FULL = lang === "en" ? "Wednesday" : "水";
export const THURSDAY_FULL = lang === "en" ? "Thursday" : "木";
export const FRIDAY_FULL = lang === "en" ? "Friday" : "金";
export const SATURDAY_FULL = lang === "en" ? "Saturday" : "土";
export const TALENT = lang === "en" ? "Talent" : "才";

// END_DATE

export const OPEN_SETTINGS = lang === "en" ? "Open Settings " : "オープン設定";
export const HISTORY_SETTINGS = lang === "en" ? "History Settings " : "履歴";
export const BILL_LIST = lang === "en" ? "Bill List" : "伝票";
export const BILL_PAID_LIST = lang === "en" ? "Bill Paid List" : "会計済み";
export const ADD_NEW_BILL = lang === "en" ? "Add New Bill" : "作成";
export const SELECT_SHOP = lang === "en" ? "Select Shop" : "店舗選択";
export const PROFIT = lang === "en" ? "Profit" : "売上";
export const PROFIT_TIME_DETAIL = lang === "en" ? "Profit Time Detail" : "時間別";
export const PROFIT_HOST_DETAIL = lang === "en" ? "Profit Host Detail" : "HOST別";
export const PROFIT_PRODUCT_DETAIL = lang === "en" ? "Profit Product Detail" : "商品別";
export const RANKING = lang === "en" ? "Ranking" : "ランキング";
export const DAILY_REPORT = lang === "en" ? "Daily Report" : "日報";
export const DAILY_REPORT_DETAIL = lang === "en" ? "Daily Report Detail" : "レポート詳細";
export const BILL_PAID_PAGE = lang === "en" ? "Bill Paid Page" : "伝票リスト";
export const CUSTOMER_LIST = lang === "en" ? "Customer List" : "顧客";
export const CUSTOMER_DETAIL = lang === "en" ? "Customer Detail" : "顧客の詳細";
export const HOST_LIST = lang === "en" ? "Host List" : "HOSTリスト";
export const HOST_LIST_DETAIL = lang === "en" ? "Host List Detail" : "HOSTリスト詳細";
export const STAFF_LIST = lang === "en" ? "Staff List" : "スタッフ";
export const STAFF_LIST_DETAIL = lang === "en" ? "Staff List Detail" : "スタッフリスト詳細";
export const SALARY_SETTINGS = lang === "en" ? "Salary Settings" : "給与設定";
export const CATEGORIES = lang === "en" ? "Categories" : "カテゴリー";
export const PRODUCTS = lang === "en" ? "Products" : "商品";
export const LOG_MANAGEMENT = lang === "en" ? "Log Management" : "ログ";
export const SETTINGS = lang === "en" ? "Settings" : "設定";
export const SETUP = lang === "en" ? "Setup" : "設定";
export const LOGIN = lang === "en" ? "Login" : "ログイン";
export const EMAIL = lang === "en" ? "Email or Username" : "メールアドレスまたはユーザ名";
export const EMAIL_ONLY = lang === "en" ? "Email" : "メールアドレス";
export const PASSWORD = lang === "en" ? "Password" : "パスワード";
export const REMEMBER_ME = lang === "en" ? "Remember Me" : "パスワードを保存";
export const FORGOT_PASSWORD = lang === "en" ? "Forgot password?" : "パスワードをお忘れですか？";
export const TERM_OF_USE = lang === "en" ? "Term Of Use" : "利用規約";
export const PRIVACY_POLICY = lang === "en" ? "Privacy Policy" : "プライバシーポリシー";
export const ADDITIONAL_NOTE = lang === "en" ? "Additional Notes" : "その他の注意事項";
export const BACK_TO_BILL_LIST = lang === "en" ? "Back to bill list" : "伝票リストに戻る";
export const PAGE_NOT_FOUND = lang === "en" ? "Page not found" : "ページが見つかりません";
export const PAGE_NOT_FOUND_SUBTITLE =
  lang === "en"
    ? "Sorry, the page you visited does not exist."
    : "申し訳ありませんが、アクセスしたページは存在しません。";
export const PLEASE_LOGIN_TO_CONTINUE =
  lang === "en" ? "Please login to continue" : "続行するにはログインしてください";
export const ACCOUNT_SETTINGS = lang === "en" ? "Account settings" : "アカウント設定";
export const CHANGE_PASSWORD = lang === "en" ? "Change password" : "パスワードを変更する";
export const LOGOUT = lang === "en" ? "Logout" : "ログアウト";
export const SELECT_SHOP_TO_CONTINUE =
  lang === "en" ? "Select shop to continue" : "続行するには店舗を選択してください";
export const SELECT = lang === "en" ? "Select" : "選択する";
export const TABLE_LIST = lang === "en" ? "Table List" : "テーブルリスト";
export const NOTIFICATION_SETTINGS = lang === "en" ? "Notification Settings" : "通知設定";
export const UNLOCK_SCREEN_CODE =
  lang === "en" ? "Unlock Screen Code" : "画面コードのロックを解除する";
export const PAY_SETTINGS = lang === "en" ? "Pay Settings" : "基本設定";
export const SHOP_SETTINGS = lang === "en" ? "Shop Settings" : "基本設定";
export const SHOW_ALL_TABLE_IN_BILL_LIST =
  lang === "en" ? "Show all table in bill list" : "テーブルに表示";
export const BILL_TOP = lang === "en" ? "Bill Top" : "請求書を上に表示";
export const USER_MANUAL = lang === "en" ? "User Manual" : "ユーザーマニュアル";
export const CONTACT = lang === "en" ? "Contact" : "連絡先";
export const TABLE_NAME = lang === "en" ? "Table Name" : "テーブル名";
export const ACTION = lang === "en" ? "Action" : "ACTION";
export const DELETE = lang === "en" ? "Delete" : "削除";
export const EDIT = lang === "en" ? "Edit" : "編集";
export const NEW_TABLE = lang === "en" ? "New Table" : "テーブル作成";
export const NAME_OF_TABLE = lang === "en" ? "Name of table..." : "テーブル名...";
export const YES = lang === "en" ? "Yes" : "はい";
export const NO = lang === "en" ? "No" : "いいえ";
export const ARE_YOU_SURE_DELETE_THIS_TABLE =
  lang === "en" ? "Are you sure delete this table?" : "このテーブルを削除してもよろしいですか？";
export const UNPAID = lang === "en" ? "UNPAID" : "伝票";
export const PAID = lang === "en" ? "PAID" : "会計済み";
export const BILL_SEARCH_PLACEHOLDER = lang === "en" ? "bill code,..." : "伝票を検索する";
export const YEN = lang === "en" ? "¥" : "¥";
export const YEN_2 = lang === "en" ? "円" : "円";
export const PERCENT_ICON = lang === "en" ? "%" : "％";
export const SEARCH = lang === "en" ? "Search" : "検索";
export const RESET = lang === "en" ? "Reset" : "リセット";
export const NAME = lang === "en" ? "Name" : "名前";
export const STAFFID = lang === "en" ? "StaffID" : "スタッフID";
export const NAME_HOLDER = lang === "en" ? "Name..." : "名前...";
export const POSITION = lang === "en" ? "Position" : "役職";
export const PHONE = lang === "en" ? "Phone" : "電話番号";
export const ADDRESS = lang === "en" ? "Address" : "住所";
export const NO_VISITORS = lang === "en" ? "No. Visitors" : "顧客数";
export const APPOINTEE_TIMES = lang === "en" ? "Appointee times" : "指名回数";
export const RECEIVABLES = lang === "en" ? "Receivables" : "売掛金";
export const RECEIVABLES_LIST = lang === "en" ? "Receivables List" : "売掛金リスト";
export const WORKING_DAYS = lang === "en" ? "Working days" : "出勤日数";
export const ARE_YOU_SURE_DELETE_THIS_CATEGORY =
  lang === "en"
    ? "Are you sure delete this category?"
    : "このカテゴリーを削除してもよろしいですか？";
export const NAME_OF_CATEGORY = lang === "en" ? "Name of category..." : "カテゴリー名";
export const CREATE_MAIN_MENU = lang === "en" ? "Create Main Menu" : "MAIN MENUの作成";
export const MAIN_MENU = lang === "en" ? "Main Menu" : "MAIN MENU";
export const CATEGORY_NAME = lang === "en" ? "Category name" : "カテゴリー名";
export const PRODUCT_NAME = lang === "en" ? "Product name" : "商品名";
export const PRODUCTID = lang === "en" ? "Product ID" : "商品ID";
export const INVENTORY = lang === "en" ? "Inventory" : "在庫";
export const PRICE = lang === "en" ? "Price" : "価格";
export const NA = lang === "en" ? "-" : "-";
export const SAVE = lang === "en" ? "Save" : "保存する";
export const UPDATE_PRODUCT = lang === "en" ? "Update Product" : "商品の編集";
export const NEW_PRODUCT = lang === "en" ? "New Product" : "作成";
export const PRODUCT_IMAGE = lang === "en" ? "Product Image" : "画像をアップロード";
export const JPG_OR_PNG =
  lang === "en"
    ? "You can only upload JPG/PNG file!"
    : "アップロードできるのはJPG / PNGファイルのみです！";
export const SMALLER_2MB =
  lang === "en" ? "Image must smaller than 2MB!" : "画像は2MB未満である必要があります！";
export const MUST_CREATE_CATEGORY_FIRST =
  lang === "en" ? "You must create category first" : "最初にカテゴリーを作成する必要があります";
export const CREATE_PRODUCT_REQUIRED =
  lang === "en" ? "Name, Price is required field" : "名前、価格は必須フィールドです";
export const FILE_TYPE = lang === "en" ? "File Type" : "ファイルの種類";
export const TYPE = lang === "en" ? "TYPE" : "TYPE";
export const SALES = lang === "en" ? "Sales" : "売上";
export const BILL = lang === "en" ? "Bill" : "明細書";
export const INFORMATION = lang === "en" ? "Information" : "情報";
export const APPOINTEE = lang === "en" ? "Appointee" : "指名";
export const CONDITION = lang === "en" ? "Condition" : "状態";
export const CUSTOMERS = lang === "en" ? "Customers" : "顧客";
export const CUSTOMERSID = lang === "en" ? "CustomersID" : "顧客ID";

export const UPDATE_SALARY = lang === "en" ? "Update salary" : "給与を更新する";
export const NEW_SALARY = lang === "en" ? "New salary" : "作成";
export const CONDITION_FROM = lang === "en" ? "Condition from" : "からの状態";
export const CONDITION_TO = lang === "en" ? "Condition to" : "条件";
export const ARE_YOU_SURE_DELETE_THIS_SALARY =
  lang === "en"
    ? "Are you sure delete this salary setting?"
    : "この給与設定を削除してもよろしいですか？";
export const FROM = lang === "en" ? "From" : "広告媒体";
export const VISIT_TIMES = lang === "en" ? "Visit times" : "来店回数";
export const VISIT_DATE = lang === "en" ? "Visit date" : "来店日";
export const LAST_APPOINTEE = lang === "en" ? "Last appointee" : "指名";
export const LAST_BILL = lang === "en" ? "Last Bill" : "最後の法案";
export const LAST_VISIT = lang === "en" ? "Last visit" : "来店日";
export const LAST_CUSTOMER = lang === "en" ? "Last Customer" : "最後の顧客";
export const STAY_TIME = lang === "en" ? "Stay time" : "滞在時間";
export const CREATE_CUSTOMER_REQUIRED =
  lang === "en" ? "Name is required field" : "名前は必須フィールドです";
export const UPDATE_CUSTOMER = lang === "en" ? "Update Customer" : "顧客の更新";
export const NEW_CUSTOMER = lang === "en" ? "New Customer" : "作成";
export const ARE_YOU_SURE_DELETE_THIS_CUSTOMER =
  lang === "en" ? "Are you sure delete this customer?" : "この顧客を削除してもよろしいですか？";
export const CHECK_INVENTORY = lang === "en" ? "Check inventory" : "在庫を通知する";
export const BACK_TO_TOP = lang === "en" ? "Back to top" : "TOPに戻る";
export const NEXT_STEP = lang === "en" ? "Next Step" : "作成";
export const PREV_STEP = lang === "en" ? "Prev Step" : "PREV";
export const DONE = lang === "en" ? "Done" : "DONE";
export const MULTI_CREATE = lang === "en" ? "Multi Create" : "マルチクリエイト";
export const AMOUNT = lang === "en" ? "Amount" : "数量";
export const TABLE = lang === "en" ? "Table" : "テーブル";
export const TABLE_USED = lang === "en" ? "Table Used" : "卓数";
export const PRODUCT = lang === "en" ? "Product" : "商品";
export const PAYMENT = lang === "en" ? "Payment" : "支払い";
export const PAYMENT2 = lang === "en" ? "Payment" : "入金";

export const EMPTY_TEXT = lang === "en" ? "Empty" : "データなし";
export const NOT_FOUND_TEXT = lang === "en" ? "Not found" : "見つかりません";
export const ARE_YOU_SURE_DELETE_THIS_BILL =
  lang === "en" ? "Are you sure delete this bill?" : "この伝票を削除してもよろしいですか？";
export const ARE_YOU_SURE_PRINT_INVOICE =
  lang === "en" ? "Are you sure print invoice?" : "請求書を印刷してもよろしいですか？";
export const PRINT = lang === "en" ? "Print" : "印刷";
export const USING_PRINTER = lang === "en" ? "Using printer" : "プリンター利用";
export const CREATE = lang === "en" ? "Create" : "作成";
export const NEXT = lang === "en" ? "NEXT" : "作成";
export const NO_CUSTOMER = lang === "en" ? "No. Customer" : "顧客数";
export const CUSTOMER_NAME = lang === "en" ? "Customer Name" : "顧客名";
export const FREE = lang === "en" ? "FREE" : "FREE";
export const SELECT_TABLE = lang === "en" ? "Select Table" : "テーブル選択";
export const LOADING = lang === "en" ? "Loading" : "読み込み中";
export const BY_TIME = lang === "en" ? "By Time" : "時間";
export const BY_HOST = lang === "en" ? "By Host" : "HOST";
export const BY_PRODUCT = lang === "en" ? "By Product" : "商品";
export const COMPARE = lang === "en" ? "Compare" : "比較する";
export const HOUR = lang === "en" ? "Hour" : "時間";
export const DAY = lang === "en" ? "Day" : "日";
export const STEM = lang === "en" ? "Stem" : "幹";
export const BRANCH = lang === "en" ? "Brand" : "枝";
export const POS_STAFF = lang === "en" ? "Pos staff" : "POSスタッフ";
export const POS_OFFICE = lang === "en" ? "Pos office" : "POS バックオフィス";

export const MONTH = lang === "en" ? "Month" : "月";
export const THIS_MONTH = lang === "en" ? "This Month" : "当月";
export const YEAR = lang === "en" ? "Year" : "年";
export const DEFAULT = lang === "en" ? "Default" : "Default";
export const NUMBER_CUSTOMER = lang === "en" ? "No. Customers" : "顧客数";
export const NUMBER_SALES = lang === "en" ? "Number of sales" : "販売数";
export const AMOUNT_CUSTOMER = lang === "en" ? "Amount/Customer" : "平均単価";
export const TABLES = lang === "en" ? "Tables" : "卓数";
export const AVERAGE_REVENUE = lang === "en" ? "Average Revenue" : "平均収益";
export const CASH = lang === "en" ? "Cash" : "現金";
export const CARD = lang === "en" ? "Card" : "カード";
export const AR = lang === "en" ? "A/R" : "売掛";
export const HIGHEST = lang === "en" ? "Highest" : "最高";
export const LONGEST = lang === "en" ? "Longest" : "最長";
export const LOWEST = lang === "en" ? "Lowest" : "最低";
export const SHORTEST = lang === "en" ? "Shortest" : "最短";
export const SEARCH_PLACEHOLDER = lang === "en" ? "Enter your search" : "検索を入力";
export const BAR_CHART = lang === "en" ? "Bar chart" : "棒グラフ";
export const LINE_CHART = lang === "en" ? "Line chart" : "折れ線グラフ";
export const ADD_SHOP = lang === "en" ? "Add shop" : "店舗を追加";
export const RANK = lang === "en" ? " Rank" : " ランク";
export const ORDER = lang === "en" ? "Order" : "注文";
export const HOST = lang === "en" ? "Host" : "HOST";
export const STAFF = lang === "en" ? "Staff" : "スタッフ";
export const SALARY = lang === "en" ? "Salary" : "給料";
export const USER = lang === "en" ? "User" : "ユーザー";
export const AUTHOR = lang === "en" ? "Author" : "担当";
export const TIME = lang === "en" ? "Time" : "時間";
export const DATA = lang === "en" ? "Data" : "データ";
export const CODE = lang === "en" ? "Code" : "コード";
export const CODE2 = lang === "en" ? "Code" : "伝票コード";

export const PERCENT = lang === "en" ? "Percent" : "パーセント";
export const PAYMENT_METHOD = lang === "en" ? "Payment method" : "支払方法";
export const BASIC_SALARY = lang === "en" ? "Basic salary" : "給与";
export const RESERVE = lang === "en" ? "Reserve" : "レジ金";
export const TARGET = lang === "en" ? "Target" : "売上目標";
export const SHOW_TARGET = lang === "en" ? "Show Target" : "売上目標を表示";
export const SALES_INFORMATION = lang === "en" ? "Sales Information" : "売上情報";
export const SALES_ANALYSIS = lang === "en" ? "Sales Analysis" : "売上分析";
export const WORKING_STAFF = lang === "en" ? "Working Staff" : "出勤スタッフ";
export const SALES_TARGET = lang === "en" ? "Sales target" : "売上目標";
export const COMPLETE_PERCENT = lang === "en" ? "Complete percent" : "達成率";
export const NO_TABLE_USED = lang === "en" ? "Number of table used" : "卓数";
export const OTHER_MONEY = lang === "en" ? "Other money" : "その他";
export const DAILY_OTHER_MONEY = lang === "en" ? "Other money" : "価格調整";
export const ARE_YOU_SURE_DELETE_THIS_OTHER_MONEY =
  lang === "en"
    ? "Are you sure delete this other money?"
    : "この他のお金を削除してもよろしいですか？";
export const TAX = lang === "en" ? "TAX" : "TAX";
export const CONSUMPTION_TAX = lang === "en" ? "VAT" : "消費税";
export const UPDATE_RECEIVABLES = lang === "en" ? "Update Receivables" : "売掛金の更新";
export const ARE_YOU_SURE_DELETE_THIS_RECEIVABLE =
  lang === "en" ? "Are you sure delete this receivable?" : "この売掛金を削除してもよろしいですか？";
export const NEW_RECEIVABLE = lang === "en" ? "New Receivable" : "新規売掛金";
export const DATE = lang === "en" ? "Date" : "日にち";
export const DATE_MONTH = lang === "en" ? "Date month" : "日付";

/**Trang */
export const AMOUNT_OF_MONEY = lang === "en" ? "Amount of money" : "金額";
export const DEPOSIT_AMOUNT = lang === "en" ? "Deposit amount" : "入金額";
export const DRINK_PACK = lang === "en" ? "Drink pack " : "ドリンクパック";
export const INTERVIEW_AND_EXPERIENCE =
  lang === "en" ? "Interview And Experience" : "インタビューと経験";
export const PENALTY = lang === "en" ? "Penalty" : "ペナルティ";
export const RESIDUAL_DEDUCTION = lang === "en" ? "Residual Decision" : "残余決定";
export const AUTHORITY = lang === "en" ? "Authority" : "権限";
export const PHONE_NUMBER = lang === "en" ? "Phone number" : "電話番号 ";
export const EMAIL_ADDRESS = lang === "en" ? "Email address" : "メールアドレス";
export const ACTIVE = lang === "en" ? "Active" : "アクティブ";
export const STARTES = lang === "en" ? "Startes" : "スターテス";
export const INVITED = lang === "en" ? "Invited" : "招待";
export const LOGIN_PERMISSION = lang === "en" ? "Login permission" : "ログイン権限";
export const SET_NAME = lang === "en" ? "Set name" : "セット名前";
export const DRINK_MENU = lang === "en" ? "Drink menu" : "DRINK MENU";
export const PLAN = lang === "en" ? "Plan" : "プラン";
export const DELETE_SET_MENU = lang === "en" ? "Delete SET MENU" : "SET MENUを削除します";
export const AUTHENTICATION_KEY = lang === "en" ? "Authentication key" : "認証キー";
export const PLEASE_ENTER_YOUR_EMAIL =
  lang === "en" ? "Please enter your email" : "メールを入力してください";
export const MY_PAGE = lang === "en" ? "My Page" : "マイページ";
export const INITIAL_FEE = lang === "en" ? "Initial Fee" : "初回料";
export const FREE_TIME_FONT = lang === "en" ? "Free Time" : "Freetime";
export const NOMINATION_NUMBER = lang === "en" ? "Nomination Number" : "指名数";
export const MEMBER_TABLE = lang === "en" ? "Member Table" : "メンバーテーブル";
export const REGULAR_TABLE = lang === "en" ? "Regular Table" : "通常テーブル";
export const VIP_TABLE = lang === "en" ? "VIP Table" : "VIPテーブル";

/** end */

/** LOGIN */
export const FORGOT_THE_PASSWORD = lang === "en" ? "Forgot The Password" : "パスワードを忘れた方";

/** END */

export const SELECT_CUSTOMER = lang === "en" ? "Select Customer" : "顧客を選択";
export const AMOUNT_PLACEHOLDER =
  lang === "en" ? "Enter number only" : "番号のみを入力してください";
export const PLUGIN_TITLE_CHART_MONTH = lang === "en" ? "Sales of this month" : "今月の売り上げ";
export const PLUGIN_TITLE_CHART_YEAR = lang === "en" ? "Sales of this year" : "今年の売り上げ";
export const TOTAL = lang === "en" ? "Total" : "合計";
export const TOTAL_RECEIVABLES = lang === "en" ? "Total Receivables" : "総売掛金";
export const TOTAL_OTHER_MONEY = lang === "en" ? "Total other money" : "その他の合計金額";
export const TIMES = lang === "en" ? " Times" : " 回数";
export const NEW_OTHER_MONEY = lang === "en" ? "New Other Money" : "新しいその他のお金";
export const UPDATE_OTHER_MONEY = lang === "en" ? "Update Other Money" : "その他のお金を更新する";
export const CONTENT = lang === "en" ? "Content" : "コンテンツ";
export const SETTING_DAILY_REPORT_TITLE =
  lang === "en" ? "Daily report (When closed)" : "日報（閉店時）";
export const SETTING_SALES_TITLE =
  lang === "en" ? "Sales (Notification by time)" : "売上（時間による通知）";
export const SETTING_INVENTORY_CHECK = lang === "en" ? "Inventory check" : "在庫チェック";
export const SETTING_NOTIFICATION_PRODUCT =
  lang === "en"
    ? "Notification when has product over limit sold"
    : "価格制限を超えた商品が販売された場合の通知";
export const SERVICE_NAME = lang === "en" ? "Service name" : "サービス名";
export const percent = lang === "en" ? "Percent" : "パーセント";
export const TAX_SETTINGS = lang === "en" ? "TAX Settings" : "税設定";
export const BILL_TIME = lang === "en" ? "Bill time" : "会計時間";
export const PAYMENT_TIME = lang === "en" ? "Payment time" : "支払い時間";
export const NOMINATION_FEES = lang === "en" ? "Nomination Fees" : "本指名料金";
export const SUB_NOMINATION_FEES = lang === "en" ? "Sub Nomination Fees" : "場内指名料金";
export const COMPANION_FEES = lang === "en" ? "Companion Fees" : "同伴料金";
export const HELP_NOMINATION_FEE = lang === "en" ? "Seat Fees" : "テーブルチャージ";
export const REFERRAL_APPOINTEE = lang === "en" ? "Referral Appointee" : "紹介指名";
export const SUPPORT_APPOINTEE = lang === "en" ? "Support Appointee" : "ヘルプ指名";
export const BRANCH_APPOINTEE = lang === "en" ? "Branch Appointee" : "枝指名";
export const COMPANION = lang === "en" ? "Companion" : "同伴";
export const SHOP_NAME = lang === "en" ? "Shop Name" : "店舗名";
export const SEAT_FEES = lang === "en" ? "Seat Fees" : "テーブルチャージ";
export const CARD_FEES = lang === "en" ? "Card Fees" : "カード手数料";
export const SET_MENU = lang === "en" ? "Set menu" : "SET MENU";
export const DEPOSIT = lang === "en" ? "deposit" : "入 金";
export const INVENTORY_NOTIFY = lang === "en" ? "Inventory to notify" : "在庫を通知";
export const UPLOAD = lang === "en" ? "Upload" : "UPLOAD";
export const SUB_CATEGORY = lang === "en" ? "Sub category of" : "のサブカテゴリ";
export const COMMISSION_PERCENT = lang === "en" ? "Commission Percent" : "手数料率";
export const FREE_TIME = lang === "en" ? "FREE TIME" : "FREE TIME";
export const CREATE_SET_MENU = lang === "en" ? "Create Set Menu" : "SET MENU作成";
export const UPDATE_SET_MENU = lang === "en" ? "Update Set Menu" : "SET MENU更新";
export const CUSTOMER_TYPE = lang === "en" ? "Customer Type" : "顧客タイプ";
export const DELETE_CUSTOMER_TYPE_MESSAGE =
  lang === "en"
    ? "Are you sure you want to delete this customer type ?"
    : "この顧客タイプを削除してもよろしいですか？";
export const INITIAL_PRICE = lang === "en" ? "Initial Price" : "初回料金";
export const FREE_NOMINATION = lang === "en" ? "Free Nomination" : "無料指名数";
export const EXTENSION_PRICE = lang === "en" ? "Extension Price" : "延長料金";
export const MENU = lang === "en" ? "Menu" : "MENU";
export const OPTIONS = lang === "en" ? "Options" : "オプション";
export const CREATE_SET_MENU_ITEM = lang === "en" ? "Create set menu item" : "商品を追加する";
export const QUANTITY = lang === "en" ? "Quantity" : "量";
export const PRODUCT_TYPE = lang === "en" ? "Product Type" : "商品名";
export const TAKE_CHARGE = lang === "en" ? "Take Charge" : "有料";
export const REMOVE = lang === "en" ? "Remove" : "削除する";
export const CANCEL = lang === "en" ? "Cancel" : "キャンセル";
export const DELETE_SET_MENU_MESSAGE =
  lang === "en"
    ? "Are you sure you want to remove this set ?"
    : "このSET MENUを削除してもよろしいですか？";
export const PLEASE_WAIT = lang === "en" ? "Please wait..." : "お待ちください...";
export const SELECT_APPOINTEE = lang === "en" ? "Select Appointee" : "指名を選択";
export const MAIN_APPOINTEE = lang === "en" ? "Main Appointee" : "本指名";
export const SUB_APPOINTEE = lang === "en" ? "Sub Appointee" : "場内指名";
export const COMPANION_APPOINTEE = lang === "en" ? "Companion Appointee" : "同伴指名";
export const UPDATED = lang === "en" ? "Updated" : "更新しました";
export const NO_APPOINTEE = lang === "en" ? "No Appointee" : "指名なし";
export const DELETE_CURRENT_SET_SELECT_OTHER_SET =
  lang === "en"
    ? "Please delete current SET before select other SET"
    : "他のSETを選択する前に、現在のSETを削除してください";
export const SELECTED_PRODUCT = lang === "en" ? "Selected Product" : "商品選択";
export const NO_SET_MENU_SELECTED =
  lang === "en" ? "No SET MENU selected" : "SET MENUが選択されていません";
export const TAX_INCLUDED = lang === "en" ? "TAX Included" : "TAX込み";
export const CONSUMPTION_TAX_INCLUDED = lang === "en" ? "VAT Included" : "消費税が含まれています";
export const TAX_AND_CONSUMPTION_TAX_INCLUDED =
  lang === "en" ? "TAX & VAT Included" : "TAXと消費税が含まれています";
export const ARE_YOU_SURE_WANT_TO_REMOVE =
  lang === "en" ? "Are you sure want to remove ?" : "削除してもよろしいですか？";
export const SOLD_OUT = lang === "en" ? "SOLD OUT" : "完売";
export const ADD = lang === "en" ? "Add" : "追加";
export const SEARCH_RESULT = lang === "en" ? "Search result" : "検索結果";
export const UPDATE = lang === "en" ? "Update" : "更新";
export const EMPTY_BILL = lang === "en" ? "Empty Bill" : "伝票はありません";
export const REMOVE_BILL = lang === "en" ? "Remove Bill" : "伝票を削除";
export const INVOICE = lang === "en" ? "Invoice" : "請求書";
export const PRINT_INVOICE = lang === "en" ? "Print Invoice" : "請求書を印刷する";
export const BILL_TOTAL = lang === "en" ? "Bill Total" : "請求金額";
export const PRICE_ADJUSTMENT = lang === "en" ? "Price Adjustment" : "価格調整";
export const MONEY_RECEIVED = lang === "en" ? "Money Received" : "支払金額";
export const CHANGE = lang === "en" ? "Change" : "釣銭";
export const CARD_FEE = lang === "en" ? "Card Fee" : "カード手数料";
export const NOTE = lang === "en" ? "Note" : "注意";
export const ADD_NOTE = lang === "en" ? "Add Note" : "メモを追加";
export const PAY = lang === "en" ? "Pay" : "支払う";
export const TOTAL_PAYMENT_CANNOT_BE_0 =
  lang === "en"
    ? "Total payment cannot be 0, please add Price Adjustment"
    : "合計支払い額を0にすることはできません。価格調整を追加してください";
export const HISTORY = lang === "en" ? "History" : "履歴";
export const OPEN = lang === "en" ? "Open" : "OPEN";
export const REOPEN = lang === "en" ? "Reopen" : "REOPEN";
export const CLOSE = lang === "en" ? "Close" : "CLOSE";
export const CLOSE2 = lang === "en" ? "Close" : "閉じる";
export const PRICE_LIMIT = lang === "en" ? "Price limit" : "価格制限";
export const REPORT_MONTH_DETAIL = lang === "en" ? "Report Month Detail" : "レポート月の詳細";
export const NOTIFICATION = lang === "en" ? "Notification" : "通知";
export const OPEN_SHOP = lang === "en" ? "Your shop is opened" : "店舗がOPENしました";
export const CLOSE_SHOP = lang === "en" ? "Your shop is closed" : "店舗がCLOSEしました";
export const NEW = lang === "en" ? "NEW" : "作成";
export const NO_FREE_NOMINATION = lang === "en" ? "No free appointee" : "無料の指名者はいない";
export const TAX_BREAKDOWN = lang === "en" ? "TAX Breakdown" : "TAXの内訳";
export const VAT_BREAKDOWN = lang === "en" ? "VAT Breakdown" : "消費税の内訳";
export const OPEN_SHOP_WARNING =
  lang === "en" ? "Please open shop to continue" : "伝票作成にはオープン設定が必要です";
export const SALARY_TYPE = lang === "en" ? "Salary Type" : "給与タイプ";
export const SALARY_ = lang === "en" ? "Detail" : "詳細";
export const SALARY_DETAIL = lang === "en" ? "Salary Detail" : "給与の詳細";
export const SALARY_BONUS = lang === "en" ? "Salary Bonus" : "報酬";
export const OTHER = lang === "en" ? "Other" : "その他";
export const BONUS_BY_SALES = lang === "en" ? "Bonus by Sales" : "売上バック";
export const BONUS_BY_NOMINATIONS = lang === "en" ? "Bonus by Nominations" : "指名バック";
export const BONUS_BY_COMPANIONS = lang === "en" ? "Bonus by Companions" : "同伴バック";
export const EDIT_SALARY_SETTINGS = lang === "en" ? "Edit Salary Settings" : "給与設定の編集";
export const NEW_SALARY_SETTINGS = lang === "en" ? "New Salary Settings" : "新しい給与設定";
export const SALARY_SETTINGS_NAME = lang === "en" ? "Salary setting name" : "給与設定名";
export const TO = lang === "en" ? "To" : "に";
export const CONDITION_FROM_TO_WARNING =
  lang === "en"
    ? "Condition from must be smaller condition to"
    : "からの条件はより小さな条件でなければなりません";
export const BONUS_BY_PRODUCTS = lang === "en" ? "Bonus by Products" : "ドリンクバック";
export const ROUNDING_TYPE = lang === "en" ? "Rounding Type" : "端数タイプ";
export const ROUNDING_OPTION = lang === "en" ? "Rounding Option" : "処理方法";
export const ROUNDING_UP = lang === "en" ? "Rounding Up" : "切り上げ";
export const NO_ROUNDING = lang === "en" ? "No Rounding" : "端数計算なし";
export const ROUNDING_DOWN = lang === "en" ? "Rounding Down" : "切り捨て";
export const STAY_TIME_LONGEST = lang === "en" ? "Stay time longest" : "滞在時間（最長）";
export const STAY_TIME_FASTEST = lang === "en" ? "Stay time fastest" : "滞在時間（最速）";
export const SALES_HIGHEST = lang === "en" ? "Sales highest" : "最高売上高";
export const SALES_LOWEST = lang === "en" ? "Sales lowest" : "最低売上高";
export const RECEIVABLES_HIGHEST = lang === "en" ? "Receivables (Highest)" : "売掛金（最高）";
export const RECEIVABLES_LOWEST = lang === "en" ? "Receivables (Lowest)" : "売掛金（最低）";
export const TOTAL_PAYMENT = lang === "en" ? "Total Payment" : "支払い合計額";
export const FIXED_SALARY = lang === "en" ? "Fixed Salary" : "固定給";
export const DAILY_SALARY = lang === "en" ? "Daily Salary" : "日給";
export const HOURLY_SALARY = lang === "en" ? "Hourly Salary" : "時給";
export const TRAINING_SALARY = lang === "en" ? "Training Salary" : "研修期間";
export const SALARY_VALUE = lang === "en" ? "Salary Value" : "給与価値";
export const GO_TO_SALARY_DETAIL = lang === "en" ? "GO TO SALARY SALARY_" : "給与の詳細に移動";
export const EXPORT_FILE = lang === "en" ? "Export File" : "ファイルのエクスポート";
export const IMPORT_FILE = lang === "en" ? "Import File" : "インポートファイル";
export const EXPORT = lang === "en" ? "Export" : "エクスポート";
export const IMPORT = lang === "en" ? "Import" : "インポート";
export const EXPORT_TYPE = lang === "en" ? "Export Type" : "エクスポートタイプ";
export const FILE_NAME_PLACEHOLDER = lang === "en" ? "File name..." : "ファイル名...";
export const CHOOSE_FILE = lang === "en" ? "Choose file" : "ファイルを選ぶ";
export const OPTIONS_TYPE = lang === "en" ? "Option Type" : "オプションタイプ";
export const TIP_PERCENT = lang === "en" ? "Tip Percent" : "チップパーセント";
export const STOCK = lang === "en" ? " Stock" : " ストック";
export const UPLOAD_FILE = lang === "en" ? "Upload File" : "ファイルをアップロードする";
export const UPLOAD_REQUIRED =
  lang === "en"
    ? "File is incorrect matching. Please try another file!"
    : "ファイルが正しく一致していません。別のファイルを試してください！";
export const EXPORT_REQUIRED =
  lang === "en" ? "All fields are required" : "すべてのフィールドが必須です";
export const NOTE_CONTENT =
  lang === "en"
    ? "The file must include the following columns only"
    : "ファイルには、次の列のみを含める必要があります";

export const ALL = lang === "en" ? "All" : "全て";
export const ROUNDING = lang === "en" ? "Rounding" : "端数計算";
export const TAKE_OUT = lang === "en" ? "TAKE OUT" : "TAKE OUT";
export const TAKE_OUT_TAX = lang === "en" ? "TAKE OUT TAX" : "軽減税率";
export const THIS_BILL_HAS_BEEN_DELETED = lang === "en" ? "This bill has been deleted" : "";
export const OPEN_SHOP_MORE_THAN_24H_WARNING =
  lang === "en"
    ? "Your shop opened more than 24 hours,  Please close and reopen"
    : "オープン設定をしてから24時間以上経過しています。このまま続ける場合はクローズボタンを押してから、再度オープン設定をしてください。";

export const CLOSE_AND_REOPEN = lang === "en" ? "Close and Reopen" : "閉じて再度開く";

export const CREATE_AT = lang === "en" ? "Created At" : "作成日";
export const KINDS = lang === "en" ? "Kinds" : "種別";
export const EXPORT_DATE = lang === "en" ? "Export Date" : "対象期間";
export const NUMBER = lang === "en" ? "No." : "順番";
export const NAME_ALREADY_EXIST = lang === "en" ? "Name already exist" : "名前はすでに存在します";
export const PEOPLE = lang === "en" ? "People" : "人";
export const OPENED_24_HOURS = lang === "en" ? "OPENED 24 HOURS" : "24時間営業";
export const OPENED_DIFF = lang === "en" ? "OPENED" : "営業";
export const BILL_DETAIL = lang === "en" ? "Bill Detail" : "伝票詳細";
export const TODAY = lang === "en" ? "Today" : "今日";

export const SALARY_LIST = lang === "en" ? "Salary List" : "給与リスト";
export const WORKING = lang === "en" ? "Working" : "作業";
export const TOTAL_BONUS = lang === "en" ? "Total Bonus" : "トータルボーナス";
export const TOTAL_SALARY = lang === "en" ? "Total Salary" : "総給与";
export const BONUS_AND_SALES = lang === "en" ? "Bonus And Sales" : "売上とボーナス";
export const SALARY_ADVANCE = lang === "en" ? "Salary Advance" : "前払い";
export const SALARY_RECEIVED = lang === "en" ? "Salary Received" : "給与";
export const ABSENT_DAYS = lang === "en" ? "Absent days" : "不在日";
export const ABSENT_HOURS = lang === "en" ? "Absent hours" : "不在時間";
export const SALARY_OF = lang === "en" ? "Salary of" : "分報酬";
export const SALARY_OTHER = lang === "en" ? "Other" : "その他";
export const WORKING_HOURS = lang === "en" ? "Working hours" : "勤務時間";
export const SALES_AND_BONUS = lang === "en" ? "Sales And Bonus" : "売上とボーナス";
export const TOTAL_OTHER = lang === "en" ? "Total Other" : "その他合計";
export const NULL = lang === "en" ? "-" : "-";
export const REMOVE_CONFIRMATION =
  lang === "en" ? "Are you sure want to remove?" : "削除してもよろしいですか？";
export const MOVE_SHOP = lang === "en" ? "Move shop" : "店舗選択";
export const FILTER = lang === "en" ? "Filter" : "フィルター";
export const SHOW_APPOINTEE = lang === "en" ? "Show appointee" : "指名者を表示";
export const DECREASE = lang === "en" ? "Decrease" : "下降";
export const INCREASE = lang === "en" ? "Increase" : "増加";

// Daily report
export const SPENDING_MONEY = lang === "en" ? "Spending money" : "経費";
export const SPENDING_MONEY2 = lang === "en" ? "Spending" : "支出";
export const NEW_SPENDING = lang === "en" ? "New Spending" : "新規経費";
export const NEW_SALARY_ADVANCE = lang === "en" ? "New Salary Advance" : "新しい給与の前払い";
export const TITLE = lang === "en" ? "Title" : "題名";

export const REVERSE = lang === "en" ? "Reverse" : "レジ金";
export const DIFFERENCE = lang === "en" ? "Difference" : "キャッシュ";
export const ACTUAL_MONEY = lang === "en" ? "Actual Money" : "実際のお金";
export const CASH_FLOW = lang === "en" ? "Cash FLow" : "キャッシュフロー";

export const ARE_YOU_SURE_DELETE_THIS_SALARY_ADVANCE =
  lang === "en"
    ? "Are you sure delete this salary advance?"
    : "この給与の前払いを削除してもよろしいですか";
export const ARE_YOU_SURE_DELETE_THIS_SPENDING_MONEY =
  lang === "en"
    ? "Are you sure delete this spending money?"
    : "この支出金を削除してもよろしいですか";
export const MONEY_RECEIVED_MORE_THAN_TOTAL_BILL =
  lang === "en"
    ? "Money received must be more than total bill"
    : "受け取ったお金は総請求額より多くなければなりません";
export const WANT_TO_PRINT_KITCHEN =
  lang === "en"
    ? "Do you want to print this order to kitchen?"
    : "この注文をキッチンに印刷しますか？";
export const ADD_AND_PRINT = lang === "en" ? "Add and Print" : "追加して印刷";
export const ADD_ONLY = lang === "en" ? "Add Only" : "追加のみ";
export const SELECT_PRINT_TYPE =
  lang === "en" ? "Please select print type" : "印刷タイプを選択してください";
export const RECEIPT = lang === "en" ? "Receipt" : "領収書";
export const NORMAL_INVOICE = lang === "en" ? "Normal Invoice" : "レシート";
export const PAY_ONLY = lang === "en" ? "Pay only" : "支払うだけ";
export const FAX = lang === "en" ? "FAX" : "FAX";
export const INVOICE_TYPE = lang === "en" ? "Invoice Type" : "但し";
export const MR = lang === "en" ? "MR" : "様";
export const PRINT_SETTING = lang === "en" ? "Print Setting" : "印刷設定";
export const SALES_OF_MONTH = lang === "en" ? "Sales of " : "分の売上";

export const SALARY_GREETINGS = lang === "en" ? "Greetings" : "今月もお苦労様でした。";
export const BONUS = lang === "en" ? "Bonus" : "ボーナス";
export const BONUS_AMOUNT = lang === "en" ? "Bonus amount" : "ボーナス金額";
export const SUPPLY = lang === "en" ? "Supply" : "支給";
export const DEPENDENTS = lang === "en" ? "Dependents" : "扶養人数";
export const SALARY_TAX = lang === "en" ? "Salary Tax" : "源泉税";
export const STAFF_NAME = lang === "en" ? "Staff Name" : "従業員氏名";
export const STAFF_ID = lang === "en" ? "Staff ID" : "従業員コード";
export const S_DETAIL = lang === "en" ? "Salary Detail" : "明細書";
export const PAYMENT_DATE = lang === "en" ? "Payment Date" : "支給日";
export const ATTENDANCE = lang === "en" ? "Attendance" : "勤怠";
export const STAMP = lang === "en" ? "Signature Stamp" : "受領印";
export const DETAIL = lang === "en" ? "Detail" : "";
export const OVERTIME = lang === "en" ? "Overtime Pay" : "残業手当";
export const COMMUTING = lang === "en" ? "Commuting Fee" : "通勤費";
export const BEHIND_TIME = lang === "en" ? "Behind Time Fee" : "遅刻";
export const HAIR_MAKE_FEE = lang === "en" ? "Hair Make Fee" : "ヘアメイク代 ";
export const COSTUME_FEE = lang === "en" ? "Costume Fee" : "衣装代";
export const DEDUCTION = lang === "en" ? "Deduction" : "控除";
export const WORKING_HOUR = lang === "en" ? "Working time" : "勤務時間";
export const BOUNTY = lang === "en" ? "Bounty" : "報奨金";
export const WELFARE = lang === "en" ? "Welfare" : "厚生費";
export const TOTAL_DEDUCTION = lang === "en" ? "Total Deduction" : "控除合計";
export const TOTAL_AMOUNT = lang === "en" ? "Total Amount" : "総支給額";
export const SALARY_HOUR = lang === "en" ? "Salary Hour" : "時給";
export const SUB_TOTAL_SALES = lang === "en" ? "Total Sales" : "小計売上";
export const CLOSED_SHOP = lang === "en" ? "is closed" : "はクローズしました";
export const HAS_PRODUCT = lang === "en" ? "has" : "には";
export const PRODUCT_ITEMS = lang === "en" ? "items" : "アイテムがあります";
export const NOTIFICATION_INVENTORY_CHECK =
  lang === "en" ? "has product is out of stock" : "の商品は在庫切れです";
export const SOLD_PRICE_LIST_MESSAGE =
  lang === "en"
    ? "The product just sold exceeds the price limit you have setting"
    : "設定した金額の商品が販売されました";
export const REWARD = lang === "en" ? "Reward" : "報酬";

// Daily Report Detail
export const DETAILED_COSTS = lang === "en" ? "Detailed costs" : "経費詳細";
export const PREPAYMENT = lang === "en" ? "Prepayment" : "前払金";
export const POSITION2 = lang === "en" ? "Position" : "役職";
export const CLASSIFICATION = lang === "en" ? "Classification" : "区分";
export const ATTENDANCE_TIME = lang === "en" ? "Attendance time" : "出勤時間";
export const LEAVE_TIME = lang === "en" ? "Leave time" : "退勤時間";
export const ATTENDANCE_STAFF = lang === "en" ? "Attendance staff" : "出勤スタッフ";
export const TOTAL_INCOME = lang === "en" ? "Total income" : "売上合計";
export const MONEY_IN_THE_SAFE = lang === "en" ? "Money in the safe" : "レジ金";
export const TAKE_HOME_MONEY = lang === "en" ? "Take home money" : "持ち帰り金";
export const NUMBER_CUSTOMER2 = lang === "en" ? "Number customer" : "来客数";
export const MAIN = lang === "en" ? "Main" : "本指名数";
export const SUB = lang === "en" ? "SUB" : "本指名数";
export const COMPANION2 = lang === "en" ? "Companion" : "同伴数";
export const CARD2 = lang === "en" ? "Card" : "カード売上";
export const CASH2 = lang === "en" ? "Cash" : "現金売上";
export const OTHER2 = lang === "en" ? "Other" : "他の金額";
export const ADVANCE_SALARY = lang === "en" ? "Advance salary" : "前払金";

export const SAKE_FEE = lang === "en" ? "Sake fee" : "酒代";
export const INGREDIENTS = lang === "en" ? "Ingredients" : "食材";
export const EQUIPMENT = lang === "en" ? "Equipment" : "備品";
export const OTHER_EXPENSES = lang === "en" ? "Other expenses" : "他経費";
export const BYTE_PAYMENT = lang === "en" ? "Byte payment" : "バイト支給";
export const CONTRACTOR_PAYMENT = lang === "en" ? "Contractor payment" : "業者支払";
export const JOB_FEE = lang === "en" ? "Job fee" : "体験入店";
export const CAST_REWARD = lang === "en" ? "Cast reward" : "キャスト報酬";
export const CAST = lang === "en" ? "Cast" : "CAST";

export const CAMPAIGN = lang === "en" ? "Campaign" : "キャンペーン";
export const DAILY_PAYMENT = lang === "en" ? "Daily payment" : "日払い";
export const REIMBURSEMENT = lang === "en" ? "Reimbursement" : "立替え";
export const TOTAL_OTHER_MONEY2 = lang === "en" ? "Total other money" : "合計金額";

export const ALL_BILL = lang === "en" ? "All Bill" : "伝票リスト";
export const GROUP_BILL = lang === "en" ? "Group Bill" : "グループ伝票";
export const FILE_NAME = lang === "en" ? "file Name" : "ファイル名";
export const TOTAL_SALARY_ADVANCE = lang === "en" ? "Total Salary Advance" : "日払い合計";
export const TOTAL_SPENDING = lang === "en" ? "Total Spending" : "経費合計";
export const PRESS_VIEW_DETAIL = lang === "en" ? "Press to view detail" : "出勤スタッフを追加";
export const SELECT_STAFF = lang === "en" ? "Select Staff" : "スタッフを選択";
export const CHECK_IN = lang === "en" ? "Check in" : "出勤";
export const CHECK_OUT = lang === "en" ? "Check out" : "退勤";

// SALARY
export const SALARY_LIST_GENERAL = lang === "en" ? "General" : "一覧";
export const SALARY_TITLE = lang === "en" ? "Salary" : "給与";
export const SALARY_REWARD = lang === "en" ? "Reward" : "報酬";
// export const SALARY_WORKING_DAYS_OF_MONTH = lang === 'en' ? ''
export const TAX_EXCLUDED = lang === "en" ? "Tax excluded" : "税込";
export const OPEN_24H = lang === "en" ? "Shop open 24/24" : "24時間営業";
export const RELOAD = lang === "en" ? "Reload" : "リロード";
export const WORKING_DAYS_OF_MONTH = lang === "en" ? "Working days / Month" : "出勤日数/月";
export const SHOW_PER_PERSON = lang === "en" ? "Show Per Person" : "一人あたりの表示";
export const PER_PERSON = lang === "en" ? "Per Person" : "一人当たり";
export const OLDEST = lang === "en" ? "Created (Oldest)" : "作成済み（最新)";
export const LATEST = lang === "en" ? "Created (Latest)" : "作成済み（最も古い)";
export const FIRST_ORDER = lang === "en" ? "First order" : "最初の注文";
export const DELETED_BILL_LIST = lang === "en" ? "Deleted Bill" : "伝票を削除";
export const RESTORE = lang === "en" ? "Restore" : "戻す";
export const RESTORE_BILL_HOLDER =
  lang === "en" ? "Are you sure to restore this bill?" : "この請求書を元に戻してよろしいですか?";
export const SELECT_CUSTOMER_BY_HOST =
  lang === "en" ? "Select Customer By Host" : "ホストで顧客を選択";
export const SELECT_DATE = lang === "en" ? "Select Date" : "日付を選択";
export const NOW = lang === "en" ? "Now" : "今";
export const EMPTY_TRASH = lang === "en" ? "Empty Trash" : "空のごみ箱";
export const DELETE_ALL_BILL = lang === "en" ? "Delete All Bill" : "すべての請求書を削除する";
export const DELETE_ALL = lang === "en" ? "Delete All" : "すべて削除";
export const DELETE_ALL_BILL_MESSAGE =
  lang === "en"
    ? "All bills will be deleted permanently and can not be restored or neither be found anywhere"
    : "すべての請求書は永久に削除されます。 それらは復元できず、どこにも見つかりません";
export const PRINT_PER_PERSON = lang === "en" ? "Print bill by product" : "製品ごとの請求書の印刷";
export const WEBSITE = lang === "en" ? "Website" : "Webサイト";
export const PRINT_LINK = lang === "en" ? "Print URL" : "URL添付";
export const GREETINGS = lang === "en" ? "Greetings" : "挨拶文";
export const SALARY_TEST = lang === "en" ? "TEST" : "給料エクスポート (テスト)";
export const EXPORTING =
  lang === "en"
    ? "Exporting file, please wait a few minutes ..."
    : "ファイルをエクスポートしています。数分お待ちください...";
export const setIncludedTAXLang = (amount) => {
  return lang === "en"
    ? `Set included ¥${amount} of TAX`
    : `このセットは¥${amount}TAXが含まれています`;
};
export const setIncludedVATLang = (amount) => {
  return lang === "en"
    ? `Set included ¥${amount} of VAT`
    : `このセットは¥${amount}消費税が含まれています`;
};
export const APPOINTEE_FEES = lang === "en" ? "Appointee fees" : "指名料金";
export const BASIC_SALARY_ = lang === "en" ? "Basic Salary" : "基本給与";
export const SELECT_TIME = lang === "en" ? "Select time" : "時間を選択";
export const DRINK_BUFFET = lang === "en" ? "Drink Buffet" : "飲み放題";
// export const PAYMENT_TIME = lang === 'en' ? 'Payment time' : '支払い時間'
export const SHOW_SHOP_PROFIT_FIRST =
  lang === "en" ? "Show shop profit first" : "ショップの利益を最初に表示する";
// export const
export const SHOP_PROFIT = lang === "en" ? "Shop Profit" : "ショップの利益";
export const INCOME = lang === "en" ? "Income" : "売上";
export const CASH_REGISTER = lang === "en" ? "Cash Register" : "レジ金";
export const NUMBER_OF_SLIPS = lang === "en" ? "Number of Slips" : "伝票枚数";
export const TOTAL_ACCOUNTS_RECEIVABLE = lang === "en" ? "Total Accounts Receivable" : "売掛金合計";
export const LIST_SLIP = lang === "en" ? "Of the Slip List" : "伝票リスト";
export const INITIAL_NUMBER_PEOPLE = lang === "en" ? "Initial number of people" : "初回人数";
export const INITIAL_NUMBER_GROUPS = lang === "en" ? "Initial number of groups" : "初回組数";
export const TOTAL_SALES = lang === "en" ? "Total sales" : "総合売上";
export const ADVANCE_MONEY = lang === "en" ? "Advance money" : "立替金";
export const NUMBER_ENROLLED = lang === "en" ? "Number of enrolled" : "在籍人数";
export const NUMBER_ATTENDEES = lang === "en" ? "Number of attendees" : "出勤人数";
export const NUMBER_INTERVIEWS = lang === "en" ? "Number of interviews" : "面接数";
export const EXPERIENCE = lang === "en" ? "Experience" : "体験";
export const REPORT_MONTHLY = lang === "en" ? "Monthly report" : "月報";
export const TAXI_FEE = lang === "en" ? "Taxi fee" : "タクシー 代 ";
export const TRANSPORTATION_FEE = lang === "en" ? "Transportation expenses" : "交通費";
export const PREPAID_TOTAL = lang === "en" ? "Prepaid total" : "前払い合計";
export const SATO = lang === "en" ? "Sato" : "佐藤";
export const NIHARA = lang === "en" ? "Nihara" : "二原";
export const AKIKO = lang === "en" ? "Akiko" : "明子";
export const PAYMENT_TIME_NEW = lang === "en" ? "Payment time new" : "支払時間";
export const SHIFT = lang === "en" ? "Shift" : "シフト";
export const TOTAL_LATE = lang === "en" ? "Total late" : "遅刻総分";
export const TITLE_SPENDING = lang === "en" ? "Title spending" : "タイトル";
export const AMOUNT_MONEY = lang === "en" ? "Amount of money" : "金額";
export const DELETE_ITEM_SPENDING = lang === "en" ? "Do you want to delete it?" : "削除しますか？";
export const DISPLAY = lang === "en" ? "Display" : "表示";
export const CATEGORYID = lang === "en" ? "Category ID" : "カテゴリーID";

//DungLe///
export const METHOD_PAYMENT = lang === "en" ? "Method of Payment" : "支払い方法";
export const INDIVIDUAL_ACCOUNTING = lang === "en" ? "Individual accounting" : "個別会計";
export const SHOW_LIST_TABLE = lang === "en" ? "Show List Table" : "テーブルリスト表示";
export const KEEP = lang === "en" ? "Keep" : "保存";
export const CALCULATION_TYPE = lang === "en" ? "Calculation type" : "TAX 計算タイプ :";
export const IMAGE_UPDATE = lang === "en" ? "Image upload" : "画像アップロード";

//customer
export const MULTIPLE_PAYMENT = lang === "en" ? "Multipe payment" : "複数";
export const ATTRACTING_CUSTOMERS = lang === "en" ? "Attracting customers" : "集客";

//staff
export const STAFF_LIST_PAGE = lang === "en" ? "Staff List" : "スタッフリスト";
export const New = lang === "en" ? "New" : "新規";
export const HELP = lang === "en" ? "Help" : "ヘルプ";
export const INTRODUCTION = lang === "en" ? "Introduction" : "紹介";
export const LASSON = lang === "en" ? "Lasson" : "ラスソン";
export const SENDING = lang === "en" ? "sending" : "送り";
export const UNPAID_MONTH = lang === "en" ? "Unpaid month" : "未収月";
export const BALANCE = lang === "en" ? "balance" : "差引残";

//Setting
export const ACCOUNTING_METHOD = lang === "en" ? "Accounting method" : "会計方法";
export const SUB_TOTAL = lang === "en" ? "Sub total" : "小計";

export const CASH_HIGHEST = lang === "en" ? "Cash (Highest)" : "現金（最高）";
export const CASH_LOWEST = lang === "en" ? "Cash (Lowest)" : "現金（最低）";
export const CARD_HIGHEST = lang === "en" ? "Card (Highest)" : "カード（最高）";
export const CARD_LOWEST = lang === "en" ? "Card (Lowest)" : "カード（最低）";
export const DELETE_USER_MESSAGE =
  lang === "en"
    ? "Are you sure delete this user?"
    : "アカウントを削除しますか？削除したら、回復できないのでご注意ください。";
export const WARNING = lang === "en" ? "Warning" : "警告";
