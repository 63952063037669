import * as cts from "../constants/events/ranking";

export const fetchRanking = (params) => ({
  type: cts.FETCH_RANKING,
  payload: { params },
});

export const fetchRankingSuccess = (data) => ({
  type: cts.FETCH_RANKING_SUCCESS,
  payload: { data },
});
