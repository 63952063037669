import React from "react";
import TextInput from "../../../helpers/TextInput";
import { useState } from "react";
import { failedToast } from "../../../helpers/AlertHelper";
import { registerAPI } from "../../../apis/user";
import Loading from "../../../components/Loading";
import { Space, Input } from "antd";
const Step3 = (props) => {
  const { setSteps, email, setIsSuccess, password, setPassword, loading, setLoading } = props;
  const [state, setState] = useState({
    name: "",
    re_password: "",
  });

  const sendSuccess = async () => {
    if (password)
      if (password === state.re_password) {
        setLoading(true);
        try {
          const res = await registerAPI({
            name: state.name,
            email: email,
            password: password,
            c_password: state.re_password,
          });
          if (res.data.message == "error") {
            if (res.data.errors.email) {
              setLoading(false);
              failedToast(null, "The email has already been taken.");
            } else {
              failedToast(null, "Invalid password");
              setLoading(false);
            }
          }
          if (res.data.message == "success") {
            setIsSuccess(true);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          failedToast(error, "that bai");
        }
      } else {
        failedToast("error", "半角英字、数字、記号を組み合わせて入力してください");
        setLoading(false);
      }
  };
  return (
    <div className="bg-button_register">
      <div className="bg-content_register">
        <div className="bg-form-register mt-30">
          <span style={{ fontFamily: "NotoSans", fontSize: "16px", color: "#000000" }}>
            名前 <span style={{ color: "red" }}>*</span>{" "}
          </span>
          <input
            className="input-register mt-15"
            style={{ fontSize: "16px" }}
            value={state.name}
            onChange={(e) => setState({ ...state, name: e.target.value })}
            placeholder="名前"
          />
        </div>
        <div style={{ height: "1px", background: "#D9D9D9" }} className="mt-15"></div>
        <div className="bg-form-register mt-15">
          <span
            className="mt-10 mb-15"
            style={{ fontFamily: "NotoSans", fontSize: "16px", color: "#000000" }}
          >
            メールアドレス
          </span>
          <input placeholder={email} className="input-register" style={{}} disabled />
        </div>
        <div style={{ height: "1px", background: "#D9D9D9" }} className="mt-15"></div>
        <div
          className="bg-form-register mt-15"
          style={{ flexDirection: "column", alignItems: "normal" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              className="mt-10 mb-15"
              style={{ fontFamily: "NotoSans", fontSize: "16px", color: "#000000" }}
            >
              パスワード <span style={{ color: "red" }}>*</span>{" "}
            </span>

            {/* <input
              type="password"
              className="input-register"
              style={{ fontSize: "15px" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            /> */}
            <Space direction="vertical">
              <Input.Password
                type="password"
                name="password"
                className="input-pass"
                style={{ fontSize: "16px" }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Space>
          </div>
          <div style={{ display: "flex" }}>
            <span
              className="mt-10"
              style={{ color: "#999", fontSize: "15px", whiteSpace: "normal" }}
            >
              {
                "半角英数字・記号を組み合わせた4文字以上。用できる記号は　! # $ % & = ~ ^ | @ < > ? + * / _ ; : -"
              }
            </span>
          </div>
        </div>
        <div style={{ height: "1px", background: "#D9D9D9" }} className="mt-15"></div>
        <div className="bg-form-register mt-15">
          <span
            className="mt-10 mb-15"
            style={{ fontFamily: "NotoSans", fontSize: "16px", color: "#000000" }}
          >
            パスワード（確認) <span style={{ color: "red" }}>*</span>{" "}
          </span>
          {/* <input
            type="password"
            className="input-register"
            style={{ fontSize: "15px" }}
            value={state.re_password}
            onChange={(e) => setState({ ...state, re_password: e.target.value })}
            placeholder="パスワード"
          /> */}
          <Space direction="vertical">
            <Input.Password
              type="password"
              className="input-pass"
              style={{ fontSize: "16px" }}
              value={state.re_password}
              onChange={(e) => setState({ ...state, re_password: e.target.value })}
              placeholder="パスワード"
            />
          </Space>
        </div>

        <button type="button" className="mt-25" onClick={sendSuccess}>
          {loading ? <Loading style={{ color: "white" }} /> : " 確認"}
        </button>
      </div>
    </div>
  );
};
export default Step3;
