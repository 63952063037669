import { combineReducers } from "redux-immutable";
import general from "./general";
import user from "./user";
import settings from "./settings";
import host from "./host";
import products from "./products";
import other from "./other";
import bill from "./bill";
import profit from "./profit";
import report from "./report";
import ranking from "./ranking";
import setMenu from "./set-menu";
import userofshop from "./user-of-shop";

const rootReducer = combineReducers({
  general,
  user,
  settings,
  host,
  products,
  other,
  bill,
  profit,
  report,
  ranking,
  setMenu,
  userofshop,
});

export default rootReducer;
