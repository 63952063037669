import { fromJS } from "immutable";
import { createReducer } from "../helpers/createReducer";
import * as cts from "../constants/events/profit";
import { SET_SELECTED_SHOP } from "../constants/events/user";
import { getRandomColor } from "../helpers/functionHelpers";

const initialState = fromJS({
  profit: {
    data: [],
    shopId: null,
    total: {},
  },
  profitTimeDetail: {
    data: [],
    shopId: null,
    currentPage: 1,
    totalPage: 1,
    total: 1,
  },

  profitHostDetail: {
    data: [],
    shopId: null,
    currentPage: 1,
    totalPage: 1,
    total: 1,
  },

  profitProductDetail: {
    data: [],
    shopId: null,
    currentPage: 1,
    totalPage: 1,
    total: 1,
  },
  shopCompare: {
    data: [],
    values: [],
  },
  shopColors: [],
});

const fetchProfitSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("profit", fromJS(data));
};

const fetchProfitTimeDetailSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("profitTimeDetail", fromJS(data));
};

const fetchProfitHostDetailSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("profitHostDetail", fromJS(data));
};

const fetchProfitProductDetailSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("profitProductDetail", fromJS(data));
};

const addShopToCompareSuccess = (state, action) => {
  const { data } = action.payload;

  let shopCompare = state.getIn(["shopCompare", "data"]).toJS();

  let shopColors = state.get("shopColors").toJS();

  const colorIndex = shopColors.findIndex((i) => i.id === data.shopId);

  const shop = {};

  if (colorIndex !== -1) {
    shop.color = () => shopColors[colorIndex].color;
    shop.pureColor = shopColors[colorIndex].color;
  } else {
    const color = getRandomColor();
    shop.color = () => color;
    shop.pureColor = color;
    shopColors = [...shopColors, { id: data.shopId, color: getRandomColor() }];
  }

  shop.data = data.data.map((i) => parseInt(i.sales, 10));
  shop.label = data.data.map(
    (i) => (i.hour === 0 ? `${i.hour}` : i.hour) || i.day || i.month || i.year
  );
  shop.id = data.shopId;
  shop.total = data.total;

  const index = shopCompare.findIndex((i) => i.id === data.shopId);

  if (index !== -1) {
    shopCompare[index] = shop;
  } else {
    shopCompare = [...shopCompare, shop];
  }

  const values = data.data.map((i) => i.day || i.month || i.year);

  return state
    .set("shopCompare", fromJS({ data: shopCompare, values }))
    .set("shopColors", fromJS(shopColors));
};

const resetShopCompare = (state) => {
  return state.set("shopCompare", fromJS({ data: [], values: [] }));
};

const removeShopCompare = (state, action) => {
  let shopCompare = state.getIn(["shopCompare", "data"]).toJS();
  shopCompare = shopCompare.filter((i) => i.id !== action.payload.id);
  return state.setIn(["shopCompare", "data"], fromJS(shopCompare));
};

const reducer = {
  [SET_SELECTED_SHOP]: () => initialState,

  [cts.FETCH_PROFIT_SUCCESS]: fetchProfitSuccess,

  [cts.FETCH_PROFIT_TIME_SUCCESS]: fetchProfitTimeDetailSuccess,

  [cts.FETCH_PROFIT_HOST_SUCCESS]: fetchProfitHostDetailSuccess,

  [cts.FETCH_PROFIT_PRODUCT_SUCCESS]: fetchProfitProductDetailSuccess,

  [cts.ADD_SHOP_TO_COMPARE_SUCCESS]: addShopToCompareSuccess,
  [cts.RESET_SHOP_COMPARE]: resetShopCompare,
  [cts.REMOVE_SHOP_COMPARE]: removeShopCompare,

  __default__: (state) => state,
};

export default createReducer(reducer, initialState);
