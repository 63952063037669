export const GET_TOKEN = "GET_TOKEN";

export const LOGIN_FLOW = "LOGIN_FLOW";
export const LOGIN_FLOW_SUCCESS = "LOGIN_FLOW_SUCCESS";

export const LOGOUT_FLOW = "LOGOUT_FLOW";
export const LOGOUT_FLOW_SUCCESS = "LOGOUT_FLOW_SUCCESS";

export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";

export const FETCH_SHOP_LIST = "FETCH_SHOP_LIST";
export const FETCH_SHOP_LIST_SUCCESS = "FETCH_SHOP_LIST_SUCCESS";

export const SET_SELECTED_SHOP = "SET_SELECTED_SHOP";

export const FETCH_OPEN_SETTINGS = "FETCH_OPEN_SETTINGS";
export const FETCH_OPEN_SETTINGS_SUCCESS = "FETCH_OPEN_SETTINGS_SUCCESS";
export const FETCH_OPEN_SETTINGS_ALL_SUCCESS = "FETCH_OPEN_SETTINGS_ALL_SUCCESS";
export const SET_OPEN_SETTINGS = "SET_OPEN_SETTINGS";
export const SET_OPEN_SETTINGS_SUCCESS = "SET_OPEN_SETTINGS_SUCCESS";

export const SET_PRINT_SETTINGS = "SET_PRINT_SETTINGS";
export const GET_PRINT_SETTINGS = "GET_PRINT_SETTINGS";

export const FETCH_USER_SETTINGS = "FETCH_USER_SETTINGS";
export const FETCH_USER_SETTINGS_SUCCESS = "FETCH_USER_SETTINGS_SUCCESS";

export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS";

// MY PAGE
export const UPDATE_USER_INFO = "UPDATE_USER_INFO ";
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
// CHANGE_PASSWORD
export const URL_CHANGE_PASSWORD_STAFF = "URL_CHANGE_PASSWORD_STAFF ";
export const URL_CHANGE_PASSWORD_STAFF_SUCCESS = "URL_CHANGE_PASSWORD_STAFF_SUCCESS";
