/* eslint-disable import/no-cycle */
import { Button } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumb } from "../../actions/general";
import { fetchProfitShopAPI } from "../../apis/profit";
import DatePicker from "../../components/DatePicker";
import * as errorCts from "../../constants/ui/error";
import * as cts from "../../constants/ui/general";
import { failedToast } from "../../helpers/AlertHelper";
// eslint-disable-next-line import/no-cycle
import { nextDate, prevDate } from "../../helpers/functionHelpers";
import * as slt from "../../selectors";
import ShopProfitItem from "./components/ShopProfitItem";

const dateFormat = ["YYYY/MM/DD", "YYYY/MM", "YYYY"];
const datePicker = ["date", "month", "year"];

const tabs = [
  { value: "date", label: cts.DAY },
  { value: "month", label: cts.MONTH },
  { value: "year", label: cts.YEAR },
];

const ShopProfitPage = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => slt.user.tokenSelector(state));

  const [activeTab, setActiveTab] = useState(0);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [shopProfit, setShopProfit] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const fetchProfit = useCallback(async () => {
    try {
      const res = await fetchProfitShopAPI(token, {
        type: tabs[activeTab].value,
        date: moment(selectedDate).format("YYYY-MM-DD"),
      });
      setShopProfit(res.data.length ? res.data : []);
    } catch (error) {
      failedToast(error, errorCts.FETCH_PROFIT_FAILED);
    }
  }, [activeTab, selectedDate]);

  useEffect(() => {
    dispatch(setBreadcrumb({ id: "bill-list", name: cts.SHOP_PROFIT }));
  }, [dispatch]);

  useEffect(() => {
    fetchProfit();
  }, [fetchProfit]);

  const _renderTabs = () => {
    return tabs.map((tab, index) => (
      <Button
        key={index.toString()}
        className={activeTab === index ? "active" : undefined}
        onClick={() => {
          // const cloneStepArr = [...stepHistory];
          // const currentIndex = cloneStepArr.findIndex((i) => {
          //   if (
          //     i.tab === activeTab &&
          //     i.dateType === datePicker[activeTimeTab] &&
          //     moment(i.date).format("YYYY-MM-DD") === moment(selectedDate).format("YYYY-MM-DD")
          //   ) {
          //     return true;
          //   }
          //   return false;
          // });
          // if (currentIndex !== -1) {
          //   cloneStepArr.slice(currentIndex);
          // }
          setActiveTab(index);
          // const last = stepHistory[stepHistory.length - 1];
          // setStepHistory([
          //   ...cloneStepArr,
          //   { id: new Date().getTime(), tab: index, date: last?.date, dateType: last?.dateType },
          // ]);
        }}
      >
        {tab.label}
      </Button>
    ));
  };

  const onPrevDate = () => {
    setSelectedDate(prevDate(selectedDate, activeTab));
  };

  const onNextDate = () => {
    setSelectedDate(nextDate(selectedDate, activeTab));
  };

  return (
    <div className="profit-wrapper">
      <div className="profit-header">
        <div className="profit-header__left">
          <Button className="btnPrev" icon={<AiOutlineLeft />} onClick={onPrevDate} />
          <DatePicker
            setOpenDatePicker={setOpenDatePicker}
            openDatePicker={openDatePicker}
            datePicker={datePicker[activeTab]}
            dateFormat={dateFormat[activeTab]}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
          />
          <Button className="btnNext" icon={<AiOutlineRight />} onClick={onNextDate} />
        </div>

        <div className="profit-header__right">{_renderTabs()}</div>
      </div>
      <div className="profit-content">
        <div className="shop-profit-content">
          {shopProfit.map((item) => {
            return (
              <ShopProfitItem
                itemProfit={item}
                activeType={tabs[activeTab].value}
                selectedDate={selectedDate}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ShopProfitPage;
