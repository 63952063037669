/* eslint-disable no-restricted-syntax */
import { fromJS } from "immutable";
import { createReducer } from "../helpers/createReducer";
import * as cts from "../constants/events/set-menu";
import { SET_SELECTED_SHOP } from "../constants/events/user";

const initialState = fromJS({
  customerTypes: {
    shopId: null,
    data: [],
  },
  isActionDone: false,
  setMenu: {
    shopId: null,
    data: [],
  },
  isCustomerDone: false,
});

// const fetchCustomerTypeSuccess = (state, action) => {
//   const { data } = action.payload;
//   return state.set("customerTypes", fromJS(data));
// };

// const createCustomerTypeSuccess = (state, action) => {
//   const { data } = action.payload;
//   const customerTypes = state.getIn(["customerTypes", "data"]).toJS();
//   const newTypes = [data].concat(customerTypes);

//   return state.setIn(["customerTypes", "data"], fromJS(newTypes)).set("isCustomerDone", true);
// };

// const updateCustomerTypeSuccess = (state, action) => {
//   const { data } = action.payload;
//   const customerTypes = state.getIn(["customerTypes", "data"]).toJS();

//   const index = customerTypes.findIndex((i) => i.id === data.id);
//   customerTypes[index] = { ...customerTypes[index], ...data };

//   return state.setIn(["customerTypes", "data"], fromJS(customerTypes)).set("isCustomerDone", true);
// };

// const deleteCustomerTypeSuccess = (state, action) => {
//   const { data } = action.payload;
//   const customerTypes = state.getIn(["customerTypes", "data"]).toJS();

//   const newTypes = customerTypes.filter((i) => i.id !== data.id);

//   return state.setIn(["customerTypes", "data"], fromJS(newTypes));
// };

// SET MENU

const fetchSetMenuSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("setMenu", fromJS(data));
};

const createSetMenuSuccess = (state, action) => {
  const { data } = action.payload;
  const setMenu = state.getIn(["setMenu", "data"]).toJS();
  const newSet = [data].concat(setMenu);

  return state.setIn(["setMenu", "data"], fromJS(newSet)).set("isActionDone", true);
};

const deleteSetMenuSuccess = (state, action) => {
  const { data } = action.payload;
  const setMenu = state.getIn(["setMenu", "data"]).toJS();

  const newSet = setMenu.filter((i) => i.id !== data.id);

  return state.setIn(["setMenu", "data"], fromJS(newSet)).set("isActionDone", true);
};

// const updateSetMenuSuccess = (state, action) => {
//   const { data } = action.payload;
//   let setMenu = state.getIn(["setMenu", "data"]).toJS();

//   if (data.is_orderby) {
//     for (const set of data.is_orderby) {
//       const index = setMenu.findIndex((i) => i.id === set.id);
//       if (index !== -1) {
//         setMenu[index].orderby = set.orderby;
//       }
//     }

//     setMenu = setMenu.sort((a, b) => a.orderby - b.orderby);
//   } else {
//     const index = setMenu.findIndex((i) => i.id === data.id);
//     if (index !== -1) {
//       setMenu[index] = data;
//     }
//   }

//   return state.setIn(["setMenu", "data"], fromJS(setMenu)).set("isActionDone", true);
// };

// reducer
const reducer = {
  [SET_SELECTED_SHOP]: () => initialState,
  // [cts.FETCH_CUSTOMER_TYPE_SUCCESS]: fetchCustomerTypeSuccess,
  // [cts.CREATE_CUSTOMER_TYPE]: (state) => state.set("isCustomerDone", false),
  // [cts.CREATE_CUSTOMER_TYPE_SUCCESS]: createCustomerTypeSuccess,
  // [cts.UPDATE_CUSTOMER_TYPE]: (state) => state.set("isCustomerDone", false),
  // [cts.UPDATE_CUSTOMER_TYPE_SUCCESS]: updateCustomerTypeSuccess,
  // [cts.DELETE_CUSTOMER_TYPE_SUCCESS]: deleteCustomerTypeSuccess,

  [cts.FETCH_SET_MENU_SUCCESS]: fetchSetMenuSuccess,
  [cts.CREATE_SET_MENU]: (state) => state.set("isActionDone", false),
  [cts.CREATE_SET_MENU_SUCCESS]: createSetMenuSuccess,
  [cts.DELETE_SET_MENU]: (state) => state.set("isActionDone", false),
  [cts.DELETE_SET_MENU_SUCCESS]: deleteSetMenuSuccess,
  // [cts.UPDATE_SET_MENU]: (state) => state.set("isActionDone", false),
  // [cts.UPDATE_SET_MENU_SUCCESS]: updateSetMenuSuccess,
  __default__: (state) => state,
};

export default createReducer(reducer, initialState);
