import request from "../helpers/axiosService";
const URL_SHOP = "/shop";

export function createShopAPI(token, data) {
  return request({
    url: URL_SHOP,
    method: "post",
    token,
    data,
    isFormData: true,
  });
}
