export const sendPushNotification = async (tokens, title, body, data) => {
  if (tokens.length === 0) return;

  const FIREBASE_API_KEY =
    "AAAAiXJMVJc:APA91bH_lvlT04ExCbcThy8nKvTAb2nuWK0L8M3vXv8ww9U0rvKj8QuZcgStYUR7ZnYUjjj0PUrQU5S9Vmiq_WgVLYXvAhdms7wXcGH5TPLHhv454357UivwHqjPjTceZNQak0ZDbJXw";
  const message = {
    registration_ids: tokens,
    notification: {
      title,
      body,
      vibrate: 1,
      sound: 1,
      show_in_foreground: true,
      priority: "high",
      content_available: true,
    },
    data,
  };

  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: `key=${FIREBASE_API_KEY}`,
  });

  await fetch("https://fcm.googleapis.com/fcm/send", {
    method: "POST",
    headers,
    body: JSON.stringify(message),
  });
};
