import memoize from "fast-memoize";

const tableList = (state) => state.getIn(["settings", "tableList"]).toJS();
const taxSettings = (state) => state.getIn(["settings", "taxSettings"]).toJS();
const notificationSettings = (state) => state.getIn(["settings", "notificationSettings"]).toJS();
const shopSettings = (state) => state.getIn(["settings", "shopSettings"]).toJS();

export const tableListSelector = memoize(tableList);
export const taxSettingsSelector = memoize(taxSettings);
export const notificationSettingsSelector = memoize(notificationSettings);
export const shopSettingsSelector = memoize(shopSettings);
