/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as act from "../actions";
import * as socket from "../sockets";

const SocketComponent = () => {
  const dispatch = useDispatch();

  // bill
  useEffect(() => {
    socket.serverSendBill((data) => {
      dispatch(act.bill.createBillSuccess(data));
    });

    socket.serverSendBillDeleted((data) => {
      dispatch(act.bill.deleteBillSuccess(data));
    });

    socket.serverSendBillUpdated((data) => {
      dispatch(act.bill.updateBillSuccess(data));
    });

    socket.serverSendAppointeeAfterAddToBill((data) => {
      dispatch(act.bill.updateBillSuccess(data));
    });

    socket.serverSendBillAfterPayment((data) => {
      dispatch(act.bill.paymentBillSuccess(data));
    });

    socket.serverSendSetInBill((data) => {
      dispatch(act.bill.addSetInBillSuccess(data, "socket-global"));
    });

    socket.serverSendSetInBillAfterUpdate((data) => {
      dispatch(act.bill.updateSetInBillSuccess(data));
    });

    socket.serverSendProductInBillAfterDelete((data) => {
      dispatch(act.bill.deleteProductInBillSuccess(data));
    });
  }, []);

  // settings
  useEffect(() => {
    socket.serverSendShopSettingsUpdated((data) => {
      dispatch(act.settings.updateShopSettingsSuccess(data));
    });

    socket.serverSendOpenSettings((data) => {
      dispatch(act.user.setOpenSettingsSuccess(data));
    });
  }, []);

  // tables
  useEffect(() => {
    socket.serverSendTableCreated((data) => {
      dispatch(act.settings.createNewTableSuccess(data));
    });

    socket.serverSendTableUpdated((data) => {
      dispatch(act.settings.updateTableSuccess(data));
    });

    socket.serverSendTableDeleted((data) => {
      dispatch(act.settings.deleteTableSuccess(data));
    });
  }, []);

  // category
  useEffect(() => {
    socket.serverSendCategoryCreated((data) => {
      dispatch(act.products.createNewCategorySuccess(data));
    });

    socket.serverSendCategoryUpdated((data) => {
      dispatch(act.products.updateCategorySuccess(data));
    });

    socket.serverSendCategoryUpdatedOrder((data) => {
      dispatch(act.products.updateOrderCategorySuccess(data));
    });

    socket.serverSendCategoryDeleted((data) => {
      dispatch(act.products.deleteCategorySuccess(data));
    });
  }, []);

  // set menu
  useEffect(() => {
    socket.serverSendSetMenuCreated((data) => {
      dispatch(act.setmenu.createSetMenuSuccess(data));
    });

    // socket.serverSendSetMenuUpdated((data) => {
    //   dispatch(act.setmenu.updateSetMenuSuccess(data));
    // });

    socket.serverSendSetMenuDeleted((data) => {
      dispatch(act.setmenu.deleteSetMenuSuccess(data));
    });
  }, []);

  return <div />;
};

export default memo(SocketComponent);
