/* eslint-disable prefer-spread */
/* eslint-disable no-restricted-syntax */
import { message } from "antd";
import moment from "moment";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/bill";
import { hideFetchLoading, hideLoading, showFetchLoading, showLoading } from "../actions/general";
import * as apis from "../apis/bill";
import { sendPushNotification } from "../apis/notification";
import * as constants from "../constants/events/bill";
import * as ctsErr from "../constants/ui/error";
import * as ctsGeneral from "../constants/ui/general";
import * as ctsSuccess from "../constants/ui/success";
import { failedToast, successToast } from "../helpers/AlertHelper";
import { getAllToken } from "../helpers/functionHelpers";
import * as socket from "../sockets";

function* fetchBillListSaga({ payload: { shopId, status, page, date } }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading("bill-list"));
  try {
    const res = yield call(apis.fetchBillListAPI, token, shopId, status, page, date);

    yield put(
      actions.fetchBillListSuccess({
        data: res.data.data,
        shopId,
        status: status === 3 ? "unpaid" : "paid",
        totalItem: res.data.data.total,
        per_page: res.data.data.per_page,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsErr.FETCH_BILL_LIST_FAILED);
  }
}

function* searchBillSaga({ payload: { shopId, status, page, code } }) {
  const token = yield select((state) => state.getIn(["user", "token"]));

  yield put(showFetchLoading("bill-list"));

  try {
    const res = yield call(apis.searchBillAPI, token, shopId, status, page, code);
    yield put(
      actions.fetchBillListSuccess({
        data: res.data,
        shopId,
        status: status === 3 ? "unpaid" : "paid",
        totalItem: res.data.total,
        per_page: res.data.per_page,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsErr.FETCH_BILL_LIST_FAILED);
  }
}

function* deleteBillSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const key = "delete-bill";
  message.loading({ content: ctsGeneral.PLEASE_WAIT, key });

  try {
    yield call(apis.deleteBillAPI, token, payload.id, payload.shopId);

    yield put(actions.deleteBillSuccess(payload));
    yield put(actions.refetchSidebarReport(true));
    socket.clientDeleteBill(payload);
    message.success({ content: ctsSuccess.DELETE_BILL_SUCCESS, key });
  } catch (error) {
    if (error.message !== "Network Error") {
      message.error({ content: ctsErr.DELETE_BILL_FAILED, key });
    } else {
      message.error({ content: ctsErr.NETWORK_DISCONNECTED, key });
    }
  }
}

function* createBillSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const key = "create-bill";
  message.loading({ content: ctsGeneral.PLEASE_WAIT, key });
  try {
    const res = yield call(apis.createBillAPI, token, payload.params);
    yield put(actions.createBillSuccess(res.data.data));
    socket.clientCreateBill(res.data.data);

    if (payload.params.isSetDate) {
      yield put(actions.setNewBillInfo(res.data.data));
      payload.history.push(`/bills/new-bill?id=${res.data.data.id}&is_paid=0`);
      yield put(actions.setBillStep(2));
    } else {
      payload.history.push("/bills");
    }

    message.success({ content: ctsSuccess.CREATE_BILL_SUCCESS, key });
  } catch (error) {
    if (error.message !== "Network Error") {
      message.error({ content: ctsErr.CREATE_BILL_FAILED, key });
    } else {
      message.error({ content: ctsErr.NETWORK_DISCONNECTED, key });
    }
  }
}

function* updateBillSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const selectedShopId = yield select((state) => state.getIn(["user", "selectedShop", "id"]));
  // const key = "update-bill";
  // message.loading({ content: ctsGeneral.PLEASE_WAIT, key });
  try {
    const res = yield call(apis.updateBillAPI, token, payload.id, payload.params);
    // message.success({ content: ctsSuccess.UPDATE_BILL_MESSAGE, key });

    yield put(actions.updateBillSuccess(res.data.data, payload.isRestoreBill));
    yield put(actions.refetchSidebarReport(true));
    socket.clientUpdateBill({
      ...res.data.data,
      shop_id: selectedShopId,
    });
  } catch (error) {
    console.log(error);
    if (error.message !== "Network Error") {
      message.error(ctsErr.UPDATE_BILL_FAILED);
    } else {
      message.error(ctsErr.NETWORK_DISCONNECTED);
    }
  }
}

function* fetchProductInBillSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(actions.fetchBillDetailLoading());

  try {
    const res = yield call(apis.fetchProductInBillAPI, token, payload.id);
    yield put(actions.hideFetchBillDetailLoading());
    yield put(actions.fetchProductInBillSuccess(res.data));
  } catch (error) {
    yield put(actions.hideFetchBillDetailLoading());
    failedToast(error, ctsErr.FETCH_PRODUCT_IN_BILL_FAILED);
  }
}

function* addAppointeeToBillSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading("add-appointee-in-bill"));

  try {
    const res = yield call(apis.updateBillAPI, token, payload.id, payload.params);
    yield put(hideLoading());
    yield put(actions.updateBillSuccess(res.data.data));
    yield put(actions.setNewBillInfo(res.data.data));
    socket.clientAddAppointeeToBill(res.data.data);
    successToast(ctsSuccess.UPDATE_BILL_MESSAGE);
  } catch (error) {
    failedToast(error, ctsErr.UPDATE_BILL_FAILED);
  }
}

function* addSetInBillSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const selectedShop = yield select((state) => state.getIn(["user", "selectedShop"]).toJS());
  yield put(showLoading("add-set-in-bill"));

  try {
    const res = yield call(apis.addProductInBillAPI, token, payload.params);
    yield put(hideLoading());

    yield put(
      actions.addSetInBillSuccess({
        ...res.data[0],
        set_menu: { name: payload.params.products[0].name },
      })
    );
    socket.clientAddSetInBill({
      ...res.data[0],
      set_menu: { name: payload.params.products[0].name },
      shop_id: selectedShop.id,
    });
    successToast(ctsSuccess.ADDED);
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.ADD_PRODUCT_IN_BILL_FAILED);
  }
}

function* updateSetInBillSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const selectedShop = yield select((state) => state.getIn(["user", "selectedShop"]).toJS());
  yield put(showLoading("add-set-in-bill"));

  try {
    const res = yield call(apis.updateProductInBillAPI, token, payload.id, payload.params);
    yield put(hideLoading());

    yield put(actions.updateSetInBillSuccess(res.data.data));
    socket.clientUpdateSetInBill({ ...res.data.data, shop_id: selectedShop.id });
    successToast(ctsSuccess.UPDATED);
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.UPDATE_PRODUCT_IN_BILL_FAILED);
  }
}

function* deleteProductInBillSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const selectedShop = yield select((state) => state.getIn(["user", "selectedShop"]).toJS());
  yield put(showLoading(`delete-product-in-bill-${payload.params.id}`));

  try {
    yield call(apis.deleteProductInBillAPI, token, payload.params.id, payload.params);
    yield put(
      actions.deleteProductInBillSuccess({ ...payload.params, billId: payload.params.billId })
    );
    socket.clientDeleteProductInBill({
      ...payload.params,
      billId: payload.params.billId,
      shop_id: selectedShop.id,
    });
    yield put(hideLoading());
    successToast(ctsSuccess.DELETED);
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.DELETE_PRODUCT_IN_BILL_FAILED);
  }
}

function* addProductInBillSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const selectedShop = yield select((state) => state.getIn(["user", "selectedShop"]).toJS());
  const openSettings = yield select((state) => state.getIn(["user", "openSettings"]).toJS());
  const notificationSettingsList = yield select((state) =>
    state.getIn(["settings", "notificationSettingsList"]).toJS()
  );

  yield put(showLoading("add-product-in-bill"));

  try {
    const response = yield call(apis.checkFirstOrder, token, {
      customer_id: payload.params.customer_id,
      shop_id: selectedShop.id,
      date: moment().format("YYYY-MM-DD"),
    });

    const res = yield call(apis.addProductInBillAPI, token, {
      bill_id: payload.params.billInfo.id,
      date: payload.params.date,
      products: payload.params.preOrder.map((item) => ({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        price: item.price,
        user_id: item?.user_id || null,
        type: 0, // 0: product, 1: set
        selected_options: item.selected_options,
        take_out: payload.params.takeOut ? 1 : 0,
        first_order: response.data.first_order ? 1 : 0,
      })),
    });
    yield put(hideLoading());

    let products = res.data.map((item) => {
      const found = payload.params.preOrder.find((i) => i.id === item.product_id);
      item.product = found;
      return item;
    });

    products = products.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

    payload.params.preOrder = payload.params.preOrder.map((item) => {
      const found = res.data.find((i) => i.product_id === item.id);
      if (found) {
        item.selected_options = found.selected_options;
      }
      return item;
    });

    yield put(actions.addProductInBillSuccess(products, payload.params.preOrder));
    socket.clientAddProductInBill({
      products,
      preOrder: payload.params.preOrder,
      shop_id: selectedShop.id,
    });
    successToast(ctsSuccess.ADDED);
    const result = [];

    for (const item of payload.params.preOrder) {
      if (
        parseInt(item.stock - item.quantity, 10) <= item.quantity_notification &&
        `${item.active_stock}` === "1"
      ) {
        result.push(item);
      }
    }
    if (result.length > 0 && `${openSettings.status}` === "1") {
      const needNotifyList = notificationSettingsList.data.filter((i) => i.inventory_check === 1);
      const TOKENS = getAllToken(needNotifyList);

      const productName = result.map(
        (i) =>
          `${i.name}${ctsGeneral.HAS_PRODUCT}${i.stock - i.quantity}${ctsGeneral.PRODUCT_ITEMS}`
      );

      yield call(
        sendPushNotification,
        TOKENS,
        `${selectedShop.name}${ctsGeneral.NOTIFICATION_INVENTORY_CHECK}`,
        productName.join(", "),
        { type: "inventory-check", shopId: selectedShop.id }
      );
    }
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.ADD_PRODUCT_IN_BILL_FAILED);
  }
}

function* updateProductInBillSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const selectedShop = yield select((state) => state.getIn(["user", "selectedShop"]).toJS());
  yield put(showLoading("update-product-in-bill"));

  try {
    const res = yield call(apis.updateProductInBillAPI, token, payload.id, payload.params);
    res.data.data.oldQuantity = payload.params.oldQuantity;
    res.data.data.product = payload.params.product;
    yield put(hideLoading());

    yield put(actions.updateProductInBillSuccess(res.data.data));
    socket.clientUpdateProductInBill({ ...res.data.data, shop_id: selectedShop.id });
    successToast(ctsSuccess.UPDATED);
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.UPDATE_PRODUCT_IN_BILL_FAILED);
  }
}

function* paymentBillSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const selectedShop = yield select((state) => state.getIn(["user", "selectedShop"]).toJS());
  const productInBill = yield select((state) => state.getIn(["bill", "productInBill"]).toJS());
  const openSettings = yield select((state) => state.getIn(["user", "openSettings"]).toJS());
  const notificationSettingsList = yield select((state) =>
    state.getIn(["settings", "notificationSettingsList"]).toJS()
  );
  yield put(showLoading("payment-bill"));

  try {
    const res = yield call(apis.updateBillAPI, token, payload.id, payload.params);
    yield put(actions.paymentBillSuccess(res.data.data));
    // yield put(fetchReportInSidebarFromLocal(res.data.data.total_payment || 0));
    socket.clientPaymentBill({ ...res.data.data, set_menu: payload.params.set_menu });
    socket.clientPaymentBillGlobal({
      id: res.data?.data?.id,
      shop_id: res.data?.data?.shop_id,
      sales: res.data?.data?.total_payment || 0,
    });
    successToast(ctsSuccess.PAYMENT_BILL_MESSAGE);
    yield put(hideLoading());
    payload.history.push("/bills");
    const largest = Math.max.apply(
      Math,
      productInBill.filter((i) => !i.set_menu).map((i) => i.price)
    );
    const result = [];
    for (const item of notificationSettingsList.data) {
      if (item.notification_price_limit && largest >= item.price_limit) {
        result.push(item);
      }
    }
    if (result.length > 0 && `${openSettings.status}` === "1") {
      const TOKENS = getAllToken(result);

      yield call(
        sendPushNotification,
        TOKENS,
        selectedShop.name,
        ctsGeneral.SOLD_PRICE_LIST_MESSAGE,
        {
          type: "price-limit",
          shopId: selectedShop.id,
        }
      );
    }
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.PAYMENT_BILL_FAILED);
  }
}

export default function* billWatcher() {
  yield takeEvery(constants.FETCH_BILL_LIST, fetchBillListSaga);
  yield takeLatest(constants.SEARCH_BILL, searchBillSaga);
  yield takeLatest(constants.DELETE_BILL, deleteBillSaga);
  yield takeLatest(constants.CREATE_BILL, createBillSaga);
  yield takeLatest(constants.UPDATE_BILL, updateBillSaga);
  yield takeEvery(constants.FETCH_PRODUCT_IN_BILL, fetchProductInBillSaga);
  yield takeLatest(constants.ADD_APPOINTEE_TO_BILL, addAppointeeToBillSaga);
  yield takeLatest(constants.ADD_SET_IN_BILL, addSetInBillSaga);
  yield takeLatest(constants.UPDATE_SET_IN_BILL, updateSetInBillSaga);
  yield takeLatest(constants.DELETE_PRODUCT_IN_BILL, deleteProductInBillSaga);
  yield takeLatest(constants.ADD_PRODUCT_IN_BILL, addProductInBillSaga);
  yield takeLatest(constants.UPDATE_PRODUCT_IN_BILL, updateProductInBillSaga);
  yield takeLatest(constants.PAYMENT_BILL, paymentBillSaga);
  // yield takeLatest(constants.EMPTY_BILL, emptyBillSaga);
}
