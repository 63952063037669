import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Upload, Button, message, Modal } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import * as cts from "../../../constants/ui/general";
import { getBase64 } from "../../../helpers/functionHelpers";
import { createShopAPI } from "../../../apis/shop";
import { toast } from "../../../helpers/AlertHelper";
import * as errorCts from "../../../constants/ui/error";
import * as successCts from "../../../constants/ui/success";
import * as svg from "../../../constants/ui/svg";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import { flatMap } from "lodash";
const Step1 = (props) => {
  const { setSteps, token, stateSteps, email, steps } = props;
  const history = useHistory();
  const [imageState, setImageState] = useState(true);
  const [state, setState] = useState({
    licenseImgFile: null,
    idImgFile: null,
  });
  const [licenseImg, setLicenseImg] = useState();
  const [idImg, setIdImg] = useState();
  const [isAllowUpload, setIsAllowUpload] = useState(false);
  const [hoverUpImg, setHoverUpImg] = useState(false);
  const [hoverDownImg, setHoverDownImg] = useState(false);

  const createShop = async () => {
    try {
      const formDataFile = new FormData();

      formDataFile.append("name", stateSteps.shopName);
      formDataFile.append("email", email);
      // formDataFile.append("phone", stateSteps.phone || "");
      // formDataFile.append("postcode", stateSteps.postCode || "");
      formDataFile.append("cid_location", stateSteps.smallLocation);
      formDataFile.append("cid_career", stateSteps.careers);
      formDataFile.append("identity_card", state.licenseImgFile);
      formDataFile.append("business_license", state.idImgFile);
      const res = await createShopAPI(token, formDataFile);
      if (res.data) {
        toast(successCts.WAIT_FOR_CONFIRMATION, "success", 5000);

        history.push("/");
      }
    } catch (error) {
      toast(errorCts.CREATE_FAILED, "error", 3000);
    }
  };
  function beforeUpload(file) {
    if (licenseImg) {
      return null;
    } else {
      setIsAllowUpload(true);
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        setIsAllowUpload(false);
        message.error(cts.JPG_OR_PNG);
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        setIsAllowUpload(false);
        message.error(cts.SMALLER_2MB);
      }
      return isJpgOrPng && isLt2M;
    }
  }
  const handleLicenseImgChange = (info) => {
    if (!isAllowUpload) {
      return;
    }
    getBase64(info.file.originFileObj, (imageUrl) => {
      setState({
        ...state,
        licenseImgFile: info.file.originFileObj,
      });
      setLicenseImg(imageUrl);
    });
  };
  const handleIdImgChange = (info) => {
    if (!isAllowUpload) {
      return;
    }
    getBase64(info.file.originFileObj, (imageUrl) => {
      setState({
        ...state,
        idImgFile: info.file.originFileObj,
      });
      setIdImg(imageUrl);
    });
  };

  const onDeleteItemUp = () => {
    Modal.confirm({
      className: "Modal_confirm_product",
      title: cts.DELETE_ITEM_SPENDING,
      okText: renderRemoveItemUp(),
      cancelText: _renderCancel(),
    });
  };

  const onDeleteItemDown = () => {
    Modal.confirm({
      className: "Modal_confirm_product",
      title: cts.DELETE_ITEM_SPENDING,
      okText: renderRemoveItemDown(),
      cancelText: _renderCancel(),
    });
  };

  const _renderCancel = () => {
    return (
      <>
        <Button className="btn-cancel_item">{cts.CANCEL}</Button>
      </>
    );
  };

  const renderRemoveItemUp = () => {
    return (
      <>
        <Button
          className="btn-remove_item"
          onClick={() => {
            setLicenseImg(null);
          }}
        >
          {cts.REMOVE}
        </Button>
      </>
    );
  };

  const renderRemoveItemDown = () => {
    return (
      <>
        <Button
          className="btn-remove_item"
          onClick={() => {
            setIdImg(null);
          }}
        >
          {cts.REMOVE}
        </Button>
      </>
    );
  };

  return (
    <div className="bg-content_register">
      <h5 className="mb-10" style={{ fontWeight: 600, fontFamily: "NotoSans", fontSize: "20px" }}>
        ライセンスのアップロード
      </h5>
      {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}> */}
      <span
        className="mb-30"
        style={{
          display: "flex",
          justifyContent: "center",
          color: "#000000",
          color: "#000000",
          fontFamily: "NotoSans",
          fontSize: "16px",
          fontWeight: 400,
        }}
      >
        ライセンスと身分証明書をアップロードしてください。
      </span>
      {/* </div> */}
      <span
        className="mt-5"
        style={{
          display: "flex",
          justifyContent: "center",
          color: "#000000",
          fontFamily: "NotoSans",
          fontSize: "16px",
          fontWeight: 500,
        }}
      >
        ライセンス
      </span>
      <div className="img-pre">
        <Upload
          className="btn-shop-upload"
          beforeUpload={beforeUpload}
          onChange={handleLicenseImgChange}
          maxCount={1}
          disabled={imageState && licenseImg?.length > 0}
        >
          {licenseImg ? (
            <div
              className="upload-img"
              onMouseOut={() => setTimeout(() => setHoverUpImg(false), 200)}
              onMouseOver={() => setTimeout(() => setHoverUpImg(true), 200)}
            >
              <img src={licenseImg} alt="avatar" />
              {hoverUpImg && (
                <div className="btn-hover d-flex">
                  <Button
                    className="btn-edit"
                    onClick={() => {
                      beforeUpload();
                      setImageState(false);
                    }}
                    style={{
                      background: "#ffffff",
                      borderRadius: "6px",
                      color: "#666687",
                      width: "40px",
                      height: "40px",
                      marginRight: "5px",
                      marginTop: "-130px",
                      borderStyle: "none",
                    }}
                    icon={<AiOutlineEdit size={20} />}
                  />
                  <Button
                    className="btn-trash"
                    onClick={() => {
                      onDeleteItemUp();
                      setImageState(true);
                    }}
                    style={{
                      background: "#ffffff",
                      borderRadius: "6px",
                      color: "#666687",
                      width: "40px",
                      height: "40px",
                      marginLeft: "15px",
                      marginTop: "-130px",
                      borderStyle: "none",
                    }}
                    icon={<HiOutlineTrash size={20} />}
                  />
                </div>
              )}
            </div>
          ) : (
            <Button className=" mt-15">
              <span style={{ height: "30px" }}>{svg.addIcon}</span>
              <span
                style={{
                  color: "#666687",
                  fontFamily: "NotoSans",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                アップロード
              </span>
            </Button>
          )}
        </Upload>
      </div>
      <span
        className="mt-15 mb-5"
        style={{
          color: "#000000",
          fontFamily: "NotoSans",
          fontSize: "15px",
          fontWeight: 500,
          display: "flex",
          justifyContent: "center",
        }}
      >
        身分証明書
      </span>

      <div className="img-after">
        <Upload
          className="btn-shop-upload"
          beforeUpload={beforeUpload}
          onChange={handleIdImgChange}
          maxCount={1}
          disabled={imageState && idImg?.length > 0}
        >
          {idImg ? (
            <div
              onMouseOut={() => setTimeout(() => setHoverDownImg(false), 200)}
              onMouseOver={() => setTimeout(() => setHoverDownImg(true), 200)}
              className="upload-img"
            >
              <img src={idImg} alt="avatar" />
              {hoverDownImg && (
                <div className="btn-hover d-flex">
                  <Button
                    className="btn-edit"
                    onClick={() => {
                      // beforeUpload();
                      setImageState(false);
                    }}
                    style={{
                      background: "#ffffff",
                      borderRadius: "6px",
                      color: "#666687",
                      width: "30px",
                      height: "30px",
                      marginRight: "5px",
                      marginTop: "-130px",
                      borderStyle: "none",
                    }}
                    icon={<AiOutlineEdit size={20} />}
                  />
                  <Button
                    className="btn-trash"
                    onClick={() => {
                      onDeleteItemDown();
                      setImageState(true);
                    }}
                    style={{
                      background: "#ffffff",
                      borderRadius: "6px",
                      color: "#666687",
                      width: "30px",
                      height: "30px",
                      marginLeft: "15px",
                      marginTop: "-130px",
                      borderStyle: "none",
                    }}
                    icon={<HiOutlineTrash size={20} />}
                  />
                </div>
              )}
            </div>
          ) : (
            <Button className="mt-15">
              <span style={{ height: "30px" }}>{svg.addIcon}</span>
              <span
                style={{
                  color: "#666687",
                  fontFamily: "NotoSans",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                アップロード
              </span>
            </Button>
          )}
        </Upload>
      </div>

      <button type="button" className="mt-25" onClick={createShop}>
        送信
      </button>

      {isAllowUpload === true ? (
        <button
          type="button"
          style={{ backgroundColor: "white", color: "#666687", border: "1px solid #D9D9D9" }}
          className="mt-25"
          onClick={() => {
            if (steps === 0) {
              return;
            } else {
              setSteps(steps - 1);
            }
          }}
        >
          <ArrowLeftOutlined /> 戻る
        </button>
      ) : (
        <button
          type="button"
          style={{ backgroundColor: "white", color: "#666687", border: "1px solid #D9D9D9" }}
          // onClick={history.goBack()}
          className="mt-25"
        >
          戻る
        </button>
      )}
    </div>
  );
};
export default Step1;
