/* eslint-disable import/no-cycle */
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NoShopAvatar from "../../../assets/images/shop-active.png";
import * as cts from "../../../constants/ui/general";
import { formatMoneyNew } from "../../../helpers/functionHelpers";
import * as slt from "../../../selectors";

const ShopProfitItem = ({ itemProfit, activeType, selectedDate }) => {
  const history = useHistory();
  const shopList = useSelector((state) => slt.user.shopListSelector(state));
  const checkShop = () => {
    return shopList.find((i) => `${i.id}` === `${itemProfit.id}`);
  };
  return (
    <div
      className="shop-profit-item"
      onClick={() => {
        history.push({
          pathname: "/bills/all-bill",
          search: `?type=${activeType}&date=${moment(selectedDate).format("YYYY-MM-DD")}&shop_id=${
            checkShop()?.id
          }`,
        });
      }}
    >
      <div className="left">
        <div className="shop-logo-wrapper">
          <img alt="logo" className="shop-logo" src={NoShopAvatar} />
        </div>
        <div className="info">
          <h3>{itemProfit.name}</h3>
          <span>{formatMoneyNew(itemProfit.sales)}</span>
        </div>
      </div>
      <div className="right">
        <div className="career">
          <span>{checkShop()?.career?.name || cts.NA}</span>
        </div>
      </div>
    </div>
  );
};

export default ShopProfitItem;
