export const FETCH_HOST_LIST = "FETCH_HOST_LIST";
export const FETCH_HOST_LIST_SUCCESS = "FETCH_HOST_LIST_SUCCESS";

export const FETCH_HOST_FOR_ORDER_LIST = "FETCH_HOST_FOR_ORDER_LIST";
export const FETCH_HOST_FOR_ORDER_LIST_SUCCESS = " FETCH_HOST_FOR_ORDER_LIST_SUCCESS";

export const FETCH_HOST_RECEIVABLES_LIST = "FETCH_HOST_RECEIVABLES_LIST";
export const FETCH_HOST_RECEIVABLES_LIST_SUCCESS = "FETCH_HOST_RECEIVABLES_LIST_SUCCESS";

export const CREATE_HOST_RECEIVABLES = "CREATE_HOST_RECEIVABLES";
export const CREATE_HOST_RECEIVABLES_SUCCESS = "CREATE_HOST_RECEIVABLES_SUCCESS";

export const UPDATE_HOST_RECEIVABLES = "UPDATE_HOST_RECEIVABLES";
export const UPDATE_HOST_RECEIVABLES_SUCCESS = "UPDATE_HOST_RECEIVABLES_SUCCESS";

export const DELETE_HOST_RECEIVABLES = "DELETE_HOST_RECEIVABLES";
export const DELETE_HOST_RECEIVABLES_SUCCESS = "DELETE_HOST_RECEIVABLES_SUCCESS";

export const FETCH_HOST_SALES = "FETCH_HOST_SALES";
export const FETCH_HOST_SALES_SUCCESS = "FETCH_HOST_SALES_SUCCESS";

export const FETCH_HOST_SALARY = "FETCH_HOST_SALARY";
export const FETCH_HOST_SALARY_SUCCESS = "FETCH_HOST_SALARY_SUCCESS";

export const UPDATE_HOST_SALARY = "UPDATE_HOST_SALARY";
export const UPDATE_HOST_SALARY_SUCCESS = "UPDATE_HOST_SALARY_SUCCESS";

export const FETCH_HOST_OTHER_MONEY_LIST = "FETCH_HOST_OTHER_MONEY";
export const FETCH_HOST_OTHER_MONEY_LIST_SUCCESS = "FETCH_HOST_OTHER_MONEY_SUCCESS";

export const CREATE_HOST_OTHER_MONEY = "CREATE_HOST_OTHER_MONEY";
export const CREATE_HOST_OTHER_MONEY_SUCCESS = "CREATE_HOST_OTHER_MONEY_SUCCESS";

export const UPDATE_HOST_OTHER_MONEY = "UPDATE_HOST_OTHER_MONEY";
export const UPDATE_HOST_OTHER_MONEY_SUCCESS = "UPDATE_HOST_OTHER_MONEY_SUCCESS";

export const DELETE_HOST_OTHER_MONEY = "DELETE_HOST_OTHER_MONEY";
export const DELETE_HOST_OTHER_MONEY_SUCCESS = "DELETE_HOST_OTHER_MONEY_SUCCESS";

export const UPDATE_HOST_FOR_ORDER_LIST = "UPDATE_HOST_FOR_ORDER_LIST";
export const UPDATE_HOST_FOR_ORDER_LIST_SUCCESS = "UPDATE_HOST_FOR_ORDER_LIST_SUCCESS";

export const UPDATE_SETTINGS_NOMINATION_HOST = "UPDATE_SETTINGS_NOMINATION_HOST";
export const UPDATE_SETTINGS_NOMINATION_HOST_SUCCESS = "UPDATE_SETTINGS_NOMINATION_HOST_SUCCESS";
