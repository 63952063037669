import * as cts from "../constants/events/products";

export const fetchCategoryList = (shopId, type_get) => ({
  type: cts.FETCH_CATEGORY_LIST,
  payload: { shopId, type_get },
});

export const fetchCategoryListSuccess = (data) => ({
  type: cts.FETCH_CATEGORY_LIST_SUCCESS,
  payload: { data },
});

export const createNewCategory = (params, noLoading) => ({
  type: cts.CREATE_NEW_CATEGORY,
  payload: { params, noLoading },
});

export const createNewCategorySuccess = (data) => ({
  type: cts.CREATE_NEW_CATEGORY_SUCCESS,
  payload: { data },
});

export const createMultiCategory = (params) => ({
  type: cts.CREATE_MULTI_CATEGORY,
  payload: { params },
});

export const createMultiCategorySuccess = (data) => ({
  type: cts.CREATE_MULTI_CATEGORY_SUCCESS,
  payload: { data },
});

export const updateCategory = (id, params) => ({
  type: cts.UPDATE_CATEGORY,
  payload: { id, params },
});

export const updateCategorySuccess = (data) => ({
  type: cts.UPDATE_CATEGORY_SUCCESS,
  payload: { data },
});

export const deleteCategory = (id) => ({
  type: cts.DELETE_CATEGORY,
  payload: { id },
});

export const deleteCategorySuccess = (data) => ({
  type: cts.DELETE_CATEGORY_SUCCESS,
  payload: { data },
});

export const fetchProductList = (params) => ({
  type: cts.FETCH_PRODUCT_LIST,
  payload: { params },
});

export const fetchProductListSuccess = (data) => ({
  type: cts.FETCH_PRODUCT_LIST_SUCCESS,
  payload: { data },
});

export const createProduct = (params) => ({
  type: cts.CREATE_PRODUCT,
  payload: { params },
});

export const createProductSuccess = (data) => ({
  type: cts.CREATE_PRODUCT_SUCCESS,
  payload: { data },
});

export const createMultiProduct = (params) => ({
  type: cts.CREATE_MULTI_PRODUCT,
  payload: { params },
});

export const createMultiProductSuccess = (data) => ({
  type: cts.CREATE_MULTI_PRODUCT_SUCCESS,
  payload: { data },
});

export const updateProduct = (id, params) => ({
  type: cts.UPDATE_PRODUCT,
  payload: { id, params },
});

export const updateProductSuccess = (data) => ({
  type: cts.UPDATE_PRODUCT_SUCCESS,
  payload: { data },
});

export const deleteProduct = (id, params, isDeleteImage) => ({
  type: cts.DELETE_PRODUCT,
  payload: { id, params, isDeleteImage },
});

export const deleteProductSuccess = (data, isDeleteImage) => ({
  type: cts.DELETE_PRODUCT_SUCCESS,
  payload: { data, isDeleteImage },
});

export const fetchProductListForOrder = (params) => ({
  type: cts.FETCH_PRODUCT_LIST_FOR_ORDER,
  payload: { params },
});

export const fetchProductListForOrderSuccess = (data) => ({
  type: cts.FETCH_PRODUCT_LIST_FOR_ORDER_SUCCESS,
  payload: { data },
});

export const updateOrderCategory = (params) => ({
  type: cts.UPDATE_ORDER_CATEGORY,
  payload: { params },
});

export const updateOrderCategorySuccess = (data) => ({
  type: cts.UPDATE_ORDER_CATEGORY_SUCCESS,
  payload: { data },
});

export const updateOrderProduct = (params) => ({
  type: cts.UPDATE_ORDER_PRODUCT,
  payload: { params },
});

export const updateOrderProductSuccess = (data) => ({
  type: cts.UPDATE_ORDER_PRODUCT_SUCCESS,
  payload: { data },
});

export const resetFetchedCategory = () => ({
  type: cts.RESET_FETCHED_CATEGORY,
});
