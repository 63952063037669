/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import uniqid from "uniqid";
import * as settingAct from "./actions/settings";
import * as userAct from "./actions/user";
import "./App.less";
import "./assets/scss/index.scss";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import SocketComponent from "./components/SocketComponent";
import LoginPageV3 from "./containers/loginpageV3";
import RegisterPage from "./containers/Register/RegisterPage";
import StoreInformationPage from "./containers/StoreInformation/StoreInformationPage";
import SignupFromInvitePage from "./containers/SignupFromInvitePage";
import getToken from "./helpers/getToken";
import { routes, SetMenuPage } from "./routes";
import * as generalSlt from "./selectors/general";
import * as settingSlt from "./selectors/settings";
import * as userSlt from "./selectors/user";
import HeaderSetup from "./containers/Setup/components/HeaderSetup";
import { IS_SETUP_PAGE } from "./constants";
import BillsTableDetail from "./containers/BillsTableDetail";

export default function App() {
  const dispatch = useDispatch();
  const [isRender, setRender] = useState(false);
  const token = useSelector((state) => userSlt.tokenSelector(state));
  const isShowSidebar = useSelector((state) => generalSlt.isShowSidebarSelector(state));
  const breadcrumb = useSelector((state) => generalSlt.breadcrumbSelector(state));
  const selectedShop = useSelector((state) => userSlt.selectedShopSelector(state));
  const userInfo = useSelector((state) => userSlt.userInfoSelector(state));
  const notificationSettings = useSelector((state) =>
    settingSlt.notificationSettingsSelector(state)
  );
  useEffect(() => {
    if (selectedShop.id && notificationSettings.shopId !== selectedShop.id) {
      dispatch(settingAct.getNotification(selectedShop.id));
    }
  }, [dispatch, selectedShop.id]);

  useEffect(() => {
    const tokenOfGet = getToken();

    if (tokenOfGet) {
      dispatch(userAct.getTokenFlow(tokenOfGet));
    }
  }, [dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(userAct.getUserInfo(token));
      dispatch(userAct.fetchShopList(token));
    }
    if (userInfo?.id) {
      dispatch(userAct.fetchUserSettings({ user_id: userInfo.id }));
    }
  }, [token, dispatch, userInfo?.id]);

  useEffect(() => {
    setTimeout(() => setRender(true), 100);
  });

  const showContentMenus = (routes) => {
    routes.push({
      id: uniqid(),
      path: "/categories/set-menu",
      exact: true,
      main: ({ history }) => <SetMenuPage history={history} />,
    });

    return (
      <Switch>
        <Route path="/bills-table-detail" exact component={() => <BillsTableDetail />} />

        {
          routes.map((route) => {
            return (
              <Route
                key={`${route.id} ${route.path}`}
                path={route.path}
                exact={route.exact}
                component={route.main}
              />
            )
          })
        }
      </Switch>
    )
  };

  const isHideSidebar = breadcrumb.id === "setup";

  if (window.location.pathname.includes("signup")) {
    return <SignupFromInvitePage />;
  }
  return (
    <>
      {!isRender && <div className="overlay-before-render" />}
      {token ? (
        <Router>
          <>
            {/* {fullScreenLoading && <FullScreenLoading />} */}
            <div className="wrapper">
              <Sidebar hidden={isHideSidebar} />
              {/* <Header hidden={isHideSidebar} /> */}
              {IS_SETUP_PAGE ? <HeaderSetup /> : <Header />}

              {/* <div
                className={`article ${isShowSidebar ? "" : "hide"} ${
                  isHideSidebar ? "article-full" : ""
                }`}
              > */}
              <div
                className={`article ${IS_SETUP_PAGE ? "" : "hide"} ${IS_SETUP_PAGE ? "article-full" : ""
                  }`}
              >
                {showContentMenus(routes)}
              </div>
            </div>
          </>
        </Router>
      ) : (
        <Router>
          <Switch>
            <Route path="/bills-table-detail" exact component={() => <BillsTableDetail />} />
            <Route path="/" exact component={() => <LoginPageV3 />} />
            <Route path="/register" exact component={() => <RegisterPage />} />
            <Route path="/storeinformation" exact component={() => <StoreInformationPage />} />
          </Switch>
        </Router>
      )}
      <SocketComponent />
    </>
  );
}
