import * as cts from "../constants/events/user";

export const getTokenFlow = (data) => ({
  type: cts.GET_TOKEN,
  payload: { data },
});

export const loginFlow = (params, history) => ({
  type: cts.LOGIN_FLOW,
  payload: { params, history },
});

export const loginFlowSuccess = (data) => ({
  type: cts.LOGIN_FLOW_SUCCESS,
  payload: { data },
});

export const logoutFlow = (history) => ({
  type: cts.LOGOUT_FLOW,
  payload: history,
});

export const logoutFlowSuccess = () => ({
  type: cts.LOGOUT_FLOW_SUCCESS,
});

export const getUserInfo = (params) => ({
  type: cts.GET_USER_INFO,
  payload: { params },
});

export const getUserInfoSuccess = (data) => ({
  type: cts.GET_USER_INFO_SUCCESS,
  payload: { data },
});

export const fetchShopList = (params) => ({
  type: cts.FETCH_SHOP_LIST,
  payload: { params },
});

export const fetchShopListSuccess = (data) => ({
  type: cts.FETCH_SHOP_LIST_SUCCESS,
  payload: { data },
});

export const setSelectedShop = (params) => ({
  type: cts.SET_SELECTED_SHOP,
  payload: { params },
});

export const fetchOpenSettings = (params, hasLoading) => ({
  type: cts.FETCH_OPEN_SETTINGS,
  payload: { params, hasLoading },
});

export const fetchOpenSettingsSuccess = (data) => ({
  type: cts.FETCH_OPEN_SETTINGS_SUCCESS,
  payload: { data },
});

export const fetchOpenSettingsAllSuccess = (data) => ({
  type: cts.FETCH_OPEN_SETTINGS_ALL_SUCCESS,
  payload: { data },
});

export const setOpenSettings = (params, noMsg) => ({
  type: cts.SET_OPEN_SETTINGS,
  payload: { params, noMsg },
});

export const setOpenSettingsSuccess = (data) => ({
  type: cts.SET_OPEN_SETTINGS_SUCCESS,
  payload: { data },
});

export const setPrintSettings = (params) => ({
  type: cts.SET_PRINT_SETTINGS,
  payload: { params },
});

export const getPrintSettings = (params) => ({
  type: cts.GET_PRINT_SETTINGS,
  payload: { params },
});

export const fetchUserSettings = (params) => ({
  type: cts.FETCH_USER_SETTINGS,
  payload: { params },
});

export const fetchUserSettingsSuccess = (data) => ({
  type: cts.FETCH_USER_SETTINGS_SUCCESS,
  payload: { data },
});

export const updateUserSettings = (params) => ({
  type: cts.UPDATE_USER_SETTINGS,
  payload: { params },
});

export const updateUserSettingsSuccess = (data) => ({
  type: cts.UPDATE_USER_SETTINGS_SUCCESS,
  payload: { data },
});

// MY PAGE

export const updateUserInfo = (params) => ({
  type: cts.UPDATE_USER_INFO,
  payload: { params },
});

export const updateUserInfoSuccess = (data) => ({
  type: cts.UPDATE_USER_INFO_SUCCESS,
  payload: { data },
});

// Delete user
export const deleteUser = (params) => ({
  type: cts.DELETE_USER,
  payload: { params },
});

export const deleteUserSuccess = (data) => ({
  type: cts.DELETE_USER_SUCCESS,
  payload: { data },
});

// CHANGE_PASSWORD
export const updateUserPassword = (params) => ({
  type: cts.URL_CHANGE_PASSWORD_STAFF,
  payload: { params },
});

export const updateUserPasswordSuccess = (data) => ({
  type: cts.URL_CHANGE_PASSWORD_STAFF_SUCCESS,
  payload: { data },
});
