export const FETCH_CUSTOMER_LIST = "FETCH_CUSTOMER_LIST";
export const FETCH_CUSTOMER_LIST_SUCCESS = "FETCH_CUSTOMER_LIST_SUCCESS";

export const FETCH_CUSTOMER_FOR_ORDER_LIST = "FETCH_CUSTOMER_FOR_ORDER_LIST";
export const FETCH_CUSTOMER_FOR_ORDER_LIST_SUCCESS = "FETCH_CUSTOMER_FOR_ORDER_LIST_SUCCESS";

export const FETCH_CUSTOMER_DETAIL = "FETCH_CUSTOMER_DETAIL";
export const FETCH_CUSTOMER_DETAIL_SUCCESS = "FETCH_CUSTOMER_DETAIL_SUCCESS";

export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAILED = "CREATE_CUSTOMER_FAILED";

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";

export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";

export const FETCH_LOGS = "FETCH_LOGS";
export const FETCH_LOGS_SUCCESS = "FETCH_LOGS_SUCCESS";

export const FETCH_SALARY_LIST = "FETCH_SALARY_LIST";
export const FETCH_SALARY_LIST_SUCCESS = "FETCH_SALARY_LIST_SUCCESS";

export const CREATE_SALARY = "CREATE_SALARY";
export const CREATE_SALARY_SUCCESS = "CREATE_SALARY_SUCCESS";
export const CREATE_SALARY_FAILED = "CREATE_SALARY_FAILED";

export const UPDATE_SALARY = "UPDATE_SALARY";
export const UPDATE_SALARY_SUCCESS = "UPDATE_SALARY_SUCCESS";

export const DELETE_SALARY = "DELETE_SALARY";
export const DELETE_SALARY_SUCCESS = "DELETE_SALARY_SUCCESS";

export const FETCH_REPORT = "FETCH_REPORT";
export const FETCH_REPORT_SUCCESS = "FETCH_REPORT_SUCCESS";

export const FETCH_SALARY_NOMINATION = "FETCH_SALARY_NOMINATION";
export const FETCH_SALARY_NOMINATION_SUCCESS = "FETCH_SALARY_NOMINATION_SUCCESS";

export const UPDATE_SALARY_NOMINATION = "UPDATE_SALARY_NOMINATION";

export const UPDATE_SALARY_NOMINATION_SUCCESS = "UPDATE_SALARY_NOMINATION_SUCCESS";

export const FETCH_REPORT_IN_SIDEBAR = "FETCH_REPORT_IN_SIDEBAR";
export const FETCH_REPORT_IN_SIDEBAR_SUCCESS = "FETCH_REPORT_IN_SIDEBAR_SUCCESS";

export const FETCH_REPORT_IN_SIDEBAR_FROM_SOCKET = "FETCH_REPORT_IN_SIDEBAR_FROM_SOCKET";
export const FETCH_REPORT_IN_SIDEBAR_FROM_LOCAL = "FETCH_REPORT_IN_SIDEBAR_FROM_LOCAL";
