import memoize from "fast-memoize";

const profit = (state) => state.getIn(["profit", "profit"]).toJS();
const profitTimeDetail = (state) => state.getIn(["profit", "profitTimeDetail"]).toJS();
const profitHostDetail = (state) => state.getIn(["profit", "profitHostDetail"]).toJS();
const profitProductDetail = (state) => state.getIn(["profit", "profitProductDetail"]).toJS();
const shopCompare = (state) => state.getIn(["profit", "shopCompare"]).toJS();

export const profitSelector = memoize(profit);
export const profitTimeDetailSelector = memoize(profitTimeDetail);
export const profitHostDetailSelector = memoize(profitHostDetail);
export const profitProductDetailSelector = memoize(profitProductDetail);
export const shopCompareSelector = memoize(shopCompare);
