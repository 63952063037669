import { fromJS } from "immutable";
import * as cts from "../constants/events/user-of-shop";
import { SET_SELECTED_SHOP } from "../constants/events/user";
import { createReducer } from "../helpers/createReducer";

const initialState = fromJS({
  userofshopList: {
    data: [],
    shopId: "",
    total: 0,
  },
});
const setSelectedShop = () => {
  return initialState;
};

const fetchUserOfShopListSuccess = (state, action) => {
  const { data } = action.payload.data;
  return state
    .setIn(["userofshopList", "data"], fromJS(data.data))
    .setIn(["userofshopList", "total"], fromJS(data.total))
    .setIn(["userofshopList", "shopId"], data.shopId);
};

const updateUserOfShopListSuccess = (state, action) => {
  const useOfShopList = state.getIn(["userofshopList", "data"]).toJS();
  const index = useOfShopList.findIndex((i) => i.id === action.payload.data.id);
  useOfShopList[index] = action.payload.data;
  return state.setIn(["userofshopList", "data"], fromJS(useOfShopList));
};

const createhUserOfShopListSuccess = (state, action) => {
  const { shopId, params } = action.payload;
  const staffs = state.getIn(["userofshopList", "data"]).toJS();

  let newTable = null;
  if (params?.length > 0) {
    newTable = [...params.reverse(), ...staffs];
  } else {
    newTable = [params].concat(staffs);
  }
  return state.setIn(["userofshopList", "data"], fromJS(newTable));
};

const deletehUserOfShopListSuccess = (state, action) => {
  const { data } = action.payload;
  const staffs = state.getIn(["userofshopList", "data"]).toJS();

  const newStaffs = staffs.filter((i) => i.id !== data.id);

  return state.setIn(["userofshopList", "data"], fromJS(newStaffs));
};
const createStaffSuccess = (state, action) => {
  const { data } = action.payload;
  const hostList = state.getIn(["userofshopList", "data"]).toJS();
};

//reducer
const reducer = {
  [SET_SELECTED_SHOP]: setSelectedShop,
  [cts.FETCH_USER_OF_SHOP_LIST_SUCCESS]: fetchUserOfShopListSuccess,
  [cts.UPDATE_USER_OF_SHOP_LIST_SUCCESS]: updateUserOfShopListSuccess,
  [cts.CREATE_USER_OF_SHOP_LIST_SUCCESS]: createhUserOfShopListSuccess,
  [cts.DELETE_USER_OF_SHOP_LIST_SUCCESS]: deletehUserOfShopListSuccess,
  [cts.CREATE_STAFF_SUCCESS]: createStaffSuccess,

  __default__: (state) => state,
};
export default createReducer(reducer, initialState);
