export const FETCH_USER_OF_SHOP_LIST = "FETCH_USER_OF_SHOP_LIST";
export const FETCH_USER_OF_SHOP_LIST_SUCCESS = "FETCH_USER_OF_SHOP_LIST_SUCCESS";

export const UPDATE_USER_OF_SHOP_LIST = " UPDATE_USER_OF_SHOP_LIST ";
export const UPDATE_USER_OF_SHOP_LIST_SUCCESS = "UPDATE_USER_OF_SHOP_LIST_SUCCESS";

export const CREATE_USER_OF_SHOP_LIST = "CREATE_USER_OF_SHOP_LIST";
export const CREATE_USER_OF_SHOP_LIST_SUCCESS = "CREATE_USER_OF_SHOP_LIST_SUCCESS";

export const DELETE_USER_OF_SHOP_LIST = "DELETE_USER_OF_SHOP_LIST";
export const DELETE_USER_OF_SHOP_LIST_SUCCESS = "DELETE_USER_OF_SHOP_LIST_SUCCESS";
export const CREATE_STAFF = "CREATE_STAFF";
export const CREATE_STAFF_SUCCESS = "CREATE_STAFF_SUCCESS";
