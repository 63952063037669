import request from "../helpers/axiosService";

// const URL_LOGIN = "api/login";
// const URL_LOGOUT = "api/logout";
// const URL_GET_USER_INFO = "api/user";
// const URL_FETCH_SHOP_LIST = "api/shop";

const URL_LOGIN = "login";
const URL_LOGOUT = "logout";
const URL_GET_USER_INFO = "user";
const URL_FETCH_SHOP_LIST = "shop";
const URL_OPEN_SETTINGS = "v2/open-settings";
const URL_GET_USER_SETTINGS = "/user-settings";
const URL_CHECK_EMAIL = "/check-email";
const URL_REGISTER = "/v2/register";
const URL_ADD_STAFF = "/add-staff";
const URL_ACTIVE_CODE = "/active-user";
const URL_SEND_MAIL = "/send-email";
const URL_USERS_MANAGEMENT = "/manager-user-shop";
const URL_USER_INFO = "/user-info/0";
const URL_CHANGE_PASSWORD_STAFF = "/change-password";
const URL_LOGIN_V2 = "v2/login";
export function loginAPI(data) {
  return request({
    url: URL_LOGIN,
    method: "post",
    data: { ...data, role: 3 },
    noNeedToken: true,
  });
}

export function logoutAPI(token, params) {
  return request({
    url: URL_LOGOUT,
    method: "get",
    params,
    token,
  });
}

export function getUserInfoAPI(token) {
  return request({
    url: URL_GET_USER_INFO,
    method: "get",
    token,
  });
}

export function fetchShopListAPI(token) {
  return request({
    url: URL_FETCH_SHOP_LIST,
    method: "get",
    token,
  });
}

export function fetchOpenSettingsAPI(token, params) {
  return request({
    url: URL_OPEN_SETTINGS,
    method: "get",
    params,
    token,
  });
}

export function setOpenSettingsAPI(token, data) {
  return request({
    url: URL_OPEN_SETTINGS,
    method: "post",
    data,
    token,
  });
}

export function fetchUserSettingsAPI(token, params) {
  return request({
    url: URL_GET_USER_SETTINGS,
    method: "get",
    params,
    token,
  });
}

export function updateUserSettingsAPI(token, data) {
  return request({
    url: URL_GET_USER_SETTINGS,
    method: "post",
    data,
    token,
  });
}

export function checkEmailAPI(params) {
  return request({
    url: URL_CHECK_EMAIL,
    method: "get",
    params,
    noNeedToken: true,
  });
}

export function registerAPI(data) {
  return request({
    url: URL_REGISTER,
    method: "post",
    data,
  });
}

export function addStaffAPI(data, token) {
  return request({
    url: URL_ADD_STAFF,
    method: "post",
    data,
    token,
  });
}
export function activeCodeAPI(data) {
  return request({
    url: URL_ACTIVE_CODE,
    method: "post",
    data,
  });
}
export function loginV2API(data) {
  return request({
    url: URL_LOGIN_V2,
    method: "post",
    data,
  });
}

// MY PAGE
export function updateInfoStaffApi(token, data) {
  return request({
    url: URL_USER_INFO,
    method: "post",
    token,
    data,
    isFormData: true,
  });
}
export function deleteUserAPI(token) {
  return request({
    url: `${URL_USER_INFO}`,
    method: "delete",
    token,
  });
}

// CHANGE_PASSWORD
export function updatePasswordStaff(token, data) {
  return request({
    url: URL_CHANGE_PASSWORD_STAFF,
    method: "post",
    token,
    data,
  });
}
