/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dropdown, Menu, Tooltip, Button } from "antd";
import React, { useEffect } from "react";
import { BiStore, BiTransferAlt } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";

import { FiKey, FiSettings } from "react-icons/fi";
import { IoIosLogOut, IoIosMenu } from "react-icons/io";
import { RiAccountPinCircleLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import * as act from "../actions";
import { toggleAside } from "../actions/general";

import { logoutFlow, setSelectedShop } from "../actions/user";
import Logo from "../assets/images/logo.png";
import NoAvatar from "../assets/images/no-avatar.png";
import { IMAGE_BASE_URL_OF_USER, IS_SETUP_PAGE } from "../constants";
import * as cts from "../constants/ui/general";
import { convertDate, getDayInWeek, getFirstRoute } from "../helpers/functionHelpers";
import * as genSlt from "../selectors/general";
import * as settingSlt from "../selectors/settings";
import * as userSlt from "../selectors/user";
import * as socket from "../sockets";
import NoShopAvatar from "../assets/images/shop-active.png";

export default function HeaderComponent(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { hidden } = props;

  const isShowSidebar = useSelector((state) => genSlt.isShowSidebarSelector(state));
  const breadcrumb = useSelector((state) => genSlt.breadcrumbSelector(state));
  const userInfo = useSelector((state) => userSlt.userInfoSelector(state));
  const selectedShop = useSelector((state) => userSlt.selectedShopSelector(state));
  const shopList = useSelector((state) => userSlt.shopListSelector(state));
  const shopSettings = useSelector((state) => settingSlt.shopSettingsSelector(state));
  const openSettings = useSelector((state) => userSlt.openSettingsSelector(state));

  useEffect(() => {
    if (!selectedShop.id || openSettings.isFetched) {
      return;
    }
    if (openSettings.shopId !== selectedShop.id) {
      dispatch(act.user.fetchOpenSettings({ shop_id: selectedShop.id }));
    }
  }, [dispatch, selectedShop.id, openSettings.shopId]);

  useEffect(() => {
    if (selectedShop.id && shopSettings.shopId !== selectedShop.id) {
      dispatch(act.settings.getShopSettings({ shop_id: selectedShop.id }));
    }
  }, [dispatch, shopSettings.id, selectedShop.id]);
  // const open = false;

  const getPrintSettings = async () => {
    const printSettingsStorage = await localStorage.getItem("printSettings");
    dispatch(act.user.getPrintSettings(JSON.parse(printSettingsStorage) || {}));
  };

  useEffect(() => {
    getPrintSettings();
  }, [getPrintSettings]);

  // const userOption = () => {
  //   return (
  //     <Menu className="user-option">
  //       <div className="user-option_name">
  //         <RiAccountPinCircleLine />
  //         {userInfo.name}
  //       </div>
  //       <Menu.Item>
  //         <NavLink className="navlink-user-option" to="/select-shop">
  //           <BiStore />
  //           {cts.SELECT_SHOP}
  //         </NavLink>
  //       </Menu.Item>
  //       <Menu.Item>
  //         <NavLink className="navlink-user-option" to="/account-settings">
  //           <FiSettings />
  //           {cts.ACCOUNT_SETTINGS}
  //         </NavLink>
  //       </Menu.Item>
  //       <Menu.Item>
  //         <NavLink className="navlink-user-option" to="change-password">
  //           <FiKey />
  //           {cts.CHANGE_PASSWORD}
  //         </NavLink>
  //       </Menu.Item>
  //       <button
  //         type="button"
  //         onClick={() => {
  //           dispatch(logoutFlow(history));
  //         }}
  //       >
  //         <IoIosLogOut />
  //         {cts.LOGOUT}
  //       </button>
  //     </Menu>
  //   );
  // };
  const userOption = () => {
    return (
      <Menu className="user-option">
        <div className="user-option_name" style={{ background: "white" }}>
          <div className="open-settings top">
            <div className="user-option_namev2">
              <div className="user-name-img">
                <img className="mr-15" alt="avatar" src={NoShopAvatar} />
                <div className="name" style={{ lineHeight: "18px" }}>
                  {selectedShop.name || "loading..."}
                </div>
              </div>
              <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                <Dropdown
                  className="header-select-shop"
                  overlay={selectShopOption}
                  trigger="click"
                  placement="bottomRight"
                >
                  <Tooltip title={cts.MOVE_SHOP} placement="bottom">
                    <BiTransferAlt color="black" size={18} />
                  </Tooltip>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <Menu.Item>
          <NavLink className="navlink-user-option" to="/select-shop">
            <BiStore />
            {cts.SELECT_SHOP}
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink className="navlink-user-option" to="/account-settings">
            <FiSettings />
            {cts.ACCOUNT_SETTINGS}
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink className="navlink-user-option" to="change-password">
            <FiKey />
            {cts.CHANGE_PASSWORD}
          </NavLink>
        </Menu.Item>
        <button
          type="button"
          onClick={() => {
            dispatch(logoutFlow(history));
          }}
        >
          <IoIosLogOut />
          {cts.LOGOUT}
        </button>
      </Menu>
    );
  };
  const selectShopOption = () => {
    return (
      <div className="select-shop-option">
        {shopList.map((item) => (
          <button
            className={selectedShop.id === item.id ? "active" : ""}
            type="button"
            onClick={() => {
              socket.clientJoinRoom({
                shop_id: item.id,
                old_shop_id: selectedShop?.id,
              });
              dispatch(setSelectedShop(item.id));
              localStorage.setItem("POS_WEB_SELECTED_SHOP", item.id);
            }}
          >
            {item.name}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className={`header ${hidden ? "hidden" : ""}`}>
      <div className="header__left">
        <div className={`logo ${IS_SETUP_PAGE ? "setup" : ""}`}>
          {!IS_SETUP_PAGE && (
            <button type="button" onClick={() => dispatch(toggleAside(!isShowSidebar))}>
              <IoIosMenu />
            </button>
          )}
          <NavLink to={getFirstRoute()} className="logo-left">
            <img alt="logo" src={Logo} />
          </NavLink>
        </div>
        {IS_SETUP_PAGE && (
          <button
            type="button"
            className="switch-button"
            onClick={() => dispatch(toggleAside(!isShowSidebar))}
          >
            <IoIosMenu size={24} />
          </button>
        )}
        {/* <h2>{breadcrumb ? breadcrumb.name : "POS"}</h2> */}
      </div>
      <div className="header__right">
        <img
          alt="avatar"
          className="avatar"
          src={userInfo.avatar ? `${IMAGE_BASE_URL_OF_USER}/avatar/${userInfo.avatar}` : NoAvatar}
          style={{ marginRight: "15px" }}
        />
        <div
          className="name_user"
          style={{ display: "flex", flexDirection: "column", marginRight: "15px" }}
        >
          <span style={{ fontSize: "16px", fontWeight: 600 }}>{userInfo?.name}</span>
          <span style={{ color: "#666687" }}>
            {userInfo.id === selectedShop.cid_user ? "Master" : "----"}
          </span>
        </div>
        <Dropdown overlay={userOption} placement="bottomRight" trigger="click">
          <Tooltip title={cts.SETTINGS} placement="bottom">
            <Button className="btn-icon" icon={<BsChevronDown color="black" />} />
          </Tooltip>
        </Dropdown>
      </div>
      {/* header v1 */}
      {/* {IS_SETUP_PAGE ? (
          <>
            <Dropdown className="header-select-shop" overlay={selectShopOption} trigger="click">
              <h5 className="name">{selectedShop.name || "loading..."}</h5>
            </Dropdown>
            {open ? <img src={OpenTime} alt="open" /> : <img src={CloseTime} alt="close" />}
          </>
        ) : null}
        <h4 className="date date-now">{`${convertDate(new Date())} (${getDayInWeek(
          new Date()
        )})`}</h4>

        {IS_SETUP_PAGE ? (
          <Dropdown overlay={userOption} placement="bottomLeft" arrow trigger="click">
            <img
              alt="avatar"
              className="avatar"
              src={
                userInfo.avatar ? `${IMAGE_BASE_URL_OF_USER}/avatar/${userInfo.avatar}` : NoAvatar
              }
            />
          </Dropdown>
        ) : null} */}
      {/* </div> */}
    </div>
  );
}
