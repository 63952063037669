import { call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { hideFetchLoading, hideLoading, showFetchLoading, showLoading } from "../actions/general";
import * as act from "../actions/user-of-shop";
import { addStaffAPI } from "../apis/user";
import * as apis from "../apis/user-of-shop";
import * as cts from "../constants/events/user-of-shop";
import * as errCts from "../constants/ui/error";
import * as success from "../constants/ui/success";
import { failedToast, successToast, toast } from "../helpers/AlertHelper";
import * as socket from "../sockets";

function* fetchUserOfShopListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchUserOfShopListAPI, token, payload.params);
    yield put(
      act.fetchUserOfShopListSuccess({ shopId: payload.params.shopId, data: res.data.data })
    );

    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, errCts.FETCH_CATEGORY_LIST_FAILED);
  }
}

function* updateUserOfShopListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());
  try {
    yield put(showLoading());
    const res = yield call(apis.updateUserOfShopListAPI, token, payload.params.id, payload.params);
    if (res.data) {
      yield put(act.updateUserOfShopListSuccess(res.data));
      socket.clientUpdateStaff(res.data);
      successToast(success.UPDATE_STAFF_MESSAGE);
    }

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.UPDATE_STAFF_FAILED);
  }
}

function* createUserOfShopListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));

  if (!payload.noLoading) {
    yield put(showLoading("create-staff"));
  } else {
    yield put(showLoading());
  }

  try {
    yield put(showLoading());
    const res = yield call(addStaffAPI, payload.params, token);
    yield put(act.createNewUserOfShopListSuccess(payload.params.shop_id, res.data));
    socket.clientCreateStaff(res.data.data);
    if (!payload.params.notAlert) {
      successToast(success.CREATE_STAFF_MESSAGE);
    }

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.CREATE_STAFF_FAILED);
  }
}

function* deleteUserOfShopListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    yield put(showLoading());
    const res = yield call(apis.deleteUserOfShopListAPI, token, payload.id);
    yield put(act.deleteUserOfShopListSuccess(res.data));
    socket.clientDeleteStaff(res.data);
    successToast(success.DELETE_CATEGORY_MESSAGE);

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.DELETE_STAFF_FAILED);
  }
}

export default function* userofshopWatcher() {
  yield takeEvery(cts.FETCH_USER_OF_SHOP_LIST, fetchUserOfShopListSaga);
  yield takeLatest(cts.CREATE_USER_OF_SHOP_LIST, createUserOfShopListSaga);
  yield takeLatest(cts.DELETE_USER_OF_SHOP_LIST, deleteUserOfShopListSaga);
  yield takeLatest(cts.UPDATE_USER_OF_SHOP_LIST, updateUserOfShopListSaga);
}
