export const FETCH_BILL_LIST = "FETCH_BILL_LIST";
export const FETCH_BILL_LIST_SUCCESS = "FETCH_BILL_LIST_SUCCESS";
export const SEARCH_BILL = "SEARCH_BILL";
export const DELETE_BILL = "DELETE_BILL";
export const DELETE_BILL_SUCCESS = "DELETE_BILL_SUCCESS";
export const SET_BILL_STEP = "SET_BILL_STEP";
export const CREATE_BILL = "CREATE_BILL ";
export const CREATE_BILL_SUCCESS = "CREATE_BILL_SUCCESS";
export const RESET_PRODUCT_IN_BILL = "RESET_PRODUCT_IN_BILL";
export const FETCH_PRODUCT_IN_BILL = "FETCH_PRODUCT_IN_BILL";
export const FETCH_PRODUCT_IN_BILL_SUCCESS = "FETCH_PRODUCT_IN_BILL_SUCCESS";

export const UPDATE_BILL = "UPDATE_BILL";
export const UPDATE_BILL_SUCCESS = "UPDATE_BILL_SUCCESS";

export const ADD_PRODUCT_IN_BILL = "ADD_PRODUCT_IN_BILL";
export const ADD_PRODUCT_IN_BILL_SUCCESS = "ADD_PRODUCT_IN_BILL_SUCCESS";

export const ADD_SET_IN_BILL = "ADD_SET_IN_BILL";
export const ADD_SET_IN_BILL_SUCCESS = "ADD_SET_IN_BILL_SUCCESS";

export const UPDATE_PRODUCT_IN_BILL = "UPDATE_PRODUCT_IN_BILL";
export const UPDATE_PRODUCT_IN_BILL_SUCCESS = "UPDATE_PRODUCT_IN_BILL_SUCCESS";

export const ADD_APPOINTEE_TO_BILL = "ADD_APPOINTEE_TO_BILL";
export const ADD_APPOINTEE_TO_BILL_SUCCESS = "ADD_APPOINTEE_TO_BILL_SUCCESS";

export const UPDATE_SET_IN_BILL = "UPDATE_SET_IN_BILL";
export const UPDATE_SET_IN_BILL_SUCCESS = "UPDATE_SET_IN_BILL_SUCCESS";

export const DELETE_PRODUCT_IN_BILL = "DELETE_PRODUCT_IN_BILL";
export const DELETE_PRODUCT_IN_BILL_SUCCESS = "DELETE_PRODUCT_IN_BILL_SUCCESS";

export const EMPTY_BILL = "EMPTY_BILL";
export const EMPTY_BILL_SUCCESS = "EMPTY_BILL_SUCCESS";

export const PAYMENT_BILL = "PAYMENT_BILL";
export const PAYMENT_BILL_SUCCESS = "PAYMENT_BILL_SUCCESS";

export const FETCH_BILL_DETAIL_LOADING = "FETCH_BILL_DETAIL_LOADING";
export const HIDE_FETCH_BILL_DETAIL_LOADING = "HIDE_FETCH_BILL_DETAIL_LOADING";

export const RESET_FETCHED_BILL_STATE = "RESET_FETCHED_BILL_STATE";
export const SET_NEW_BILL_INFO = "SET_NEW_BILL_INFO";
export const EMPTY_NEW_BILL_INFO = "EMPTY_NEW_BILL_INFO";
export const IS_REFETCH_SIDEBAR_REPORT = "IS_REFETCH_SIDEBAR_REPORT";
