import * as cts from "../constants/events/settings";

// TABLE
export const fetchTableList = (shopId, type_get) => ({
  type: cts.FETCH_TABLE_LIST,
  payload: { shopId, type_get },
});

export const fetchTableListSuccess = (data) => ({
  type: cts.FETCH_TABLE_LIST_SUCCESS,
  payload: { data },
});

export const createNewTable = (params) => ({
  type: cts.CREATE_NEW_TABLE,
  payload: { params },
});

export const createNewTableSuccess = (data) => ({
  type: cts.CREATE_NEW_TABLE_SUCCESS,
  payload: { data },
});

export const updateTable = (id, params) => ({
  type: cts.UPDATE_TABLE,
  payload: { id, params },
});

export const updateTableSuccess = (data) => ({
  type: cts.UPDATE_TABLE_SUCCESS,
  payload: { data },
});

export const deleteTable = (id) => ({
  type: cts.DELETE_TABLE,
  payload: { id },
});

export const deleteTableSuccess = (data) => ({
  type: cts.DELETE_TABLE_SUCCESS,
  payload: { data },
});

// TAX
export const getTax = (shopId) => ({
  type: cts.GET_TAX,
  payload: { shopId },
});

export const getTaxSuccess = (data) => ({
  type: cts.GET_TAX_SUCCESS,
  payload: { data },
});

export const updateTax = (params) => ({
  type: cts.UPDATE_TAX,
  payload: { params },
});

export const updateTaxSuccess = (data) => ({
  type: cts.UPDATE_TAX_SUCCESS,
  payload: { data },
});

// NOTIFICATION
export const getNotification = (shopId, userId) => ({
  type: cts.GET_NOTIFICATION,
  payload: { shopId, userId },
});

export const getNotificationSuccess = (data) => ({
  type: cts.GET_NOTIFICATION_SUCCESS,
  payload: { data },
});

export const updateNotification = (params) => ({
  type: cts.UPDATE_NOTIFICATION,
  payload: { params },
});

export const updateNotificationSuccess = (data) => ({
  type: cts.UPDATE_NOTIFICATION_SUCCESS,
  payload: { data },
});

// SHOP SETTINGS
export const getShopSettings = (params) => ({
  type: cts.GET_SHOP_SETTINGS,
  payload: { params },
});

export const getShopSettingsSuccess = (data) => ({
  type: cts.GET_SHOP_SETTINGS_SUCCESS,
  payload: { data },
});

export const updateShopSettings = (params) => ({
  type: cts.UPDATE_SHOP_SETTINGS,
  payload: { params },
});

export const updateShopSettingsSuccess = (data) => ({
  type: cts.UPDATE_SHOP_SETTINGS_SUCCESS,
  payload: { data },
});

export const setShowCategory = (params, type) => ({
  type: cts.SET_SHOW_CATEGORY,
  payload: { params, type },
});

export const setShowCategorySuccess = (data) => ({
  type: cts.SET_SHOW_CATEGORY_SUCCESS,
  payload: { data },
});
