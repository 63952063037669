/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-template */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-else-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Button, Dropdown, Image, Menu, Tooltip } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { BiStore, BiTransferAlt } from "react-icons/bi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { FiKey, FiSettings } from "react-icons/fi";
import { IoIosLogOut, IoMdAlarm, IoMdCash } from "react-icons/io";
import { IoCashOutline } from "react-icons/io5";
import { RiAccountPinCircleLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import * as act from "../actions";
import { toggleAside } from "../actions/general";
import { logoutFlow, setSelectedShop } from "../actions/user";
import NoAvatar from "../assets/images/no-avatar.png";
import OpenIcon from "../assets/images/open-active.png";
import ProfitIcon from "../assets/images/profit-active.png";
import Open24h from "../assets/images/ringing_clock.gif";
import Close24h from "../assets/images/close-active.png";

import NoShopAvatar from "../assets/images/shop-active.png";
import {
  IMAGE_BASE_URL_OF_USER,
  IS_SETUP_PAGE,
  SHOW_BILL_LIST,
  SHOW_SOMETHING,
} from "../constants";
import * as cts from "../constants/ui/general";
import { routes } from "../routes";
import * as slt from "../selectors";
import * as socket from "../sockets";
import Money from "./Money";

export default function SidebarComponent(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { hidden } = props;
  const isShowSidebar = useSelector((state) => slt.general.isShowSidebarSelector(state));
  const openSettings = useSelector((state) => slt.user.openSettingsSelector(state));
  const reportSidebar = useSelector((state) => slt.other.reportSidebarSelector(state));
  const selectedShop = useSelector((state) => slt.user.selectedShopSelector(state));
  const userInfo = useSelector((state) => slt.user.userInfoSelector(state));
  const shopList = useSelector((state) => slt.user.shopListSelector(state));
  const shopSettings = useSelector((state) => slt.settings.shopSettingsSelector(state));
  const isRefetchSidebarReport = useSelector((state) =>
    slt.bill.isRefetchSidebarReportSelector(state)
  );

  const [myInterval, setMyInterval] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isFetchProfitBySocket, setFetchProfitBySocket] = useState(null);
  const [isFetchReport, setFetchReport] = useState(null);
  const [timeNow, setTimeNow] = useState(moment());

  // const resizeWindow = useCallback(() => {
  //   setWindowWidth(window.innerWidth);
  //   if (window.innerWidth < 1000) {
  //     dispatch(toggleAside(false));
  //   } else {
  //     dispatch(toggleAside(true));
  //   }
  // }, [dispatch]);

  const fetchReport = useCallback(() => {
    // const open_time = moment(`${openSettings.open_time}+09:00`);
    // const diff = moment().diff(moment(open_time || moment()), "minute");
    // let now = moment().format("YYYY-MM-DD");

    // if (openSettings.open_time && diff < 1440) {
    //   now = moment().subtract(1, "d").format("YYYY-MM-DD");
    // }
    let myDate = moment().format("YYYY-MM-DD");

    if (openSettings?.open_time) {
      myDate = moment(openSettings.open_time).format("YYYY-MM-DD");
    }

    dispatch(
      act.other.fetchReportInSidebar({
        date: myDate,
        shop_id: selectedShop.id,
      })
    );
  }, [selectedShop.id, openSettings]);

  useEffect(() => {
    if (isShowSidebar && selectedShop.id) {
      fetchReport();
      // setTimeNow(moment());
    }
  }, [isShowSidebar]);

  useEffect(() => {
    if (`${openSettings.status}` === "1" && isShowSidebar) {
      const interval = setInterval(() => {
        setTimeNow(moment());
      }, 60000);
      setMyInterval(interval);
    } else if (myInterval) {
      clearInterval(myInterval);
      setMyInterval(null);
    }
  }, [isShowSidebar, openSettings]);

  // useEffect(() => {
  //   if (window.innerWidth < 1000) {
  //     dispatch(toggleAside(false));
  //   } else {
  //     dispatch(toggleAside(true));
  //   }

  //   window.addEventListener("resize", resizeWindow);
  //   return () => window.removeEventListener("resize", resizeWindow);
  // }, [dispatch, resizeWindow]);

  useEffect(() => {
    if (`${openSettings.status}` === "1" && reportSidebar.shopId !== selectedShop.id) {
      fetchReport();
    }
  }, [openSettings.status, reportSidebar.shopId !== selectedShop.id]);

  useEffect(() => {
    if (isFetchProfitBySocket) {
      fetchReport();
    }
  }, [isFetchProfitBySocket]);

  useEffect(() => {
    if (isRefetchSidebarReport) {
      // setFetchProfitBySocket(Date.now());
      fetchReport();
      dispatch(act.bill.refetchSidebarReport(false));
    }
  }, [isRefetchSidebarReport]);

  useEffect(() => {
    if (isFetchReport) {
      fetchReport();
    }
  }, [isFetchReport]);

  useEffect(() => {
    socket.serverSendBillAfterPayment((data) => {
      // dispatch(act.other.fetchReportInSidebarFromSocket(data.total_payment));

      if (!isRefetchSidebarReport) {
        setFetchReport(data.id);
      }
    });

    socket.serverSendBillUpdated(() => {
      if (!isRefetchSidebarReport) {
        setFetchProfitBySocket(Date.now());
      }
    });
  }, []);

  const userOption = () => {
    return (
      <Menu className="user-option">
        <div className="user-option_name" key={4}>
          <RiAccountPinCircleLine />
          {userInfo.name}
        </div>
        <Menu.Item key={1}>
          <NavLink className="navlink-user-option" to="/select-shop">
            <BiStore />
            {cts.SELECT_SHOP}
          </NavLink>
        </Menu.Item>
        <Menu.Item key={2}>
          <NavLink className="navlink-user-option" to="/account-settings">
            <FiSettings />
            {cts.ACCOUNT_SETTINGS}
          </NavLink>
        </Menu.Item>
        <Menu.Item key={3}>
          <NavLink className="navlink-user-option" to="change-password">
            <FiKey />
            {cts.CHANGE_PASSWORD}
          </NavLink>
        </Menu.Item>
        <button
          type="button"
          onClick={() => {
            dispatch(logoutFlow(history));
          }}
        >
          <IoIosLogOut />
          {cts.LOGOUT}
        </button>
      </Menu>
    );
  };

  const _renderOpen24h = () => {
    const open_time = moment((openSettings.open_time || new Date()) + "+09:00");
    const diff = moment(timeNow || moment()).diff(moment(open_time), "minute");
    const hour = Math.floor(diff / 60);
    const minute = diff % 60;

    return (
      <Button
        style={{
          display: "flex",
          flexDirection: "row",
          height: 30,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: diff > 1440 || openSettings.status !== 1 ? "#ff7370" : "#0076ff",
          border: 0,
          marginTop: 5,
        }}
      >
        {diff > 1440 ? (
          <Image className="clock" src={Open24h} preview={false} />
        ) : openSettings.status !== 1 ? (
          <Image
            // className="ant-image-img"
            src={require("../assets/images/close-active.png")}
            preview={false}
            style={{ width: "20px", height: "20px" }}
          />
        ) : (
          <IoMdAlarm color="white" size={18} />
        )}

        <h5 style={{ color: "white", marginLeft: 5 }}>
          {diff > 1440
            ? cts.OPENED_24_HOURS
            : openSettings.status !== 1
            ? ""
            : `${cts.OPENED_DIFF} ${hour}${cts.HOUR_MINI}${minute}${cts.MINUTE_MINI}`}
        </h5>
        {/* {diff > 1440 ? (
          <Image className="clock" src={Open24h} preview={false} />
        ) : (
          <IoMdAlarm color="white" size={18} />
        )}
        <h5 style={{ color: "white", marginLeft: 5 }}>
          {diff > 1440
            ? cts.OPENED_24_HOURS
            : `${cts.OPENED_DIFF} ${hour}${cts.HOUR_MINI}${minute}${cts.MINUTE_MINI}`}
        </h5> */}
      </Button>
    );
  };

  // const _renderTopSideBar = () => {
  //   return (
  //     <div className="shop-settings-sidebar">
  //       <div className="open-settings top">
  //         <div className="top-left">
  //           <img
  //             alt="avatar"
  //             className="avatar"
  //             src={
  //               userInfo.avatar ? `${IMAGE_BASE_URL_OF_USER}/avatar/${userInfo.avatar}` : NoAvatar
  //             }
  //           />
  //           <span className="name">{userInfo?.name}</span>
  //         </div>
  //         <Dropdown overlay={userOption} placement="bottomRight" trigger="click">
  //           <Tooltip title={cts.SETTINGS} placement="bottom">
  //             <Button className="btn-icon" icon={<FiSettings color="white" />} />
  //           </Tooltip>
  //         </Dropdown>
  //       </div>
  //       <div className="divider" />
  //       <div className="open-settings top">
  //         <div className="top-left">
  //           <div className="avatar shop">
  //             {selectedShop.avatar_image ? (
  //               <img
  //                 alt="avatar"
  //                 className="avatar"
  //                 src={`${IMAGE_BASE_URL_OF_USER}/shop/logo/${selectedShop.avatar_image}`}
  //               />
  //             ) : (
  //               <img alt="avatar" style={{ height: 20, width: 20 }} src={NoShopAvatar} />
  //             )}
  //           </div>
  //           <div className="name" style={{ lineHeight: "18px" }}>
  //             {selectedShop.name || "loading..."}
  //           </div>
  //         </div>
  //         <div className="top-right">
  //           {!shopSettings.data.open_24h ? (
  //             <>
  //               {SHOW_BILL_LIST ? (
  //                 <Tooltip title={cts.OPEN_SETTINGS} placement="bottom">
  //                   <NavLink
  //                     className="btn-icon"
  //                     to="/open-settings"
  //                     exact={true}
  //                     onClick={() => windowWidth < 1000 && dispatch(toggleAside(false))}
  //                     activeClassName=""
  //                   >
  //                     <img src={OpenIcon} />
  //                   </NavLink>
  //                 </Tooltip>
  //               ) : (
  //                 <Tooltip title={cts.SHOP_PROFIT} placement="bottom">
  //                   <NavLink
  //                     className="btn-icon"
  //                     to="/shop-profit"
  //                     exact={true}
  //                     onClick={() => windowWidth < 1000 && dispatch(toggleAside(false))}
  //                     activeClassName=""
  //                   >
  //                     <img src={ProfitIcon} />
  //                   </NavLink>
  //                 </Tooltip>
  //               )}
  //             </>
  //           ) : null}
  //           <Dropdown
  //             className="header-select-shop"
  //             overlay={selectShopOption}
  //             trigger="click"
  //             placement="bottomRight"
  //           >
  //             <Tooltip title={cts.MOVE_SHOP} placement="bottom">
  //               <Button
  //                 className="btn-icon"
  //                 icon={<BiTransferAlt color="white" size={18} />}
  //                 style={{ marginLeft: 5 }}
  //               />
  //             </Tooltip>
  //           </Dropdown>
  //         </div>
  //       </div>
  //       <div className="divider" />
  //     </div>
  //   );
  // };

  const _renderSale = () => {
    // if (`${openSettings.status}` === "1" || shopSettings.data.open_24h) {
    return (
      <div className="open-settings-sidebar">
        {!shopSettings.data.open_24h ? _renderOpen24h() : null}
        <div className="open-settings d-flex center middle">
          <div className="d-flex center middle">
            <FaRegMoneyBillAlt size={24} style={{ opacity: "0.7" }} />
          </div>
          <span className="text" style={{ maxWidth: 110 }}>
            {cts.RESERVE}
          </span>
          <Money>{openSettings.sum}</Money>
        </div>
        <div className="open-settings d-flex center middle">
          <div className="d-flex center middle">
            <IoMdCash size={24} style={{ opacity: "0.7" }} />
          </div>
          <span className="text" style={{ maxWidth: 110 }}>
            {cts.TARGET}
          </span>
          <Money>{openSettings.target !== "" ? openSettings.target : 0}</Money>
        </div>
        <div className="open-settings d-flex center middle">
          <div className="d-flex center middle">
            <IoCashOutline size={24} style={{ opacity: "0.7" }} />
          </div>
          <span className="text" style={{ maxWidth: 110 }}>
            {cts.SALES}
          </span>
          <Money>{reportSidebar.sales}</Money>
        </div>
        <div className="divider" />
      </div>
    );
    // }
  };

  const _renderMenu = () => {
    let menus = routes.filter((route) => route.path !== "/bills/new-bill");

    if (selectedShop?.career && selectedShop.career?.name === "RESTAURANT") {
      menus = menus.filter((route) => route.path !== "/ranking");
    }
    if (!SHOW_BILL_LIST) {
      menus = menus.filter((route) => route.name !== cts.OPEN_SETTINGS);
    }
    return menus.map((item) => {
      // commenting id
      // if (item.name && item.id && !item.hide && `${openSettings.status}` !== "1") {
      //   if (item.name === cts.OPEN_SETTINGS) {
      //     return;
      //   } else {
      //     return (
      //       <NavLink
      //         key={item.id}
      //         to={item.path}
      //         exact={item.exact}
      //         onClick={() => {
      //           if (windowWidth < 1000) {
      //             dispatch(toggleAside(false));
      //           }
      //           history.push("/bills");
      //         }}
      //       >
      //         <img alt="icon" src={item.icon} />
      //         <img className="icon-active" alt="icon-active" src={item.activeIcon} />
      //         <span>{item.name}</span>
      //       </NavLink>
      //     );
      //   }
      // }
      if (item.name && item.id && !item.hide) {
        return (
          <NavLink
            key={item.id}
            to={item.path}
            exact={item.exact}
            onClick={(e) => {
              if (!SHOW_SOMETHING && item.name === cts.OPEN_SETTINGS) {
                return e.preventDefault();
              }
              return windowWidth < 1000 && dispatch(toggleAside(false));
            }}
            activeClassName={item.name === cts.OPEN_SETTINGS ? "" : "active"}
            className={item.name === cts.OPEN_SETTINGS ? "custom" : null}
          >
            {item.name === cts.OPEN_SETTINGS ? (
              _renderSale()
            ) : (
              <>
                <img alt="icon" src={item.icon} />
                <img className="icon-active" alt="icon-active" src={item.activeIcon} />
                <span>{item.name}</span>
              </>
            )}
          </NavLink>
        );
        // }
      }
    });
  };

  if (IS_SETUP_PAGE) {
    return <></>;
  }

  const selectShopOption = () => {
    return (
      <div className="select-shop-option">
        {shopList.map((item, index) => (
          <button
            key={index.toString()}
            className={selectedShop.id === item.id ? "active" : ""}
            type="button"
            onClick={() => {
              dispatch(act.bill.resetFetchedBill());
              dispatch(act.products.resetFetchedCategory());
              dispatch(setSelectedShop(item.id));
              socket.clientJoinRoom({
                shop_id: item?.id || selectedShop?.id,
                old_shop_id: selectedShop?.id || null,
              });
              localStorage.setItem("POS_WEB_SELECTED_SHOP", item.id);
              // history.push("/bills");
            }}
          >
            {item.name}
          </button>
        ))}
      </div>
    );
  };

  return (
    <>
      {isShowSidebar && <div className="overlay" onClick={() => dispatch(toggleAside(false))} />}
      <div
        className={`sidebar ${isShowSidebar ? "" : "hide"} ${hidden ? "hidden" : ""}`}
        // onClick={() => !isShowSidebar && dispatch(toggleAside(true))}
        // onMouseEnter={() => !isShowSidebar && dispatch(toggleAside(true))}
      >
        {/* {!isShowSidebar && (
          <div
            style={{
              backgroundColor: "none",
              position: "absolute",
              width: 80,
              height: "100%",
              zIndex: 10000,
            }}
            onClick={() => dispatch(toggleAside(true))}
          />
        )} */}

        <div className="menu">
          {/* <div className="sidebar-header">
            <Dropdown overlay={selectShopOption} trigger="click" placement="bottomCenter">
              <h5 className="name">{selectedShop.name || "loading..."}</h5>
            </Dropdown>
            {open ? <img src={OpenTime} alt="open" /> : <img src={CloseTime} alt="close" />}
            <h4 className="date date-now">{`${convertDate(new Date())} (${getDayInWeek(
              new Date()
            )})`}</h4>
          </div> */}
          {/* {_renderTopSideBar()} */}
          {_renderMenu()}
        </div>
      </div>
    </>
  );
}
