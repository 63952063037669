import { Dropdown, Menu, Tooltip, Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { BiStore, BiTransferAlt } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import { FiKey, FiSettings } from "react-icons/fi";
import { IoIosLogOut, IoIosMenu } from "react-icons/io";
import { RiAccountPinCircleLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import * as act from "../../../actions";
import { toggleAside } from "../../../actions/general";
import { logoutFlow, setSelectedShop } from "../../../actions/user";
import Logo from "../../../assets/images/logo.png";
import NoAvatar from "../../../assets/images/no-avatar.png";
import { IMAGE_BASE_URL_OF_USER, IS_SETUP_PAGE } from "../../../constants";
import * as cts from "../../../constants/ui/general";
import { convertDate, getDayInWeek, getFirstRoute } from "../../../helpers/functionHelpers";
import * as genSlt from "../../../selectors/general";
import * as settingSlt from "../../../selectors/settings";
import * as userSlt from "../../../selectors/user";
import * as socket from "../../../sockets";
import NoShopAvatar from "../../../assets/images/shop-active.png";
import NoLogo from "../../../assets/images/no-logo.png";
import * as svg from "../../../constants/ui/svg";
import { setActiveStepSetup } from "../../../actions/general";
import * as generalSlt from "../../../selectors/general";
import CategoryListTable from "./CategoryListTable";

export default function HeaderComponent(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { hidden, isRender } = props;
  const [activeComponentSetting, setActiveComponentSetting] = useState(0);

  const isShowSidebar = useSelector((state) => genSlt.isShowSidebarSelector(state));
  const breadcrumb = useSelector((state) => genSlt.breadcrumbSelector(state));
  const activeStepSetup = useSelector((state) => generalSlt.activeStepSetupSelector(state));
  const [activeStep, setActiveStep] = useState(activeStepSetup.activeStep);
  const userInfo = useSelector((state) => userSlt.userInfoSelector(state));
  const selectedShop = useSelector((state) => userSlt.selectedShopSelector(state));
  const shopList = useSelector((state) => userSlt.shopListSelector(state));
  const shopSettings = useSelector((state) => settingSlt.shopSettingsSelector(state));
  const openSettings = useSelector((state) => userSlt.openSettingsSelector(state));
  const [isCheck, setIsCheck] = useState(false);

  useEffect(() => {
    if (!selectedShop.id || openSettings.isFetched) {
      return;
    }
    if (openSettings.shopId !== selectedShop.id) {
      dispatch(act.user.fetchOpenSettings({ shop_id: selectedShop.id }));
    }
  }, [dispatch, selectedShop.id, openSettings.shopId]);

  useEffect(() => {
    if (selectedShop.id && shopSettings.shopId !== selectedShop.id) {
      dispatch(act.settings.getShopSettings({ shop_id: selectedShop.id }));
    }
  }, [dispatch, shopSettings.id, selectedShop.id]);

  const getPrintSettings = async () => {
    const printSettingsStorage = await localStorage.getItem("printSettings");
    dispatch(act.user.getPrintSettings(JSON.parse(printSettingsStorage) || {}));
  };

  useEffect(() => {
    getPrintSettings();
  }, [getPrintSettings]);

  const userOption2 = () => {
    return (
      <Menu className="user-option">
        <div className="user-option_name">
          <RiAccountPinCircleLine />
          {userInfo.name}
        </div>
        <Menu.Item>
          <NavLink className="navlink-user-option" to="/select-shop">
            <BiStore />
            {cts.SELECT_SHOP}
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink className="navlink-user-option" to="/account-settings">
            <FiSettings />
            {cts.ACCOUNT_SETTINGS}
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink className="navlink-user-option" to="change-password">
            <FiKey />
            {cts.CHANGE_PASSWORD}
          </NavLink>
        </Menu.Item>
        <button
          type="button"
          onClick={() => {
            dispatch(logoutFlow(history));
          }}
        >
          <IoIosLogOut />
          {cts.LOGOUT}
        </button>
      </Menu>
    );
  };

  const onClickHeader = useCallback(() => {
    dispatch(setActiveStepSetup({ activeStep: 7, name: cts.MY_PAGE }));
    setActiveStep(7);
    localStorage.setItem("POS_WEB_ACTIVE_SIDEBAR", 7);
    localStorage.removeItem("POS_WEB_ACTIVE_STAFF");
    setActiveComponentSetting(0);
    localStorage.setItem("POS_WEB_ACTIVE_SETTING", 0);
  }, [dispatch, activeStep]);

  const onClickLogo = useCallback(() => {
    dispatch(setActiveStepSetup({ activeStep: 1, name: cts.CATEGORIES }));
    setActiveStep(1);
    setActiveComponentSetting(3);
    localStorage.setItem("POS_WEB_ACTIVE_SIDEBAR", 1);
    localStorage.removeItem("POS_WEB_ACTIVE_STAFF");
    localStorage.setItem("POS_WEB_ACTIVE_SETTING", 3);
  }, [dispatch, activeStep]);

  const userOption = () => {
    return (
      <>
        <Menu className="user-option-setup">
          {/* <Menu.Item>
          <NavLink className="navlink-user-option" to="/select-shop">
            <BiStore />
            {cts.SELECT_SHOP}
          </NavLink>
        </Menu.Item> */}
          {/* <Menu.Item>
            <NavLink className="navlink-user-option" to="/account-settings">
              <FiSettings />
              {cts.ACCOUNT_SETTINGS}
            </NavLink>
          </Menu.Item> */}
          {/* <Menu.Item>
            <NavLink className="navlink-user-option" to="change-password">
              <FiKey />
              {cts.CHANGE_PASSWORD}
            </NavLink>
          </Menu.Item> */}
          <button onClick={onClickHeader}>
            <FiSettings />
            {cts.ACCOUNT_SETTINGS}
          </button>
          <button
            type="button"
            onClick={() => {
              dispatch(logoutFlow(history));
            }}
          >
            <IoIosLogOut />
            {cts.LOGOUT}
          </button>
        </Menu>
      </>
    );
  };
  const selectShopOption = () => {
    return (
      <div className="select-shop-option-setup">
        {shopList.map((item, index) => (
          <button
            key={index}
            className={selectedShop.id === item.id ? "active" : ""}
            type="button"
            onClick={() => {
              socket.clientJoinRoom({
                shop_id: item.id,
                old_shop_id: selectedShop?.id,
              });
              dispatch(setSelectedShop(item.id));
              localStorage.setItem("POS_WEB_SELECTED_SHOP", item.id);
            }}
          >
            <img
              style={{
                width: 20,
                height: 20,
                borderRadius: "50%",
                margin: "0 10px 0 0",
              }}
              alt="avatar"
              className="avatar-shop-setup"
              src={
                item.avatar_image
                  ? `${IMAGE_BASE_URL_OF_USER}/shop/logo/small/${item.avatar_image}`
                  : require("../../../assets/images/no-logo.png")
              }
            />
            <span>{item.name}</span>
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className={`header-setup ${hidden ? "hidden" : ""}`}>
      <div className="header-setup__left">
        <div className="logo setup">
          <button
            type="button"
            style={{ display: "flex" }}
            onClick={() => dispatch(toggleAside(!isShowSidebar))}
          >
            {svg.menuIcon}
          </button>
        </div>
        <div
          className="d-flex middle center ml-15"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setActiveComponentSetting(0);
            onClickLogo();
          }}
        >
          {/* <h1 style={{ fontWeight: 800 }}>POS</h1> <h2> - Setup</h2> */}
          {svg.logoPosWeb}
        </div>
      </div>
      <div className="header-setup__right" style={{ cursor: "pointer" }}>
        <Dropdown
          overlay={userOption}
          placement="bottomRight"
          arrow
          trigger="click"
          style={{ cursor: "pointer" }}
        >
          <Tooltip
            className="d-flex center"
            style={{ cursor: "pointer" }}
            // title={cts.SETTINGS}
            placement="bottom"
          >
            <img
              alt="avatar"
              className="avatar"
              src={
                userInfo.avatar ? `${IMAGE_BASE_URL_OF_USER}/avatar/${userInfo.avatar}` : NoAvatar
              }
              style={{ marginRight: "15px" }}
            />
            <div
              className="name_user"
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "15px",
                cursor: "pointer",
              }}
            >
              <span style={{ fontSize: "16px", fontWeight: 600 }}>{userInfo?.name}</span>
            </div>
            <Button className="btn-icon" icon={<BsChevronDown color="black" />} />
          </Tooltip>
        </Dropdown>
      </div>
    </div>
  );
}
