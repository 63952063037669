import * as cts from "../constants/events/report";

export const fetchReportDay = (params, noLoading) => ({
  type: cts.FETCH_REPORT_DAY,
  payload: { params, noLoading },
});

export const fetchReportDaySuccess = (data) => ({
  type: cts.FETCH_REPORT_DAY_SUCCESS,
  payload: { data },
});

export const fetchReportMonth = (params) => ({
  type: cts.FETCH_REPORT_MONTH,
  payload: { params },
});

export const fetchReportMonthSuccess = (data) => ({
  type: cts.FETCH_REPORT_MONTH_SUCCESS,
  payload: { data },
});

export const fetchWorkingStaff = (params) => ({
  type: cts.FETCH_WORKING_STAFF,
  payload: { params },
});

export const fetchWorkingStaffSuccess = (data) => ({
  type: cts.FETCH_WORKING_STAFF_SUCCESS,
  payload: { data },
});
