import request from "../helpers/axiosService";

// const URL_GET_REPORT_DAY = "api/report/date";
// const URL_GET_REPORT_MONTH = "api/report/month";
// const URL_GET_WORKING_STAFF = "api/report/staff-work";

const URL_GET_REPORT_DAY = "/v2/report/date";
const URL_GET_REPORT_MONTH = "/v2/report/month";
const URL_GET_WORKING_STAFF = "report/staff-work";
const URL_GET_SALARYADVANCE = "/salary-advance";
const URL_GET_SPENDINGMONEY = "/spending-money";
const URL_GET_SPENDING_CATEGORY = "/spending-category";
const URL_GET_TIME_CARD = "time-card";

export function fetchReportDayAPI(token, params) {
  return request({
    url: URL_GET_REPORT_DAY,
    method: "get",
    params,
    token,
  });
}
export function fetchReportMonthAPI(token, params) {
  return request({
    url: URL_GET_REPORT_MONTH,
    method: "get",
    params,
    token,
  });
}
export function fetchWorkingStaffAPI(token, params) {
  return request({
    url: URL_GET_WORKING_STAFF,
    method: "get",
    params,
    token,
  });
}

// SALARY
export function fetchSalaryAdvanceApi(token, params) {
  return request({
    url: URL_GET_SALARYADVANCE,
    method: "get",
    params,
    token,
  });
}
export function createSalaryAdvanceApi(token, params) {
  return request({
    url: URL_GET_SALARYADVANCE,
    method: "post",
    params,
    token,
  });
}

export function deleteSalaryAdvanceApi(token, id, shopId) {
  return request({
    url: `${URL_GET_SALARYADVANCE}/${id}`,
    method: "delete",
    params: { shopId },
    token,
  });
}

export function updateSalaryAdvanceApi(token, id, data) {
  return request({
    url: `${URL_GET_SALARYADVANCE}/${id}`,
    method: "put",
    data,
    token,
  });
}

// spending

export function fetchSpendingMoneyAPI(token, params) {
  return request({
    url: URL_GET_SPENDINGMONEY,
    method: "get",
    params,
    token,
  });
}

export function fetchSpendingCategoryAPI(token, params) {
  return request({
    url: URL_GET_SPENDING_CATEGORY,
    method: "get",
    params,
    token,
  });
}
export function createSpendingMoneyApi(token, data) {
  return request({
    url: URL_GET_SPENDINGMONEY,
    method: "post",
    data,
    token,
  });
}

// export function deleteSpendingMoneyApi(token, id, shopId) {
//   return request({
//     url: `${URL_GET_SPENDINGMONEY}/${id}`,
//     method: "delete",
//     params: { shopId },
//     token,
//   });
// }
export function deleteSpendingMoneyApi(token, id, data) {
  return request({
    url: `${URL_GET_SPENDINGMONEY}/${id}`,
    method: "delete",
    data,
    token,
  });
}

export function updateSpendingMoneyApi(token, id, data) {
  return request({
    url: `${URL_GET_SPENDINGMONEY}/${id}`,
    method: "put",
    data,
    token,
  });
}

export function fetchTimeCardApi(token, params) {
  return request({
    url: URL_GET_TIME_CARD,
    method: "get",
    params,
    token,
  });
}

export function createTimeCardApi(token, data) {
  return request({
    url: URL_GET_TIME_CARD,
    method: "post",
    data,
    token,
  });
}

export function updateTimeCardApi(token, id, data) {
  return request({
    url: `${URL_GET_TIME_CARD}/${id}`,
    method: "put",
    data,
    token,
  });
}

export function deleteTimeCardApi(token, id) {
  return request({
    url: `${URL_GET_TIME_CARD}/${id}`,
    method: "delete",
    token,
  });
}

export function updateAllSpendingMoneyApi(token, data) {
  return request({
    url: `${URL_GET_SPENDINGMONEY}/0`,
    method: "put",
    data,
    token,
  });
}

export function createAllSpendingMoneyApi(token, data) {
  return request({
    url: URL_GET_SPENDINGMONEY,
    method: "post",
    data,
    token,
  });
}
export function postSpendingCategoryAPI(token, data) {
  return request({
    url: URL_GET_SPENDING_CATEGORY,
    method: "post",
    data,
    token,
  });
}
