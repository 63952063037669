import memoize from "fast-memoize";

const listBill = (state) => state.getIn(["bill", "listBill"]).toJS();
const step = (state) => state.getIn(["bill", "step"]);
const productInBill = (state) => state.getIn(["bill", "productInBill"]).toJS();
const newBillInfo = (state) => state.getIn(["bill", "newBillInfo"]).toJS();
const fetchBillDetailLoading = (state) => state.getIn(["bill", "fetchBillDetailLoading"]);
const refetchSidebarReport = (state) => state.getIn(["bill", "isRefetchSidebarReport"]);
const isActionDone = (state) => state.getIn(["bill", "isActionDone"]);

export const listBillSelector = memoize(listBill);
export const stepSelector = memoize(step);
export const productInBillSelector = memoize(productInBill);
export const fetchBillDetailLoadingSelector = memoize(fetchBillDetailLoading);
export const newBillInfoSelector = memoize(newBillInfo);
export const isRefetchSidebarReportSelector = memoize(refetchSidebarReport);
export const isActionDoneSelector = memoize(isActionDone);
