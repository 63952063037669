import { fromJS } from "immutable";
import * as userCts from "../constants/events/user";
import { createReducer } from "../helpers/createReducer";
import * as socket from "../sockets";

const initialState = fromJS({
  token: null,
  userInfo: {},
  shopList: [],
  selectedShop: {},
  openSettings: {},
  openSettingsList: {
    data: [],
    shopId: null,
    currentPage: 1,
    totalPage: 1,
    total: 1,
  },
  userSettings: {
    isFetched: false,
  },
  printSettings: {},
});

const getToken = (state, action) => {
  const { data } = action.payload;
  return state.set("token", data);
};

const loginSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("token", data.access_token).set("userInfo", fromJS(data.user));
};

const logout = (state) =>
  state
    .set("token", null)
    .set("userInfo", fromJS({}))
    .set("shopList", fromJS([]))
    .set("selectedShop", fromJS({}))
    .set("openSettings", fromJS({}))
    .set("openSettingsList", fromJS({ data: [], shopId: null, currentPage: 1, totalPage: 1 }));

const getUserInfoSuccess = (state, action) => {
  const { data } = action.payload;

  return state.set("userInfo", fromJS(data));
};

const updateUserInfoSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("userInfo", fromJS({ ...data }));
};
// change pass
const updateUserPassWordSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("userInfo", fromJS({ ...data }));
};
//

const fetchShopListSuccess = (state, action) => {
  const { data } = action.payload;

  if (data.length > 0) {
    const selectedShop = localStorage.getItem("POS_WEB_SELECTED_SHOP");

    if (selectedShop) {
      const slt = data.find((i) => `${i.id}` === `${selectedShop}`);
      socket.clientJoinRoom({ shop_id: +slt.id || data[0].id });
      return state.set("shopList", fromJS(data)).set("selectedShop", fromJS(slt || data[0]));
    }
    socket.clientJoinRoom({ shop_id: +data[0].id });
    return state.set("shopList", fromJS(data)).set("selectedShop", fromJS(data[0]));
  }
  return state;
};

const setSelectedShop = (state, action) => {
  const { params } = action.payload;
  const shopList = state.get("shopList").toJS();
  const shop = shopList.find((i) => `${i.id}` === `${params}`);

  if (shop) {
    return state
      .set("selectedShop", fromJS(shop))
      .set("openSettings", fromJS({}))
      .set(
        "openSettingsList",
        fromJS({
          data: [],
          shopId: null,
          currentPage: 1,
          totalPage: 1,
        })
      );
  }

  return state.set("openSettings", fromJS({})).set(
    "openSettingsList",
    fromJS({
      data: [],
      shopId: null,
      currentPage: 1,
      totalPage: 1,
    })
  );
};

const fetchUserSettingsSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("userSettings", fromJS({ ...data, isFetched: true }));
};

const updateUserSettingsSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("userSettings", fromJS({ ...data }));
};

const fetchOpenSettingsSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("openSettings", fromJS({ ...data, isFetched: true }));
};

const fetchOpenSettingsAllSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("openSettingsList", fromJS(data));
};

const setOpenSettingsSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("openSettings", fromJS(data));
};

const setPrintSettings = (state, action) => {
  const { params } = action.payload;
  return state.set("printSettings", fromJS(params));
};

const fetchPrintSettings = (state, action) => {
  const { params } = action.payload;
  return state.set("printSettings", fromJS(params));
};
const deleteUserSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("userDelete", fromJS(data));
};

// reducer
const reducer = {
  [userCts.GET_TOKEN]: getToken,
  [userCts.LOGIN_FLOW_SUCCESS]: loginSuccess,
  [userCts.LOGOUT_FLOW_SUCCESS]: logout,
  [userCts.GET_USER_INFO_SUCCESS]: getUserInfoSuccess,
  [userCts.GET_USER_INFO_SUCCESS]: updateUserInfoSuccess,
  [userCts.URL_CHANGE_PASSWORD_STAFF_SUCCESS]: updateUserPassWordSuccess,
  [userCts.FETCH_SHOP_LIST_SUCCESS]: fetchShopListSuccess,
  [userCts.SET_SELECTED_SHOP]: setSelectedShop,
  [userCts.FETCH_OPEN_SETTINGS_SUCCESS]: fetchOpenSettingsSuccess,
  [userCts.FETCH_OPEN_SETTINGS_ALL_SUCCESS]: fetchOpenSettingsAllSuccess,
  [userCts.SET_OPEN_SETTINGS_SUCCESS]: setOpenSettingsSuccess,
  [userCts.SET_PRINT_SETTINGS]: setPrintSettings,
  [userCts.GET_PRINT_SETTINGS]: fetchPrintSettings,
  [userCts.FETCH_USER_SETTINGS_SUCCESS]: fetchUserSettingsSuccess,
  [userCts.UPDATE_USER_SETTINGS_SUCCESS]: updateUserSettingsSuccess,
  [userCts.DELETE_USER_SUCCESS]: deleteUserSuccess,
  __default__: (state) => state,
};

export default createReducer(reducer, initialState);
