import memoize from "fast-memoize";

const token = (state) => state.getIn(["user", "token"]);
const userInfo = (state) => state.getIn(["user", "userInfo"]).toJS();
const shopList = (state) => state.getIn(["user", "shopList"]).toJS();
const selectedShop = (state) => state.getIn(["user", "selectedShop"]).toJS();
const openSettings = (state) => state.getIn(["user", "openSettings"]).toJS();
const openSettingsList = (state) => state.getIn(["user", "openSettingsList"]).toJS();
const printSettings = (state) => state.getIn(["user", "printSettings"]).toJS();
const userSettings = (state) => state.getIn(["user", "userSettings"]).toJS();

export const tokenSelector = memoize(token);
export const userInfoSelector = memoize(userInfo);
export const shopListSelector = memoize(shopList);
export const selectedShopSelector = memoize(selectedShop);
export const openSettingsSelector = memoize(openSettings);
export const openSettingsListSelector = memoize(openSettingsList);
export const userSettingsSelector = memoize(userSettings);
export const printSettingsSelector = memoize(printSettings);
