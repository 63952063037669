import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as slt from "../selectors";
import { getBillDetailAPI } from "../apis/bill";
import Money from "../components/Money";
import * as cts from "../constants/ui/general";

export default function BillsTableDetail(props) {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dataTableRef = React.useRef(null);
  const hostListRef = React.useRef([]);
  const [dataTable, setDataTable] = useState([]);
  const shopSettings = useSelector((state) => slt.settings.shopSettingsSelector(state));
  const params = {
    shop_id: useQuery()?.get("shop_id"),
    table_id: useQuery()?.get("table_id"),
  };
  const [paramsURL, setPasramsURL] = useState(params);
  const [billInformation, setBillInformation] = useState({
    table_id: "",
    created_at: "",
    total_payment: "",
    bill_detail: [],
  });
  const main = billInformation?.service_fee
    ?.filter((item) => item.type === 1)
    .reduce(
      (a, b) => {
        return {
          name: cts.MAIN_APPOINTEE,
          quantity: a.quantity + 1,
          price: b.user_of_shop.nomination_fee
            ? b.user_of_shop.nomination_fee
            : shopSettings.data.nomination_fee,
        };
      },
      { quantity: 0, price: 0 }
    );

  const subs = billInformation?.service_fee
    ?.filter((item) => item.type === 2)
    .reduce(
      (a, b) => {
        return {
          name: cts.SUB_APPOINTEE,
          quantity: a.quantity + 1,
          price: shopSettings.data.sub_nomination_fee,
        };
      },
      { quantity: 0, price: 0 }
    );
  const companions = billInformation?.service_fee
    ?.filter((item) => item.type === 3)
    .reduce(
      (a, b) => {
        return {
          name: cts.COMPANION_APPOINTEE,
          quantity: a.quantity + 1,
          price: shopSettings.data.companion_fee,
        };
      },
      { quantity: 0, price: 0 }
    );

  const supports = billInformation?.service_fee
    ?.filter((item) => item.type === 5)
    .reduce(
      (a, b) => {
        return {
          name: cts.SUPPORT_APPOINTEE,
          quantity: a.quantity + 1,
          price: shopSettings.data.support_nomination_fee,
        };
      },
      { quantity: 0, price: 0 }
    );

  useEffect(() => {
    if (main && main.price !== 0) {
      hostListRef.current.push(main);
    }
    if (subs && subs.price !== 0) {
      hostListRef.current.push(subs);
    }
    if (companions && companions.price !== 0) {
      hostListRef.current.push(companions);
    }
    if (supports && supports.price !== 0) {
      hostListRef.current.push(supports);
    }
  }, [billInformation?.service_fee]);
  const fecthBillData = useCallback(async () => {
    try {
      const res = await getBillDetailAPI(paramsURL);
      setBillInformation(res.data[0]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fecthBillData();
  }, []);

  const tableInfor = {
    total_payment: Number(billInformation?.total_payment).toLocaleString(),
    table_name: billInformation?.tables?.name,
    created_at: billInformation?.created_at.slice(0, 10),
    time: billInformation?.created_at?.slice(11, 16),
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: cts.NAME,
      dataIndex: "name",
      key: "name",
    },
    {
      title: cts.QUANTITY,
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: cts.PRICE,
      dataIndex: "price",
      key: "price",
      render: (text, record, index) => {
        return <Money>{record?.price * record?.quantity}</Money>;
      },
    },
  ];

  useEffect(() => {
    const dataProductOfBill = billInformation?.bill_detail;
    if (dataProductOfBill && hostListRef.current) {
      const data = [...dataProductOfBill, ...hostListRef.current];
      setDataTable(data);
    }
  }, [billInformation]);
  return (
    <div className="qr-check-bill flex">
      <div className="flex w-full justify-center min-heught-700">
        <div className="flex flex-col width-30 min-w-375 bg-white">
          <div className="w-full flex flex-col justify-center items-center text-white background-666687 height-160">
            <div className="btn-open-modal-bill-detail text-14 flex font-regular w-full justify-end pr-20 pl-20 ">
              <Button className="background-666687" onClick={() => setIsModalOpen(true)}>
                <span className="text-white">{cts.BILL_DETAIL}</span>
              </Button>
            </div>

            <div className="text-14 font-regular flex">
              <div className="px-2">{cts.TOTAL}</div>
            </div>
            <span className="text-36 font-bold">¥{tableInfor?.total_payment}</span>
          </div>

          <div className="w-full flex flex-col px-4 border-b border-dashed py-10">
            <span className="text-14 font-regular text-8C8C8C ">Table</span>
            <div className="flex items-center justify-center text-18 font-medium">
              #{tableInfor?.table_name}
            </div>
          </div>

          <div className="w-full flex flex-col px-4 border-b border-dashed py-10">
            <span className="text-14 font-regular text-8C8C8C ">Date</span>
            <div className="flex items-center justify-center text-18 font-medium">
              {moment(tableInfor?.created_at)?.format("YYYY年MM月DD日")}
            </div>
          </div>
          <div className="w-full flex flex-col px-4 border-b border-dashed py-10">
            <span className="text-14 font-regular text-8C8C8C ">Time</span>
            <div className="flex items-center justify-center text-18 font-medium">
              {tableInfor?.time}
            </div>
          </div>
        </div>
      </div>

      <div className="modal-load-list-product-of-bill">
        <>
          <Modal
            closeIcon={<CloseOutlined onClick={handleCancel} />}
            title={<span className="tile-of-bill-detail text-16 ">{cts.BILL_DETAIL}</span>}
            visible={isModalOpen}
            footer={null}
          >
            <Table
              className="table-load-products-of-bill"
              columns={columns}
              dataSource={dataTable}
              pagination={false}
              size="small"
              footer={() => {
                return (
                  <div className="height-160 flex width-full justify-end pr-40">
                    <div className="flex width-55 justify-between">
                      <div className="font-bold">{cts.TOTAL_PAYMENT}:</div>
                      <div className="font-bold">
                        <Money>{billInformation?.total_payment}</Money>
                      </div>
                    </div>
                  </div>
                );
              }}
              scroll={{
                y: "400px",
              }}
            />
          </Modal>
        </>
      </div>
    </div>
  );
}
