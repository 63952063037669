import { fromJS } from "immutable";
import { createReducer } from "../helpers/createReducer";
import * as cts from "../constants/events/report";
import { SET_SELECTED_SHOP } from "../constants/events/user";

const initialState = fromJS({
  reportDay: {
    data: [],
    date: null,
  },
  reportMonth: {
    data: [],
    shopId: null,
  },
  staffs: {
    data: [],
    date: null,
  },
});

const fetchReportDay = (state, action) => {
  const { params } = action.payload;
  const date = state.getIn(["reportDay", "date"]);

  if (params.date !== date) {
    return state.setIn(["reportDay", "data"], fromJS([]));
  }
  return state;
};
const fetchReportDaySuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("reportDay", fromJS(data));
};

const fetchReportMonthSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("reportMonth", fromJS(data));
};

const fetchWorkingStaffSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("staffs", fromJS(data));
};

const reducer = {
  [SET_SELECTED_SHOP]: () => initialState,
  [cts.FETCH_REPORT_DAY]: fetchReportDay,
  [cts.FETCH_REPORT_DAY_SUCCESS]: fetchReportDaySuccess,
  [cts.FETCH_REPORT_MONTH_SUCCESS]: fetchReportMonthSuccess,
  [cts.FETCH_WORKING_STAFF_SUCCESS]: fetchWorkingStaffSuccess,
  __default__: (state) => state,
};

export default createReducer(reducer, initialState);
