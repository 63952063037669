import * as cts from "../constants/events/general";

export const toggleAside = (bool) => ({
  type: cts.TOGGLE_ASIDE,
  payload: bool,
});

export const showLoading = (params) => ({
  type: cts.SHOW_LOADING,
  payload: { params },
});

export const hideLoading = () => ({
  type: cts.HIDE_LOADING,
});

export const showFullScreenLoading = () => ({
  type: cts.SHOW_FULL_SCREEN_LOADING,
});

export const hideFullScreenLoading = () => ({
  type: cts.HIDE_FULL_SCREEN_LOADING,
});

export const showFetchLoading = (params) => ({
  type: cts.SHOW_FETCH_LOADING,
  payload: { params },
});

export const hideFetchLoading = () => ({
  type: cts.HIDE_FETCH_LOADING,
});

export const setBreadcrumb = (params) => ({
  type: cts.SET_BREADCRUMB,
  payload: { params },
});
export const setActiveStepSetup = (params) => ({
  type: cts.SET_ACTIVE_STEP_SETUP,
  payload: { params },
});
export const showAlert = (body, type = "success", time = 2500) => ({
  type: cts.SHOW_ALERT,
  payload: { body, type, time },
});
