import memoize from "fast-memoize";

const isActionDone = (state) => state.getIn(["setMenu", "isActionDone"]);
const setMenu = (state) => state.getIn(["setMenu", "setMenu"]).toJS();

const customerTypes = (state) => state.getIn(["setMenu", "customerTypes"]).toJS();
// const isCustomerDone = (state) => state.getIn(["setMenu", "isCustomerDone"]);

export const isActionDoneSelector = memoize(isActionDone);
export const setMenuSelector = memoize(setMenu);
export const customerTypesSelector = memoize(customerTypes);
// export const isCustomerDoneSelector = memoize(isCustomerDone);
