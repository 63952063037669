/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable no-return-await */
/* eslint-disable no-lonely-if */
/* eslint-disable indent */
/* eslint-disable prefer-template */
/* eslint-disable import/extensions */
/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, DatePicker, Input, message, Modal, Radio, Select } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import * as cts from "../constants/ui/general";
import {
  convertDate2,
  getMonthAndYear2,
  PDFConvertor,
  XLSXConvertor,
} from "../helpers/functionHelpers";

const ExportModal = (props) => {
  const {
    showExport,
    setShowExport,
    selectedDate,
    setSelectedDate,
    exportInfo,
    setExportInfo,
    timeTabs,
    getExportData,
    isOptional,
    exportLoading,
    setExportLoading,
    fromReport,
    plainOptions,
    fromSalary,
    landscapeMode,
    setResetState,
  } = props;

  const [data, setData] = useState([]);

  const onChange = (list) => {
    setExportInfo({
      ...exportInfo,
      checkedList: list,
      indeterminate: !!list.length && list.length < plainOptions?.length,
      checkAll: list.length === plainOptions?.length,
    });
  };

  const onCheckAllChange = (e) => {
    setExportInfo({
      ...exportInfo,
      checkedList: e.target.checked ? plainOptions?.map((item) => item.value) : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  const _renderDateFormat = () => {
    let format = "";
    if (fromSalary) {
      format = "YYYY/MM";
    } else {
      format =
        exportInfo.dateType === `${cts.DAY}`
          ? "YYYY/MM/DD"
          : exportInfo.dateType === `${cts.MONTH}`
          ? "YYYY/MM"
          : "YYYY";
    }
    return format;
  };

  const _renderPicker = () => {
    let picker = "";
    if (fromSalary) {
      picker = "month";
    } else {
      picker =
        exportInfo.dateType === `${cts.DAY}`
          ? "date"
          : exportInfo.dateType === `${cts.MONTH}`
          ? "month"
          : "year";
    }
    return picker;
  };

  const pdfGeneration = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight * 0.85);
      pdf.save(`${exportInfo.name}.pdf`);
    });
  };

  useEffect(() => {
    if (fromSalary === "salaryIndividual") {
      setData(getExportData());
    }
  }, [exportInfo.type]);

  const otherArr = data[data?.length - 2];
  const lastArr = data[data?.length - 1];

  const onExport = async () => {
    let data2 = [];
    if (
      exportInfo.name === "" ||
      exportInfo.exportType === "" ||
      exportInfo.checkedList.length === 0
    ) {
      message.warn(cts.EXPORT_REQUIRED);
      return;
    } else {
      if (exportInfo.type !== "pdf") {
        data2 = await getExportData();
        // CSVConvertor(data, plainOptions, setExportInfo, exportInfo, setExportLoading);
        await XLSXConvertor(data2, exportInfo, setExportInfo, setExportLoading, setShowExport);
        // setResetState(true);
        // message.success(ctsSuccess.EXPORT_SUCCESS_MESSAGE);
      } else {
        if (fromSalary === "salaryIndividual") {
          return pdfGeneration();
        }
        data2 = await getExportData();
        await PDFConvertor(
          data2,
          setExportInfo,
          exportInfo,
          setExportLoading,
          plainOptions,
          setShowExport,
          landscapeMode,
          fromSalary
        );
        // setResetState(true);
        // message.success(ctsSuccess.EXPORT_SUCCESS_MESSAGE);
      }
    }
  };

  const _renderFileType = () => {
    const types = [
      // { label: "CSV", value: "csv" },
      { label: "XLSX", value: "xlsx" },
      { label: "PDF", value: "pdf" },
    ];
    return types.map((item) => {
      return (
        <Radio.Group
          key={item.label}
          onChange={(e) => {
            setExportInfo({ ...exportInfo, type: e.target.value });
          }}
          value={exportInfo.type}
        >
          <Radio value={item.value}>{item.label}</Radio>
        </Radio.Group>
      );
    });
  };

  const _renderTableSalary = (props = {}) => {
    const {
      header,
      inputData,
      height = "360px",
      width = "23%",
      borderWidth = "1px",
      borderColor = "#b3c5e6",
      backgroundColor = "#d8e2f1",
      marginTop = "10px",
      headerHeight = "40px",
      headerWidth,
      bodyHeight = "280px",
      bodyWidth,
      leftColumnWidth = "50%",
      leftColumnHeight,
      leftColumnPadding,
      leftColumnMargin,
      leftColumnTextAlign,
      rightColumnWidth = "50%",
      rightColumnHeight,
      rightColumnPadding,
      rightColumnMargin,
      rightColumnTextAlign,
      footerHeight = "40px",
      footerWidth,
      noFooter,
      letterSpacing = "5px",
    } = props;

    const total = inputData.length > 0 ? inputData[inputData.length - 1] : null;
    return (
      <div
        style={{
          marginTop: marginTop,
          height: height,
          width: width,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: headerHeight,
            border: `${borderWidth} solid ${borderColor}`,
            backgroundColor: backgroundColor,
            borderRadius: "5px 5px 0 0",
            color: "#365b99",
            fontWeight: "bold",
            letterSpacing: letterSpacing,
          }}
        >
          {header}
        </div>
        <div
          style={{
            borderLeft: `${borderWidth} solid ${borderColor}`,
            borderRight: `${borderWidth} solid ${borderColor}`,
            height: bodyHeight,
            display: "flex",
            flexDirection: "row",
            borderBottom: noFooter ? `${borderWidth} solid ${borderColor}` : null,
            borderRadius: `${noFooter ? "0 0 5px 5px" : "0px"}`,
          }}
        >
          <div
            style={{
              borderRight: `${borderWidth} solid ${borderColor}`,
              display: "flex",
              flexDirection: "column",
              width: leftColumnWidth,
              backgroundColor: backgroundColor,
            }}
          >
            {inputData.length > 0
              ? inputData.slice(0, inputData.length - 1).map((item, index) => {
                  return (
                    <span
                      key={index.toString()}
                      style={{ padding: "3px", whiteSpace: "no-wrap", color: "365b99" }}
                    >
                      {item?.label}
                    </span>
                  );
                })
              : null}
          </div>
          <div style={{ width: rightColumnWidth, display: "flex", flexDirection: "column" }}>
            {inputData.length > 0
              ? inputData.slice(0, inputData.length - 1).map((item, index) => {
                  return (
                    <span key={index.toString()} style={{ padding: "3px", textAlign: "end" }}>
                      {item?.value}
                    </span>
                  );
                })
              : null}
          </div>
        </div>
        {!noFooter ? (
          <div
            style={{
              height: footerHeight,
              display: "flex",
              flexDirection: "row",
              border: `${borderWidth} solid ${borderColor}`,
              borderRadius: "0 0 5px 5px",
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                borderRight: `${borderWidth} solid ${borderColor}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: leftColumnWidth,
                backgroundColor: backgroundColor,
              }}
            >
              <span
                style={{
                  paddingLeft: "3px",
                  display: "flex",
                  justifyContent: "flex-start",
                  backgroundColor: backgroundColor,
                  color: "#365b99",
                  letterSpacing: letterSpacing,
                }}
              >
                {total?.label}
              </span>
            </div>
            <div
              style={{
                width: rightColumnWidth,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "row",
                marginBottom: "1px",
              }}
            >
              <span style={{ paddingRight: "3px", textAlign: "end" }}>{total?.value}</span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const _renderOptionals = () => {
    if (!fromSalary) {
      return (
        <div className="input-group">
          <h5>
            {cts.EXPORT_TYPE} <span style={{ color: "red" }}>*</span>
          </h5>
          <Select
            style={{ width: "100%" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            value={exportInfo.exportType}
            onChange={(value) => {
              setExportInfo({ ...exportInfo, exportType: value });
            }}
          >
            {timeTabs.map((item) => {
              return (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      );
    }
    if (fromSalary === "salaryIndividual") {
      return (
        <>
          <div className="input-group">
            <h5>{cts.PAYMENT_DATE}</h5>
            <DatePicker
              style={{ width: "100%" }}
              allowClear={false}
              picker="date"
              format="YYYY/MM/DD"
              value={moment(exportInfo?.paymentDate)}
              onChange={(date) => {
                setExportInfo({ ...exportInfo, paymentDate: date });
              }}
              dropdownAlign={{ points: ["bc", "bc"] }}
            />
          </div>
          <div className="input-group">
            <h5>
              {cts.TITLE} <span style={{ color: "red" }}>*</span>
            </h5>
            <Select
              style={{ width: "100%" }}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              value={exportInfo?.title}
              onChange={(e) => {
                setExportInfo({ ...exportInfo, title: e });
              }}
            >
              {timeTabs.map((item) => {
                return (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </>
      );
    }
    return null;
  };
  return (
    <Modal
      title={cts.EXPORT_FILE}
      visible={showExport}
      centered
      confirmLoading={exportLoading}
      onOk={() => onExport()}
      onCancel={() => setShowExport(false)}
      closable={false}
      afterClose={() => setExportLoading(null)}
      okText={cts.EXPORT}
    >
      <div className="export-wrapper" style={{ position: "relative", overflow: "hidden" }}>
        <div className="input-group" style={{ display: "flex", flexDirection: "row" }}>
          <h5>
            {cts.FILE_TYPE} <span style={{ color: "red", marginRight: 20 }}>*</span>
          </h5>
          <div className="radio">{_renderFileType()}</div>
        </div>
        <div className="input-group">
          <h5>
            {cts.FILE_NAME} <span style={{ color: "red" }}>*</span>
          </h5>
          <Input
            placeholder={cts.FILE_NAME_PLACEHOLDER}
            suffix={`.${exportInfo.type}`}
            value={exportInfo.name}
            onChange={(e) => {
              setExportInfo({
                ...exportInfo,
                name: e.target.value.replace(/[`~';"!@#$^&%*()+=/{}|:<>?,.]/g, ""),
              });
            }}
          />
        </div>
        {isOptional && (
          <>
            <div className="input-group">
              <h5>{cts.DAY}</h5>
              <DatePicker
                style={{ width: "100%" }}
                allowClear={false}
                picker={_renderPicker()}
                format={_renderDateFormat()}
                value={moment(selectedDate)}
                onChange={(date) => {
                  setSelectedDate(date.format("YYYY-MM-DD"));
                  setExportInfo({ ...exportInfo, date: date.format("YYYY-MM-DD") });
                }}
                dropdownAlign={{ points: ["tc", "bc"] }}
              />
            </div>
            {_renderOptionals()}
          </>
        )}
        {plainOptions && plainOptions.length > 0 && fromSalary !== "salaryIndividual" ? (
          <div className="input-group">
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
              <h5>
                {cts.OPTIONS} <span style={{ color: "red", marginRight: 20 }}>*</span>
              </h5>
              <Checkbox
                indeterminate={exportInfo.indeterminate}
                onChange={onCheckAllChange}
                checked={exportInfo.checkAll}
              >
                {cts.ALL}
              </Checkbox>
            </div>
            <Checkbox.Group
              options={plainOptions}
              value={exportInfo.checkedList}
              onChange={onChange}
            />
          </div>
        ) : null}
        {exportLoading !== 0 && (
          <div className="input-group">
            <p className="loading-title">
              {cts.LOADING} {exportLoading}%
            </p>
            <div className="progress-bar">
              <div
                className="progress-fill"
                style={{
                  width: `${exportLoading}%`,
                  height: 15,
                  borderRadius: 7.5,
                  backgroundColor: "#666687",
                }}
              />
            </div>
          </div>
        )}
        {fromSalary === "salaryIndividual" && exportInfo.type === "pdf" ? (
          <div
            id="divToPrint"
            style={{
              position: "absolute",
              zIndex: -1,
              padding: "15px",
              fontSize: "10px",
              height: "619px",
              width: "712px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  width: "45%",
                  height: "100px",
                  border: "1px solid #b3c5e6",
                  borderRadius: "15px",
                  padding: "10px 15px",
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "12px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>
                    {exportInfo?.title === "reward" ? cts.REWARD : cts.SALARY_RECEIVED}
                    {getMonthAndYear2(exportInfo?.exportDate)}
                  </span>
                  <span>
                    {cts.SHOP_NAME} : {exportInfo?.shopName || cts.NA}
                  </span>
                  <span style={{ color: "#365b99" }}>
                    {cts.STAFF_NAME} : {exportInfo?.host}
                  </span>
                  <span style={{ color: "#365b99" }}>{cts.STAFF_ID} :</span>
                </div>
                <span style={{ color: "#365b99", fontSize: "12px" }}>{cts.S_DETAIL}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  minWidth: "40%",
                  height: "100px",
                  border: "1px solid #b3c5e6",
                  borderRadius: "15px",
                  padding: "10px 15px",
                  fontSize: "12px",
                }}
              >
                <span style={{ marginRight: "30px" }}>
                  {cts.PAYMENT_DATE} : {convertDate2(exportInfo.paymentDate)}
                </span>
                {data.length === 7
                  ? lastArr.map((item, index) => {
                      return (
                        <span key={index.toString()} style={{ marginRight: "30px" }}>
                          {item.label} : {item.value}
                        </span>
                      );
                    })
                  : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                <div
                  style={{
                    width: "80px",
                    height: "80px",
                    border: "1px solid #b3c5e6",
                    borderRadius: "15px",
                    marginBottom: "5px",
                  }}
                />
                <span style={{ textAlign: "end", color: "#365b99" }}>{cts.STAMP}</span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  height: "360px",
                  width: "24%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {data.length === 7
                  ? _renderTableSalary({
                      header: cts.ATTENDANCE,
                      inputData: data[0],
                      noFooter: true,
                      height: "270px",
                      bodyHeight: "230px",
                      width: "100%",
                    })
                  : null}
                <div
                  style={{
                    minHeight: "60px",
                    borderRadius: "5px",
                    border: "1px solid #b3c5e6",
                    fontSize: "10px",
                    marginTop: "30px",
                    color: "356b99",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderRight: "1px solid #b3c5e6",
                    }}
                  >
                    {data.length === 7
                      ? otherArr.map((item, index) => {
                          return (
                            <span
                              key={index.toString()}
                              style={{
                                width: "100%",
                                height: "50%",
                                borderBottom:
                                  index === otherArr.length - 1 ? "" : "1px solid #b3c5e6",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                letterSpacing: "8px",
                                padding: "0 40px",
                                backgroundColor: "#d8e2f1",
                              }}
                            >
                              {item.label}
                            </span>
                          );
                        })
                      : null}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {data.length === 7
                      ? otherArr.map((item, index) => {
                          return (
                            <span
                              key={index.toString()}
                              style={{
                                width: "100%",
                                height: "50%",
                                borderBottom:
                                  index === otherArr.length - 1 ? "" : "1px solid #b3c5e6",
                                textAlign: "end",
                                padding: "0 3px",
                              }}
                            >
                              {item.value}
                            </span>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
              {data.length === 7
                ? _renderTableSalary({
                    header: cts.SUPPLY,
                    inputData: data[1],
                    width: "25%",
                    leftColumnWidth: "55%",
                    rightColumnWidth: "45%",
                  })
                : null}
              {data.length === 7
                ? _renderTableSalary({
                    header: cts.BONUS,
                    inputData: data[2],
                  })
                : null}
              <div
                style={{
                  height: "360px",
                  width: "24%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {data.length === 7
                  ? _renderTableSalary({
                      header: cts.DEDUCTION,
                      inputData: data[3],
                      height: "190px",
                      width: "100%",
                      bodyHeight: "120px",
                    })
                  : null}
                {data.length === 7
                  ? _renderTableSalary({
                      header: cts.TOTAL_SALARY,
                      inputData: data[4],
                      height: "140px",
                      width: "100%",
                      marginTop: "20px",
                      bodyHeight: "70px",
                    })
                  : null}
              </div>
            </div>
            <div
              style={{
                height: "60px",
                border: "1px solid #b3c5e6",
                borderRadius: "5px",
                paddingLeft: "15px",
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              {cts.SALARY_GREETINGS}
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default memo(ExportModal);
