/* eslint-disable import/no-cycle */
import React from "react";
import Loadable from "react-loadable";
import uniqid from "uniqid";
import CategoriesActive from "./assets/images/category-active.png";
import CategoriesImg from "./assets/images/category.png";
import CustomerActive from "./assets/images/customer-active.png";
import CustomerImg from "./assets/images/customer.png";
import HostActive from "./assets/images/host-active.png";
import HostImg from "./assets/images/host.png";
import LogActive from "./assets/images/log-active.png";
import LogImg from "./assets/images/log.png";
import OpenSettingsActiveImg from "./assets/images/open-active.png";
import OpenSettingsImg from "./assets/images/open.png";
import BillListActive from "./assets/images/order-active.png";
import NewBillActive from "./assets/images/order-add-active.png";
import NewBillImg from "./assets/images/order-add.png";
import BillListImg from "./assets/images/order.png";
import ProductsActive from "./assets/images/product-active.png";
import ProductsImg from "./assets/images/product.png";
import ProfitActive from "./assets/images/profit-active.png";
import ProfitImg from "./assets/images/profit.png";
import RankingActive from "./assets/images/ranking-active.png";
import RankingImg from "./assets/images/ranking.png";
import DailyReportActive from "./assets/images/report-active.png";
import DailyReportImg from "./assets/images/report.png";
import SalaryActive from "./assets/images/salary-active.png";
import SalaryImg from "./assets/images/salary.png";
import SettingsActive from "./assets/images/settings-active.png";
import SettingsImg from "./assets/images/settings.png";
import SelectShopActive from "./assets/images/shop-active.png";
import SelectShopImg from "./assets/images/shop.png";
import { IS_SETUP_PAGE, SHOW_SOMETHING } from "./constants";
import * as cts from "./constants/ui/general";
// import { BillListPage, NewBillPage } from "./containers/Bill";
// import { CustomerListPage } from "./containers/Customer";
// import { DailyReportPage } from "./containers/DailyReport";
// import { HostListPage } from "./containers/Host";
// import { LogManagementPage } from "./containers/Log";
// import { CategoriesPage, ProductsPage } from "./containers/Products";
// import { ProfitPage } from "./containers/Profit";
// import { RankingPage } from "./containers/Ranking";
// import { SalarySettingsPage } from "./containers/Salary";
// import { SelectShopPage } from "./containers/SelectShop";
// import { SettingsPage } from "./containers/Settings";
import Loading from "./containers/Bill/components/Loading";
import DailyCalendarPage from "./containers/DailyReportV2/components/DailyCalendarPage";
import NotFoundPage from "./containers/NotFoundPage";
import ShopProfitPage from "./containers/ShopProfit/ShopProfitPage";
// import MyPage from "./containers/Setup/components/mypage/MyPage";

const HomePage = Loadable({
  loader: () => import("./containers/HomePage"),
  loading: Loading,
});

const BillListPage = Loadable({
  loader: () => import("./containers/Bill/BillListPage"),
  loading: Loading,
});

const NewBillPage = Loadable({
  loader: () => import("./containers/Bill/NewBillPage"),
  loading: Loading,
});
const BillGroup = Loadable({
  loader: () => import("./containers/Bill/BillGroup"),
  loading: Loading,
});

const DeletedBillPage = Loadable({
  loader: () => import("./containers/Bill/DeletedBillPage"),
  loading: Loading,
});

const AllBillList = Loadable({
  loader: () => import("./containers/Bill/AllBillList"),
  loading: Loading,
});

// const CustomerListPage = Loadable({
//   loader: () => import("./containers/Customer/CustomerListPage"),
//   loading: Loading,
// });

const CustomerListPage = Loadable({
  loader: () => import("./containers/Customer/CustomerListPage"),
  loading: Loading,
});

const CustomerDetail = Loadable({
  loader: () => import("./containers/Customer/components/CustomerDetail"),
  loading: Loading,
});

// const DailyReportPage = Loadable({
//   loader: () => import("./containers/DailyReport/DailyReportPage"),
//   loading: Loading,
// });

const DailyReportPage = Loadable({
  loader: () => import("./containers/DailyReportV2/DailyReportPage"),
  loading: Loading,
});

const DailyReportDetail = Loadable({
  loader: () => import("./containers/DailyReport/DailyReportDetail"),
  loading: Loading,
});

const BillPaidPage = Loadable({
  loader: () => import("./containers/DailyReport/BillPaidList"),
  loading: Loading,
});

const HostListPage = Loadable({
  loader: () => import("./containers/Host/HostListPage"),
  loading: Loading,
});

const HostListDetail = Loadable({
  loader: () => import("./containers/Host/components/HostListDetail"),
  loading: Loading,
});

const SalaryListPage = Loadable({
  loader: () => import("./containers/Salary/NewSalaryListPage"),
  loading: Loading,
});

const LogManagementPage = Loadable({
  loader: () => import("./containers/Log/LogManagementPage"),
  loading: Loading,
});

const CategoriesPage = Loadable({
  loader: () => import("./containers/Products/CategoriesPage"),
  loading: Loading,
});

const ProductsPage = Loadable({
  loader: () => import("./containers/Products/ProductsPage"),
  loading: Loading,
});

const ProfitPage = Loadable({
  loader: () => import("./containers/Profit/ProfitPage"),
  loading: Loading,
});

const ProfitTimeDetailPage = Loadable({
  loader: () => import("./containers/Profit/ProfitTimeDetail"),
  loading: Loading,
});

const ProfitHostDetailPage = Loadable({
  loader: () => import("./containers/Profit/ProfitHostDetail"),
  loading: Loading,
});

const ProfitProductDetailPage = Loadable({
  loader: () => import("./containers/Profit/ProfitProductDetail"),
  loading: Loading,
});

const RankingPage = Loadable({
  loader: () => import("./containers/Ranking/RankingPage"),
  loading: Loading,
});

const SalarySettingsPage = Loadable({
  loader: () => import("./containers/Salary/SalarySettingsPage"),
  loading: Loading,
});

const SelectShopPage = Loadable({
  loader: () => import("./containers/SelectShop/SelectShopPage"),
  loading: Loading,
});

const SettingsPage = Loadable({
  loader: () => import("./containers/Settings/SettingsPage"),
  loading: Loading,
});

const MyPage = Loadable({
  loader: () => import("./containers/Setup/components/mypage/MyPage"),
  loading: Loading,
});

const SetupPage = Loadable({
  loader: () => import("./containers/Setup/SetupPage"),
  loading: Loading,
});

// const SetupPage = Loadable({
//   loader: () => import("./containers/SetupV2/SetupPage"),
//   loading: Loading,
// });

const OpenSettingsPage = Loadable({
  loader: () => import("./containers/OpenSettings/OpenSettingsPage"),
  loading: Loading,
});

// demo
const DepositDetail = Loadable({
  loader: () => import("./containers/DailyReportV2/components/DepositDetail"),
  loading: Loading,
});
// end

// const OpenSettingsPage = Loadable({
//   loader: () => import("./containers/OpenSettingsV2/OpenSettingsPage"),
//   loading: Loading,
// });

const HistorySettingsPage = Loadable({
  loader: () => import("./containers/OpenSettings/HistorySettingsPage"),
  loading: Loading,
});

export const SetMenuPage = Loadable({
  loader: () => import("./containers/SetMenu/SetMenuPage"),
  loading: Loading,
});

const commons = [
  {
    path: "/",
    exact: true,
    name: "home",
    main: ({ history }) => <HomePage history={history} />,
  },
  {
    path: "/select-shop",
    exact: true,
    name: cts.SELECT_SHOP,
    icon: SelectShopImg,
    activeIcon: SelectShopActive,
    main: ({ history }) => <SelectShopPage history={history} />,
  },
  {
    path: "/shop-profit",
    exact: true,
    name: cts.SHOP_PROFIT,
    main: ({ history }) => <ShopProfitPage history={history} />,
  },
  {
    exact: true,
    path: "/deposit",
    name: cts.DEPOSIT,
    main: ({ history }) => <DepositDetail history={history} />,
  },
];

const mainRoutes = [
  {
    id: uniqid(),
    path: "/open-settings",
    exact: true,
    name: cts.OPEN_SETTINGS,
    icon: OpenSettingsImg,
    activeIcon: OpenSettingsActiveImg,
    main: ({ history }) => <OpenSettingsPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/open-settings/history",
    exact: true,
    name: cts.HISTORY_SETTINGS,
    main: ({ history }) => <HistorySettingsPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/bills",
    exact: true,
    name: cts.BILL_LIST,
    icon: BillListImg,
    activeIcon: BillListActive,
    main: ({ history }) => <BillListPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/bills/group-bill",
    exact: true,
    name: cts.BILL_LIST,
    icon: BillListImg,
    activeIcon: BillListActive,
    main: ({ history }) => <BillGroup history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/bills/new-bill",
    exact: true,
    name: cts.ADD_NEW_BILL,
    icon: NewBillImg,
    activeIcon: NewBillActive,
    main: ({ history }) => <NewBillPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/bills/all-bill",
    exact: true,
    name: cts.ALL_BILL,
    main: ({ history }) => <AllBillList history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/bills/deleted-bill",
    exact: true,
    name: cts.DELETED_BILL_LIST,
    main: ({ history }) => <DeletedBillPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/profit",
    exact: true,
    name: cts.PROFIT,
    icon: ProfitImg,
    activeIcon: ProfitActive,
    main: ({ history }) => <ProfitPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: `/profit/time-detail/:id`,
    exact: true,
    name: cts.PROFIT_TIME_DETAIL,
    main: ({ history }) => <ProfitTimeDetailPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: `/profit/host-detail`,
    exact: true,
    name: cts.PROFIT_HOST_DETAIL,
    main: ({ history }) => <ProfitHostDetailPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: `/profit/product-detail`,
    exact: true,
    name: cts.PROFIT_PRODUCT_DETAIL,
    main: ({ history }) => <ProfitProductDetailPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/daily-report",
    exact: true,
    name: cts.DAILY_REPORT,
    icon: DailyReportImg,
    activeIcon: DailyReportActive,
    main: ({ history }) => <DailyReportPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/daily-report-calendar",
    exact: true,
    name: cts.DAILY_REPORT,
    icon: DailyReportImg,
    activeIcon: DailyReportActive,
    main: ({ history }) => <DailyCalendarPage history={history} />,
    hide: true,
  },

  {
    id: uniqid(),
    path: "/daily-report/detail",
    exact: true,
    name: cts.DAILY_REPORT_DETAIL,
    main: ({ history }) => <DailyReportDetail history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/daily-report/bill-paid",
    exact: true,
    name: cts.BILL_PAID_PAGE,
    main: ({ history }) => <BillPaidPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/ranking",
    exact: true,
    name: cts.RANKING,
    icon: RankingImg,
    activeIcon: RankingActive,
    main: ({ history }) => <RankingPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/hosts",
    exact: true,
    name: cts.STAFF_LIST,
    icon: HostImg,
    activeIcon: HostActive,
    main: ({ history }) => <HostListPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: `/hosts/detail/:id`,
    exact: true,
    name: cts.HOST_LIST_DETAIL,
    main: ({ history }) => <HostListDetail history={history} />,
    hide: true,
  },
  // {
  //   id: uniqid(),
  //   path: `/salary`,
  //   exact: true,
  //   name: cts.SALARY,
  //   icon: SalaryImg,
  //   activeIcon: SalaryActive,
  //   main: ({ history }) => <SalaryListPage history={history} />,
  // },
  {
    id: uniqid(),
    path: `/salary-settings`,
    exact: true,
    name: cts.SALARY_SETTINGS,
    main: ({ history }) => <SalarySettingsPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/customers",
    exact: true,
    name: cts.CUSTOMER_LIST,
    icon: CustomerImg,
    activeIcon: CustomerActive,
    main: ({ history }) => <CustomerListPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: `/customers/detail/:id`,
    exact: true,
    name: cts.CUSTOMER_DETAIL,
    main: ({ history }) => <CustomerDetail history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/categories",
    exact: true,
    name: cts.CATEGORIES,
    icon: CategoriesImg,
    activeIcon: CategoriesActive,
    main: ({ history }) => <CategoriesPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/products",
    exact: true,
    name: cts.PRODUCTS,
    icon: ProductsImg,
    activeIcon: ProductsActive,
    main: ({ history }) => <ProductsPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/log",
    exact: true,
    name: cts.LOG_MANAGEMENT,
    icon: LogImg,
    activeIcon: LogActive,
    main: ({ history }) => <LogManagementPage history={history} />,
    hide: true,
  },
  // {
  //   id: uniqid(),
  //   path: "/salary-settings",
  //   exact: true,
  //   name: cts.SALARY_SETTINGS,
  //   icon: SalaryImg,
  //   activeIcon: SalaryActive,
  //   main: ({ history }) => <SalarySettingsPage history={history} />,
  // },
  {
    id: uniqid(),
    path: "/settings",
    exact: true,
    name: cts.SETTINGS,
    icon: SettingsImg,
    activeIcon: SettingsActive,
    main: ({ history }) => <SettingsPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/categories/set-menu",
    exact: true,
    name: cts.SET_MENU,
    hide: true,
    main: ({ history }) => <SetMenuPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/account-settings",
    exact: true,
    name: cts.SET_MENU,
    hide: true,
    main: ({ history }) => <MyPage history={history} />,
  },
];

const setupRoute = [
  {
    id: uniqid(),
    path: "/setup",
    exact: true,
    name: cts.SETUP,
    icon: SettingsImg,
    activeIcon: SettingsActive,
    main: ({ history }) => <SetupPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/categories/set-menu",
    exact: true,
    name: cts.SET_MENU,
    hide: true,
    main: ({ history }) => <SetMenuPage history={history} />,
  },
];

const back_office_routes = [
  {
    id: uniqid(),
    path: "/open-settings",
    exact: true,
    name: cts.OPEN_SETTINGS,
    icon: OpenSettingsImg,
    activeIcon: OpenSettingsActiveImg,
    main: ({ history }) => <OpenSettingsPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/bills",
    exact: true,
    name: cts.BILL_LIST,
    icon: BillListImg,
    activeIcon: BillListActive,
    hide: false,
    main: ({ history }) => <BillListPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/bills/new-bill",
    exact: true,
    name: cts.ADD_NEW_BILL,
    icon: NewBillImg,
    activeIcon: NewBillActive,
    main: ({ history }) => <NewBillPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/bills/all-bill",
    exact: true,
    name: cts.ALL_BILL,
    main: ({ history }) => <AllBillList history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/profit",
    exact: true,
    name: cts.PROFIT,
    icon: ProfitImg,
    activeIcon: ProfitActive,
    main: ({ history }) => <ProfitPage history={history} />,
  },
  {
    id: uniqid(),
    path: `/profit/time-detail/:id`,
    exact: true,
    name: cts.PROFIT_TIME_DETAIL,
    main: ({ history }) => <ProfitTimeDetailPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: `/profit/host-detail`,
    exact: true,
    name: cts.PROFIT_HOST_DETAIL,
    main: ({ history }) => <ProfitHostDetailPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: `/profit/product-detail`,
    exact: true,
    name: cts.PROFIT_PRODUCT_DETAIL,
    main: ({ history }) => <ProfitProductDetailPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/daily-report",
    exact: true,
    name: cts.DAILY_REPORT,
    icon: DailyReportImg,
    activeIcon: DailyReportActive,
    main: ({ history }) => <DailyReportPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/daily-report/detail",
    exact: true,
    name: cts.DAILY_REPORT_DETAIL,
    main: ({ history }) => <DailyReportDetail history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/daily-report/bill-paid",
    exact: true,
    name: cts.BILL_PAID_PAGE,
    main: ({ history }) => <BillPaidPage history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/ranking",
    exact: true,
    name: cts.RANKING,
    icon: RankingImg,
    activeIcon: RankingActive,
    main: ({ history }) => <RankingPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/hosts",
    exact: true,
    name: cts.STAFF_LIST,
    icon: HostImg,
    activeIcon: HostActive,
    main: ({ history }) => <HostListPage history={history} />,
  },
  {
    id: uniqid(),
    path: `/hosts/detail/:id`,
    exact: true,
    name: cts.HOST_LIST_DETAIL,
    main: ({ history }) => <HostListDetail history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/customers",
    exact: true,
    name: cts.CUSTOMER_LIST,
    icon: CustomerImg,
    activeIcon: CustomerActive,
    main: ({ history }) => <CustomerListPage history={history} />,
  },
  {
    id: uniqid(),
    path: `/customers/detail/:id`,
    exact: true,
    name: cts.CUSTOMER_DETAIL,
    main: ({ history }) => <CustomerDetail history={history} />,
    hide: true,
  },
  {
    id: uniqid(),
    path: "/categories",
    exact: true,
    name: cts.CATEGORIES,
    icon: CategoriesImg,
    activeIcon: CategoriesActive,
    main: ({ history }) => <CategoriesPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/products",
    exact: true,
    name: cts.PRODUCTS,
    icon: ProductsImg,
    activeIcon: ProductsActive,
    main: ({ history }) => <ProductsPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/categories/set-menu",
    exact: true,
    name: cts.SET_MENU,
    hide: true,
    main: ({ history }) => <SetMenuPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/log",
    exact: true,
    name: cts.LOG_MANAGEMENT,
    icon: LogImg,
    activeIcon: LogActive,
    main: ({ history }) => <LogManagementPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/settings",
    exact: true,
    name: cts.SETTINGS,
    icon: SettingsImg,
    activeIcon: SettingsActive,
    main: ({ history }) => <SettingsPage history={history} />,
  },
];

const another_routes = [
  {
    id: uniqid(),
    path: "/categories",
    exact: true,
    name: cts.CATEGORIES,
    icon: CategoriesImg,
    activeIcon: CategoriesActive,
    main: ({ history }) => <CategoriesPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/products",
    exact: true,
    name: cts.PRODUCTS,
    icon: ProductsImg,
    activeIcon: ProductsActive,
    main: ({ history }) => <ProductsPage history={history} />,
  },
  {
    id: uniqid(),
    path: "/categories/set-menu",
    exact: true,
    name: cts.SET_MENU,
    hide: true,
    main: ({ history }) => <SetMenuPage history={history} />,
  },
];

const checkMainRoutes = SHOW_SOMETHING ? mainRoutes : another_routes;

const whichRoutes = IS_SETUP_PAGE ? setupRoute : checkMainRoutes;

export const routes = [
  ...commons,
  ...whichRoutes,
  {
    id: uniqid(),
    path: "*",
    exact: false,
    main: () => <NotFoundPage />,
  },
];
