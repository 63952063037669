import axios from "axios";
import Cookies from "js-cookie";
import { API_ENDPOINT } from "../constants";

const service = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 30000,
});

service.interceptors.request.use(
  (config) => {
    config.data = config.data || [];

    config.params = config.params || {};

    config.headers["Content-Type"] = "application/json";

    if (!config.noNeedToken) {
      config.headers.Authorization = `Bearer ${config.token}`;
    }

    if (config.isFormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove("POS_WEB_TOKEN");
      localStorage.removeItem("POS_WEB_SELECTED_SHOP");
      localStorage.removeItem("POS_WEB_USER_ID");
      window.location = "/";
    } else {
      return Promise.reject(error);
    }
  }
);

// const service = (option) => {
//   const config = {
//     url: `${API_ENDPOINT}/${option.url}`,
//     method: option.method,
//     timeout: 30000,
//   };

//   if (!option.noNeedToken) {
//     config.headers = {
//       Authorization: `Bearer ${option.token}`,
//       "Content-Type": option.isFormData ? "multipart/form-data" : "application/json",
//     };
//   }

//   if (option.data) {
//     config.data = option.data;
//   }

//   if (option.params) {
//     config.params = option.params;
//   }

//   return axios(config);
// };

export default service;
