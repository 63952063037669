import * as cts from "../constants/events/ads";

export const deleteAds = (id) => ({
  type: cts.DELETE_ADS,
  payload: { id },
});

export const deleteAdsSuccess = (data) => ({
  type: cts.DELETE_ADS_SUCCESS,
  payload: { data },
});

// Nhánh của quảng cáo

export const fetchAdsCustomer = (params) => ({
  type: cts.FETCH_ADS_CUSTOMER,
  payload: { params },
});

export const fetchAdsCustomerSuccess = (data) => ({
  type: cts.FETCH_ADS_CUSTOMER_SUCCESS,
  payload: { data },
});
