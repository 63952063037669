import { fromJS } from "immutable";
import { createReducer } from "../helpers/createReducer";
import * as genCts from "../constants/events/general";
// import * as errCts from "../constants/ui/error";

const initialState = fromJS({
  isShowSidebar: false,
  loading: false,
  fullScreenLoading: false,
  fetchLoading: "",
  alertContent: {
    body: "",
    time: 2500,
    type: "",
    show: false,
    id: 0,
  },
  breadcrumb: {},
  activeStepSetup: {},
});

const showAlert = (state, { payload: { body, type, time } }) => {
  return state.set("alertContent", fromJS({ body, time, type, show: true }));
};

const hideAlert = (state) => state.setIn(["alertContent", "show"], false);

const showLoading = (state, action) => state.set("loading", action?.payload?.params || true);

const hideLoading = (state) => state.set("loading", false);

const showFullScreenLoading = (state) => state.set("fullScreenLoading", true);

const hideFullScreenLoading = (state) => state.set("fullScreenLoading", false);

const showFetchLoading = (state, action) =>
  state.set("fetchLoading", action?.payload?.params || true);

const hideFetchLoading = (state) => state.set("fetchLoading", false);

const toggleAside = (state, action) => {
  const bool = action.payload;
  return state.set("isShowSidebar", fromJS(bool));
};

const setBreadcrumb = (state, action) => {
  const { params } = action.payload;

  return state.set("breadcrumb", fromJS(params));
  // return state.set("breadcrumb", fromJS({ id: "settings", name: "Settings" }));
};
const setActiveStepSetup = (state, action) => {
  const { params } = action.payload;
  return state.set("activeStepSetup", fromJS(params));
};
const reducer = {
  [genCts.SHOW_LOADING]: showLoading,
  [genCts.HIDE_LOADING]: hideLoading,
  [genCts.SHOW_FULL_SCREEN_LOADING]: showFullScreenLoading,
  [genCts.HIDE_FULL_SCREEN_LOADING]: hideFullScreenLoading,
  [genCts.SHOW_FETCH_LOADING]: showFetchLoading,
  [genCts.HIDE_FETCH_LOADING]: hideFetchLoading,
  [genCts.TOGGLE_ASIDE]: toggleAside,
  [genCts.SET_BREADCRUMB]: setBreadcrumb,
  [genCts.SHOW_ALERT]: showAlert,
  [genCts.HIDE_ALERT]: hideAlert,
  [genCts.SET_ACTIVE_STEP_SETUP]: setActiveStepSetup,
  __default__: (state) => state,
};

export default createReducer(reducer, initialState);
