import request from "../helpers/axiosService";

const URL_BILL = "bills";

const URL_BILL_DETAIL = "bill-detail";
const URL_SEARCH_BILL = "search-bills";
const URL_ALL_BILL = "/bill-list-by-shop";
const URL_DELETED_BILLS_BY_SHOP = "/bill-deleted-by-shop";
const URL_CHECK_FIRST_ORDER = "/first-order";
const URL_DELETED_BILL_PERMANENTLY = "/delete-bill-forever/1";
const URL_GET_BILL = "/bills-table-detail";

export const getBillDetailAPI = (params) => {
  return request({
    url: URL_GET_BILL,
    method: "get",
    params,
  });
};

export const fetchBillListAPI = (token, shopId, status, page = 1, date) => {
  return request({
    url: URL_BILL,
    method: "get",
    params: { shopId, status, page, per_page: status === 3 ? 200 : 15, date, group_get: 1 },
    token,
  });
};

export const fetchBillByID = (token, billId) => {
  return request({
    url: `${URL_BILL}/${billId}`,
    method: "get",
    token,
  });
};

export function fetchDeletedBillByShop(token, params) {
  return request({
    url: URL_DELETED_BILLS_BY_SHOP,
    method: "get",
    params,
    token,
  });
}

export const searchBillAPI = (token, shopId, status, page = 1, code) => {
  return request({
    url: URL_SEARCH_BILL,
    method: "get",
    params: { shopId, status, page, code, per_page: status === 3 ? 200 : 15 },
    token,
  });
};

export const deleteBillAPI = (token, billId, shopId) => {
  return request({
    url: `${URL_BILL}/${billId}`,
    method: "delete",
    params: { shopId },
    token,
  });
};

export const createBillAPI = (token, data) => {
  return request({
    url: URL_BILL,
    method: "post",
    data,
    token,
  });
};

export function updateBillAPI(token, id, data) {
  return request({
    url: `${URL_BILL}/${id}`,
    method: "put",
    data,
    token,
  });
}

export function fetchProductInBillAPI(token, bill_id) {
  return request({
    url: URL_BILL_DETAIL,
    method: "get",
    params: { bill_id },
    token,
  });
}

export function addProductInBillAPI(token, data) {
  return request({
    url: URL_BILL_DETAIL,
    method: "post",
    data,
    token,
  });
}

export function updateProductInBillAPI(token, id, data) {
  return request({
    url: `${URL_BILL_DETAIL}/${id}`,
    method: "put",
    data,
    token,
  });
}

export function deleteProductInBillAPI(token, id, params) {
  return request({
    url: `${URL_BILL_DETAIL}/${id}`,
    method: "delete",
    params,
    token,
  });
}

export function emptyBillAPI(token, id, bill_id) {
  return request({
    url: `${URL_BILL_DETAIL}/${id}`,
    method: "delete",
    params: { bill_id, delete_all: 1 },
    token,
  });
}

export function fetchAllBillListAPI(token, params) {
  return request({
    url: `${URL_ALL_BILL}`,
    method: "get",
    params,
    token,
  });
}

export function removeDeletedBillAPI(token, data) {
  return request({
    url: `${URL_DELETED_BILL_PERMANENTLY}`,
    method: "delete",
    data,
    token,
  });
}
export function checkFirstOrder(token, params) {
  return request({
    url: URL_CHECK_FIRST_ORDER,
    method: "get",
    params,
    token,
  });
}
export function searchGeneral(token, params) {
  return request({
    url: URL_BILL,
    method: "get",
    params,
    token,
  });
}
