import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AppContainer } from "react-hot-loader";
import { ConfigProvider } from "antd";
import jaJP from "antd/lib/locale/ja_JP";

import "moment/locale/ja";

import App from "./App";

import configureStore from "./helpers/configureStore";

if (process.env.NODE_ENV === "production") {
}

const store = configureStore();

const render = () => {
  ReactDOM.render(
    <ConfigProvider locale={jaJP}>
      <AppContainer>
        <Provider store={store}>
          <App />
        </Provider>
      </AppContainer>
    </ConfigProvider>,
    document.getElementById("root")
  );
};

render();

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept("./App", () => {
    render();
  });
}
