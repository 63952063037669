/* eslint-disable no-unused-vars */
import request from "../helpers/axiosService";

// const URL_PROFIT = "api/profit";
// const URL_PROFIT_TIME = "api/profit-time";
// const URL_PROFIT_HOST = "api/profit-host";
// const URL_PROFIT_PRODUCT = "api/profit-product";
// const URL_PROFIT_LIST_TIME = "api/profit-list-time";

const URL_PROFIT = "v2/profit";
const URL_PROFIT_TIME = "v2/profit/time-detail";
const URL_PROFIT_HOST = "v2/profit/host-detail";
const URL_PROFIT_PRODUCT = "profit/product-detail";
const URL_PROFIT_LIST_TIME = "profit-list-time";
const URL_GET_PROFIT_SHOP = "/shop/sales";

export function fetchProfitAPI(token, params) {
  return request({
    url: URL_PROFIT,
    method: "get",
    params,
    token,
  });
}

export function fetchProfitTimeAPI(token, params) {
  return request({
    url: URL_PROFIT_TIME,
    method: "get",
    params: { ...params },
    token,
  });
}

export function fetchProfitHostAPI(token, params) {
  return request({
    url: URL_PROFIT_HOST,
    method: "get",
    params: { ...params },
    token,
  });
}

export function fetchProfitProductAPI(token, params) {
  return request({
    url: URL_PROFIT_PRODUCT,
    method: "get",
    params: { ...params },
    token,
  });
}

export function fetchProfitListTimeAPI(token, params) {
  return request({
    url: URL_PROFIT_LIST_TIME,
    method: "get",
    params,
    token,
  });
}

export function fetchProfitShopAPI(token, params) {
  return request({
    url: URL_GET_PROFIT_SHOP,
    method: "get",
    params: { ...params, role: 4 },
    token,
  });
}
