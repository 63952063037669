/* eslint-disable object-shorthand */
/* eslint-disable no-else-return */
import { put, takeEvery, call, select, takeLatest } from "redux-saga/effects";
import { hideFetchLoading, showFetchLoading, hideLoading, showLoading } from "../actions/general";
import * as apis from "../apis/host";
import { failedToast, successToast } from "../helpers/AlertHelper";
import * as cts from "../constants/events/host";
import * as act from "../actions/host";
import * as ctsError from "../constants/ui/error";
import * as ctsSuccess from "../constants/ui/success";
import { addStaffAPI } from "../apis/user";

// HOST LIST
function* fetchHostListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchHostListAPI, token, payload.shopId);

    const { data, current_page, last_page, total } = res.data;

    yield put(
      act.fetchHostListSuccess({
        data: data,
        shopId: payload.shopId,
        currentPage: current_page,
        totalPage: last_page,
        total,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsError.FETCH_HOST_LIST_FAILED);
  }
}

function* fetchHostForOrderListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchHostForOrderListAPI, token, payload.shopId);
    yield put(
      act.fetchHostForOrderListSuccess({
        data: res.data?.data?.data || res.data?.data || [],
        shopId: payload.shopId,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsError.FETCH_HOST_LIST_FAILED);
  }
}
function* updateHostForOrderListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading("update-host"));
  try {
    const res = yield call(apis.updateHostForOrderListAPI, token, payload.id, payload.params);
    if (res.data.error?.length > 0) {
      failedToast({}, res.data.error[0]);
    } else {
      yield put(act.updateHostForOrderListSuccess(res.data));
      successToast(ctsSuccess.UPDATE_ORDER_LIST_SUCCESS);
    }
  } catch (error) {
    failedToast(error, ctsError.UPDATE_ORDER_LIST_FAILED);
  }
  yield put(hideLoading());
}

// HOST RECEIVABLES
function* fetchHostReceivablesListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchHostReceivablesListAPI, token, payload.params);
    const { data, current_page, last_page } = res.data;
    yield put(
      act.fetchHostReceivablesListSuccess({
        data: data,
        currentPage: current_page,
        totalPage: last_page,
        hostId: payload.params.host_id,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsError.FETCH_RECEIVABLES_LIST_FAILED);
  }
}

function* createHostReceivablesSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    const res = yield call(apis.createHostReceivablesAPI, token, payload.params);
    res.data.customer = payload.params.customer;
    yield put(act.createHostReceivablesSuccess(res.data));
    successToast(ctsSuccess.CREATE_HOST_RECEIVABLES_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsError.CREATE_RECEIVABLES_FAILED);
  }
}

function* updateHostReceivablesSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    const res = yield call(apis.updateHostReceivablesAPI, token, payload.id, payload.params);
    res.data.customer = payload.params.customer;
    yield put(act.updateHostReceivablesSuccess(res.data));
    successToast(ctsSuccess.UPDATE_HOST_RECEIVABLES_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsError.UPDATE_RECEIVABLES_FAILED);
  }
}

function* deleteHostReceivablesSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    yield call(apis.deleteHostReceivablesAPI, token, payload.params.id, payload.params.shop_id);
    yield put(act.deleteHostReceivablesSuccess({ id: payload.params.id }));
    successToast(ctsSuccess.DELETE_HOST_RECEIVABLES_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsError.DELETE_RECEIVABLES_FAILED);
  }
}

// HOST SALES //
function* fetchHostSalesSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchHostSalesAPI, token, payload.params);

    yield put(
      act.fetchHostSalesSuccess({
        data: res.data,
        hostId: payload.params.host_id,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsError.FETCH_HOST_SALES_FAILED);
  }
}

// HOST SALARY //
function* fetchHostSalarySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchHostSalaryAPI, token, payload.params);
    yield put(act.fetchHostSalarySuccess({ data: res.data, hostId: payload.params.host_id }));
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsError.FETCH_HOST_SALARY_FAILED);
  }
}

function* updateHostSalarySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    const res = yield call(apis.updateHostSalaryAPI, token, payload.params);

    yield put(act.updateHostSalarySuccess({ data: res.data, hostId: payload.params.host_id }));
    successToast(ctsSuccess.UPDATE_HOST_SALARY_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsError.UPDATE_HOST_SALARY_FAILED);
  }
}

// HOST OTHER MONEY //
function* fetchHostOtherMoneyListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchHostOtherMoneyListAPI, token, payload.params);
    const { data, current_page, last_page } = res.data;
    yield put(
      act.fetchHostOtherMoneyListSuccess({
        data: data,
        currentPage: current_page,
        totalPage: last_page,
        hostId: payload.params.host_id,
      })
    );
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, ctsError.FETCH_OTHER_MONEY_LIST_FAILED);
  }
}

function* createHostOtherMoneySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    const res = yield call(apis.createHostOtherMoneyAPI, token, payload.params);
    res.data.customer = payload.params.customer;
    yield put(act.createHostOtherMoneySuccess(res.data));
    successToast(ctsSuccess.CREATE_HOST_OTHER_MONEY_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsError.CREATE_OTHER_MONEY_FAILED);
  }
}

function* updateHostOtherMoneySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    const res = yield call(apis.updateHostOtherMoneyAPI, token, payload.id, payload.params);
    res.data.customer = payload.params.customer;
    yield put(act.updateHostOtherMoneySuccess(res.data));
    successToast(ctsSuccess.UPDATE_HOST_OTHER_MONEY_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsError.UPDATE_OTHER_MONEY_FAILED);
  }
}

function* deleteHostOtherMoneySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    yield call(apis.deleteHostOtherMoneyAPI, token, payload.params.id, payload.params.shop_id);

    yield put(act.deleteHostOtherMoneySuccess({ id: payload.params.id }));
    successToast(ctsSuccess.DELETE_HOST_OTHER_MONEY_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsError.DELETE_OTHER_MONEY_FAILED);
  }
}

function* updateSettingNominationHostSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    const res = yield call(apis.updateSettingNominationHostAPI, token, payload.params);
    yield put(act.updateSettingNominationHostSuccess(res.data));
    successToast(ctsSuccess.DELETE_HOST_OTHER_MONEY_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsError.DELETE_OTHER_MONEY_FAILED);
  }
}

export default function* hostWatcher() {
  yield takeEvery(cts.FETCH_HOST_LIST, fetchHostListSaga);
  yield takeEvery(cts.FETCH_HOST_FOR_ORDER_LIST, fetchHostForOrderListSaga);
  yield takeEvery(cts.UPDATE_HOST_FOR_ORDER_LIST, updateHostForOrderListSaga);
  yield takeEvery(cts.FETCH_HOST_RECEIVABLES_LIST, fetchHostReceivablesListSaga);
  yield takeLatest(cts.CREATE_HOST_RECEIVABLES, createHostReceivablesSaga);
  yield takeLatest(cts.UPDATE_HOST_RECEIVABLES, updateHostReceivablesSaga);
  yield takeLatest(cts.DELETE_HOST_RECEIVABLES, deleteHostReceivablesSaga);
  yield takeLatest(cts.UPDATE_SETTINGS_NOMINATION_HOST, updateSettingNominationHostSaga);
  yield takeEvery(cts.FETCH_HOST_SALES, fetchHostSalesSaga);

  yield takeEvery(cts.FETCH_HOST_SALARY, fetchHostSalarySaga);
  yield takeLatest(cts.UPDATE_HOST_SALARY, updateHostSalarySaga);

  yield takeEvery(cts.FETCH_HOST_OTHER_MONEY_LIST, fetchHostOtherMoneyListSaga);
  yield takeLatest(cts.CREATE_HOST_OTHER_MONEY, createHostOtherMoneySaga);
  yield takeLatest(cts.UPDATE_HOST_OTHER_MONEY, updateHostOtherMoneySaga);
  yield takeLatest(cts.DELETE_HOST_OTHER_MONEY, deleteHostOtherMoneySaga);
}
