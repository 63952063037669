import * as cts from "../constants/events/set-menu";

export const fetchSetMenu = (params) => ({
  type: cts.FETCH_SET_MENU,
  payload: { params },
});

export const fetchSetMenuSuccess = (data) => ({
  type: cts.FETCH_SET_MENU_SUCCESS,
  payload: { data },
});

export const createSetMenu = (params) => ({
  type: cts.CREATE_SET_MENU,
  payload: { params },
});

export const createSetMenuSuccess = (data) => ({
  type: cts.CREATE_SET_MENU_SUCCESS,
  payload: { data },
});

export const deleteSetMenu = (id) => ({
  type: cts.DELETE_SET_MENU,
  payload: { id },
});

export const deleteSetMenuSuccess = (data) => ({
  type: cts.DELETE_SET_MENU_SUCCESS,
  payload: { data },
});

// export const fetchCustomerType = (params) => ({
//   type: cts.FETCH_CUSTOMER_TYPE,
//   payload: { params },
// });

// export const fetchCustomerTypeSuccess = (data) => ({
//   type: cts.FETCH_CUSTOMER_TYPE_SUCCESS,
//   payload: { data },
// });

// export const createCustomerType = (params) => ({
//   type: cts.CREATE_CUSTOMER_TYPE,
//   payload: { params },
// });

// export const createCustomerTypeSuccess = (data) => ({
//   type: cts.CREATE_CUSTOMER_TYPE_SUCCESS,
//   payload: { data },
// });

// export const updateCustomerType = (id, params) => ({
//   type: cts.UPDATE_CUSTOMER_TYPE,
//   payload: { id, params },
// });

// export const updateCustomerTypeSuccess = (data) => ({
//   type: cts.UPDATE_CUSTOMER_TYPE_SUCCESS,
//   payload: { data },
// });

// export const deleteCustomerType = (id) => ({
//   type: cts.DELETE_CUSTOMER_TYPE,
//   payload: { id },
// });

// export const deleteCustomerTypeSuccess = (data) => ({
//   type: cts.DELETE_CUSTOMER_TYPE_SUCCESS,
//   payload: { data },
// });

// export const fetchSetMenu = (params) => ({
//   type: cts.FETCH_SET_MENU,
//   payload: { params },
// });

// export const fetchSetMenuSuccess = (data) => ({
//   type: cts.FETCH_SET_MENU_SUCCESS,
//   payload: { data },
// });

// export const createSetMenu = (params) => ({
//   type: cts.CREATE_SET_MENU,
//   payload: { params },
// });

// export const createSetMenuSuccess = (data) => ({
//   type: cts.CREATE_SET_MENU_SUCCESS,
//   payload: { data },
// });

// export const updateSetMenu = (id, params) => ({
//   type: cts.UPDATE_SET_MENU,
//   payload: { id, params },
// });

// export const updateSetMenuSuccess = (data) => ({
//   type: cts.UPDATE_SET_MENU_SUCCESS,
//   payload: { data },
// });
