/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import { call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { hideFetchLoading, hideLoading, showFetchLoading, showLoading } from "../actions/general";
import * as act from "../actions/products";
import * as apis from "../apis/products";
import * as cts from "../constants/events/products";
import * as errCts from "../constants/ui/error";
import * as success from "../constants/ui/success";
import { errorToast, failedToast, successToast, toast } from "../helpers/AlertHelper";
import * as socket from "../sockets";

function* fetchCategoryListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));

  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchCategoryListAPI, token, payload.shopId, payload.type_get);
    // const newData = res.data?.data?.sort((a, b) => a.orderby - b.orderby);

    yield put(act.fetchCategoryListSuccess({ data: res.data?.data, shopId: payload.shopId }));

    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, errCts.FETCH_CATEGORY_LIST_FAILED);
  }
}

function* createNewCategorySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));

  if (!payload.noLoading) {
    yield put(showLoading("create-category"));
  } else {
    yield put(showLoading());
  }

  try {
    yield put(showLoading());
    const res = yield call(apis.createCategoryAPI, token, payload.params);
    yield put(act.createNewCategorySuccess(res.data.data));
    socket.clientCreateCategory(res.data.data);
    if (!payload.params.notAlert) {
      successToast(success.CREATE_CATEGORY_MESSAGE);
    }

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.CREATE_CATEGORY_FAILED);
  }
}

function* createMultiCategorySaga({ payload }) {
  try {
    for (let [index, item] of payload.params.entries()) {
      yield call(createNewCategorySaga, { payload: { params: { ...item, notAlert: true } } });

      yield put(act.createMultiCategorySuccess(Math.ceil((index / payload.params.length) * 100)));
      yield delay(1000);
    }
    yield put(act.createMultiProductSuccess(100));
    yield put(act.createMultiProductSuccess(0));
    successToast(success.CREATE_MULTI_CATEGORY_MESSAGE);
  } catch (error) {
    failedToast(error, errCts.CREATE_PRODUCT_FAILED);
  }
}

function* updateCategorySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());
  try {
    yield put(showLoading());
    const res = yield call(apis.updateCategoryAPI, token, payload.id, payload.params);
    if (res.data) {
      yield put(act.updateCategorySuccess(res.data));
      socket.clientUpdateCategory(res.data);
      successToast(success.UPDATE_CATEGORY_MESSAGE);
    }

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.UPDATE_CATEGORY_FAILED);
  }
}

function* deleteCategorySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    yield put(showLoading());
    const res = yield call(apis.deleteCategoryAPI, token, payload.id);
    yield put(act.deleteCategorySuccess(res.data.data));
    socket.clientDeleteCategory(res.data.data);
    successToast(success.DELETE_CATEGORY_SUCCESS);

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.DELETE_CATEGORY_FAILED);
  }
}

function* fetchProductListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const { shopId, page, cid_category, keyword, sort, per_page = 18, type_get } = payload.params;

  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchProductListAPI, token, {
      shopId,
      page,
      cid_category: cid_category?.id || "",
      key: keyword,
      sort_value: sort.value === "default" ? "" : sort.type,
      sort_type: sort.value === "default" ? 0 : sort.orderBy,
      type_get: type_get ? type_get : undefined,
      per_page,
    });

    const { data, current_page, last_page, total } = res.data.data;
    // const newData = sort.value === "default" ? data.sort((a, b) => a.orderby - b.orderby) : data;
    yield put(
      act.fetchProductListSuccess({
        data,
        current_page,
        last_page,
        total,
        shopId: payload.params.shopId,
        isMerge: payload.params.isMerge,
      })
    );

    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, errCts.FETCH_PRODUCT_LIST_FAILED);
  }
}

function* createProductSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  const {
    code_input,
    name,
    bonus_money,
    category,
    inventory,
    price,
    imageFile,
    shop_id,
    isCheckInventory,
    inventoryToNotify,
    tipPercent,
    priceOption,
    options,
    notAlert,
  } = payload.params;

  const formDataFile = new FormData();

  formDataFile.append("code_input", code_input);
  formDataFile.append("name", name);
  formDataFile.append("price", parseInt(price, 10));
  formDataFile.append("bonus_money", parseInt(bonus_money, 10));
  formDataFile.append("stock", inventory ? parseInt(inventory, 10) : 0);
  formDataFile.append("cid_category", category);
  formDataFile.append("shopId", shop_id);
  formDataFile.append("image", imageFile);
  formDataFile.append("active_stock", isCheckInventory ? 1 : 0); // 0: inactive, 1: active
  formDataFile.append("quantity_notification", inventoryToNotify || 0);
  formDataFile.append("tip_percent", tipPercent || 0);
  formDataFile.append("price_option", priceOption ? 1 : 0);
  formDataFile.append("product_options", JSON.stringify(options));
  try {
    const res = yield call(apis.createProductAPI, token, formDataFile);
    console.log("res saga", res);
    if (res.data?.data?.id) {
      console.log("pass");
      yield put(act.createProductSuccess(res.data.data));
      if (!notAlert) {
        successToast(success.CREATE_PRODUCT_MESSAGE);
      }
    } else if (
      (res.data?.length &&
        res.data[1] === "The code input has already been taken." &&
        res.data[0] === "The name field is required.") ||
      res.data[0] === "The code input has already been taken."
    ) {
      console.log("fail");
      toast(errCts.NAME_ALREADY_EXIST, "warning");
    }
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.CREATE_PRODUCT_FAILED);
  }
}

function* createMultiProductSaga({ payload }) {
  try {
    for (let [index, item] of payload.params.entries()) {
      yield call(createProductSaga, { payload: { params: { ...item, notAlert: true } } });

      yield put(act.createMultiProductSuccess(Math.ceil((index / payload.params.length) * 100)));
      yield delay(500);
    }
    yield put(act.createMultiProductSuccess(100));
    yield put(act.createMultiProductSuccess(0));
    successToast(success.CREATE_MULTI_PRODUCT_MESSAGE);
  } catch (error) {
    failedToast(error, errCts.CREATE_MULTI_PRODUCT_FAILED);
  }
}

function* updateProductSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  const {
    code_input,
    name,
    category,
    bonus_money,
    inventory,
    price,
    imageFile,
    shop_id,
    isCheckInventory,
    tipPercent,
    inventoryToNotify,
    priceOption,
    options,
    status,
  } = payload.params;

  const formDataFile = new FormData();

  formDataFile.append("_method", "PUT");
  formDataFile.append("code_input", code_input);
  formDataFile.append("name", name);
  formDataFile.append("price", parseInt(price, 10));
  formDataFile.append("bonus_money", parseInt(bonus_money, 10));
  formDataFile.append("stock", inventory ? parseInt(inventory, 10) : 0);
  formDataFile.append("cid_category", category);
  formDataFile.append("shopId", shop_id);
  formDataFile.append("image", imageFile);
  formDataFile.append("active_stock", isCheckInventory ? 1 : 0); // 0:inactive, 1: active
  formDataFile.append("quantity_notification", inventoryToNotify || 0);
  formDataFile.append("tip_percent", tipPercent || 0);
  formDataFile.append("price_option", priceOption ? 1 : 0);
  formDataFile.append("product_options", JSON.stringify(options));
  formDataFile.append("status", status);

  try {
    const res = yield call(apis.updateProductAPI, token, payload.id, formDataFile);
    yield put(act.updateProductSuccess(res.data.data));
    if (!payload.params.notAlert) {
      successToast(success.UPDATE_PRODUCT_MESSAGE);
    }
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.UPDATE_PRODUCT_FAILED);
  }
}

function* deleteProductSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    let res;
    if (payload.isDeleteImage) {
      res = yield call(apis.deleteProductAPI, token, payload.id, { name_image: payload.params });
    } else {
      res = yield call(apis.deleteProductAPI, token, payload.id);
    }
    // const res = yield call(apis.deleteProductAPI, token, payload.id);
    yield put(act.deleteProductSuccess(res.data.data, payload.isDeleteImage ? true : false));
    // yield put(act.deleteProductSuccess(res.data.data));
    if (payload.isDeleteImage) {
      successToast(success.DELETE_PRODUCT_IMAGE_SUCCESS);
    } else {
      successToast(success.DELETE_PRODUCT_MESSAGE);
    }
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    if (payload.isDeleteImage) {
      failedToast(error, errCts.DELETE_PRODUCT_IMAGE_FAILED);
    } else {
      failedToast(error, errCts.DELETE_PRODUCT_FAILED);
    }
  }
}

function* updateOrderCategorySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const selectedShop = yield select((state) => state.getIn(["user", "selectedShop"]).toJS());

  try {
    yield put(act.updateOrderCategorySuccess(payload.params.localData));
    yield call(apis.updateCategoryAPI, token, 1, {
      is_orderby: payload.params.is_orderby,
      shopId: selectedShop.id,
    });
    socket.clientUpdateOrderCategory({ order: payload.params.localData, shop_id: selectedShop.id });
  } catch (error) {
    failedToast(error, errCts.UPDATE_CATEGORY_FAILED);
  }
}

function* updateOrderProductSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const selectedShop = yield select((state) => state.getIn(["user", "selectedShop"]).toJS());
  try {
    yield put(act.updateOrderProductSuccess(payload.params.data));
    yield call(
      apis.updateProductAPI,
      token,
      1,
      {
        orderby: payload.params.is_orderby,
        shopId: selectedShop.id,
      },
      "no-form-data"
    );
  } catch (error) {
    failedToast(error, errCts.UPDATE_PRODUCT_FAILED);
  }
}

export default function* userWatcher() {
  yield takeEvery(cts.FETCH_CATEGORY_LIST, fetchCategoryListSaga);
  yield takeLatest(cts.CREATE_NEW_CATEGORY, createNewCategorySaga);
  yield takeLatest(cts.UPDATE_CATEGORY, updateCategorySaga);
  yield takeLatest(cts.DELETE_CATEGORY, deleteCategorySaga);
  yield takeLatest(cts.UPDATE_ORDER_CATEGORY, updateOrderCategorySaga);
  yield takeLatest(cts.UPDATE_ORDER_PRODUCT, updateOrderProductSaga);
  yield takeEvery(cts.FETCH_PRODUCT_LIST, fetchProductListSaga);
  yield takeLatest(cts.CREATE_PRODUCT, createProductSaga);
  yield takeLatest(cts.UPDATE_PRODUCT, updateProductSaga);
  yield takeLatest(cts.DELETE_PRODUCT, deleteProductSaga);
  yield takeEvery(cts.CREATE_MULTI_PRODUCT, createMultiProductSaga);
  yield takeEvery(cts.CREATE_MULTI_CATEGORY, createMultiCategorySaga);
}
