/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as cts from "../constants/ui/general";
import { calendarBuilder, formatMoney } from "../helpers/functionHelpers";

export default function Calendar(props) {
  const { value, setValue, eventData, dateClick, eventClick, showHeader, showEvent, onDateChange } =
    props;
  const history = useHistory();
  // const [value, setValue] = useState(moment());
  const [calendar, setCalendar] = useState([]);
  const dayNames = [
    cts.SUNDAY,
    cts.MONDAY,
    cts.TUESDAY,
    cts.WEDNESDAY,
    cts.THURSDAY,
    cts.FRIDAY,
    cts.SATURDAY,
  ];

  useEffect(() => {
    setCalendar(calendarBuilder(value));
  }, [value]);

  const clickEvent = (date) => {
    history.push({
      pathname: "/daily-report",
      search: `date=${moment(date).format("YYYY-MM-DD")}`,
    });
  };

  const dayStyles = (date) => {
    const day = "day";
    if (date.isSame(value, "day")) return `${day} today `;

    if (date.isSame(value, "day") && date.isBefore(value, "month")) {
      return `${day} today day-of-prev-month`;
    }
    if (date.isBefore(value, "month")) {
      return `${day} day-of-prev-month`;
    }
    if (date.isAfter(value, "month")) {
      return `${day} day-of-next-month`;
    }
    return day;
  };

  const _renderEvents = (date) => {
    if (eventData.length > 0) {
      return eventData.map((events, idx) => {
        if (moment(events.date).isSame(date, "day")) {
          return (
            <div
              className="events-wrapper"
              key={idx.toString()}
              //   onClick={() =>
              //     history.push({
              //       pathname: "/daily-report/detail",
              //       search: `date=${moment(date).format("YYYY-MM-DD")}`,
              //     })
              //   }
            >
              <h5 className="events event-1">
                {cts.YEN}
                {formatMoney(events.sales || 0)}
              </h5>
              <h5 className="events event-2">
                {events.number_customer || 0} {cts.PEOPLE}
              </h5>
              {showEvent ? (
                <h5 className="events event-3">
                  {cts.YEN}
                  {formatMoney(events.target || 0)}
                </h5>
              ) : null}
            </div>
          );
        }
      });
    }
  };

  return (
    <div className="calendar">
      {showHeader ? <div className="calendar__header">{value.format("YYYY/MM/DD")}</div> : null}
      <div className="calendar__content">
        <div className="week-header">
          {dayNames.map((d, index) => (
            <div className="week-name" key={index.toString()}>
              {d}
            </div>
          ))}
        </div>
        {calendar.map((week, index) => (
          <div className="day-row" key={index.toString()}>
            {week.map((date, i) => (
              <div
                key={i.toString()}
                className={dayStyles(date)}
                // onClick={dateClick ? clickEvent(date) : null}
              >
                <div
                  onClick={() => {
                    history.push({
                      pathname: "/daily-report",
                      search: `date=${moment(date).format("YYYY-MM-DD")}`,
                    });
                  }}
                >
                  {date.format("D").toString()}
                </div>
                {_renderEvents(date)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
