export const DELETE_ADS = "DELETE_ADS";
export const DELETE_ADS_SUCCESS = "DELETE_ADS";

//Custome Ads
export const FETCH_ADS_CUSTOMER = "FETCH_ADS_CUSTOMER";
export const FETCH_ADS_CUSTOMER_SUCCESS = "FETCH_ADS_CUSTOMER_SUCCESS";

//Introduction ads
export const FETCH_ADS_INTRODUCTION = "FETCH_ADS_INTRODUCTION";
export const FETCH_ADS_INTRODUCTION_SUCCESS = "FETCH_ADS_INTRODUCTION_SUCCESS";
