import { fromJS } from "immutable";
import { createReducer } from "../helpers/createReducer";
import * as cts from "../constants/events/settings";
import { SET_SELECTED_SHOP } from "../constants/events/user";
// import * as errCts from "../constants/ui/error";

const initialState = fromJS({
  tableList: {
    data: [],
    shopId: "",
  },
  taxSettings: {
    tax: 0,
    shopId: null,
  },
  notificationSettings: {
    data: {},
    shopId: null,
  },
  notificationSettingsList: {
    data: [],
    shopId: null,
  },
  openSettings: null,
  shopSettings: {
    data: {},
    shopId: null,
  },
  printSetting: {},
});

const setSelectedShop = (state) => {
  return state.set("tableList", fromJS({ data: [], shopId: "" }));
};

const fetchTableListSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("tableList", fromJS(data));
};

const createNewTableSuccess = (state, action) => {
  const { data } = action.payload;
  const tables = state.getIn(["tableList", "data"]).toJS();

  let newTable = null;

  if (data?.length > 0) {
    newTable = [...data.reverse(), ...tables];
  } else {
    newTable = [data].concat(tables);
  }

  return state.setIn(["tableList", "data"], fromJS(newTable));
};

const updateTableSuccess = (state, action) => {
  // const { data } = action.payload;
  // if (data.is_orderby) {
  //   return state.setIn(["tableList", "data"], fromJS(data.is_orderby));
  // }
  // const tables = state.getIn(["tableList", "data"]).toJS();

  // if (data?.tables?.length > 0) {
  //   data.tables.map((item) => {
  //     const index = tables.findIndex((i) => i.id === item.id);
  //     tables[index] = item;
  //   });
  // } else {
  //   const index = tables.findIndex((i) => i.id === data.id);
  //   tables[index] = data;
  // }

  // return state.setIn(["tableList", "data"], fromJS(tables));
  const { data } = action.payload;
  if (data.localData) {
    return state.setIn(["tableList", "data"], fromJS(data.localData));
  } else {
    const tables = state.getIn(["tableList", "data"]).toJS();

    const index = tables.findIndex((i) => i.id === data.id);
    tables[index] = data;

    return state.setIn(["tableList", "data"], fromJS(tables));
  }
};

const deleteTableSuccess = (state, action) => {
  const { data } = action.payload;
  const tables = state.getIn(["tableList", "data"]).toJS();

  const newTables = tables.filter((i) => i.id !== data.id);

  return state.setIn(["tableList", "data"], fromJS(newTables));
};

// TAX
const getTaxSuccess = (state, action) => {
  const { data } = action.payload;

  return state
    .setIn(["taxSettings", "tax"], fromJS(data.tax))
    .setIn(["taxSettings", "shopId"], fromJS(data.shopId));
};

const updateTaxSuccess = (state, action) => {
  const { data } = action.payload;

  return state
    .setIn(["taxSettings", "tax"], fromJS(data.tax))
    .setIn(["taxSettings", "shopId"], fromJS(data.shopId));
};

// NOTIFICATION
const getNotificationSuccess = (state, action) => {
  const { data } = action.payload;
  if (data.userId) {
    return state.set("notificationSettings", fromJS(data));
  }
  return state.set("notificationSettingsList", fromJS(data));
};

const updateNotificationSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("notificationSettings", fromJS(data));
};

const getShopSettingsSuccess = (state, action) => {
  const { data } = action.payload;
  return state.set("shopSettings", fromJS(data));
};

const updateShopSettingsSuccess = (state, action) => {
  const { data } = action.payload;
  const shopSettings = state.getIn(["shopSettings", "data"]).toJS();
  // return state.setIn(["shopSettings", "data"], fromJS({ ...shopSettings, ...data }));
  return state.setIn(["shopSettings", "data"], fromJS(data));
};

// reducer
const reducer = {
  [SET_SELECTED_SHOP]: setSelectedShop,
  [cts.FETCH_TABLE_LIST_SUCCESS]: fetchTableListSuccess,
  [cts.CREATE_NEW_TABLE_SUCCESS]: createNewTableSuccess,
  [cts.UPDATE_TABLE_SUCCESS]: updateTableSuccess,
  [cts.DELETE_TABLE_SUCCESS]: deleteTableSuccess,
  [cts.GET_TAX_SUCCESS]: getTaxSuccess,
  [cts.UPDATE_TAX_SUCCESS]: updateTaxSuccess,
  [cts.GET_NOTIFICATION_SUCCESS]: getNotificationSuccess,
  [cts.UPDATE_NOTIFICATION_SUCCESS]: updateNotificationSuccess,
  [cts.GET_SHOP_SETTINGS_SUCCESS]: getShopSettingsSuccess,
  [cts.UPDATE_SHOP_SETTINGS_SUCCESS]: updateShopSettingsSuccess,
  __default__: (state) => state,
};

export default createReducer(reducer, initialState);
