import request from "../helpers/axiosService";

// const URL_CUSTOMER = "api/customers";
// const URL_CUSTOMER_FULL_INFO = "api/customers-list";
// const URL_LOG = "api/logs";
// const URL_SALARY = "api/salary-settings";
// const URL_CUSTOMER_DETAIL = "api/customers-list-detail";
// const URL_REPORT = "api/report/date";
const URL_DEBIT_MONTH = "/debt-payment-month";
const URL_DEBIT_DAY = "/debt-payment";
const URL_CUSTOMER = "customers";
const URL_CUSTOMER_FULL_INFO = "v2/customers-list";
const URL_LOG = "logs";
const URL_SALARY = "salary-settings";
const URL_CUSTOMER_DETAIL = "v2/customers-list-detail";
const URL_GET_REPORT_MONTH = "v2/report/month";
const URL_GET_REPORT_DAY = "v2/report/date";
const URL_SALARY_NOMINATION = "salary-setting-nominations";
const URL_HOST_SALARY_INFO = "salary-calculate-list";
const URL_GET_CUSTOMER_BY_HOST = "/customer-by-host";
const URL_GET_CUSTOMER_RECEIVABLES = "/sum-receivable";

//sum-receivable
export function fetchCustomerReceivablesAPI(token, params) {
  return request({
    url: URL_GET_CUSTOMER_RECEIVABLES,
    method: "get",
    params,
    token,
  });
}

//DEDBIT
export function fetchDebitMonthAPI(token, params) {
  return request({
    url: URL_DEBIT_MONTH,
    method: "get",
    params,
    token,
  });
}
export function fetchDebitDayAPI(token, params) {
  return request({
    url: URL_DEBIT_DAY,
    method: "get",
    params,
    token,
  });
}
export function createDebitAPI(token, params) {
  return request({
    url: URL_DEBIT_DAY,
    method: "post",
    params,
    token,
  });
}
// CUSTOMER
export function fetchCustomerListAPI(token, params) {
  return request({
    url: URL_CUSTOMER_FULL_INFO,
    method: "get",
    // params: { shop_id, per_page: 1000 },
    params,
    token,
  });
}

export function fetchCustomerByHostAPI(token, params) {
  return request({
    url: URL_GET_CUSTOMER_BY_HOST,
    method: "get",
    token,
    params,
  });
}

export function fetchCustomerForOrderListAPI(token, params) {
  return request({
    url: URL_CUSTOMER,
    method: "get",
    // params: { shopId, per_page: 1000 },
    params,
    token,
  });
}

export function fetchCustomerDetailAPI(token, params) {
  return request({
    url: URL_CUSTOMER_DETAIL,
    method: "get",
    params: { ...params },
    token,
  });
}

export function createCustomerAPI(token, data) {
  return request({
    url: URL_CUSTOMER,
    method: "post",
    data,
    token,
  });
}

export function updateCustomerAPI(token, id, data) {
  return request({
    url: `${URL_CUSTOMER}/${id}`,
    method: "put",
    data,
    token,
  });
}

export function deleteCustomerAPI(token, id) {
  return request({
    url: `${URL_CUSTOMER}/${id}`,
    method: "delete",
    token,
  });
}

// LOG
export function fetchLogsAPI(token, params) {
  return request({
    url: URL_LOG,
    method: "get",
    params: { ...params, check_month: 1 },
    token,
  });
}

// SALARY
export function fetchSalaryListAPI(token, shop_id) {
  return request({
    url: URL_SALARY,
    method: "get",
    params: { shop_id, per_page: 200 },
    token,
  });
}

export function createSalaryAPI(token, data) {
  return request({
    url: URL_SALARY,
    method: "post",
    data,
    token,
  });
}

export function updateSalaryAPI(token, id, data) {
  return request({
    url: `${URL_SALARY}/${id}`,
    method: "put",
    data,
    token,
  });
}

export function deleteSalaryAPI(token, { id, shop_id }) {
  return request({
    url: `${URL_SALARY}/${id}`,
    method: "delete",
    params: { shop_id },
    token,
  });
}

export function fetchSalaryNominationAPI(token, shop_id) {
  return request({
    url: URL_SALARY_NOMINATION,
    method: "get",
    params: { shop_id },
    token,
  });
}

export function updateSalaryNominationAPI(token, data) {
  return request({
    url: URL_SALARY_NOMINATION,
    method: "post",
    data,
    token,
  });
}

export function fetchHostSalaryInfo(token, params) {
  return request({
    url: URL_HOST_SALARY_INFO,
    method: "get",
    params,
    token,
  });
}

// REPORT
export function fetchReportMonthAPI(token, params) {
  return request({
    url: URL_GET_REPORT_MONTH,
    method: "get",
    params,
    token,
  });
}

export function fetchReportDayAPI(token, params) {
  return request({
    url: URL_GET_REPORT_DAY,
    method: "get",
    params,
    token,
  });
}
