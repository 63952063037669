import * as cts from "../constants/events/other";

// CUSTOMER
export const fetchCustomerList = (params) => ({
  type: cts.FETCH_CUSTOMER_LIST,
  payload: { params },
});

export const fetchCustomerListSuccess = (data) => ({
  type: cts.FETCH_CUSTOMER_LIST_SUCCESS,
  payload: { data },
});

export const fetchCustomerForOrderList = (params) => ({
  type: cts.FETCH_CUSTOMER_FOR_ORDER_LIST,
  payload: { params },
});

export const fetchCustomerForOrderListSuccess = (data) => ({
  type: cts.FETCH_CUSTOMER_FOR_ORDER_LIST_SUCCESS,
  payload: { data },
});

export const fetchCustomerDetail = (params) => ({
  type: cts.FETCH_CUSTOMER_DETAIL,
  payload: { params },
});

export const fetchCustomerDetailSuccess = (data) => ({
  type: cts.FETCH_CUSTOMER_DETAIL_SUCCESS,
  payload: { data },
});

export const createCustomer = (params) => ({
  type: cts.CREATE_CUSTOMER,
  payload: { params },
});

export const createCustomerSuccess = (data) => ({
  type: cts.CREATE_CUSTOMER_SUCCESS,
  payload: { data },
});

export const updateCustomer = (id, params) => ({
  type: cts.UPDATE_CUSTOMER,
  payload: { id, params },
});

export const updateCustomerSuccess = (data) => ({
  type: cts.UPDATE_CUSTOMER_SUCCESS,
  payload: { data },
});

export const deleteCustomer = (id) => ({
  type: cts.DELETE_CUSTOMER,
  payload: { id },
});

export const deleteCustomerSuccess = (data) => ({
  type: cts.DELETE_CUSTOMER_SUCCESS,
  payload: { data },
});

// LOG
export const fetchLogs = (params) => ({
  type: cts.FETCH_LOGS,
  payload: { params },
});

export const fetchLogsSuccess = (data) => ({
  type: cts.FETCH_LOGS_SUCCESS,
  payload: { data },
});

// SALARY
export const fetchSalaryList = (shopId) => ({
  type: cts.FETCH_SALARY_LIST,
  payload: { shopId },
});

export const fetchSalaryListSuccess = (data) => ({
  type: cts.FETCH_SALARY_LIST_SUCCESS,
  payload: { data },
});

export const createSalary = (params) => ({
  type: cts.CREATE_SALARY,
  payload: { params },
});

export const createSalarySuccess = (data) => ({
  type: cts.CREATE_SALARY_SUCCESS,
  payload: { data },
});

export const updateSalary = (id, params) => ({
  type: cts.UPDATE_SALARY,
  payload: { id, params },
});

export const updateSalarySuccess = (data) => ({
  type: cts.UPDATE_SALARY_SUCCESS,
  payload: { data },
});

export const deleteSalary = (params) => ({
  type: cts.DELETE_SALARY,
  payload: { params },
});

export const deleteSalarySuccess = (data) => ({
  type: cts.DELETE_SALARY_SUCCESS,
  payload: { data },
});

export const fetchSalaryNomination = (params) => ({
  type: cts.FETCH_SALARY_NOMINATION,
  payload: { params },
});

export const fetchSalaryNominationSuccess = (data) => ({
  type: cts.FETCH_SALARY_NOMINATION_SUCCESS,
  payload: { data },
});

export const updateSalaryNomination = (params) => ({
  type: cts.UPDATE_SALARY_NOMINATION,
  payload: { params },
});

export const updateSalaryNominationSuccess = (data) => ({
  type: cts.UPDATE_SALARY_NOMINATION_SUCCESS,
  payload: { data },
});

// REPORT
export const fetchReport = (params) => ({
  type: cts.FETCH_REPORT,
  payload: { params },
});

export const fetchReportSuccess = (data) => ({
  type: cts.FETCH_REPORT_SUCCESS,
  payload: { data },
});

export const fetchReportInSidebar = (params) => ({
  type: cts.FETCH_REPORT_IN_SIDEBAR,
  payload: { params },
});

export const fetchReportInSidebarSuccess = (data) => ({
  type: cts.FETCH_REPORT_IN_SIDEBAR_SUCCESS,
  payload: { data },
});

export const fetchReportInSidebarFromSocket = (sales) => ({
  type: cts.FETCH_REPORT_IN_SIDEBAR_FROM_SOCKET,
  payload: { sales },
});

export const fetchReportInSidebarFromLocal = (sales) => ({
  type: cts.FETCH_REPORT_IN_SIDEBAR_FROM_LOCAL,
  payload: { sales },
});
