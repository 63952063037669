import React from "react";
import TextInput from "../../../helpers/TextInput";
import { useState } from "react";
import { activeCodeAPI } from "../../../apis/user";
import { failedToast } from "../../../helpers/AlertHelper";
import { Spin } from "antd";
import * as cts from "../../../constants/ui/general";
import Loading from "../../../components/Loading";
const Step1 = (props) => {
  const { setSteps, setEmail, email, setLoading, loading } = props;
  var validRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const sendEmail = async () => {
    if (email) {
      if (email.match(validRegex)) {
        setLoading(true);
        try {
          const res = await activeCodeAPI({ email });
          if (res.data.message == "error") {
            failedToast(null, "Invalid Email");
          }
          if (res.data.message == "success") {
            setSteps(1);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);

          failedToast(error, "登録に失敗しました。");
        }
      } else {
        failedToast("error", "メールアドレスのフォーマットを間違います");
      }
    } else {
      failedToast("error", "メールを入力してください");
    }
  };
  return (
    <div className="bg-button_register mt-30">
      <div className="bg-content_register">
        <div className="mb-30">
          <h5>下記にメールアドレスを入力してください。</h5>
          <h5> メールアドレスは、後からでも変更可能です。</h5>
        </div>
        <span style={{ color: "#000000", fontFamily: "NotoSans", fontSize: "16px" }}>
          {cts.EMAIL_ONLY}
        </span>
        <input
          style={{ fontSize: "16px" }}
          type="text"
          placeholder="account@gmail.com"
          className="input-register mt-15"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <button type="button" className="forgot mt-25" onClick={sendEmail}>
          {loading ? <Loading style={{ color: "white" }} /> : " 上記に同意して次へ"}
        </button>
      </div>
    </div>
  );
};
export default Step1;
