/* eslint-disable object-shorthand */
/* eslint-disable indent */
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { hideFetchLoading, hideLoading, showFetchLoading, showLoading } from "../actions/general";
import * as act from "../actions/settings";
import * as apis from "../apis/settings";
import * as cts from "../constants/events/settings";
import * as errCts from "../constants/ui/error";
import * as success from "../constants/ui/success";
import { failedToast, successToast } from "../helpers/AlertHelper";
import * as socket from "../sockets";

// TABLE
function* fetchTableListSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));

  yield put(showFetchLoading());

  try {
    const res = yield call(apis.fetchTableListAPI, token, payload.shopId, payload.type_get);

    yield put(act.fetchTableListSuccess({ data: res.data.data, shopId: payload.shopId }));

    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, errCts.FETCH_TABLE_LIST_FAILED);
  }
}

function* createNewTableSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    yield put(showLoading());
    const res = yield call(apis.createNewTableAPI, token, payload.params);
    yield put(act.createNewTableSuccess(res.data.data));
    socket.clientCreateTable(res.data.data);
    successToast(success.CREATE_TABLE_MESSAGE);

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.CREATE_TABLE_FAILED);
  }
}

function* updateTableSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  if (!payload.params.localData) yield put(showLoading());
  try {
    if (payload.params.localData) {
      yield put(act.updateTableSuccess(payload.params));
      socket.clientUpdateTable(payload.params);
    }
    if (!payload.params.is_orderby) {
      yield call(apis.updateTableAPI, token, payload.id, payload.params);
      yield put(act.updateTableSuccess({ id: payload.id, ...payload.params }));
      socket.clientUpdateTable({ id: payload.id, ...payload.params });
      successToast(success.UPDATE_TABLE_MESSAGE);
    } else {
      yield call(apis.updateTableAPI, token, payload.id, {
        is_orderby: payload.params.is_orderby,
      });
      socket.clientUpdateTable(payload.params);
    }

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.UPDATE_TABLE_FAILED);
  }
}

function* deleteTableSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    const res = yield call(apis.deleteTableAPI, token, payload.id);

    yield put(act.deleteTableSuccess(res.data.data));
    socket.clientDeleteTable(res.data.data);
    successToast(success.DELETE_TABLE_MESSAGE);

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.DELETE_TABLE_FAILED);
  }
}

// TAX
function* getTaxSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  try {
    const res = yield call(apis.getTaxAPI, token, payload.shopId);
    const data = res.data.id ? parseInt(res.data.percent, 10) : 0;
    yield put(act.getTaxSuccess({ tax: data, shopId: payload.shopId }));
  } catch (error) {
    failedToast(error, errCts.GET_TAX_FAILED);
  }
}

function* updateTaxSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());
  try {
    const res = yield call(apis.updateTaxAPI, token, payload.params);
    yield put(
      act.updateTaxSuccess({ tax: parseInt(res.data.percent, 10), shopId: res.data.shop_id })
    );
    successToast(success.UPDATE_TAX_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.UPDATE_TAX_FAILED);
  }
}

// NOTIFICATION
function* getNotificationSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  try {
    const res = yield call(apis.getNotificationAPI, token, payload.shopId, payload.userId);

    if (payload.userId && !res.data.user_id) {
      res.data = {
        daily_report: 0,
        inventory_check: 0,
        last_song: 0,
        notification_price_limit: 0,
        price_limit: 0,
        notification_time: "[]",
        notification_time_check: 0,
      };
    }
    yield put(
      act.getNotificationSuccess({ data: res.data, shopId: payload.shopId, userId: payload.userId })
    );
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.GET_NOTIFICATION_FAILED);
  }
}

function* updateNotificationSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));

  yield put(showLoading());
  try {
    const res = yield call(apis.updateNotificationAPI, token, payload.params);
    yield put(act.updateNotificationSuccess({ data: res.data, shopId: payload.params.shop_id }));
    successToast(success.UPDATE_NOTIFICATION_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.UPDATE_NOTIFICATION_FAILED);
  }
}

// SHOP SETTINGS
function* getShopSettingsSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  const selectedShop = yield select((state) => state.getIn(["user", "selectedShop"]).toJS());

  yield put(showFetchLoading());
  try {
    const res = yield call(apis.getShopSettingsAPI, token, payload.params);
    const RESTAURANT = selectedShop?.career?.name === "RESTAURANT";
    const data = {
      ...res.data,
      nomination_fee: RESTAURANT ? 0 : res.data?.nomination_fee || 0,
      sub_nomination_fee: RESTAURANT ? 0 : res.data?.sub_nomination_fee || 0,
      seat_fee: RESTAURANT ? 0 : res.data?.seat_fee || 0,
      companion_fee: RESTAURANT ? 0 : res.data?.companion_fee || 0,
      tax: RESTAURANT ? 0 : res.data?.percent || 0,
      card_fee: res.data?.card_fee || 0,
      card_fee_type: res.data?.card_fee_type || "amount",
      calculation_tax_type: res.data?.calculation_tax_type || 1,
      show_main_menu: res.data?.show_main_menu || 0,
      show_set_menu: res.data?.show_set_menu || 0,
      show_shop_profit: res.data?.show_shop_profit,
      tax_type: res.data?.tax_type
        ? JSON.parse(res.data?.tax_type)
        : {
            nomination_fee: { vat: 0, tax: 0 },
            sub_nomination_fee: { vat: 0, tax: 0 },
            companion_fee: { vat: 0, tax: 0 },
            seat_fee: { vat: 0, tax: 0 },
          },
      tax_exclude: RESTAURANT ? res.data.tax_exclude : 0,
      option_rounding: RESTAURANT ? 0 : res.data.option_rounding,
    };
    yield put(act.getShopSettingsSuccess({ data: data, shopId: payload.params.shop_id }));
    yield put(hideFetchLoading());
  } catch (error) {
    yield put(hideFetchLoading());
    failedToast(error, errCts.FETCH_SHOP_SETTINGS_FAILED);
  }
}

function* updateShopSettingsSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());

  try {
    const res = yield call(apis.updateShopSettingsAPI, token, payload.params);
    res.data.tax_type = JSON.parse(res.data.tax_type);
    res.data.tax = res.data.percent;
    yield put(act.updateShopSettingsSuccess(res.data));
    socket.clientUpdateShopSettings(res.data);
    successToast(success.UPDATE_SHOP_SETTINGS_MESSAGE);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.UPDATE_SHOP_SETTINGS_FAILED);
  }
}

function* setShowCategorySaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading(payload.type));

  try {
    const res = yield call(apis.updateShopSettingsAPI, token, payload.params);
    yield put(act.updateShopSettingsSuccess(res.data));
    socket.clientUpdateShopSettings(res.data);
    successToast(success.UPDATE_SUCCESS);
    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, errCts.UPDATE_FAILED);
  }
}

export default function* userWatcher() {
  yield takeEvery(cts.FETCH_TABLE_LIST, fetchTableListSaga);
  yield takeLatest(cts.CREATE_NEW_TABLE, createNewTableSaga);
  yield takeLatest(cts.UPDATE_TABLE, updateTableSaga);
  yield takeLatest(cts.DELETE_TABLE, deleteTableSaga);
  yield takeEvery(cts.GET_TAX, getTaxSaga);
  yield takeLatest(cts.UPDATE_TAX, updateTaxSaga);
  yield takeEvery(cts.GET_NOTIFICATION, getNotificationSaga);
  yield takeLatest(cts.UPDATE_NOTIFICATION, updateNotificationSaga);
  yield takeEvery(cts.GET_SHOP_SETTINGS, getShopSettingsSaga);
  yield takeLatest(cts.UPDATE_SHOP_SETTINGS, updateShopSettingsSaga);
  yield takeLatest(cts.SET_SHOW_CATEGORY, setShowCategorySaga);
}
