import { put, takeEvery, call, select } from "redux-saga/effects";
import * as cts from "../constants/events/ranking";
import * as apis from "../apis/ranking";
import * as act from "../actions/ranking";
import * as ctsErr from "../constants/ui/error";
import { showLoading, hideLoading } from "../actions/general";
import { failedToast } from "../helpers/AlertHelper";

export function* fetchRankingSaga({ payload }) {
  const token = yield select((state) => state.getIn(["user", "token"]));
  yield put(showLoading());
  try {
    const res = yield call(apis.fetchRankingAPI, token, payload.params);

    yield put(
      act.fetchRankingSuccess({
        data: res.data,
        shopId: payload.params.shop_id,
      })
    );

    yield put(hideLoading());
  } catch (error) {
    yield put(hideLoading());
    failedToast(error, ctsErr.FETCH_RANKING_FAILED);
  }
}

export default function* rankingWatcher() {
  yield takeEvery(cts.FETCH_RANKING, fetchRankingSaga);
}
